import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";

import { showAlertMessage, hideAlertMessage } from "../../store/actions";
import CommentsBlock from "../molecules/CommentsBlock";
import ConfirmBlock from "./ConfirmBlock";

function PartialStockCheckModal({
  isOpen,
  toggle,
  confirmedStaffErrors,
  confirmBlocktitle,
  setConfirmedStaff,
  confirmInitialState,
  createStockCheck,
}) {
  const dispatch = useDispatch();

  const [comments, setComments] = useState();
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  useState(false);

  useEffect(() => {
    if (inputsConfirmed) {
      if (!comments) {
        showAlertMessage(dispatch, {
          message: "Please fill in the required Reason / Comments",
          colour: "warning",
        });
        setInputsConfirmed(false);
      } else {
        createStockCheck(comments);
      }
    }
  }, [inputsConfirmed]);

  function handleCommentsChange({ target: { value } }) {
    hideAlertMessage(dispatch);
    setComments(value);
  }

  return (
    <Modal
      centered
      className="DiscrepancyModal"
      fullscreen="lg"
      isOpen={isOpen}
      scrollable
      size="xl"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Partial Stock Check?</ModalHeader>
      <ModalBody>
        <CommentsBlock
          required
          comments={comments}
          setComments={handleCommentsChange}
        />
        <Col>
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            title={confirmBlocktitle}
            inputsConfirmed={inputsConfirmed}
            setInputsConfirmed={setInputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
            confirmInitialState={confirmInitialState}
          />
        </Col>
      </ModalBody>
    </Modal>
  );
}

export default PartialStockCheckModal;
