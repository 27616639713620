import axios from "axios";
import AuthService from "./AuthService";

const PatientService = {};

PatientService.getCancelSource = () => axios.CancelToken.source();
PatientService.isCancel = axios.isCancel;

PatientService.getAllPatient = async () => {
  return await AuthService.get("/Patient");
};

PatientService.getAllLocationPatient = async (locationId, config) => {
  return await AuthService.get(`/Patient/locationId/${locationId}`, config);
};

PatientService.getPatients = async (locationId, patient, config) => {
  return await AuthService.get(`/Patient/locationId/${locationId}/${encodeURIComponent(patient)}`, config);
};

PatientService.createNewPatient = async (patient, config) => {
  return await AuthService.post("/Patient", patient, config);
};

PatientService.getPatientById = async (patientId) => {
  return await AuthService.get(`/Patient/PatientId/${patientId}`);
};

PatientService.updatePatient = async (patient, config) => {
  return await AuthService.patch("/Patient", patient, config);
};

PatientService.getPatientByUniqueId = async (uniqueId) => {
  return await AuthService.get(`/Patient/UniqueIdentifier/${uniqueId}`);
};

export default PatientService;
