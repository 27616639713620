import React from "react";
import { Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as FourOFour } from "../assets/images/404.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll, faUserLock } from "@fortawesome/free-solid-svg-icons";

const PageMissing = () => {
  const history = useHistory();

  return (
    <Col className="PageMissing">
      <Row className="d-flex justify-content-center">
        <Col md="7" className="PageMissing-svgImage">
          <FourOFour />
          <div className="PageMissing-link">
            <h2 className="mb-5">
              <span>Oops, looks like</span>
              <span>this page doesn’t exist...</span>
            </h2>
            <Link className="shadow" to="/">
              <FontAwesomeIcon className="on-left" icon={faUserLock} />
              Go to Login
            </Link>
            <Link
              className="shadow"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
              to="/"
            >
              <FontAwesomeIcon className="on-left" icon={faReplyAll} />
              Back to where you came from{" "}
            </Link>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(PageMissing);
