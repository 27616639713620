import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";

import { initialStateSignedWitnessed } from "../../config/confirm";
import SystemContext from "../../context/SystemContext";
import OrdersService from "../../services/OrdersService";
import StockService from "../../services/StockService";
import { hideAlertMessage, showAlertMessage } from "../../store/actions";
import CommentsBlock from "../molecules/CommentsBlock";
import StockManagementTableLine from "../molecules/StockManagementTableLine";
import StockTableLine from "../molecules/StockTableLine";
import ConfirmBlock from "./ConfirmBlock";

let serviceCancelSource;
let serviceConfig;

function DiscrepancyModal({
  isOpen,
  onComplete,
  onError,
  stock,
  toggle,
  defaultValue,
  modifiedOrders,
  editDatix
}) {
  const { locationRoute, user } = useContext(SystemContext);
  const dispatch = useDispatch();
  const mgId = useSelector((state) => state.settings.mgId);
  const discrepancyByBalance = useSelector(
    (state) => state.settings.discrepancyByBalance
  );

  const [alertMessage, setAlertMessage] = useState();
  const [amountDisposed, setAmountDisposed] = useState(0);
  const [amountSupplied, setAmountSupplied] = useState(0);
  const [authorisedWitnessRequired, setAuthorisedWitnessRequired] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [datIxNoValue, setDatIxNoValue] = useState();
  const [disposalReference, setDisposalReference] = useState(null);
  const [doseAdministered, setDoseAdministered] = useState(0);
  const [doseAdministeredUom, setDoseAdministeredUom] = useState("");
  const [drugsCollectedBy, setDrugsCollectedBy] = useState("");
  const [drugsHandedTo, setDrugsHandedTo] = useState("");
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [newBalanceValue, setNewBalanceValue] = useState(
    defaultValue ? defaultValue : null
  );
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [localStock, setLocalStock] = useState(stock);
  const [stockComments, setStockComments] = useState();
  const [stockName, setStockName] = useState({ name: "Discrepancy/Incident" });
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (editDatix){
      setDatIxNoValue(editDatix.reference);
      setStockComments(editDatix.comments);
    } else {
      setDatIxNoValue();
      setStockComments();
    }
  }, [editDatix]);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (newBalanceValue < 0) {
        setAlertMessage({
          message:
            "Please amend the stock adjustment, cannot submit a negative balance of stock",
          colour: "warning",
        });
        setInputsConfirmed(false);
      } else if (locationRoute === "pharmacy") {
        if (modifiedOrders) {
          returnToPharmacyAction();
        } else {
          updatePharmacyStock();
        }
      }
      else {
        updateWardStock();
      }
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    setLocalStock(stock);
  }, [stock]);

  function reset() {
    setAmountDisposed(0);
    setAmountSupplied(0);
    setAuthorisedWitnessRequired(null);
    setConfirmedStaff();
    setDatIxNoValue();
    setDisposalReference(null);
    setDoseAdministered(0);
    setDoseAdministeredUom("");
    setDrugsCollectedBy();
    setDrugsHandedTo();
    setSelectedLocation(null);
    setLocalStock((localStock) => ({
      ...localStock,
      runningBalance: newBalanceValue,
    }));
    setStockComments();
    // setStockName("");
    setValidate(false);
  }

  function returnToPharmacyAction() {
    const updatedOrders = modifiedOrders.map(
      ({ disposalReference, authorisedWitnessRequired, ...order }) => ({
        ...order,
        mgId: mgId.REGISTERED_FOR_REUSE,
        CreatedBy: user.username,
        SignedBy: confirmedStaff.signed,
        WitnessedBy: confirmedStaff.witnessed,
      })
    );

    StockService.updateDrugsFromWards(user.location.lcid, updatedOrders, {
      cancelToken: serviceCancelSource.token,
    })
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: "Orders signed off",
            colour: "success",
            timeout: 5000,
          });
          updatePharmacyStock();
        } else {
          setAlertMessage({
            message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      })
      .catch((error) => {
        if (!StockService.isCancel(error)) {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            setAlertMessage({
              message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        }
      })
      .finally(() => setInputsConfirmed(false));
  }

  function updatePharmacyStock() {
    const payload = {
      Comments: stockComments,
      CreatedBy: user.username,
      DatixNo: datIxNoValue,
      SignedBy: confirmedStaff.signed,
      StkPhId: localStock.stkPhId,
      WitnessedBy: confirmedStaff.witnessed,
    };
    let alertMessageSuccess;
    let managePharmacyStock = (payload) => StockService.managePharmacyStock(payload, serviceConfig);

    if (stockName.name === "Discrepancy/Incident") {
      payload.AmountSupplied = localStock.runningBalance;
      payload.MgId = mgId.DISCREPANCY_INCIDENT;
      payload.NewBalance = newBalanceValue;
      alertMessageSuccess = "Discrepency/Incident recorded";
      if (editDatix){
        payload.StkMgPhId = editDatix.stockManagementId;
        payload.StkPhHistoryId = editDatix.stockHistoryId;
        managePharmacyStock = (payload) => StockService.editPharmayStock(payload, serviceConfig);
      }
    } else {
      payload.AmountSupplied = amountSupplied;
      payload.AuthorisedWitnessRequired = authorisedWitnessRequired;
      payload.DisposalReference = disposalReference;
      payload.MgId = mgId.FOR_DISPOSAL;
      payload.NewBalance = newBalanceValue;
      alertMessageSuccess = "Stock Dispose/Destroy confirmed";
    }

    managePharmacyStock(payload)
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: alertMessageSuccess,
            colour: "success",
          });
          onComplete(stock, newBalanceValue);
          // reset();
        } else {
          setAlertMessage({
            message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      })
      .catch((error) => {
        const responseData = error?.response?.data;
        const responseStatus = error?.response?.status;

        if (!StockService.isCancel(error)) {
          if (responseStatus === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            if (
              responseStatus === 400 &&
              responseData &&
              typeof responseData === "string" &&
              responseData.trim() !== ""
            ) {
              setAlertMessage({
                message: responseData,
                colour: "danger",
              });
              reset();
              onComplete(stock);
            } else {
              setAlertMessage({
                message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                 If unable to resolve contact IT service desk.`,
                colour: "danger",
              });
            }
          }
        }
      })
      .finally(() => setInputsConfirmed(false));
  }

  function updateWardStock() {
    const payload = {
      AmountDisposed: amountDisposed,
      Comments: stockComments,
      CreatedBy: user.username,
      DatixNo: datIxNoValue,
      DoseAdministered: doseAdministered,
      DoseAdministeredUom: doseAdministeredUom,
      reference: editDatix?.disposalReference?? disposalReference,
      NewBalance: newBalanceValue,
      SignedBy: confirmedStaff.signed,
      StkId: localStock.stkId,
      WitnessedBy: confirmedStaff.witnessed,
    };
    let manageWardStock = (payload) => OrdersService.adminToPatient(payload, serviceConfig);

    let  alertMessage;

    if (stockName.name === "Discrepancy/Incident") {
      payload.MgId = mgId.DISCREPANCY_INCIDENT;
      payload.AmountSupplied = localStock.runningBalance;
      alertMessage = "Discrepency / Incident recorded";
      if (editDatix){
        manageWardStock = (payload) => OrdersService.WardStockEditDatix(payload, serviceConfig);
      }
    } else if (stockName.name === "To Dispose/Destroy") {
      payload.MgId = mgId.DISPOSE_IN_WARD;
      payload.AmountSupplied = amountSupplied;
      alertMessage = "Stock Dispose/Destroy confirmed";
    } else {
      // "Return to Pharmacy"
      payload.MgId = mgId.RECEIVED_IN_PHARMACY;
      payload.AmountSupplied = amountSupplied;
      alertMessage = "Stock Return to Pharmacy confirmed";
    }

    manageWardStock(payload)
      .then(() => {
        setAlertMessage({
          message: alertMessage,
          colour: "success",
        });
        onComplete(stock, newBalanceValue);
        // reset();
      })
      .catch((error) => {
        const responseData = error?.response?.data;
        const responseStatus = error?.response?.status;

        if (!StockService.isCancel(error)) {
          if (responseStatus === 401) {
            setConfirmedStaffErrors(responseData);
          } else {
            if (
              responseStatus === 400 &&
              responseData &&
              typeof responseData === "string" &&
              responseData.trim() !== ""
            ) {
              setAlertMessage({
                message: responseData,
                colour: "danger",
              });
              reset();
              onError(updateLocalStock);
            } else {
              setAlertMessage({
                message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                colour: "danger",
              });
            }
          }
        }
      })
      .finally(() => setInputsConfirmed(false));
  }

  function updateLocalStock (newStock){
    setLocalStock(newStock);
  }

  function handleCommentsChange({ target: { value } }) {
    setStockComments(value);
  }

  function renderStockManagementHeaders(name) {
    switch (name) {
      case "Discrepancy/Incident":
        return (
          <>
            <th>Datix Report Ref No.</th>
            <th>Quantity Adjusted</th>
            <th>New Balance</th>
          </>
        );
      case "Patient Transfer":
        return (
          <>
            <th>Ward Transferred To</th>
            <th>Drugs Collected By</th>
          </>
        );
      case "Leave/Discharge":
        return <th>Drugs Handed To</th>;
      default:
        return null;
    }
  }

  return (
    <Modal
      centered
      className="DiscrepancyModal"
      fullscreen="lg"
      isOpen={isOpen}
      scrollable
      size="xl"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Stock Management {editDatix && " -- Edit" }</ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              {locationRoute === "pharmacy" && <th></th>}
              {stock?.isPatientOwn && <><th>Patient</th><th>Bag Reference</th></>}
              <th>Unit Dose</th>
              <th>Running Balance</th>
            </tr>
          </thead>
          {localStock && (
            <tbody>
              <StockTableLine
                disabled
                stock={localStock}
                showCategory={locationRoute === "pharmacy"}
              />
            </tbody>
          )}
        </Table>
        {localStock && (
          <>
            <Table className="stock-management-table">
              <thead>
                <tr>
                  <th>Type Of Stock Management</th>
                  {renderStockManagementHeaders(stockName.name)}
                  {stockName.name && stockName.name !== "Discrepancy/Incident" && (
                    <>
                      <th>Quantity</th>
                      <th>Authorised Witness Required</th>
                      <th>Reference</th>
                      <th>New Balance</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <StockManagementTableLine
                  active
                  disableDropdown
                  line={{ ...localStock, name: "" }}
                  setAlertMessage={setAlertMessage}
                  setAuthorisedWitnessRequired={setAuthorisedWitnessRequired}
                  setDatIxNoValue={setDatIxNoValue}
                  setDisposalReference={setDisposalReference}
                  setDrugsCollectedBy={setDrugsCollectedBy}
                  setDrugsHandedTo={setDrugsHandedTo}
                  setNewBalanceValueFor={setNewBalanceValue}
                  setQuantityValue={setAmountSupplied}
                  setSelectedLocation={setSelectedLocation}
                  setStockName={setStockName}
                  stockName={stockName}
                  stockView
                  discrepancyByBalance={discrepancyByBalance}
                  defaultValue={defaultValue}
                  editDatix={editDatix}
                />
              </tbody>
            </Table>
            {stockName && (
              <>
                <Row>
                  <Col>
                    <CommentsBlock
                      comments={stockComments}
                      setComments={handleCommentsChange}
                    />
                  </Col>
                </Row>
                <ConfirmBlock
                  className="ml-0 mr-0"
                  confirmedStaffErrors={confirmedStaffErrors}
                  confirmInitialState={initialStateSignedWitnessed}
                  inputsConfirmed={inputsConfirmed}
                  setConfirmedStaff={setConfirmedStaff}
                  setInputsConfirmed={setInputsConfirmed}
                  setValidate={setValidate}
                  title={stockName.name}
                />
              </>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default DiscrepancyModal;
