import React from "react";
import { connect } from "react-redux";
import { ReactComponent as EcdrLogo } from "../../assets/icons/Ecdrlogo.svg";

// Error Boundaries currently have to be classes
class ErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null,
    hasError: false,
  };

  static defaultProps = {
    heading: "Something went wrong, please reload the page",
    message: (
      <>
        The eCDR-Pro system may be offline.
        <br />
        If unable to resolve please contact the IT service desk.
      </>
    ),
  };

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: "2rem" }}>
          <h1 style={{ marginBottom: "2rem" }}>
            <EcdrLogo style={{ height: "2rem", width: "6.5rem" }} role="img" />
          </h1>
          <h2>{this.props.heading}</h2>
          <p>{this.props.message}</p>
          <details>
            <pre>
              <br />
              {this.state.error.toString()}
              {this.state.errorInfo && this.state.errorInfo.componentStack}
              <br />
              <br />
              eCDR-Pro Release {this.props.VERSION}
            </pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return { VERSION: state.settings.VERSION };
}

export default connect(mapStateToProps)(ErrorBoundary);
