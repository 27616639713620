const DEFAULT_COLOUR = "primary";
const DEFAULT_LABEL = "Message";
const DEFAULT_TIMEOUT = 5000;
let timeoutId;

function getDefaults(colour) {
  switch (colour) {
    case "danger":
      return { label: "Error message", timeout: Infinity };
    case "info":
      return { label: "Information message", timeout: Infinity };
    case "success":
      return { label: "Success message", timeout: DEFAULT_TIMEOUT };
    case "warning":
      return { label: "Warning message", timeout: Infinity };
    default:
      return { label: DEFAULT_LABEL, timeout: Infinity };
  }
}

export const HIDE_ALERT_MESSAGE = "HIDE_ALERT_MESSAGE";
export const SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";

export function hideAlertMessage(dispatch) {
  dispatch({ type: HIDE_ALERT_MESSAGE });
}

export function showAlertMessage(dispatch, alertMessage) {
  alertMessage = {
    colour: DEFAULT_COLOUR,
    ...getDefaults(alertMessage.colour),
    ...alertMessage,
  };

  dispatch({ type: SHOW_ALERT_MESSAGE, alertMessage });
  clearTimeout(timeoutId);

  if (
    alertMessage.timeout !== null &&
    alertMessage.timeout !== 0 &&
    alertMessage.timeout !== Infinity
  ) {
    timeoutId = setTimeout(() => {
      hideAlertMessage(dispatch);
    }, alertMessage.timeout);
  }
}
