import React, { useState, useEffect } from "react";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import ConfirmBlock from "./ConfirmBlock";
import CommentsBlock from "../molecules/CommentsBlock";

const CancelPrescriptionModal = ({ isOpen, rejectOrder, setModal, isPrescription }) => {
  const initialState = [
    {
      label: "Signed by",
      name: "signed",
      value: "",
      state: false,
    },
    {
      label: "Witnessed by",
      name: "witnessed",
      value: "",
      state: false,
    },
  ];

  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors] = useState();
  const [complianceComments, setComplianceComments] = useState("");

  const inputTerm = (e) => {
    setComplianceComments(e.target.value);
  };

  const toggle = () => setModal(!isOpen);

  useEffect(() => {
    if (inputsConfirmed) {
      rejectOrder(
        true,
        confirmedStaff.signed,
        confirmedStaff.witnessed,
        complianceComments
      );
    }
  }, [inputsConfirmed]);

  const orderType = isPrescription ? "Prescription" : "Order";

  return (
    <Modal
      centered
      className="DiscrepancyModal"
      fullscreen="lg"
      isOpen={isOpen}
      toggle={toggle}
      scrollable
      size="xl"
    >
      <ModalHeader toggle={toggle} >{`Single order: Cancel ${orderType}`}</ModalHeader>
      <ModalBody>
        <CommentsBlock
          comments={complianceComments}
          setComments={inputTerm}
          required={false}
        />
        <Col>
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            title={`Cancel ${orderType}`}
            inputsConfirmed={inputsConfirmed}
            setInputsConfirmed={setInputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
            confirmInitialState={initialState}
          />
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CancelPrescriptionModal);
