import React, { useState, useEffect } from "react";
import { Row, Col, Button, InputGroup, Input, Form } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faCheckCircle,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LocationsService from "../services/LocationsService";
import AdminRules from "../config/_adminrules";
import PatientService from "../services/PatientService";

let messageTimeoutId;
let redirectTimeoutId;

const NewPatient = ({ history, isPharmacy, locationRoute, user }) => {
  const initalState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(initalState);
  const [detailRoleClass, setdetailRoleClass] = useState("");
  const [detailRole, setdetailRole] = useState("");
  const [detailAccess, setdetailAccess] = useState("");
  const [detailEdit, setdetailEdit] = useState(true);
  const [deleteMember, setdeleteMember] = useState(false);
  const [memberDeleted, setmemberDeleted] = useState(false);

  const [detailFirstName, setdetailFirstName] = useState("");
  const [detailLastName, setdetailLastName] = useState("");
  const [detailLocation, setdetailLocation] = useState("");
  const [detailNumber, setdetailNumber] = useState("");
  const [detailBirth, setdetailBirth] = useState("");

  const [locResults, setlocResults] = useState([]);

  const updateName = (e) => {
    switch (e.target.name) {
      case "detailFirstName":
        setdetailFirstName(e.target.value);
        break;
      case "detailLastName":
        setdetailLastName(e.target.value);
        break;
      default:
        break;
    }
  };

  const updateLocation = (e) => {
    setdetailLocation(e.target.value);
  };

  const updateNumber = (e) => {
    setdetailNumber(e.target.value);
  };

  const updateBirth = (e) => {
    setdetailBirth(e.target.value);
  };

  const updateRole = (role) => {
    let access = [];
    for (let i = 0; i < role["access"].length; i++) {
      let c = i;
      access.push(
        AdminRules.Access.find((ra) => ra["id"] === role["access"][c])
      );
    }

    setdetailRole(role["name"]);
    setdetailRoleClass(role["class"]);
    setdetailAccess(access);
  };

  const createNewPatient = () => {
    PatientService.createNewPatient({
      LcId: user.location.lcId,
      uniqueIdentifier: detailNumber,
      FirstName: detailFirstName,
      LastName: detailLastName,
      Dob: detailBirth,
      Address: detailLocation,
      Postcode: detailLocation,
      CreatedBy: user.username,
      isPharmacy,
    })
      .then(() => {
        setAlertMessage({
          message: "New Patient Created",
          colour: "success",
          timeout: 5000,
        });
        redirectTimeoutId = setTimeout(() => {
          history.push(`/${locationRoute}/dashboard`);
        }, 5000);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setAlertMessage({
            message: `The logged in user has insufficient permissions to perform this action,
            if required please contact your IT help-desk to change`,
            colour: "danger",
          });
        } else {
          setAlertMessage({
            message: `Unable to create the new patient, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      });
  };

  // Get all locations to filter through
  useEffect(() => {
    LocationsService.getLocations(user["location"]["siteId"])
      .then((response) => {
        setlocResults(response.data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `${
            locationRoute === "ward" ? "Ward" : "Pharmacy"
          } list is unavailable right now,
            the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });

    return () => {
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(initalState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="SystemNewPerson">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
          <h1>Add new patient {locationRoute === "ward" ? " to ward" : ""}</h1>
        </Col>
        <Col
          md={{ size: 3, offset: 3 }}
          className="d-flex align-items-end flex-column"
        >
          <Link
            className="shadow-sm btn btn-primary"
            to={`/${locationRoute}/dashboard`}
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO {locationRoute === "ward" ? "WARD" : "PHARMACY"}
          </Link>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="SystemNewPerson-detail SystemNewPerson-new">
          {/* <Row className={`mt-4 ${deleteMember && "blur"}`}>
            <Col>
              <h3 className="SystemNewPerson-detail_trust">
                Hospital : Calderdale Royal Hospital
              </h3>
              <h2 className="SystemNewPerson-detail_trust">
                Ward : {user["location"]["wardInfo"]}
              </h2>
            </Col>
            <Col >
              {!detailEdit && (
                <Button
                  className="mr-2 shadow-sm"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setdetailEdit(true);
                  }}
                >
                  <FontAwesomeIcon className="on-left" icon={faEdit} />
                  Edit
                </Button>
              )}
              <Button
                className="mr-2 shadow-sm"
                color="black"
                onClick={(e) => {
                  e.preventDefault();
                  setdeleteMember(!deleteMember);
                }}
              >
                <FontAwesomeIcon className="on-left" icon={faTrashAlt} />
                DELETE
              </Button>
            </Col>
          </Row> */}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              createNewPatient();
              setdetailEdit(!detailEdit);
            }}
          >
            <Row className={`${deleteMember && "blur"}`}>
              <Col>
                <div className="SystemNewPerson-detail_block">
                  <InputGroup>
                    <span className="block SystemNewPerson-sub">
                      <span className="mandatory-label">*</span>
                      First Name(s) :
                    </span>
                    <Input
                      className={`SystemNewPerson-detail_name ${
                        detailEdit && "shadow-sm"
                      }`}
                      type="text"
                      name="detailFirstName"
                      value={detailFirstName}
                      onChange={updateName}
                      disabled={!detailEdit}
                      readOnly={!detailEdit}
                      autoComplete="off"
                      maxLength="55"
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <span className="block SystemNewPerson-sub">
                      <span className="mandatory-label">*</span>
                      Last Name :
                    </span>
                    <Input
                      className={`SystemNewPerson-detail_name ${
                        detailEdit && "shadow-sm"
                      }`}
                      type="text"
                      name="detailLastName"
                      value={detailLastName}
                      onChange={updateName}
                      disabled={!detailEdit}
                      readOnly={!detailEdit}
                      autoComplete="off"
                      maxLength="55"
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <span className="block SystemNewPerson-sub">
                    {isPharmacy && <sup className="mandatory-label">*</sup>}
                    Unique Identifier (MRN or NHS no):
                    </span>
                    <Input
                      className={`SystemNewPerson-detail_num-id ${
                        detailEdit && "shadow-sm"
                      }`}
                      type="text"
                      name="staff member number"
                      value={detailNumber}
                      onChange={updateNumber}
                      disabled={!detailEdit}
                      readOnly={!detailEdit}
                      autoComplete="off"
                      maxLength="10"
                      required={isPharmacy}
                    />
                  </InputGroup>
                  <InputGroup>
                    <span className="block SystemNewPerson-sub">DOB :</span>
                    <Input
                      className={`SystemNewPerson-detail_dob ${
                        detailEdit && "shadow-sm"
                      }`}
                      type="date"
                      name="staff member number"
                      value={detailBirth}
                      onChange={updateBirth}
                      disabled={!detailEdit}
                      readOnly={!detailEdit}
                      autoComplete="off"
                    />
                  </InputGroup>
                  <InputGroup>
                    <span className="block SystemNewPerson-sub SystemNewPerson-sub_location">
                    {isPharmacy && <sup className="mandatory-label">*</sup>}
                      Address :
                    </span>
                    <Input
                      className={`SystemNewPerson-detail_location ${
                        detailEdit && "shadow-sm"
                      }`}
                      type="textarea"
                      name="staff member location"
                      value={detailLocation}
                      onChange={updateLocation}
                      disabled={!detailEdit}
                      readOnly={!detailEdit}
                      autoComplete="off"
                      required={isPharmacy}
                    />
                  </InputGroup>
                  <div className="d-flex justify-content-end SystemNewPerson-detail_sign">
                    {detailEdit && !deleteMember && (
                      <Button
                        className="confirmButton shadow-sm"
                        color="primary"
                        type="submit"
                      >
                        CONFIRM{" "}
                        <FontAwesomeIcon
                          className="on-right"
                          icon={faCheckCircle}
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {deleteMember && (
        <div className="SystemNewPerson-detail_deleted">
          <p className="shadow">
            This record has been deleted
            <span>
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </p>
        </div>
      )}
    </Col>
  );
};

export default React.memo(NewPatient);
