import { SET_SETTINGS } from "../actions/settingsActions";

const initialState = { mgId: {}, statusId: {}, loaded: false };

const reducer = (state = initialState, { type, settings }) => {
  switch (type) {
    case SET_SETTINGS:
      return { ...state, ...settings };
    default:
      return state;
  }
};

export default reducer;
