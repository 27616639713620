import axios from "axios";
import AuthService from "./AuthService";

const IllicitDrugService = {};

IllicitDrugService.getCancelSource = () => axios.CancelToken.source();
IllicitDrugService.isCancel = axios.isCancel;

IllicitDrugService.addIllicitDrug = async (locationRoute, stock, config) => {
  return await AuthService.post(`/illicitdrug/${locationRoute}`, stock, config);
};

IllicitDrugService.getIllicitDrug = async (id, config) => {
  return await AuthService.get(`/illicitdrug/id/${id}`, config);
};

IllicitDrugService.getIllicitDrugs = async (
  locationRoute,
  locationId,
  config
) => {
  return await AuthService.get(
    `/illicitdrug/${locationRoute}/${locationId}`,
    config
  );
};

IllicitDrugService.manageIllicitDrug = async (locationId, payload, config) => {
  return await AuthService.post(
    `/illicitdrug/pharmacy/manage/${locationId}`,
    payload,
    config
  );
};

IllicitDrugService.returnIllicitDrugToPharmacy = async (stock, config) => {
  return await AuthService.post(
    "/illicitdrug/ward/returntopharmacy",
    stock,
    config
  );
};

export default IllicitDrugService;
