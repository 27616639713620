import AuthService from "./AuthService";

const AuditService = {};

AuditService.getAllStockAudit = async () => {
  return await AuthService.get("/StockAudit");
};

AuditService.addNewStockAuditItem = async (auditArray) => {
  return await AuthService.post("/StockAudit", auditArray);
};

AuditService.postWardStockAudit = async (locationId, auditArray) => {
  return await AuthService.post(
    `/StockAudit/WardStockAudit/${locationId}`,
    auditArray
  );
};

AuditService.getWardStockAudit = async (locationId) => {
  return await AuthService.get(`/StockAudit/WardStockAudit/${locationId}`);
};

AuditService.createStockAudit = async (locationId, auditArray) => {
  return await AuthService.post(
    `/StockAudit/PharmacyStockAudit/${locationId}`,
    auditArray
  );
};

export default AuditService;
