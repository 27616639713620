import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";

import SystemContext from "../../context/SystemContext";

const RemoveFromCupboard = () => {
  const { user, locationRoute, actHap } = useContext(SystemContext);

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Col className="RemoveFromCupboard">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col md="6">
          <h1>Remove from cupboard</h1>
        </Col>
        {/* <Col md="6 d-flex align-items-end flex-column">
          <Button
            color="primary"
            className="shadow-sm"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO DASHBOARD
          </Button>
        </Col> */}
      </Row>
    </Col>
  );
};

export default React.memo(RemoveFromCupboard);
