import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import cx from "classnames";

import { Dropdown, Label, Loader } from "../../../component/atoms";
import DrugSearchList from "../../../component/organisms/DrugSearchList";
import {
  getDateStrings,
  getTimestamp,
  replacePropertyValues,
  truncateString,
} from "../../../helpers";
import {
  getInitialState,
  getURLParams,
  getValidationMessage,
  setCustomErrorMessages,
  setValidities,
} from "../../../helpers/forms";
import ReportService from "../../../services/ReportService";
import { hideAlertMessage, showAlertMessage } from "../../../store/actions";

const administerEventName = "CD Administered";
const disposedEventName = "CD Disposed (Administered)";
let serviceCancelSource;
let serviceConfig;

function GeneralStock({ locationRoute, user }) {

  const Pharmacy = "pharmacy";
  const isPharmacy = (locationRoute === Pharmacy);

  const dispatch = useDispatch();
  const REPORT_FORM_MIN_DATE = useSelector(
    (state) => state.settings.REPORT_FORM_MIN_DATE
  );
  const REPORT_TABLE_MAX_ROWS = useSelector(
    (state) => state.settings.REPORT_TABLE_MAX_ROWS
  );
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );
  const mgId = useSelector((state) => state.settings.mgId);
  const statusId = useSelector((state) => state.settings.statusId);

  let eventTypes = [
    {
      name: "StockEventTypeIds",
      value: mgId.ADD_CD,
      label: "CD added",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.ADMINISTER_TO_PATIENT,
      label: "CD administered to patient",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.DISPOSED_BY_WARD,
      label: "CD disposed",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.RETURN_TO_PHARMACY_FOR_REUSE,
      label: "CD returned to pharmacy (re-use)",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.RETURN_TO_PHARMACY_FOR_DISPOSAL,
      label: "CD returned to pharmacy (disposal)",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.RECEIVED_IN_PHARMACY,
      label: "CD supplied",
    },
    {
      name: "StockEventTypeIds",
      value: mgId.DISCREPANCY_INCIDENT,
      label: "Discrepancy / Incident",
    },

    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_CREATED,
      label: "Order created",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_SUPPLIED,
      label: "Order supplied",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_REJECTED_BY_WARD,
      label: "Order rejected by ward",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_REJECTED_BY_PHARMACY,
      label: "Order rejected by pharmacy",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_COLLECTED_FROM_PHARMACY,
      label: "Order collected from pharmacy",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_ARRIVED_ON_THE_WARD,
      label: "Order arrived on the ward",
    },
    {
      name: "OrderEventTypeIds",
      value: statusId.ORDER_ACCEPTED_BY_THE_WARD,
      label: "Order accepted by the ward",
    },
    {
      name: "IncludeStockCheck",
      value: true,
      label: "Stock check",
    },
  ];
  if (!isPharmacy){
    eventTypes.push({
      name: "StockEventTypeIds",
      value: mgId.PATIENT_TRANSFER,
      label: "CD Transferred",
    });
    eventTypes.push({
      name: "StockEventTypeIds",
      value: mgId.LEAVE_DISCHARGE,
      label: "CD Leave / Discharge",
    });
  }
  if (isPharmacy){
    eventTypes.push({
      name: "OrderEventTypeIds",
      value: statusId.CANCELLED,
      label: "Order canceled",
    });
  }
  const formFields = {
    Medicine: {
      id: "medicine",
      label: "Medicine",
      name: "Medicine",
      required: true,
    },
    StartDate: {
      autoComplete: "off",
      id: "start-date",
      label: "Date from",
      name: "StartDate",
      pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
      placeholder: "yyyy-mm-dd",
      required: true,
      type: "date",
    },
    EndDate: {
      autoComplete: "off",
      id: "end-date",
      label: "Date to",
      name: "EndDate",
      pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
      placeholder: "yyyy-mm-dd",
      required: true,
      type: "date",
    },
    EventTypes: {
      id: "event-types",
      label: "Event types",
      list: eventTypes,
      multiple: true,
      name: "EventTypes",
      placeholder: "Select event types",
      required: true,
    },
  };

  const [alertMessage, setAlertMessage] = useState();
  const [formState, setFormState] = useState(
    getInitialState(formFields, useRef)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isPartialReport, setIsPartialReport] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [report, setReport] = useState();

  const maxDate = getTimestamp().split("T")[0];

  useEffect(() => {
    serviceCancelSource = ReportService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    setValidities(setFormState);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isPartialReport) {
      setAlertMessage({
        message: `Only showing the first ${REPORT_TABLE_MAX_ROWS} records, please narrow your date search`,
        colour: "warning",
      });
    }
  }, [isPartialReport]);

  async function getReport() {
    const config = { ...serviceConfig, params: getURLParams(formState) };
    let isCancelled = false;

    setIsLoading(true);
    setIsPartialReport(false);
    setReport(null);

    try {
      const getStockHistory = isPharmacy
        ? ReportService.getPharmacyStockHistory
        : ReportService.getWardStockHistory;

      const { data } = await getStockHistory(
        user.location.lcId,
        config
      );

      if (data.length > REPORT_TABLE_MAX_ROWS) {
        data.splice(REPORT_TABLE_MAX_ROWS);
        setIsPartialReport(true);
      }
      const removedSupplied = removeSuppliedAndEmptyRows(data);
      setReport(removedSupplied);
      setShowValidity(false);
    } catch (error) {
      if (ReportService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the log records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setCustomErrorMessages(setFormState, error.response);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function removeSuppliedAndEmptyRows(data) {
    if (
      data.some(
        (x) =>
          x.eventText === administerEventName ||
          x.eventText === disposedEventName
      )
    ) {
      const removedSupply = data.map((d) =>
        d.eventText === administerEventName || d.eventText === disposedEventName
          ? { ...d, quantity: null }
          : d
      );
      //Remove all 0 value administer and disposed
      return removedSupply.filter(
        (d) =>
          !(
            d.amountAdministered === "0" && d.eventText === administerEventName
          ) && !(d.amountDisposed === "0" && d.eventText === disposedEventName)
      );
    } else {
      return data;
    }
  }

  const StockEventSynonyms = {
    [mgId.ADMINISTER_TO_PATIENT]: [mgId.ADMINISTER_TO_PATIENT, mgId.ADMINISTED_DISPOSED],
    [mgId.ADD_CD]: [mgId.ADD_CD, mgId.NEW_STOCK],
    [mgId.DISPOSED_BY_WARD]: [mgId.DISPOSED_BY_WARD, mgId.DISPOSED],
    [mgId.RETURN_TO_PHARMACY_FOR_REUSE]: [mgId.RETURN_TO_PHARMACY_FOR_REUSE, mgId.REGISTERED_FOR_REUSE],
    [mgId.SUPPLIED_TO_PATIENT]: [],
    [mgId.ADMINISTED_DISPOSED]: [],
  };

  function handleFieldChange({
    target: { name, validationMessage, validity, value },
  }) {
    if (formState[name].innerRef) {
      formState[name].innerRef.current.setCustomValidity("");
    }
    // Add the other event types that mean the same (for the user) that the selected ones
    if (value.StockEventTypeIds) {
      const updatedEventIds = value.StockEventTypeIds.flatMap((eventType) => [
        ...StockEventSynonyms[eventType] || [eventType],
      ]).map(String);
      value.StockEventTypeIds = updatedEventIds;
    }

    setFormState((formState) =>
      replacePropertyValues(formState, name, {
        customErrorMessage: validationMessage,
        validity,
        value,
      })
    );
  }

  function handleFormSubmit(event) {
    const message = getValidationMessage(formState);

    if (message) {
      setAlertMessage({
        message,
        colour: "warning",
      });
    } else {
      getReport();
    }

    setShowValidity(true);
    event.preventDefault();
  }

  function renderReport() {
    if (!report.length) {
      return (
        <p className="page-message">
          There are no logged records for that date range
        </p>
      );
    }

    const sortedList = report
      .sort((a, b) => {
        return new Date(a.eventDateTime) - new Date(b.eventDateTime);
      })
      .reverse();

    return (
      <Table className="report-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Event</th>
            <th>Patient Name</th>
            <th>Quantity</th>
            <th>
              Amount
              <br />
              Administered
            </th>
            <th>
              Amount
              <br />
              Disposed
            </th>
            <th>
              Signed
              <br />
              by
            </th>
            <th>
              Witnessed
              <br />
              by
            </th>
            <th>Balance</th>
            <th>Comments</th>
            <th>
              Incident
              <br />
              Ref
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item, index) => {
            const dateStrings = getDateStrings(item.eventDateTime);

            return (
              <tr key={index}>
                <td className="min-height">
                  <time dateTime={item.eventDateTime}>{dateStrings.date}</time>
                </td>
                <td>
                  <time dateTime={item.eventDateTime}>{dateStrings.time}</time>
                </td>
                <td>{item.eventText}</td>
                <td className="break-words">{item.patientName && `${item.patientName}${
                  item.isPatientOwn ? " (PO)" : ""
                }`}</td>
                <td>
                  {item.quantity !== null && item.quantity !== undefined && (
                    <span>
                      {item.quantity} {item.drugUoM}
                    </span>
                  )}
                </td>
                <td>
                  {item.amountAdministered}{" "}
                  {item.amountAdministered ? item.administeredUoM : ""}
                </td>
                <td>
                  {item.amountDisposed}{" "}
                  {item.amountDisposed ? item.administeredUoM : ""}
                </td>
                <td className="break-words" title={item.signedBy}>
                  {truncateString(item.signedBy, SIGNATURE_DISPLAY_MAX_LENGTH)}
                </td>
                <td className="break-words" title={item.witnessedBy}>
                  {truncateString(
                    item.witnessedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td>
                  {item.balance && (
                    <span>
                      {item.balance} {item.drugUoM}
                    </span>
                  )}
                </td>
                <td className="break-words">{item.comments}</td>
                <td>{item.reference}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <Col className={`GeneralStock GeneralStock-${locationRoute} report-page`}>
      <Row className="mb-4">
        {isPharmacy
          ? <h1>General, Named Patient and TTO CDs</h1>
          : <h1>Log: General Stock and Named Patient CDs</h1>
        }
      </Row>
      <Form
        className={cx("form horizontal", { "show-validity": showValidity })}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <Row className="full-width-sm">
          <Col md="10">
            <FormGroup
              title={formState.Medicine.customErrorMessage || undefined}
            >
              <Label disabled={isLoading} {...formFields.Medicine} />
              <DrugSearchList
                disableButton
                disabled={isLoading}
                onChange={handleFieldChange}
                searchListClass="search-list-fixed"
                setAlertMessage={setAlertMessage}
                showDrugName
                showPatientOwn
                selectableSearch
                {...formFields.Medicine}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={
                formState.StartDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.StartDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.StartDate.innerRef}
                max={
                  (formState.EndDate.validity.valid &&
                    formState.EndDate.value) ||
                  maxDate
                }
                min={REPORT_FORM_MIN_DATE}
                onChange={handleFieldChange}
                {...formFields.StartDate}
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup
              title={
                formState.EndDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.EndDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.EndDate.innerRef}
                max={maxDate}
                min={
                  (formState.StartDate.validity.valid &&
                    formState.StartDate.value) ||
                  REPORT_FORM_MIN_DATE
                }
                onChange={handleFieldChange}
                {...formFields.EndDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={formState.EventTypes.customErrorMessage || undefined}
            >
              <Label disabled={isLoading} {...formFields.EventTypes} />
              <Dropdown
                disabled={isLoading}
                onChange={handleFieldChange}
                {...formFields.EventTypes}
              />
            </FormGroup>
          </Col>
          <Col className="buttons offset-md-3" md="2">
            <Button color="primary" disabled={isLoading} type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {report && renderReport()}
        <Loader show={isLoading} />
      </Row>
    </Col>
  );
}

export default GeneralStock;
