export const ADMINISTRATION_EVENT_TYPE = 1;
export const MANAGEMENT_EVENT_TYPE = 2;

export const historyEvents = {
  ADMINISTER_TO_PATIENT_S: {
    groupHead: true,
    title: "Supplied",
    type: ADMINISTRATION_EVENT_TYPE,
    isDoseUom: false,
    showInAdminister: true,
  },
  ADMINISTER_TO_PATIENT_A: {
    groupBody: true,
    noBalance: true,
    title: "Administered",
    type: ADMINISTRATION_EVENT_TYPE,
    isDoseUom: true,
    showInAdminister: true,
  },
  ADMINISTER_TO_PATIENT_D: {
    groupBody: true,
    noBalance: true,
    title: "Disposed",
    type: ADMINISTRATION_EVENT_TYPE,
    isDoseUom: true,
    showInAdminister: true,
  },
  ADMINISTER_TO_PATIENT: {
    type: ADMINISTRATION_EVENT_TYPE,
  },
  DISCREPANCY_INCIDENT: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  DISPOSE_DESTROY: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  DISPOSE_IN_WARD: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  DISPOSED: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  DISPOSED_BY_WARD: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  LEAVE_DISCHARGE: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  PATIENT_TRANSFER: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  PATIENTS_OWN_REGISTERED: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  ORDER_REGISTERED: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  RECEIVED_IN_PHARMACY: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  REGISTERED_FOR_REUSE: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  RETURN_TO_PHARMACY_FOR_REUSE: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  RETURN_TO_PHARMACY_FOR_DISPOSAL: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  STOCK_CHECK: {
    noQuantity: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  NEW_STOCK: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  READY_FOR_COLLECTION: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  READY_FOR_COLLECTION_O: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  CANCELLED: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  Pharmacy: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  ReadyForCollection: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  RejectedByWard: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  RejectedByPharmacy: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  InTransit: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  ReadyToRegister: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  Registered: {
    type: MANAGEMENT_EVENT_TYPE,
  },
  OutpatientCollected: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  OfflineWardCollected: {
    optionalEvent: true,
    type: MANAGEMENT_EVENT_TYPE,
  },
  Cancelled: {
    type: MANAGEMENT_EVENT_TYPE,
  },
};
