import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import WardPicker from "../../component/organisms/WardPicker";
import AuditLine from "../../component/molecules/Pharmacy/AuditLine";
import { initialStateSigned } from "../../config/confirm";
import AuditService from "../../services/AuditService";

import SystemContext from "../../context/SystemContext";
import { useHistory } from "react-router";

let messageTimeoutId;
let redirectTimeoutId;

const StockAuditCreate = () => {
  const { user, locationRoute } = useContext(SystemContext);
  let history = useHistory();

  const messageState = {
    message: "",
    colour: "",
  };
  const siteId = user["location"]["siteId"];

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [approved, setapproved] = useState(messageState);

  const [wardAlert, setwardAlert] = useState(false);
    const [areaName, setareaName] = useState({ lcId: "", wardInfo: "", isOnline: true });

  const [auditOptions, setauditOptions] = useState([]);
  const [auditSend, setauditSend] = useState([]);

  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState([]);
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  useEffect(() => {
    AuditService.getAllStockAudit().then((response) => {
      setauditOptions(response.data);
      setauditSend(response.data.map((item) => ({ saId: item.saId })));
    });

    return () => {
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (areaName.lcId) {
        const payload = auditSend.map((item) => ({
          ...item,
          createdBy: user.username,
          signedBy: confirmedStaff.signed,
        }));

        AuditService.createStockAudit(areaName["lcId"], payload)
          .then(() => {
            redirectTimeoutId = setTimeout(function () {
              history.push("/pharmacy/audit");
            }, 5000);
            setAlertMessage({
              message: "CD Compliance Audit List created",
              colour: "success",
              timeout: 5000,
            });
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: `There was a problem creating the CD Compliance Audit List, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                colour: "danger",
              });
            }

            setInputsConfirmed(false);
          });
      } else {
        setAlertMessage({
          message: "Please select a clinical area",
          colour: "warning",
        });
        setInputsConfirmed(false);
      }
    }
  }, [inputsConfirmed]);

  const toggleSelectItem = (saId) => {
    setauditSend((auditSend) => {
      if (auditSend.some((item) => item.saId === saId)) {
        return auditSend.filter((item) => item.saId !== saId);
      } else {
        return [...auditSend, { saId }];
      }
    });
  };

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="StockAuditCreate">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-3">
        <Col className="d-flex align-items-baseline justify-content-end flex-row">
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to="/pharmacy/audit"
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MAIN SCREEN
          </Link>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h1>Apply Compliance List to -</h1>
          <WardPicker
            inputID="chooseauditarea"
            siteId={siteId}
            setAlertMessage={setAlertMessage}
            areaName={areaName}
            setareaName={setareaName}
            wardAlert={wardAlert}
            setwardAlert={setwardAlert}
            includePharmacy={true}
          />
        </Col>
      </Row>
      <Row className="StockAuditCreate-list">
        {auditOptions && (
          <ul>
            {auditOptions.map((aud, adx) => (
              <AuditLine
                key={adx}
                audit={aud}
                toggleSelectItem={toggleSelectItem}
                defaultChecked
                view="create"
              />
            ))}
          </ul>
        )}
      </Row>
      <ConfirmBlock
        confirmedStaffErrors={confirmedStaffErrors}
        confirmInitialState={initialStateSigned}
        inputsConfirmed={inputsConfirmed}
        setConfirmedStaff={setConfirmedStaff}
        setInputsConfirmed={setInputsConfirmed}
      />
    </Col>
  );
};

export default React.memo(StockAuditCreate);
