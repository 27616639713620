import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import SystemContext from "../../context/SystemContext";
import CommentsBlock from "../../component/molecules/CommentsBlock";
import QuantityInput from "../../component/molecules/QuantityInput";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";
import { initialStateSignedWitnessed } from "../../config/confirm";
import StockService from "../../services/StockService";
import { showAlertMessage } from "../../store/actions";

const DEFAULT_ERROR_KEY = "";
const STOCK_EXISTS_ERROR = "Stock already exists for location";

let serviceCancelSource;
let serviceConfig;

function AddCD({ history, locationId, user }) {
  const dispatch = useDispatch();

  const [comments, setComments] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [datixNo, setDatixNo] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [selectedDrug, setSelectedDrug] = useState();
  const {locationRoute } = useContext(SystemContext);

  const mainPagePath = `/${locationRoute}/stock`;

  const isPharmacyRoute = () => locationRoute === "pharmacy";

  const getStockPromise = (locationId) =>
    isPharmacyRoute()
      ? StockService.getPharmacyStock(locationId)
      : StockService.wardStock(locationId);

  const discrepancy = async (payload) =>
    isPharmacyRoute()
      ? await StockService.pharmacyDiscrepancy(
          locationId,
          payload,
          serviceConfig
        )
      : await StockService.discrepancy(locationId, payload, serviceConfig);

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (isConfirmed) {
      setConfirmedStaffErrors();

      if (!comments) {
        showAlertMessage(dispatch, {
          message: "Please fill in the required Reason / Comments",
          colour: "warning",
        });
        setIsConfirmed(false);
      } else {
        manageDrug();
      }
    }
  }, [isConfirmed]);

  function checkForTextInData(data, searchText) {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (item === searchText) {
            return true;
          }
        }
      } else if (typeof data[key] === "object") {
        if (checkForTextInData(data[key], searchText)) {
          return true;
        }
      }
    }
    return false;
  }
  async function manageDrug() {
    const payload = {
      cdApId: !selectedDrug.cdCatalogue ? selectedDrug.Id : selectedDrug.cdCatalogue.Id,
        cdVpId: !selectedDrug.cdCatalogue ? selectedDrug.virtualId : selectedDrug.cdCatalogue.virtualId,
        catalogueId: selectedDrug.catalogueId,
      siteId: user.location.siteId,
      comments,
      createdBy: user.username,
      datixNo,
      newBalance,
      signedBy: confirmedStaff.signed,
      witnessedBy: confirmedStaff.witnessed,
    };

    setIsLoading(true);

    try {
      const response = await discrepancy(payload);

      if (response.status === 202) {
        showAlertMessage(dispatch, {
          message: "Successfully added the CD",
          colour: "success",
        });

        const { catalogueId, cdVpId, cdApId } = payload;

        await getStockPromise(locationId).then((res) => {
          const stock = res.data;
          const foundItemIndex = stock.findIndex(
            (el) => el.catalogueId === catalogueId
          );

          if (foundItemIndex < 0) {
            showAlertMessage({
              message: `Unable to redirect because no matching drug was found in stock`,
              colour: "warning",
            });
            setIsConfirmed(false);
            setIsLoading(false);

            return;
          }

          history.push(`/${locationRoute}/stock/administer`, {
            nxt: stock[foundItemIndex],
          });
        });
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsConfirmed(false);
        setIsLoading(false);
      }
    } catch (error) {
      if (!StockService.isCancel(error)) {
        if (error.response?.status === 400) {
          if (
            checkForTextInData(error.response.data, STOCK_EXISTS_ERROR)
          ) {
            showAlertMessage(dispatch, {
              message:
                "Stock item already exists, please perform a stock discrepancy to amend the balance",
              colour: "warning",
            });
          } else if (error.response.data) {
            showAlertMessage(dispatch, {
              message: error.response.data,
              colour: "danger",
            });
          }
          else {
            showAlertMessage(dispatch, {
              message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        } else if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setIsConfirmed(false);
        setIsLoading(false);
      }
    }
  }

  function resetPage() {
    setComments();
    setDatixNo();
    setNewBalance(0);
    setSelectedDrug();
  }

  function handleCommentsChange({ target: { value } }) {
    setComments(value);
  }

  function handleDatixNoChange({ target: { value } }) {
    setDatixNo(value);
  }

  function handleDeleteButtonClick() {
    resetPage();
  }

  function renderRow() {
    return (
      <tr>
            <td>{!selectedDrug.cdCatalogue ?
                selectedDrug.cdcatalogueNameOrFormularyName
                : selectedDrug.cdCatalogue.cdcatalogueNameOrFormularyName
            }</td>
        <td>
          <Input
            autoComplete="off"
            onChange={handleDatixNoChange}
            placeholder="Optional"
          />
        </td>
        <td>
          <QuantityInput
            allowZero
            placeholder={newBalance}
            onValueChange={setNewBalance}
            required
            showEmptyValue
                    unitsValue={!selectedDrug.cdCatalogue ?
                        selectedDrug.cdCataloguePackSize[0]?.uom
                        : selectedDrug.cdCatalogue.cdCataloguePackSize[0]?.uom
                    }
          />
        </td>
        <td>
          <Button
            aria-label="Delete item"
            className="icon-delete"
            color="transparent"
            onClick={handleDeleteButtonClick}
            outline
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <div className="AddCD">
      <header className="page-header">
        <h1 className="page-heading">Add CD</h1>
        <Link className="btn btn-primary btn-icon" to={mainPagePath}>
          <FontAwesomeIcon className="on-left" icon={faReplyAll} />
          Back to main screen
        </Link>
      </header>
      <Row>
        <Col sm="6" xl="4">
          <DrugSearchList
            searchListClass="search-list-fixed"
            setAlertMessage={(alertMessage) =>
              showAlertMessage(dispatch, alertMessage)
            }
            setSelectedDrug={setSelectedDrug}
            selectableSearch
          />
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Drug Name / Strength / Form</th>
            <th>Incident Ref.</th>
            <th>New Balance</th>
            <th />
          </tr>
        </thead>
        <tbody>{selectedDrug && renderRow()}</tbody>
      </Table>
      {selectedDrug && (
        <>
          <CommentsBlock required setComments={handleCommentsChange} />
          <ConfirmBlock
            className="ml-0 mr-0"
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={initialStateSignedWitnessed}
            inputsConfirmed={isConfirmed}
            isLoading={isLoading}
            setConfirmedStaff={setConfirmedStaff}
            setInputsConfirmed={setIsConfirmed}
            title="Discrepancy/Incident"
          />
        </>
      )}
    </div>
  );
}

export default AddCD;
