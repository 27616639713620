import React, { useEffect, useState } from "react";

const style = {
  transform: "rotate(-90deg)",
  transformOrigin: "center",
  transition: "stroke-dashoffset 850ms ease-in-out",
};

function ProgressBar({
  circleOneStroke,
  circleTwoStroke,
  progress,
  size,
  strokeWidth,
  className
}) {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const [offset, setOffset] = useState(circumference);

  useEffect(() => {
    setOffset(((100 - progress) / 100) * circumference);
  }, [progress, size, strokeWidth]);

  return (
    <svg className={`ProgressBar ${className}`} height={size} width={size}>
      <circle
        className="svg-circle-bg"
        cx={center}
        cy={center}
        r={radius}
        stroke={circleOneStroke}
        strokeWidth={strokeWidth}
      />
      <circle
        className="svg-circle"
        cx={center}
        cy={center}
        r={radius}
        stroke={circleTwoStroke}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeWidth={strokeWidth}
        style={style}
      />
    </svg>
  );
}

export default ProgressBar;
