import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Modal, ModalBody, ModalHeader,ModalFooter  } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AuditLine from "../component/molecules/AuditLine";
import StockAuditReview from "../component/organisms/StockAuditReview";
import AuditService from "../services/AuditService";

let messageTimeoutId;

const StockAudit = ({ isPharmacy, locationId, locationRoute, user }) => {
  const alertInitial = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(alertInitial);
  const [initials, setinitials] = useState();

  const [auditItems, setauditItems] = useState();
  const [auditSend, setauditSend] = useState();

  const [seeReview, setseeReview] = useState(false);
  
  useEffect(() => {
    setinitials(user.username.charAt(0));

    AuditService.getWardStockAudit(locationId)
      .then((response) => {
        setauditSend(
          response.data.map((drg) => {
            return {
              saId: drg.saId,
              isComplete: false,
              hasValue: false,
              concerns: "",
              concernscreatedby: "",
            };
          })
        );
        setauditItems(response.data);
      })
      .catch(() => {
        setAlertMessage({
          message: `Audit items are unavailable at the moment, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
        });
      });
  }, []);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(alertInitial),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  const updateLine = (saId, isComplete, hasValue) => {
      setauditSend((auditSend) =>
          auditSend.map((item) => {
              if (item.saId === saId) {
                  return { ...item, isComplete, hasValue };
              }

              return item;
          })
      );
    setauditItems((auditItems) =>
      auditItems.map((item) => {
        if (item.saId === saId) {
          return { ...item, isComplete, hasValue };
        }

        return item;
      })
    );
  };

  const confirmComments = (saId, commentInfo) => {
      setauditSend((auditSend) =>
          auditSend.map((item) => {
              if (item.saId === saId) {
                  return { ...item, concerns: commentInfo, concernscreatedby: commentInfo !== null ? user.username : null };
              }

              return item;
          })
      );
      setauditItems((auditItems) =>
      auditItems.map((item) => {
        if (item.saId === saId) {
            return { ...item, concerns: commentInfo, concernscreatedby: commentInfo !== null ? user.username : null };
        }

        return item;
      })
    );
  };

  const reviewAudit = () => {
     if (auditSend.some((item) => !item.isComplete && !item.concerns && item.hasValue)) {
      setAlertMessage({
        message:
          "Please enter a comment for each non-compliant item, either a reason for non-compliance or “n/a”",
          colour: "warning",
          label: "Warning message",
      });
        setseeReview(false);
    } else if (!auditSend.some((x) => x.hasValue === true)){
      setAlertMessage({
        message:
          "Please complete at least one compliance audit",
          colour: "warning",
          label: "Warning message",
      });
        setseeReview(false);
    } 
    else {
      setseeReview(true);
    }


  };

  return (
    <Col className="StockAudit">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-3">
        <Col sm="8">
          <h1>CD Compliance Audit {seeReview && "Review"}</h1>
        </Col>
        {seeReview && (
          <Col
            className="d-flex align-items-baseline justify-content-end flex-row"
            sm="4"
          >
            <Button
              className="btn-icon shadow-sm"
              color="primary"
              onClick={() => {setseeReview(false)}}
            >
              <FontAwesomeIcon className="on-left" icon={faEdit} />
              EDIT AUDIT
            </Button>
          </Col>
        )}
      </Row>
      {seeReview  ? (
        <> 
         <StockAuditReview
                      auditItems={auditItems.filter(x => x.hasValue === true)}
                      auditSend={auditSend.filter(x => x.hasValue === true)}
                      isPharmacy={isPharmacy}
                      setAlertMessage={setAlertMessage}
                      locationRoute={locationRoute}
                      user={user}
                      isPartialAudit={!auditSend.every(x => x.hasValue === true)}
         />
        </>
      ) : (
        <>
          <Row>
            <Table className="StockAudit-table">
              <thead>
                <tr>
                  <th>Check</th>
                  <th className="multi-line">
                    Compliant?
                    <div>
                      <em>Yes</em>
                      <em>No</em>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {auditItems &&
                  auditItems.map((audit, index) => (
                    <AuditLine
                      audit={audit}
                      confirmComments={confirmComments}
                      initials={initials}
                      key={index}
                      updateLine={updateLine}
                    />
                  ))}
              </tbody>
            </Table>
          </Row>
          <Row className="justify-content-end">
            <Button
              className="confirmButton shadow-sm"
              color="primary"
              onClick={reviewAudit}
            >
              AUDIT COMPLETED
              <FontAwesomeIcon className="on-right" icon={faCheckCircle} />
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};

export default React.memo(StockAudit);
