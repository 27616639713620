import React, { useState, useEffect } from "react";
import SearchInput from "../molecules/SearchInput";
import CDService from "../../services/CDService";

const ASLDrugSearchList = ({
  disabled,
  ward,
  setAlertMessage,
  setSelectedDrug,
}) => {
  //search value to filter asl list
  const [searchValue, setSearchValue] = useState("");
  // list of asl drugs from API
  const [aslDrugs, setAslDrugs] = useState("");
  // filtered drug list by searchValue
  const [searchDrugs, setSearchDrugs] = useState("");
  // clear searchDrugs list
  const [clear, setClear] = useState(false);

  const closeSearch = () => {
    setSearchDrugs("");
    setSearchValue("");
    setClear(true);
  };

  const addDrug = (drug) => {
    setSelectedDrug(drug);
  };

  useEffect(() => {
    CDService.getWardASL(ward)
      .then((response) => {
        setAslDrugs(response.data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `Approved list for this ward is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  }, [ward]);

  useEffect(() => {
    if (searchValue.length > 0) {
      setClear(false);
      setSearchDrugs(
        aslDrugs.filter((item) =>
            item["cdcatalogueNameOrFormularyName"].toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else if (searchValue.length < 1) {
      setClear(true);
      setSearchDrugs("");
    } else if (searchValue) {
      setClear(false);
      setSearchDrugs(aslDrugs);
    }
  }, [searchValue]);

  return (
    <>
      <SearchInput
        disabled={disabled}
        inputClass="search-holder"
        name="drug search"
        type="text"
        placeholder="Drug search"
        value={setSearchValue}
        clear={clear}
      />
      {searchDrugs && (
        <div className="search-list">
          <ul>
            {searchDrugs.map((sch, sdx) => (
              <li
                key={sdx}
                className="shadow-sm"
                onClick={(e) => {
                  e.preventDefault();
                  addDrug(sch);
                }}
              >
                <label htmlFor={sch["catalogueId"]}>
                        <span className="name">{sch["cdcatalogueNameOrFormularyName"]}</span>
                  <span className="pack">
                    <strong>Pack sizes: </strong>
                    {sch.formulary[0]?.formularyPacksizes && (
                      <ul>
                        {sch.formulary[0]?.formularyPacksizes.map((pack, pdx) => (
                          <li key={pdx}>
                            {pack["quantity"]} {pack["uom"]}
                          </li>
                        ))}
                      </ul>
                    )}
                  </span>
                  <span className="category">
                    <strong>CD Category: </strong>
                    {sch["schedule"]}
                  </span>
                  <input
                    type="checkbox"
                    value={sch["catalogueId"]}
                    id={sch["catalogueId"]}
                  />
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ASLDrugSearchList;
