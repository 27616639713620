import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import cx from "classnames";
import { Label, Loader } from "../../component/atoms";
import {
  getDateStrings,
  getTimestamp,
  replacePropertyValues,
  truncateString,
} from "../../helpers";
import {
  getInitialState,
  getURLParams,
  getValidationMessage,
  setCustomErrorMessages,
  setValidities,
} from "../../helpers/forms";
import ReportService from "../../services/ReportService";
import AuditService from "../../services/AuditService";
import { hideAlertMessage, showAlertMessage } from "../../store/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { complianceLevels } from "../../config/complianceLevels"
const formFields = {
  StartDate: {
    autoComplete: "off",
    id: "start-date",
    label: "Date from",
    name: "StartDate",
    pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
    placeholder: "yyyy-mm-dd",
    required: true,
    type: "date",
  },
  EndDate: {
    autoComplete: "off",
    id: "end-date",
    label: "Date to",
    name: "EndDate",
    pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
    placeholder: "yyyy-mm-dd",
    required: true,
    type: "date",
  },
};
let serviceCancelSource;
let serviceConfig;

function GeneralStock({ locationRoute, user }) {
  const dispatch = useDispatch();
  const REPORT_FORM_MIN_DATE = useSelector(
    (state) => state.settings.REPORT_FORM_MIN_DATE
  );
  const REPORT_TABLE_MAX_ROWS = useSelector(
    (state) => state.settings.REPORT_TABLE_MAX_ROWS
  );
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );

  const [alertMessage, setAlertMessage] = useState();
  const [formState, setFormState] = useState(
    getInitialState(formFields, useRef)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isPartialReport, setIsPartialReport] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [report, setReport] = useState();
  const [wardStocks, setWardStocks] = useState();

  const [checked, setChecked] = useState(false);
  const [showComplianceAudit, setShowComplianceAudit] = useState([]);

  const maxDate = getTimestamp().split("T")[0];

  useEffect(() => {
    serviceCancelSource = ReportService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    setValidities(setFormState);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isPartialReport) {
      setAlertMessage({
        message: `Only showing the first ${REPORT_TABLE_MAX_ROWS} records, please narrow your date search`,
        colour: "warning",
      });
    }
  }, [isPartialReport]);

  async function getReport() {
    const config = { ...serviceConfig, params: getURLParams(formState) };
    let isCancelled = false;

    setIsLoading(true);
    setIsPartialReport(false);
    setReport(null);

    try {
      const { data } = await ReportService.getComplianceAuditHistory(
        user.location.lcId,
        config
      );

      setWardStocks((await AuditService.getAllStockAudit()).data);
      if (data.length > REPORT_TABLE_MAX_ROWS) {
        data.splice(REPORT_TABLE_MAX_ROWS);
        setIsPartialReport(true);
      }

      setReport(data);
      setShowValidity(false);
    } catch (error) {
      if (ReportService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the log records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setCustomErrorMessages(setFormState, error.response);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function handleFieldChange({
    target: { name, validationMessage, validity, value },
  }) {
    if (formState[name].innerRef) {
      formState[name].innerRef.current.setCustomValidity("");
    }

    setFormState((formState) =>
      replacePropertyValues(formState, name, {
        customErrorMessage: validationMessage,
        validity,
        value,
      })
    );
  }

  function handleButtonClick(event) {
    if (showComplianceAudit.some((item) => item === event.currentTarget.id)) {
      setShowComplianceAudit(
        showComplianceAudit.filter((item) => item !== event.currentTarget.id)
      );
    } else {
      setShowComplianceAudit([...showComplianceAudit, event.currentTarget.id]);
    }
    setChecked(event.currentTarget.checked);
  }

  function handleFormSubmit(event) {
    const message = getValidationMessage(formState);
    setShowComplianceAudit([]);
    setChecked();
    if (message) {
      setAlertMessage({
        message,
        colour: "warning",
      });
    } else {
      getReport();
    }

    setShowValidity(true);
    event.preventDefault();
  }

  function renderReport() {
    if (!report.length) {
      return (
        <p className="page-message">
          There are no logged records for that date range
        </p>
      );
    }

    const sortedList = report
      .sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      })
      .reverse();

    const GetComplianceQuestions = (locationStockAuditDto) => {
      const totalItems = locationStockAuditDto.length;
      const compliantItems = locationStockAuditDto.filter((l) => l.isComplete).length;
      return { compliantItems, totalItems };
    };

    const getComplianceQuestionsText = (locationStockAuditDto) => {
      const { compliantItems, totalItems } = GetComplianceQuestions(locationStockAuditDto);
      const totalQuestions = locationStockAuditDto[0]?.totalQuestions;

      return totalQuestions && totalQuestions === totalItems
        ? `${compliantItems}/${totalQuestions} (${Math.round((compliantItems / totalQuestions) * 100)}%)`
        : "";
    };

    const GetCheckedQuestions = (locationStockAuditDto) => {
      const totalItems = locationStockAuditDto.length;
      const checkedItems = locationStockAuditDto.length;
      return { checkedItems, totalItems };
    };
    const getNonComplianceRecorded = (locationStockAuditDto) => {
      const { compliantItems, totalItems } = GetComplianceQuestions(locationStockAuditDto);
      return compliantItems === totalItems ? "N" : `Y (${totalItems - compliantItems})`;
    };
    const getCheckedQuestionsText = (locationStockAuditDto) => {
      const { checkedItems } = GetCheckedQuestions(locationStockAuditDto);
      const totalQuestions = locationStockAuditDto[0]?.totalQuestions;
      return totalQuestions
        ? `${checkedItems}/${totalQuestions} (${Math.round((checkedItems / totalQuestions) * 100)}%)`
        : "";

    };
    const getComplianceStatusButtonClass = (locationStockAuditDto) => {
      const { compliantItems, totalItems } = GetComplianceQuestions(
        locationStockAuditDto
      );
      const totalQuestions = locationStockAuditDto[0]?.totalQuestions;
      if (totalQuestions !== totalItems) {
        return "complianceAudit-button";
      }

      const compliancelLevel = compliantItems / totalQuestions;
      if (compliancelLevel > complianceLevels.okThreshold) {
        return "complianceAudit-button complianceAudit-button-okStatus";
      } else if (
        compliancelLevel >= complianceLevels.warningThreshold &&
        compliancelLevel <= complianceLevels.okThreshold
      ) {
        return "complianceAudit-button complianceAudit-button-warningStatus";
      } else if (compliancelLevel < complianceLevels.warningThreshold) {
        return "complianceAudit-button complianceAudit-button-alertStatus";
      }
    };

    return (
      <Table className="report-table">
        <thead>
          <tr>
            <th className="complianceAudit-fixedWidth">Show Details</th>
            <th>Date</th>
            <th>Time</th>
            <th>Compliance Status </th>
            <th>Non-Compliance Recorded </th>
            <th>Checked Questions </th>
            <th>Comments</th>
            <th>
              Signed
              <br />
              by
            </th>
            <th>
              Witnessed
              <br />
              by
            </th>
            {showComplianceAudit.length > 0 && <th>Review</th>}
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item, index) => {
            const dateStrings = getDateStrings(item.time);
            return (
              <tr key={index}>
                <td className="complianceAudit-fixedWidth">
                  <Button
                    className={getComplianceStatusButtonClass (item.locationStockAuditDto)}
                    color="primary"
                    id={item.time}
                    type="checkbox"
                    variant="secondary"
                    checked={checked}
                    value="1"
                    onClick={handleButtonClick}
                  >
                    {showComplianceAudit.some((m) => m === item.time)
                      ? "-"
                      : "+"}
                  </Button>
                </td>
                <td className="min-height">
                  <time dateTime={item.time}>{dateStrings.date}</time>
                </td>
                <td>
                  <time dateTime={item.time}>{dateStrings.time}</time>
                </td>
                <td>{getComplianceQuestionsText(item.locationStockAuditDto)}</td>
                <td>{getNonComplianceRecorded(item.locationStockAuditDto)}</td>
                <td>{getCheckedQuestionsText(item.locationStockAuditDto)}</td>
                <td>{item.comments}</td>
                <td className="break-words" title={item.signedBy}>
                  {truncateString(item.signedBy, SIGNATURE_DISPLAY_MAX_LENGTH)}
                </td>
                <td className="break-words" title={item.witnessedBy}>
                  {truncateString(
                    item.witnessedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>

                {showComplianceAudit.some((m) => m === item.time) && (
                  <Row className="mt-3">
                    {item.locationStockAuditDto.map((aud, aux) => (
                      <Col
                        className="complianceAudit-receipt_item"
                        md="6"
                        key={aux}
                        id={aud["saId"]}
                      >
                        <Row>
                          {aud.isComplete ? (
                            <FontAwesomeIcon
                              className="complianceAudit-check-circle"
                              icon={faCheckCircle}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="complianceAudit-fa-check-cross"
                              icon={faTimesCircle}
                            />
                          )}
                          <span className="StockAuditReview-receipt_item-info">
                            {
                              wardStocks.find(
                                (element) => element.saId === aud["saId"]
                              )["name"]
                            }
                          </span>
                        </Row>
                        {aud["concerns"] ? (
                          <Row>
                            <FontAwesomeIcon
                              className="mr-3"
                              icon={faCommentAlt}
                            />
                            <div className="complianceAudit-comment">
                              {aud["concerns"]}
                            </div>
                          </Row>
                        ) : (
                          <></>
                        )}
                      </Col>
                    ))}
                  </Row>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <Col
      className={`StockAuditReview StockAuditReview-${locationRoute} report-page`}
    >
      <Row className="mb-4">
        <h1>Compliance Audits</h1>
      </Row>
      <Form
        className={cx("form horizontal", { "show-validity": showValidity })}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={
                formState.StartDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.StartDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.StartDate.innerRef}
                max={
                  (formState.EndDate.validity.valid &&
                    formState.EndDate.value) ||
                  maxDate
                }
                min={REPORT_FORM_MIN_DATE}
                onChange={handleFieldChange}
                {...formFields.StartDate}
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup
              title={
                formState.EndDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.EndDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.EndDate.innerRef}
                max={maxDate}
                min={
                  (formState.StartDate.validity.valid &&
                    formState.StartDate.value) ||
                  REPORT_FORM_MIN_DATE
                }
                onChange={handleFieldChange}
                {...formFields.EndDate}
              />
            </FormGroup>
          </Col>
          <Col className="buttons" md="2">
            <Button color="primary" disabled={isLoading} type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {report && renderReport()}
        <Loader show={isLoading} />
      </Row>
    </Col>
  );
}

export default GeneralStock;
