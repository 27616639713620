import React, { useContext, useState } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faReplyAll
} from "@fortawesome/free-solid-svg-icons";
import SystemContext from "../../context/SystemContext";
import FormularyDrugSearchList from "../../component/molecules/FormularyDrugSearchList";
import CDService from "../../services/CDService";

const AddToFormulary = () => {
  const { user } = useContext(SystemContext);

  const siteId = user["location"]["siteId"];
  const [searchDrugs, setSearchDrugs] = useState([]);
  const [modified, setModified] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchDrugsCall = (siteId, searchValue) => {
    return searchValue.length < 3
      ? Promise.resolve([])
      : CDService.getDrugsFromCatalogueRulingOutFormulary(siteId, searchValue);
  };

  const mapSearchDrugs = (searchDrugs) => {
    return searchDrugs.map((sd) => ({
      id: sd.catalogueId,
      catalogueId: sd.catalogueId,
      cdcatalogueNameOrFormularyName: sd.cdcatalogueNameOrFormularyName,
      isSafeCustody: sd.isSafeCustody,
      isWitnessed: sd.isWitnessed,
      vpId: sd.virtualId,
      apId: sd.id === sd.virtualId? "": sd.id,
      formularyPacksizes: [...sd.cdCataloguePackSize]
    }));
  };

  return (
    <Col>
      <Row className="mb-3">
        <Col md="6">
          <h1>Add CD from Catalog</h1>
        </Col>
        <Col md="6 d-flex align-items-end flex-column">
          <Link
            className="shadow-sm btn btn-primary"
            to="/pharmacy/formulary/manage"
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MANAGE FORMULARY
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormularyDrugSearchList
            siteId={siteId}
            searchDrugsCall={searchDrugsCall}
            minCharsSearch={2}
            setSearchDrugs={setSearchDrugs}
            mapSearchDrugs={mapSearchDrugs}
            searchDrugs={searchDrugs}
            setModified={setModified}
            modified={modified}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showColumns={{add: true, quantity: false, ap: true}}
            drugSearchPlaceholder="Search for drugs in the catalog (minimum of three characters)"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(AddToFormulary);
