import React from "react";
import { Spinner } from "reactstrap";

function Loader({ show = false, ...spinnerProps }) {
  if (!show) {
    return null;
  }

  return (
    <div className="Loader loader">
      <Spinner {...spinnerProps} />
    </div>
  );
}

export default Loader;
