import React, { useState } from "react";

import OrderTableLine from "./OrderTableLine";

const OrderTableBody = ({
  isPharmacy,
  orders,
  status,
  locationRoute,
  ordersrules,
  currentView,
  currentStatus,
  currentFilter,
  updateRows,
  deleteRow,
  ordersFilter,
  setAlertMessage,
  showLocations
}) => {
  const [selectedId, setSelectedId] = useState(undefined);

  const actionRow = (isChecked, localNXT, nxt, status, setchosenRow) => {
    if (isChecked) {
      const formulary = nxt.cdCatalogue?.formulary?.find((f) => f.siteId === nxt.siteId);
      updateRows({
        odId: localNXT["odId"],
        lcId: localNXT["lcId"],
        siteId: localNXT["siteId"],
        cdApId: nxt["cdApId"],
        cdVpId: localNXT["cdVpId"],
        catalogueId: localNXT["catalogueId"],
        stId: status["next"],
        isWitnessed: formulary? formulary.isWitnessed: nxt.cdCatalogue?.isWitnessed,
        isPatient: localNXT["isPatient"],
        ptId: localNXT.patient?.ptId
      });
      setSelectedId(localNXT["odId"]);
    } else {
      deleteRow();
      setSelectedId(undefined);
    }
    setchosenRow(isChecked);
  };

  return (
    <tbody>
      {orders.length > 0 ? (
        orders.map((dg, dgx) => (
          <OrderTableLine
            isPharmacy ={isPharmacy}
            key={dgx}
            nxt={dg}
            locationRoute={locationRoute}
            status={
              status ||
              ordersrules["initialStatusView"]["statusSet"].find(
                (obj) => obj["status"] === dg["status"]["name"]
              )
            }
            currentView={currentView}
            currentStatus={currentStatus}
            currentFilter={currentFilter}
            updateRows={updateRows}
            deleteRow={deleteRow}
            ordersFilter={{ currentFilter, currentStatus }}
            setAlertMessage={setAlertMessage}
            actionRow={actionRow}
            isSwitchChecked={selectedId === dg.odId}
            showLocations={showLocations}
          />
        ))
      ) : (
        <></>
      )}
    </tbody>
  );
};

export default OrderTableBody;
