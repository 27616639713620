import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import CheckableInputs from "./CheckableInputs";

function AuditLine({ audit, confirmComments, updateLine }) {
  const [checkStatus, setCheckStatus] = useState(audit.isComplete);
  const [comment, setComment] = useState(audit.concerns || "");
  const [showComment, setShowComment] = useState(false);

  function handleCheckboxChange({ target }) {
    if (target.checked) {
      const value = target.value === "true";

        setCheckStatus(value);
        setShowComment(!value);
        // if the value is toggled to true remove any concerns / comments
        if (value) {
            audit.concerns = null;
            confirmComments(audit.saId, null);
        }
        updateLine(audit.saId, value, true);
    } else {
        setCheckStatus();
        updateLine(audit.saId, false, false);
        setShowComment(false);
    }
  }

  function handleCommentChange({ target }) {
      setComment(target.value);
      confirmComments(audit.saId, target.value);
  }

  return (
    <tr id={audit["stockAudit"]["saId"]} className="stockAudit-line">
      <td>
        <div>
          <span className="stockAudit-line_name">{audit.stockAudit.name}</span>
        </div>
        {(showComment || (audit.concerns)) && (
           <div className="stockAudit-line_comment-block">
            <label htmlFor={"audit-comment-" + audit.saId}>Reasons for non-compliance:</label>
            <Input
              id={"audit-comment-" + audit.saId}
              onChange={handleCommentChange}
              type="textarea"
              value={comment}
            />
          </div>
        )}
      </td>
      <td>
        <CheckableInputs
          inline
          list={[
            {
              checked: checkStatus === true,
              className: "compliant-yes",
              value: true,
            },
            {
              checked: checkStatus === false,
              className: "compliant-no",
              icon: faTimes,
              value: false,
            },
          ]}
          onChange={handleCheckboxChange}
        />
      </td>
    </tr>
  );
}

export default AuditLine;
