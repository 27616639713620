import React from "react";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Location = ({ name, lcId, isOnline, url, copyUrl }) => {
  return (
    <div className="location-holder">
      <Link
        to={{
          pathname: url,
          state: { locationName: name, lcId: lcId, isOnline: isOnline },
        }}
        className="location-name text-dark shadow"
      >
        <p>{name}</p>
      </Link>
      <Link
        to={{
          pathname: copyUrl,
          state: { locationName: name, lcId: lcId, isOnline: isOnline, viewState: "clone" },
        }}
        className="location-copy text-body"
      >
        <FontAwesomeIcon className="location-copy-icon" icon={faClone} />
        Copy list
      </Link>
    </div>
  );
};

export default Location;
