ASL.confirmInitialState = [
  {
    label: "Signed by",
    name: "SignedBy",
    identifier: "sInput",
    value: "",
    state: false,
  },
];

ASL.confirmAdministerState = [
  {
    label: "Signed by",
    name: "signed",
    identifier: "sInput",
    value: "",
    state: false,
  },
  {
    label: "Witnessed by",
    name: "witnessed",
    identifier: "sInput",
    value: "",
    state: false,
  },
];

function ASL() {}

export default ASL;
