import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

import StockTableLine from "../../component/molecules/StockTableLine";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DiscrepancyModal from "../../component/organisms/DiscrepancyModal";
import PartialStockCheckModal from "../../component/organisms/PartialStockCheckModal";
import {
  getDateStrings,
  getTimestamp,
  longDateStringOptions,
  sortStockByPatient,
} from "../../helpers";
import StockService from "../../services/StockService";
import { showAlertMessage } from "../../store/actions";

const confirmInitialState = [
  {
    label: "Signed by",
    name: "signed",
  },
  {
    label: "Witnessed by",
    name: "witnessed",
  },
];
let serviceCancelSource;
let serviceConfig;
let timeoutId;

function PatientsOwnStock({ user, locationRoute }) {
  const dispatch = useDispatch();

  const [confirmed, setConfirmed] = useState(false);
  const [confirmStaff, setConfirmStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [latestStockCheck, setLatestStockCheck] = useState();
  const [stockList, setStockList] = useState([]);

  const [selectedStock, setSelectedStock] = useState([]);
  const [stockCheckView, setStockCheckView] = useState(false);
  const [stockCheckComplete, setStockCheckComplete] = useState(false);

  const [modalStock, setModalStock] = useState();
  const [showPartialStockCheckModal, setShowPartialStockCheckModal] =
    useState(false);

  const isPartialStockCheck =
    selectedStock.length > 0 && selectedStock.length < stockList.length;

    const hasLiquidDrug = !!stockList.find(
      (el) => el.cdCatalogue.cdCataloguePackSize[0]?.uom === "ml"
    );

    
  useEffect(async () => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    let isCancelled = false;

    try {
      const { data } = await StockService.getPatientsOwnStock(
        user.location.lcId,
        serviceConfig
      );

      setStockList(
        sortStockByPatient(data.filter((item) => item.runningBalance))
      );
    } catch (error) {
      if (StockService.isCancel(error)) {
        isCancelled = true;
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem getting the patient drug register, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }

    getLatestStockCheck();

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(timeoutId);
    };
  }, []);

  function handlePartialStockCheckModalToggle() {
    setShowPartialStockCheckModal(false);
  }

  function handleModalToggle() {
    setModalStock();
  }

  function updateSelectedStock(stock, newBalance, isDiscrepancy) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) =>
        selectedStock.map((item) => {
          if (item.stkId === stock.stkId) {
            return {
              ...stock,
              stockCheckValid: item.stockCheckValue === newBalance,
              stockCheckValue: item.stockCheckValue,
              runningBalance: newBalance,
              isDiscrepancy: isDiscrepancy,
              previousBalance: !item.previousBalance ? item.runningBalance : item.previousBalance
            };
          }

          return item;
        })
      );
    } else {
      setSelectedStock((selectedStock) =>
        selectedStock.map((item) => {
          if (item.stkPhId === stock.stkPhId) {
            return {
              ...stock,
              stockCheckValid: item.stockCheckValue === newBalance,
              stockCheckValue: item.stockCheckValue,
              runningBalance: newBalance,
              previousBalance: !item.previousBalance ? item.runningBalance : item.previousBalance
            };
          }

          return item;
        })
      );
    }
  }

  function handleModalComplete(stock, newBalance) {
    updateStockBalance(stock, newBalance, true);
    updateSelectedStock(stock, newBalance, true);
    setModalStock();
  }

  function updateStockBalance(stock, newBalance, isDiscrepancy) {
    if (stock.stkId !== undefined) {
      setStockList((stockList) =>
        stockList.map((item) => {
          if (item.stkId === stock.stkId) {
            return {
              ...stock,
              runningBalance: newBalance,
              isDiscrepancy: isDiscrepancy,
              previousBalance: !stock.previousBalance ? stock.runningBalance : stock.previousBalance
            };
          }

          return item;
        })
      );
    } else {
      setStockList((stockList) =>
        stockList.map((item) => {
          if (item.stkPhId === stock.stkPhId) {
            return { ...stock, runningBalance: newBalance };
          }

          return item;
        })
      );
    }
  }

  useEffect(() => {
    if (confirmed) {
      setConfirmedStaffErrors();

      if (!stockCheckComplete) {
        showAlertMessage(dispatch, {
          message: "Please complete the stock check",
          colour: "warning",
        });
        setConfirmed(false);
      } else if (!selectedStock.length) {
        showAlertMessage(dispatch, {
          message:
            "Please enter at least one checked balance to continue with the stock check",
          colour: "warning",
        });
        setConfirmed(false);
      } else {
        createStockCheck();
      }
    }
  }, [confirmed]);

  async function createStockCheck(comments) {
    const timestamp = getTimestamp();
    const stock = selectedStock.map(
      ({ cdApId, cdVpId, catalogueId, stId, stkId, runningBalance, isDiscrepancy, previousBalance, bagReference }) => ({
        catalogueId,
        cdApId,
        cdVpId,
        createdAt: timestamp,
        createdBy: user.username,
        lcId: user.location.lcId,
        signedAt: timestamp,
        signedBy: confirmStaff.signed,
        siteId: user.location.siteId,
        stkId,
        witnessedAt: timestamp,
        witnessedBy: confirmStaff.witnessed,
        runningBalance,
        isPatientsOwn: true,
        comments,
        itemsChecked: selectedStock.length,
        itemsToCheck: stockList.length,
        isDiscrepancy: isDiscrepancy,
        previousBalance: previousBalance,
        bagReference: bagReference
      })
    );
    let isCancelled = false;

    try {
      const response = await StockService.createStockCheck(
        user.location.lcId,
        false,
        stock,
        serviceConfig
      );

      if (response.status === 201) {
        showAlertMessage(dispatch, {
          message: "Stock check completed",
          colour: "success",
        });
        getLatestStockCheck();
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem performing the stock check, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } catch (error) {
      if (StockService.isCancel(error)) {
        isCancelled = true;
      } else {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem performing the stock check, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      }
    } finally {
      if (showPartialStockCheckModal) {
        setStockCheckComplete(!stockCheckComplete);
        handlePartialStockCheckModalToggle();
      }
      if (!isCancelled) {
        setConfirmed(false);
      }
    }
  }

  async function getLatestStockCheck() {
    try {
      const { data } = await StockService.getStockCheck(
        user.location.siteId,
        user.location.lcId,
        true,
        serviceConfig
      );
      if (data.createdBy) {
        setLatestStockCheck({
          signedBy: data.signedBy,
          ...getDateStrings(data.signedAt, longDateStringOptions),
        });
      }
    } catch (error) {
      if (!StockService.isCancel(error)) {
        showAlertMessage(dispatch, {
          message: `There was a problem getting the last stock check information, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "warning",
        });
      }
    }
  }

  function addStock(stock) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) => [
        ...selectedStock.filter((item) => item.stkId !== stock.stkId),
        stock,
      ]);
    } else {
      setSelectedStock((selectedStock) => [
        ...selectedStock.filter((item) => item.stkPhId !== stock.stkPhId),
        stock,
      ]);
    }
  }

  function removeStock(stock) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) =>
        selectedStock.filter((item) => item.stkId !== stock.stkId)
      );
    } else {
      setSelectedStock((selectedStock) =>
        selectedStock.filter((item) => item.stkPhId !== stock.stkPhId)
      );
    }
  }

  function handleStockCheckButtonClick() {
    setStockCheckView(!stockCheckView);
    setStockCheckComplete(false);
    setSelectedStock([]);
  }

  function handleStockCheckCompleteButtonClick() {
    if (!stockCheckComplete) {
      setShowPartialStockCheckModal(isPartialStockCheck);
    }
    if (!isPartialStockCheck || stockCheckComplete) {
      setStockCheckComplete(!stockCheckComplete);
    }
  }

  function getDefaultValue() {
    if (modalStock) {
      if (
        selectedStock.filter((item) => item.stkId === modalStock.stkId)
          .length === 1
      ) {
        return selectedStock.filter(
          (item) => item.stkId === modalStock.stkId
        )[0].stockCheckValue;
      } else if (
        selectedStock.filter((item) => item.stkPhId === modalStock.stkPhId)
          .length === 1
      ) {
        return selectedStock.filter(
          (item) => item.stkPhId === modalStock.stkPhId
        )[0].stockCheckValue;
      }
    } else {
      return null;
    }
  }

  return (
    <Col className="PatientsOwnStock">
      <Row>
        <Col sm="6">
          <h1>Patient’s Own Register</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="6"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon mr-3"
            to="/ward/patient/stock/add"
          >
            <FontAwesomeIcon className="on-left" icon={faLayerGroup} />
            ADD NEW
          </Link>
          <Button
            className={cx("shadow-sm btn-icon", { active: stockCheckView })}
            color="primary"
            disabled={!stockList.length}
            name="stock-check"
            onClick={handleStockCheckButtonClick}
          >
            <FontAwesomeIcon className="on-left" icon={faLayerGroup} />
            STOCK CHECK
          </Button>
        </Col>
      </Row>
      <Row>
        <Table hover>
          <thead>
            <tr>
              <th>Control Drug Name</th>
              <th>Patient</th>
              <th>Bag Reference</th>
              <th>Unit Dose</th>
              {(!stockCheckView || stockCheckComplete) && (
                <th>
                  Running
                  <br />
                  Balance
                </th>
              )}
              {stockCheckView && (
                <>
                  <th>
                    Checked
                    <br />
                    Balance
                  </th>
                  <th />
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {stockList.map((item, index) => (
              <StockTableLine
                addStock={addStock}
                key={index}
                locationRoute={locationRoute}
                removeStock={removeStock}
                stock={item}
                stockCheck={stockCheckView}
                stockCheckComplete={stockCheckComplete}
                onDiscrepancyClick={() => setModalStock(item)}
                hasLiquidDrug={hasLiquidDrug}
              />
            ))}
          </tbody>
        </Table>
        {isLoading ? (
          <div className="loader">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading…</span>
            </div>
          </div>
        ) : (
          !stockList.length && (
            <p className="page-message">* No current stock *</p>
          )
        )}
      </Row>
      {stockCheckView && (
        <>
          <Button
            color="primary"
            className="check-complete-btn btn-icon"
            onClick={handleStockCheckCompleteButtonClick}
            disabled={
              selectedStock.length === 0 ||
              selectedStock.some((item) => !item.stockCheckValid)
            }
          >
            {stockCheckComplete ? "Edit Stock Check" : "Check Complete"}
            <FontAwesomeIcon
              className="on-right"
              icon={stockCheckComplete ? faEdit : faCheckCircle}
            />
          </Button>
          {stockCheckComplete && selectedStock.length === stockList.length && (
            <ConfirmBlock
              confirmedStaffErrors={confirmedStaffErrors}
              confirmInitialState={confirmInitialState}
              inputsConfirmed={confirmed}
              setConfirmedStaff={setConfirmStaff}
              setInputsConfirmed={setConfirmed}
              title="Stock Check"
            />
          )}
        </>
      )}
      {latestStockCheck && (
        <Row>
          <Col>
            <div className="stock-check">
              <p>
                Last Stock Check signed by{" "}
                <span className="created-by">{latestStockCheck.signedBy}</span>
              </p>
              <dl>
                <div>
                  <dt>Date:</dt>
                  <dd>{latestStockCheck.date}</dd>
                </div>
                <div>
                  <dt>Timestamp:</dt>
                  <dd>{latestStockCheck.time}</dd>
                </div>
              </dl>
            </div>
          </Col>
        </Row>
      )}
      <DiscrepancyModal
        isOpen={Boolean(modalStock)}
        onComplete={handleModalComplete}
        stock={modalStock}
        toggle={handleModalToggle}
        defaultValue={getDefaultValue()}
      />
      <PartialStockCheckModal
        isOpen={Boolean(showPartialStockCheckModal)}
        toggle={handlePartialStockCheckModalToggle}
        defaultValue={getDefaultValue()}
        confirmedStaffErrors={confirmedStaffErrors}
        confirmBlocktitle="Daily check"
        setConfirmedStaff={setConfirmStaff}
        confirmInitialState={confirmInitialState}
        createStockCheck={createStockCheck}
      />
    </Col>
  );
}

export default PatientsOwnStock;
