import React, { memo } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const PatientLine = ({ patient, showPatient }) => {
  const { ptId, firstname, lastname, patientName, dob, uniqueIdentifier, currentBed } = patient;

  const handlePatientClick = (e) => {
    e.preventDefault();
    showPatient(ptId);
  };

  return (
    <tr className="shadow-sm" key={ptId}>
      <td className="drug-name cd-item-info break-words align-top drugline">
        <Link className="table-link" onClick={handlePatientClick}>
          {patientName}
        </Link>
      </td>
      <td className="align-top drugline">
        {dob && <Moment format="DD MMM YYYY">{dob}</Moment> }
      </td>
      <td className="align-top drugline">{currentBed}</td>
    </tr>
  );
};

export default memo(PatientLine);
