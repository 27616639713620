import React, { useEffect, useState } from "react";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Col, Row } from "reactstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const SignInput = ({
  stId,
  label,
  name,
  isDisabled,
  type,
  placeholder,
  updateinputs,
  setInputs,
  isChangeClass,
  disabledModeValue,
}) => {
  const [inputAlert, setInputAlert] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputClass, setInputClass] = useState(false);

  const inputTerm = (e) => {
    setInputValue(e.target.value);
  };

  const pinConfirm = () => {
    if (inputValue.length > 4) {
      setInputClass(true);
      stId
        ? setInputs([
            ...updateinputs,
            {
              stId: stId,
              name: name,
              label: label,
              value: inputValue,
              state: true,
            },
          ])
        : setInputs([
            ...updateinputs,
            {
              name: name,
              label: label,
              value: inputValue,
              state: true,
            },
          ]);
      setInputAlert(false);
    } else {
      setInputAlert(true);
    }
  };

  useEffect(() => {
    if (isChangeClass) {
      setInputClass(false);
      setInputValue("");
    }
  }, [isChangeClass]);

  return (
    <Row
      className={`sign-holder ${inputClass && "confirmed"} ${
        inputAlert && "alert-error"
      }`}
    >
      <Col md="4">
        {inputAlert && (
          <span className="alert-icon">
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        )}
        <label htmlFor={name}>
          <span className="mandatory-label">*</span>
          {label} :
        </label>
      </Col>
      <Col md="4">
        <Input
          className={`sign-input ${
            isDisabled ? "" : !inputClass ? "shadow" : inputClass
          } ${inputAlert && "alert-input"}`}
          type={type}
          id={name}
          name={name}
          disabled={isDisabled}
          placeholder={placeholder}
          value={isDisabled ? disabledModeValue || "" : inputValue}
          onChange={inputTerm}
          readOnly={inputClass}
          autoComplete="off"
        />
      </Col>
      <Col md="4">
        <Button
          className="sign-button"
          disabled={isDisabled || inputClass}
          onClick={(e) => {
            e.preventDefault();
            pinConfirm();
          }}
        >
          <span className="sign-check-off">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          <span>{!inputClass ? "Confirm" : "Confirmed"}</span>
        </Button>
      </Col>
    </Row>
  );
};

export default SignInput;
