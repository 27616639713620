import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Row, Col } from "reactstrap";

import ConfirmBlock from "./ConfirmBlock";

import AuditService from "../../services/AuditService";
import CommentsBlock from "../../component/molecules/CommentsBlock";

let redirectTimeoutId;
let messageTimeoutId;

const StockAuditReview = ({
  auditItems,
  auditSend,
  isPharmacy,
  locationRoute,
  setAlertMessage,
 user,
  isPartialAudit,
}) => {

    const history = useHistory();
  


  const initialState = [
    {
      label: "Signed by",
      name: "signed",
      value: "",
      state: false,
    },
    {
      label: "Witnessed by",
      name: "witnessed",
      value: "",
      state: false,
    },
  ];

  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState(); 
    const [complianceComments, setComplianceComments] = useState("");
  const [listItems, setListItems] = useState([]);

    const inputTerm = (e) => {
        setComplianceComments(e.target.value);
    };

    const sendAudit = (auditDeets) => {

        if (isPartialAudit && complianceComments === "") {
            setAlertMessage({
                message:
                    "Please enter a reason for a partial compliance audit",
                colour: "warning",
                label: "Warning message",
            });
            setInputsConfirmed(false);
        }
        else
        {
            AuditService.postWardStockAudit(user.location.lcId, auditDeets)
                .then((response) => {
                    if (response.status === 201) {
                        redirectTimeoutId = setTimeout(() => {
                            history.push(`/${locationRoute}/dashboard`);
                        }, 5000);
                        setAlertMessage({
                            message: "Audit completed",
                            colour: "success",
                            timeout: 5000,
                        });
                    } else {
                        setAlertMessage({
                            message: `There was a problem creating the list for this location, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
                            colour: "danger",
                            label: "Error message",
                        });
                        setInputsConfirmed(false);
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setConfirmedStaffErrors(error.response.data);
                    } else {
                        setAlertMessage({
                            message: `There was a problem creating the list for this location, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
                            colour: "danger",
                            label: "Error message",
                        });
                    }

                    setInputsConfirmed(false);
                });
        }
  };

  const getAuditItem = (saId) =>
    auditItems.find((ai) => ai.stockAudit.saId === saId);

  useEffect(() => {
    return () => {
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    /* sort list so that all concerns are shown at the top */
    setListItems(
      auditSend
        .sort((a, b) => {
          if (a.concerns && !b.concerns) {
            return 1;
          }

          if (!a.concerns && b.concerns) {
            return -1;
          }

          return 0;
        })
        .reverse()
    );
  }, [auditSend]);

    useEffect(() => {

    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      const auditSign = auditSend.map((item) => {
        const updatedItem = {
          ...item,
          isPharmacy,
          createdBy: user.username,
          signedBy: confirmedStaff.signed,
          witnessedBy: confirmedStaff.witnessed,
          Comments: complianceComments ?? null
        };
        return updatedItem;
      });

      sendAudit(auditSign);
    }
  }, [inputsConfirmed]);

    return (

    <Row className="StockAuditReview">
      <Col>
        <Row>
          <Col className="StockAuditReview-receipt pb-3 pt-3">
            <h4>
              Date: <Moment format="dddd Do MMM YYYY" />
            </h4>
            <Row className="mt-3">
              {listItems.map((adts) => (
                <Col
                  className="StockAuditReview-receipt_item"
                  md="6"
                  key={adts.saId}
                  id={adts.saId}
                >

                  <Row>
                    {adts?.isComplete ? (
                      <FontAwesomeIcon className="mr-3" icon={faCheckCircle} />
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon={faTimesCircle} />
                    )}
                    <span className="StockAuditReview-receipt_item-info">
                      {getAuditItem(adts.saId)?.stockAudit?.name}
                    </span>
                  </Row>
                  {adts?.concerns ? (
                    <Row>
                      <FontAwesomeIcon className="mr-3" icon={faCommentAlt} />
                      <div className="StockAuditReview-comment">
                        {adts?.concerns}
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              ))}
              </Row>
              <Row>
                {isPartialAudit && (
                    <CommentsBlock comments={complianceComments} setComments={inputTerm} required={true} />
                    )
                }
               </Row>
          </Col>
          </Row>
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          setInputsConfirmed={setInputsConfirmed}
          confirmInitialState={initialState}
          inputsConfirmed={inputsConfirmed}
          setConfirmedStaff={setConfirmedStaff}
        />
      </Col>
    </Row>
  );
};

export default React.memo(StockAuditReview);
