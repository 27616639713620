import axios from "axios";
import AuthService from "./AuthService";

const ReportService = {};

ReportService.getCancelSource = () => axios.CancelToken.source();
ReportService.isCancel = axios.isCancel;

ReportService.getPharmacyDisposed = async (locationId, config) => {
  return await AuthService.get(
    `/report/pharmacy/disposed/${locationId}`,
    config
  );
};

ReportService.getPharmacyOrders = async (siteId, config) => {
  return await AuthService.get(`/report/pharmacy/orders/${siteId}`, config);
};

ReportService.getWardBalances = async (siteId, config) => {
  return await AuthService.get(`/report/pharmacy/CDBalanceWard/${siteId}`, config);
};

ReportService.getWardOrders = async (locationId, config) => {
  return await AuthService.get(`/report/ward/orders/${locationId}`, config);
};

ReportService.getWardStockCheck = async (locationId, config) => {
    return await AuthService.get(`/report/ward/stockcheck/${locationId}`, config);
};

ReportService.getWardStockHistory = async (locationId, config) => {
  return await AuthService.get(
    `/report/ward/stockhistory/${locationId}`,
    config
  );
};

ReportService.getPharmacyStockHistory = async (locationId, config) => {
  return await AuthService.get(
    `/report/pharmacy/stockhistory/${locationId}`,
    config
  );
};

ReportService.getComplianceAuditHistory = async (locationId, config) => {
  return await AuthService.get(
    `/report/ComplianceAuditHistory/${locationId}`,
    config
  );
};

export default ReportService;
