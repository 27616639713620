import React, { useState, useEffect, useContext, useCallback } from "react";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import { faReplyAll, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckableInputs from "../../component/molecules/CheckableInputs";
import CommentsBlock from "../../component/molecules/CommentsBlock";
import SystemContext from "../../context/SystemContext";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../store/actions";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";

import LocationsService from "../../services/LocationsService";

const EditLocation = ({ location }) => {
  const [lcId] = useState(location?.state?.lcId ?? "");
  const [currentLocation, setCurrentLocation] = useState({});
  const [originalLocation, setOriginalLocation] = useState({});
  const {
    location: { siteId },
    username,
  } = useContext(SystemContext).user;

  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [comments, setComments] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const initialState = [
    {
      label: "Signed by",
      name: "signed",
      value: "",
      state: false,
    },
    {
      label: "Witnessed by",
      name: "witnessed",
      value: "",
      state: false,
    },
  ];

  const handleFieldChange = (fieldName, newValue) => {
    setCurrentLocation({
      ...currentLocation,
      [fieldName]: newValue,
    });
  };

  const getLocation = (locationId) => {
    LocationsService.getLocation(siteId, locationId).then((response) => {
      const location = {
        ...response.data,
        history: response?.data?.locationHistory
          ? response.data.locationHistory.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          : [],
      };
      setCurrentLocation(location);
      setOriginalLocation(location);
    });
  };

  const handleCommentsChange = useCallback(
    ({ target: { value } }) => {
      setComments(value);
    },
    [setComments]
  );

  const saveModifications = () => {
    if (currentLocation && !currentLocation !== {} && inputsConfirmed) {
      currentLocation.signedBy = confirmedStaff.signed;
      currentLocation.witnessedBy = confirmedStaff.witnessed;
      currentLocation.createdBy = username;
      currentLocation.comments = comments;
      LocationsService.updateLocation(siteId, lcId, currentLocation)
        .then((result) => {
          showAlertMessage(dispatch, {
            message: "Location updated successfully",
            colour: "success",
          });
          getLocation(result.data.lcId);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
          showAlertMessage(dispatch, {
            message: `Location update failed, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
        })
        .finally(() => {
          setInputsConfirmed(false);
          setComments("");
        });
    }
  };

  const confirmSaveLocation = (isPrescription) => {
    if (originalLocation.isOnline && !currentLocation.isOnline) {
      const options = {
        title: "Update Location",
        // eslint-disable-next-line quotes
        message: `Are you sure you want to switch from online to offline mode?\
                 If you switch to offline, you'll need to manually manage the remaining stock \
                 in the location through the stock screen.`,
        buttons: [
          {
            label: "Yes",
            onClick: () => saveModifications(),
          },
          {
            label: "No",
            onClick: () => {
              setInputsConfirmed(false);
            },
          },
        ],
      };
      confirmAlert(options);
    } else {
      saveModifications();
    }
  };

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();
      confirmSaveLocation();
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    getLocation(lcId);
  }, [lcId]);

  const renderHistoryBlock = (
    <Row className="pb-3">
      <Col>
        <h5>History</h5>
        <table className="history-table w-100">
          <thead>
            <th>Date</th>
            <th>Time</th>
            <th>Name</th>
            <th className="desktop-only">Ward Info</th>
            <th>Is Online</th>
            <th>Signed by</th>
            <th>Witnessed by</th>
            <th>Comments</th>
          </thead>
          <tbody>
            {currentLocation?.history &&
              currentLocation.history.map((item, index) => (
                <tr key={`hist-${item.lcHistoryId}`}>
                  <td>
                    <Moment format="DD/MM/YY">{item.createdAt}</Moment>
                  </td>
                  <td>
                    <Moment format="HH:mm">{item.createdAt}</Moment>
                  </td>
                  <td>{item.name}</td>
                  <td className="desktop-only">{item.wardInfo}</td>
                  <td>{item.isOnline ? "Yes" : "No"}</td>
                  <td>{item.signedBy}</td>
                  <td>{item.witnessedBy}</td>
                  <td>{item.comments}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );

  const displayLocationBlock = (
    <Row>
      <Col>
        <Table className="mt-5">
          <thead>
            <tr>
              <th>Name</th>
              <th className="desktop-only">Ward Info</th>
              <th className="desktop-only">Type</th>
              <th>Is Online</th>
            </tr>
          </thead>
          <tbody>
            <tr className="shadow-sm">
              <td className="drug-name">
                <Input
                  id="name"
                  className="align-left custom-select-sm text-left"
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                  type="text"
                  value={currentLocation.name ?? ""}
                  maxLength="50"
                />
              </td>
              <td className="desktop-only">{currentLocation.wardInfo}</td>
              <td className="desktop-only location-type">
                {currentLocation?.locationTypes &&
                  currentLocation.locationTypes
                    .map((lt) => lt.locationType)
                    .join("\n")}
              </td>
              <td>
                <span className="d-flex justify-content-center">
                  <CheckableInputs
                    inline
                    list={[
                      {
                        checked: currentLocation.isOnline === true,
                        id: "isOnline",
                        className: "compliant-yes",
                      },
                    ]}
                    onChange={(e) =>
                      handleFieldChange("isOnline", e.target.checked)
                    }
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );

  return (
    <Col className="location">
      <Row>
        <Col xs>
          <h1 className="text-nowrap">Update Location</h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-baseline" xs>
          <Button
            color="primary"
            type="checkbox"
            variant="secondary"
            value="1"
            onClick={history.goBack}
          >
            <FontAwesomeIcon
              className="back-btn"
              icon={faReplyAll}
              alt="Go Back"
              aria-label="Go Back"
            />
            <span className="desktop-only">BACK TO MANAGE LOCATIONS</span>
            <span className="mobile-only">BACK</span>
          </Button>
        </Col>
      </Row>
      {displayLocationBlock}
      <Row>
        <Col>
          <CommentsBlock
            comments={comments}
            setComments={handleCommentsChange}
            required={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-4">
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            setInputsConfirmed={setInputsConfirmed}
            confirmInitialState={initialState}
            inputsConfirmed={inputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
          />
        </Col>
      </Row>
      {renderHistoryBlock}
    </Col>
  );
};

export default EditLocation;
