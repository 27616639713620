import React from "react";
const forDisposal = "ForDisposal";

function ReturnTableHeader({ tableViewStatus, action }) {
  return (
    <thead>
      <tr>
        <th className="ReturnOrders-set-status"></th>
        <th></th>
        {tableViewStatus === forDisposal ? (
          <th>Authorised Witness Required</th>
        ) : null}
        <th>Location</th>
        <th>Created At</th>
        <th>Reference</th>
        <th>Quantity</th>
        <th>
          {tableViewStatus === "default" // if the view is default
            ? "Status" // then always show the last block as status
            : "Select"}
        </th>
      </tr>
    </thead>
  );
}

export default ReturnTableHeader;
