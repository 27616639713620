import { HIDE_ALERT_MESSAGE, SHOW_ALERT_MESSAGE } from "../actions";

const initialState = {
  alertMessage: {
    show: false,
  },
};

const reducer = (state = initialState, { type, alertMessage }) => {
  switch (type) {
    case HIDE_ALERT_MESSAGE:
      return { ...state, alertMessage: { ...state.alertMessage, show: false } };
    case SHOW_ALERT_MESSAGE:
      return { ...state, alertMessage: { ...alertMessage, show: true } };
    default:
      return state;
  }
};

export default reducer;
