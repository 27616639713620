import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faLayerGroup,
  faPlus,
  faShuffle
} from "@fortawesome/free-solid-svg-icons";

import SearchInput from "../component/molecules/SearchInput";
import StockTableLine from "../component/molecules/StockTableLine";
import ConfirmBlock from "../component/organisms/ConfirmBlock";
import DiscrepancyModal from "../component/organisms/DiscrepancyModal";
import PartialStockCheckModal from "../component/organisms/PartialStockCheckModal";
import PaginationBlock from "../component/organisms/PaginationBlock";
import { initialStateSignedWitnessed } from "../config/confirm";
import {
  longDateStringOptions,
  getDateStrings,
  getTimestamp,
  sortStock,
} from "../helpers";
import StockService from "../services/StockService";
import { showAlertMessage } from "../store/actions";

const PAGINATION_SIZE = 1000;
const Pharmacy = "pharmacy";
const Ward = "ward";

const CurrentStock = ({
  isPharmacy,
  isWard,
  locationId,
  locationRoute,
  user,
}) => {
  const dispatch = useDispatch();

  // stock list
  const [stockCheckView, setStockCheckView] = useState(false);
  const [stockCheckComplete, setStockCheckComplete] = useState(false);
  //search value to filter asl list
  const [searchValue, setsearchValue] = useState("");
  // clear searchDrugs list
  const [clear, setclear] = useState(false);

  const [inputsConfirmed, setInputsConfirmed] = useState(false);

  const [stockList, setstockList] = useState([]);
  // filtered drug list by searchValue
  const [searchDrugs, setsearchDrugs] = useState([]);
  const [confirmedStaff, setConfirmedStaff] = useState([]);
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [selectedStock, setSelectedStock] = useState([]);
  const [stockCheck, setStockCheck] = useState();

  const [modalStock, setModalStock] = useState();
  const [showPartialStockCheckModal, setShowPartialStockCheckModal] =
    useState(false);
  
  const addStockInWardEnabled = useSelector(
    (state) => state.settings.addStockInWardEnabled
  );
  const addCDInPharmacyEnabled = useSelector(
    (state) => state.settings.addCDInPharmacyEnabled
  );
  const isPartialStockCheck =
    selectedStock.length > 0 && selectedStock.length < searchDrugs.length;
  const hasLiquidDrug = !!searchDrugs.find(
    (el) => el.cdCatalogue.cdCataloguePackSize[0]?.uom === "ml"
  );

  function createStockCheck(comments) {
    const timestamp = getTimestamp();

    const stock = selectedStock.map(
      ({ stkId, stkPhId, catalogueId, cdVpId, cdApId = null, runningBalance, isDiscrepancy, previousBalance }) => ({
        siteId: user.location.siteId,
        ...(stkId !== undefined ? { stkId } : {}),
        ...(stkPhId !== undefined ? { stkPhId } : {}),
            lcId: user.location.lcId,
            catalogueId,
        cdVpId,
            cdApId,
        signedBy: confirmedStaff.signed,
        signedAt: timestamp,
        witnessedBy: confirmedStaff.witnessed,
        witnessedAt: timestamp,
        createdBy: user.username,
        createdAt: timestamp,
        runningBalance,
        isPatientsOwn: false,
        comments,
        itemsChecked: selectedStock.length,
            itemsToCheck: searchDrugs.length,
            isDiscrepancy: isDiscrepancy,
            previousBalance: previousBalance,
      })
    );

    StockService.createStockCheck(user.location.lcId, isPharmacy, stock)
      .then((response) => {
        if (response.status === 201) {
          showAlertMessage(dispatch, {
            message: "Stock check completed",
            colour: "success",
          });
          getStockCheck();
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem performing the stock check, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem performing the stock check, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      })
      .finally(() => {
        if (showPartialStockCheckModal) {
          setStockCheckComplete(!stockCheckComplete);
          handlePartialStockCheckModalToggle();
        } else {
          setInputsConfirmed(false);
        }
      });
  }

  function getStockCheck() {
    StockService.getStockCheck(user.location.siteId, user.location.lcId, false)
      .then(({ data }) => {
        if (data.signedBy) {
          setStockCheck({
            signedBy: data.signedBy,
            ...getDateStrings(data.createdAt, longDateStringOptions),
          });
        }
      })
      .catch((error) => {
        showAlertMessage(dispatch, {
          message: `There was a problem getting the stock check, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "warning",
        });
      });
  }

  function addStock(stock) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) => [
        ...selectedStock.filter((item) => item.stkId !== stock.stkId),
        stock,
      ]);
    } else {
      setSelectedStock((selectedStock) => [
        ...selectedStock.filter((item) => item.stkPhId !== stock.stkPhId),
        stock,
      ]);
    }
  }

  function removeStock(stock) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) =>
        selectedStock.filter((item) => item.stkId !== stock.stkId)
      );
    } else {
      setSelectedStock((selectedStock) =>
        selectedStock.filter((item) => item.stkPhId !== stock.stkPhId)
      );
    }
  }

  function updateStockBalance(stock, newBalance, isDiscrepancy) {
    if (stock.stkId !== undefined) {
      setstockList((stockList) =>
        stockList.map((item) => {
            if (item.stkId === stock.stkId) {
                return {
                    ...stock,
                    runningBalance: newBalance,
                    isDiscrepancy: isDiscrepancy,
                    previousBalance: !stock.previousBalance ? stock.runningBalance : stock.previousBalance
                };
          }

          return item;
        })
      );
      setsearchDrugs((searchDrugs) =>
        searchDrugs.map((item) => {
            if (item.stkId === stock.stkId) {
                return {
                    ...stock,
                    runningBalance: newBalance,
                    isDiscrepancy: isDiscrepancy,
                    previousBalance: !stock.previousBalance ? stock.runningBalance : stock.previousBalance
                };
          }

          return item;
        })
      );
    } else {
      setstockList((stockList) =>
        stockList.map((item) => {
            if (item.stkPhId === stock.stkPhId) {
                return {
                    ...stock,
                    runningBalance: newBalance,
                    isDiscrepancy: isDiscrepancy,
                    previousBalance: !stock.previousBalance ? stock.runningBalance : stock.previousBalance
                };
          }

          return item;
        })
      );
      setsearchDrugs((searchDrugs) =>
        searchDrugs.map((item) => {
            if (item.stkPhId === stock.stkPhId) {
                return {
                    ...stock,
                    runningBalance: newBalance,
                    isDiscrepancy: isDiscrepancy,
                    previousBalance: !stock.previousBalance ? stock.runningBalance : stock.previousBalance
                };
          }

          return item;
        })
      );
    }
  }

  useEffect(() => {
    if (isPharmacy) {
      StockService.getPharmacyStock(user.location.lcId)
        .then((response) => {
          const sortedStock = sortStock(response.data);

          setstockList(sortedStock);
          setsearchDrugs(sortedStock);
        })
        .catch(() => {
          showAlertMessage(dispatch, {
            message: `Pharmacy stock list is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        });
    }

    getStockCheck();
  }, []);

  useEffect(() => {
    setstockList([]);
    setsearchDrugs([]);

    if (isWard) {
      StockService.wardStock(locationId)
        .then((response) => {
          const sortedStock = sortStock(response.data);

          setstockList(sortedStock);
          setsearchDrugs(sortedStock);
        })
        .catch(() =>
          showAlertMessage(dispatch, {
            message: `There was an issue collecting the stock items, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          })
        );
    }
  }, [locationId]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (!stockCheckComplete) {
        showAlertMessage(dispatch, {
          message: "Please complete the stock check",
          colour: "warning",
        });
        setInputsConfirmed(false);
      } else if (!selectedStock.length) {
        showAlertMessage(dispatch, {
          message:
            "Please enter at least one checked balance to continue with the stock check",
          colour: "warning",
        });
        setInputsConfirmed(false);
      } else {
        createStockCheck();
      }
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    if (searchValue) {
      setclear(false);
      setsearchDrugs(
        stockList.filter((item) =>
          item["cdCatalogue"]["cdcatalogueNameOrFormularyName"]
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
    } else {
      setclear(true);
      setsearchDrugs(stockList);
    }
  }, [searchValue]);

  useEffect(() => {
    setsearchDrugs(stockList);
  }, [stockCheckView]);

  function handleStockCheckButtonClick() {
    setStockCheckView(!stockCheckView);
    setStockCheckComplete(false);
    setSelectedStock([]);
  }

  function handleStockCheckCompleteButtonClick() {
    if (!stockCheckComplete) {
      setShowPartialStockCheckModal(isPartialStockCheck);
    }
    if (!isPartialStockCheck || stockCheckComplete) {
      setStockCheckComplete(!stockCheckComplete);
    }
  }

  function handlePartialStockCheckModalToggle() {
    setShowPartialStockCheckModal(false);
  }

  function handleModalToggle() {
    setModalStock();
  }

  function updateSelectedStock(stock, newBalance, isDiscrepancy) {
    if (stock.stkId !== undefined) {
      setSelectedStock((selectedStock) =>
        selectedStock.map((item) => {
          if (item.stkId === stock.stkId) {
            return {
              ...stock,
              stockCheckValid: item.stockCheckValue === newBalance,
                stockCheckValue: item.stockCheckValue,
                runningBalance: newBalance,
                isDiscrepancy: isDiscrepancy,
                previousBalance: !item.previousBalance ? item.runningBalance : item.previousBalance
            };
          }

          return item;
        })
      );
    } else {
      setSelectedStock((selectedStock) =>
        selectedStock.map((item) => {
          if (item.stkPhId === stock.stkPhId) {
            return {
              ...stock,
              stockCheckValid: item.stockCheckValue === newBalance,
              stockCheckValue: item.stockCheckValue,
                runningBalance: newBalance,
                isDiscrepancy: isDiscrepancy,
                previousBalance: !item.previousBalance ? item.runningBalance : item.previousBalance
            };
          }

          return item;
        })
      );
    }
  }

  function getDefaultValue() {
    if (modalStock) {
      if (
        selectedStock.filter((item) => item.stkId === modalStock.stkId)
          .length === 1
      ) {
        return selectedStock.filter(
          (item) => item.stkId === modalStock.stkId
        )[0].stockCheckValue;
      } else if (
        selectedStock.filter((item) => item.stkPhId === modalStock.stkPhId)
          .length === 1
      ) {
        return selectedStock.filter(
          (item) => item.stkPhId === modalStock.stkPhId
        )[0].stockCheckValue;
      }
    } else {
      return null;
    }
  }

    function handleModalComplete(stock, newBalance) {
    updateStockBalance(stock, newBalance, true);
    updateSelectedStock(stock, newBalance, true);
    setModalStock();
  }

  const renderTabletBody = () => {
    if (searchDrugs.length > PAGINATION_SIZE) {
      return (
        <PaginationBlock
          dataSet={searchDrugs}
          pageSize={PAGINATION_SIZE}
          renderChildren={(nxt) => (
            <StockTableLine
              addStock={addStock}
              key={nxt.stkId ?? nxt.stkPhId}
              locationRoute={locationRoute}
              removeStock={removeStock}
              stock={nxt}
              stockCheck={stockCheckView}
              stockCheckComplete={stockCheckComplete}
              onDiscrepancyClick={() => setModalStock(nxt)}
              hasLiquidDrug={hasLiquidDrug}
              user={user}
            />
          )}
        />
      );
    }

    return searchDrugs.map((nxt) => (
      <StockTableLine
        addStock={addStock}
        key={nxt.stkId ?? nxt.stkPhId}
        locationRoute={locationRoute}
        removeStock={removeStock}
        stock={nxt}
        stockCheck={stockCheckView}
        stockCheckComplete={stockCheckComplete}
        onDiscrepancyClick={() => setModalStock(nxt)}
        hasLiquidDrug={hasLiquidDrug}
        user={user}
      />
    ));
  };

  return (
    <Col className="CurrentStock">
      <Row>
        <Col xl="3" className="mb-3">
          <h1>Current stock</h1>
        </Col>
        <Col
          xl="9"
          className="d-flex align-items-baseline justify-content-end flex-row"
        >
          <SearchInput
            inputClass="CurrentStock-search"
            name="drug search"
            type="text"
            placeholder="Drug search"
            value={setsearchValue}
            clear={clear}
          />
          {locationRoute === Pharmacy &&
            <Link
              className="shadow-sm btn btn-primary btn-icon ml-3"
              to={`/${locationRoute}/stock/transfer`}
            >
              CREATE IP TRANSFER
              <FontAwesomeIcon className="on-right" icon={faShuffle} />
            </Link>
          }
          {(locationRoute === Pharmacy ||
           (locationRoute === Ward && addStockInWardEnabled)) && (
            <Link
              className="shadow-sm btn btn-primary btn-icon ml-3"
              to={`/${locationRoute}/stock/add`}
            >
              ADD NEW SUPPLY
              <FontAwesomeIcon className="on-right" icon={faLayerGroup} />
            </Link>
          )}
          {(locationRoute === Ward ||
          (locationRoute === Pharmacy && addCDInPharmacyEnabled)) && (
            <Link
              className="shadow-sm btn btn-primary btn-icon ml-3"
              to={`/${locationRoute}/stock/add-cd`}
            >
              ADD CD
              <FontAwesomeIcon className="on-right" icon={faPlus} />
            </Link>
          )}
          <Button
            color="primary"
            className={`shadow-sm ml-3 action-btn btn-icon ${
              stockCheckView ? "active" : ""
            }`}
            onClick={handleStockCheckButtonClick}
          >
            STOCK CHECK
            <FontAwesomeIcon className="on-right" icon={faLayerGroup} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table hover>
            <thead>
              <tr>
                {(user.location.isTheatre || user.location.managePharmacyAsTheatre) &&
                  <th className="alert-theatre"></th>
                }
                <th>Name</th>
                <th>Unit Dose</th>
                {(!stockCheckView || stockCheckComplete) && (
                  <th>
                    Running
                    <br />
                    Balance
                  </th>
                )}
                {stockCheckView && hasLiquidDrug && (
                  <>
                    <th>
                      Current
                      <br />
                      Balance
                    </th>
                  </>
                )}
                {stockCheckView && (
                  <>
                    <th>
                      Checked
                      <br />
                      Balance
                    </th>
                    <th />
                  </>
                )}
              </tr>
            </thead>
            <tbody>{searchDrugs && renderTabletBody()}</tbody>
          </Table>
        </Col>
      </Row>
      {stockCheckView && (
        <>
          <Button
            color="primary"
            className="check-complete-btn btn-icon"
            onClick={handleStockCheckCompleteButtonClick}
            disabled={
              selectedStock.length === 0 ||
              selectedStock.some((item) => !item.stockCheckValid)
            }
          >
            {stockCheckComplete ? "Edit Stock Check" : "Check Complete"}
            <FontAwesomeIcon
              className="on-right"
              icon={stockCheckComplete ? faEdit : faCheckCircle}
            />
          </Button>
          {stockCheckComplete &&
            selectedStock.length === searchDrugs.length && (
              <ConfirmBlock
                confirmedStaffErrors={confirmedStaffErrors}
                title="Daily check"
                inputsConfirmed={inputsConfirmed}
                setInputsConfirmed={setInputsConfirmed}
                setConfirmedStaff={setConfirmedStaff}
                confirmInitialState={initialStateSignedWitnessed}
              />
            )}
        </>
      )}
      {stockCheck && (
        <Row>
          <Col>
            <div className="stock-check">
              <p>
                Last Stock Check Conducted by{" "}
                <span className="created-by">{stockCheck.signedBy}</span>
              </p>
              <dl>
                <div>
                  <dt>Date:</dt>
                  <dd>{stockCheck.date}</dd>
                </div>
                <div>
                  <dt>Timestamp:</dt>
                  <dd>{stockCheck.time}</dd>
                </div>
              </dl>
            </div>
          </Col>
        </Row>
      )}
      <DiscrepancyModal
        isOpen={Boolean(modalStock)}
        onComplete={handleModalComplete}
        stock={modalStock}
        toggle={handleModalToggle}
        defaultValue={getDefaultValue()}
      />
      <PartialStockCheckModal
        isOpen={Boolean(showPartialStockCheckModal)}
        toggle={handlePartialStockCheckModalToggle}
        defaultValue={getDefaultValue()}
        confirmedStaffErrors={confirmedStaffErrors}
        confirmBlocktitle="Daily check"
        setConfirmedStaff={setConfirmedStaff}
        confirmInitialState={initialStateSignedWitnessed}
        createStockCheck={createStockCheck}
      />
    </Col>
  );
};

export default React.memo(CurrentStock);
