export * from "./datetime";
export * from "./forms";
export * from "./react";

// Combines cdVpId with cdApId (if present) to form a unique id string
export function getDrugId(drug) {
  return `${drug.catalogueId}`;
}

// {firstname} {lastname} ({full length uniqueIdentifier})
export function getPatientFullNameAndId(patient) {
  return `${patient.firstname} ${patient.lastname}${
    patient.uniqueIdentifier ? ` (${patient.uniqueIdentifier})` : ""
  }`;
}

export function replacePropertyValues(initialObj, key, values) {
  return Object.entries(initialObj).reduce(
    (obj, entry) => ({
      ...obj,
      [entry[0]]: entry[0] === key ? { ...entry[1], ...values } : entry[1],
    }),
    {}
  );
}

export function sortStock(arr) {
  return arr.sort((a, b) => {
    const nameA = a.cdCatalogue.cdcatalogueNameOrFormularyName.toLowerCase();
    const nameB = b.cdCatalogue.cdcatalogueNameOrFormularyName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}

export function sortStockByPatient(arr) {
  return arr.sort((a, b) => {
    const drugA = a.cdCatalogue.cdcatalogueNameOrFormularyName.toLowerCase();
    const drugB = b.cdCatalogue.cdcatalogueNameOrFormularyName.toLowerCase();
    const patientA =
      `${a.patient?.lastname} ${a.patient?.firstname}`.toLowerCase();
    const patientB =
      `${b.patient?.lastname} ${b.patient?.firstname}`.toLowerCase();

    if (patientA < patientB) {
      return -1;
    }

    if (patientA > patientB) {
      return 1;
    }

    if (drugA < drugB) {
      return -1;
    }

    if (drugA > drugB) {
      return 1;
    }

    return 0;
  });
}

export function truncateString(str, length) {
  if (str && str.length > length) {
    str = str.substring(0, length - 1) + "…";
  }

  return str;
}
