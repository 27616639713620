import React, { useContext, useState, useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../store/actions";
import SystemContext from "../../context/SystemContext";
import PatientSearchList from "../../component/molecules/PatientSearchList";
import PatientService from "../../services/PatientService";
import PatientModal from "../../component/organisms/PatientModal";
import NewPatientModal from "../../component/organisms/NewPatientModal";

const ManagePatients = () => {
  const { isPharmacy, locationId } = useContext(SystemContext);

  const { user } = useContext(SystemContext);

  const [searchPatients, setSearchPatients] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [alertMessage, setAlertMessage] = useState({});

  const [showModalPatient, setShowModalPatient] = useState(false);
  const [showModalNewPatient, setShowModalNewPatient] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const [selectedPatientId, setSelectedPatientId] = useState("");

  const searchPatientsCall = (locationId, searchValue) => {
    return PatientService.getPatients(locationId, searchValue);
  };

  const dispatch = useDispatch();

  const mapSearchPatients = (searchPatients) => {
    return searchPatients.map((sp) => ({
      ptId: sp.ptId,
      firstname: sp.firstname,
      lastname: sp.lastname,
      patientName: sp.patientName,
      dob: sp.dob,
      uniqueIdentifier: sp.uniqueIdentifier,
      currentBed: sp.currentBed,
    }));
  };

  useEffect(() => {
    if (
      (alertMessage && typeof alertMessage !== "object") ||
      (typeof alertMessage === "object" && alertMessage.message)
    ) {
      showAlertMessage(dispatch, {
        message:
          typeof alertMessage === "object"
            ? alertMessage.message
            : alertMessage,
        colour:
          typeof alertMessage === "object" ? alertMessage.colour : "danger",
      });
    }
  }, [alertMessage]);

  const showPatient = (ptId) => {
    setSelectedPatientId(ptId);
    setShowModalPatient(true);
    return () => {
      setShowModalPatient(false);
    };
  };
  const showNewPatient = () => {
    setShowModalNewPatient(true);
    return () => {
      setShowModalNewPatient(false);
    };
  };

  return (
    <Col>
      <Row className="mb-3">
        <Col md="6">
          <h1>Manage Patients</h1>
        </Col>
        <Col md="6 d-flex align-items-end flex-column">
          <Link
            className="shadow-sm btn btn-primary btn-icon ml-3"
            onClick={(e) => {
              e.preventDefault();
              showNewPatient();
            }}
          >
            ADD NEW PATIENT
            <FontAwesomeIcon className="on-right" icon={faPlus} />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <PatientSearchList
            locationId={locationId}
            searchPatientsCall={searchPatientsCall}
            mapSearchPatients={mapSearchPatients}
            setSearchPatients={setSearchPatients}
            searchPatients={searchPatients}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showPatient={showPatient}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Col>
      </Row>
      <PatientModal
        isOpen={showModalPatient}
        setIsOpen={setShowModalPatient}
        patientId={selectedPatientId}
        setAlertMessage={setAlertMessage}
      />
      <NewPatientModal
        locationId={locationId}
        user = {user.username}
        isOpen={showModalNewPatient}
        setIsOpen={setShowModalNewPatient}
        setAlertMessage={setAlertMessage}
        setRefresh={setRefresh}
        isPharmacy={isPharmacy}
      />
    </Col>
  );
};

export default React.memo(ManagePatients);
