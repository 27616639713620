const settings = {
  pharmacy: [
    {
      name: "Manage Formulary",
      path: "/pharmacy/formulary/manage",
    },
    {
      name: "Manage Locations",
      path: "/pharmacy/locations/manage",
    },
    {
      name: "Approved Stock Lists",
      path: "/pharmacy/locations/approved",
    },
    {
      name: "CD Compliance Audit management",
      path: "/pharmacy/audit",
    },
  ],
};

export default settings;
