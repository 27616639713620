import React, { useState } from "react";
import { Input } from "reactstrap";
import cx from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import { CSwitch, CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import CheckableInputs from ".././CheckableInputs";

function ReturnTableRow({
  order: {
    amountSupplied,
    comments,
    drugName,
    locationName,
    reference,
    status,
    stkId,
    stkMgId,
    isPatientOwn,
    category,
    doseUom,
    patient,
    isDisposalIntent,
        createdAt,
        catalogueId,
  },
  modifyOrder,
  removeModifiedOrder,
  tableViewStatus,
  action,
  isSelected,
  setIsSelected,
}) {
  const mgId = useSelector((state) => state.settings.mgId);
  const schedule2 = "schedule 2";

  const [checkStatus, setCheckStatus] = useState(isMustAuthoriseWitness);
  const [disposalReference, setDisposalReference] = useState();
  const forDisposal = "ForDisposal";

  function isMustAuthoriseWitness() {
      if (category.toLowerCase().startsWith(schedule2) && !isPatientOwn) return true;
    else return false;
  }

  function handleSwitchChange() {
    if (isSelected?.stkMgId === stkMgId) {
      removeModifiedOrder(stkMgId);
      setIsSelected({});
    } else {
      removeModifiedOrder(isSelected?.stkMgId === stkMgId);
      modifyOrder({
        amountSupplied,
        comments,
          mgId: status.nextMgId,
          catalogueId: catalogueId,
        reference,
        stkId,
        stkMgId,
        authorisedWitnessRequired: checkStatus,
        disposalReference: disposalReference || undefined,
        locationName: locationName,
      });
      setIsSelected({ stkMgId: stkMgId });
    }
  }

  function handleCheckboxChange({ target }) {
    if (target.checked) {
      const value = target.value === "true";
      setCheckStatus(value);
    } else {
      setCheckStatus();
    }

    if (isSelected?.stkMgId === stkMgId) {
      modifyOrder({
        amountSupplied,
        comments,
        mgId: status.nextMgId,
        reference,
        stkId,
          stkMgId,
          catalogueId: catalogueId,
        authorisedWitnessRequired: target.checked,
        disposalReference: disposalReference || undefined,
      });
    }
  }

  function setDisposalReferenceValue({ target }) {
    target.value = target.value.replace(/[^A-Za-z0-9]/gi, "");
    setDisposalReference(target.value);

    if (isSelected?.stkMgId === stkMgId) {
      modifyOrder({
        amountSupplied,
        comments,
        mgId: status.nextMgId,
        reference,
        stkId,
          stkMgId,
          catalogueId: catalogueId,
        authorisedWitnessRequired: checkStatus,
        disposalReference: target.value || undefined,
      });
    }
  }

  function renderStatus() {
    if (tableViewStatus === "default") {
      return <span className="order-status">{status?.label}</span>;
    } else if (status.mgId === mgId.DISPOSED) {
      return (
        <span className="order-status-icon">
          <FontAwesomeIcon icon={faCheckCircle} />
        </span>
      );
    } else {
      return (
        <CSwitch
          defaultChecked={isSelected?.stkMgId === stkMgId}
          onChange={handleSwitchChange}
          shape="pill"
          variant="3d"
          checked={isSelected?.stkMgId === stkMgId}
        />
      );
    }
  }

  function getPatientTooltipContent() {
    const patientStr =
      `${patient.firstname} ${patient.lastname}` +
      `${patient.address ? `, ${patient.address}` : ""}`;
    const textStr = "Return PO CD: ";

    return textStr + patientStr;
  }

  return (
    <tr
      className={cx("shadow-sm", { [`order-${status?.colour}`]: status?.colour })}
    >
      <td>
        <div className="d-flex align-items-center justify-content-between">
          <span>{drugName}</span>
          <div className="d-flex justify-content-end position-relative info-items">
            {comments ? (
              <span className="comments">{comments}</span>
            ) : (
              <span
                className={`status-icon status-off ml-${
                  comments ? "1" : "2"
                } mr-0`}
              >
                <FontAwesomeIcon icon={faCommentAlt} />
              </span>
            )}
            {isPatientOwn ? (
              <CTooltip content={getPatientTooltipContent()}>
                <span
                  className={`status-icon status-on ml-${
                    comments ? "1" : "2"
                  } mr-0`}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </span>
              </CTooltip>
            ) : (
              <span
                className={`status-icon status-off ml-${
                  comments ? "1" : "2"
                } mr-0`}
              >
                <FontAwesomeIcon icon={faUserPlus} />
              </span>
            )}
          </div>
        </div>
      </td>
      <td>
        {/* Order Category/Schedule */}
        {!isPatientOwn ? (
          <span className="order-drug_loc">{category}</span>
        ) : null}
      </td>
      {tableViewStatus === forDisposal ? (
        <td>
          <CheckableInputs
                      disabled={isPatientOwn || category.toLowerCase().startsWith(schedule2)}
            inline
            list={[
              {
                checked: checkStatus === true,
                className: "checked-yes",
                value: true,
              },
            ]}
            onChange={handleCheckboxChange}
          />
        </td>
      ) : null}
      <td>
        {/* Order location */}
        <span className="order-drug_loc">{locationName}</span>
      </td>
      <td>{<Moment format="Do MMM YYYY, HH:mm " date={createdAt} />}</td>
      <td>
        {/* Return reference */}
        {tableViewStatus === forDisposal && isSelected?.stkMgId === stkMgId ? (
          <span className="order-drug_loc">
            <Input
              autoComplete="off"
              className="amount"
              name="disposal-reference"
              onChange={setDisposalReferenceValue}
              placeholder="Optional"
              maxLength="10"
              value={disposalReference}
            />
          </span>
        ) : (
          <span>{reference}</span>
        )}
      </td>
      <td className="quantity-unit">
        {/* Order requested */}
        <span className="order-number action-in">{amountSupplied}</span>
        <span> {doseUom}</span>
      </td>
      <td>
        {/* Order status || select item */}
        {renderStatus()}
      </td>
    </tr>
  );
}

export default ReturnTableRow;
