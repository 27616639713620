AdminRules.Access = [
  {
    name: "General supervisory rights",
    id: 1,
  },
  {
    name: "Order general stock",
    id: 2,
  },
  {
    name: "Sign as messenger",
    id: 3,
  },
  {
    name: "Sign for receipt of CDs",
    id: 4,
  },
  {
    name: "Sign Register entry",
    id: 5,
  },
  {
    name: "Witness Register entry",
    id: 6,
  },
  {
    name: "Record Incident",
    id: 7,
  },
  {
    name: "Perform stock check",
    id: 8,
  },
  {
    name: "Return stock to Pharmacy",
    id: 9,
  },
  {
    name: "Acknowledge retuned stock",
    id: 10,
  },
  {
    name: "Add/Remove Permitted Staff",
    id: 11,
  },
  {
    name: "Maintain Approved Stock List",
    id: 12,
  },
];

AdminRules.Role = [
  {
    id: 1,
    name: "AOCD",
    class: "aocd",
    access: [1, 8, 11],
    supervisorRequired: false,
  },
  {
    id: 2,
    name: "Clinical Area Manger",
    class: "clinical-area-manger",
    access: [8, 11],
    supervisorRequired: false,
  },
  {
    id: 3,
    name: "Practitioner",
    class: "practitioner",
    access: [2, 3, 4, 5, 6, 7, 9],
    supervisorRequired: false,
  },
  {
    id: 4,
    name: "Associate Practitioner",
    class: "associate-practitioner",
    access: [5, 6],
    supervisorRequired: false,
  },
  {
    id: 5,
    name: "Trainee Practitioner",
    class: "trainee-practitioner",
    access: [2, 3, 4, 5, 6, 7],
    supervisorRequired: true,
  },
  {
    id: 6,
    name: "Pharmacy Staff Member",
    class: "pharmacy-staff-member",
    access: [3, 10, 12],
    supervisorRequired: false,
  },
  {
    id: 7,
    name: "Doctor",
    class: "doctor",
    access: [],
    supervisorRequired: false,
  },
  {
    id: 8,
    name: "Messenger",
    class: "messenger",
    access: [3],
    supervisorRequired: false,
  },
];

AdminRules.Staff = [
  {
    name: "Frank Hahn",
    siteId: 1,
    lcId: 1,
    location: "1A",
    role: 1,
  },
  {
    name: "Cerys Corbett",
    siteId: 1,
    lcId: 2,
    location: "AAU Stock (2C)",
    role: 2,
  },
  {
    name: "Annabel Greer",
    siteId: 1,
    lcId: 3,
    location: "AAU PODs (2D)",
    role: 3,
  },
  {
    name: "Nana Noel",
    siteId: 1,
    lcId: 4,
    location: "3B",
    role: 4,
  },
  {
    name: "Izzie Mackie",
    siteId: 1,
    lcId: 5,
    location: "4C",
    role: 5,
  },
  {
    name: "Nabil Castaneda",
    siteId: 1,
    lcId: 1,
    location: "1A",
    role: 6,
  },
  {
    name: "Faiza Dale",
    siteId: 1,
    lcId: 2,
    location: "AAU Stock (2C)",
    role: 7,
  },
  {
    name: "Kristopher Lake",
    siteId: 1,
    lcId: 3,
    location: "AAU PODs (2D)",
    role: 8,
  },
  {
    name: "Courteney Morse",
    siteId: 1,
    lcId: 4,
    location: "3B",
    role: 1,
  },
  {
    name: "Kelan Buchanan",
    siteId: 1,
    lcId: 5,
    location: "4C",
    role: 2,
  },
  {
    name: "Isabelle Andrade",
    siteId: 1,
    lcId: 1,
    location: "1A",
    role: 3,
  },
  {
    name: "Vinay Wang",
    siteId: 1,
    lcId: 2,
    location: "AAU Stock (2C)",
    role: 4,
  },
  {
    name: "Juanita Maxwell",
    siteId: 1,
    lcId: 3,
    location: "AAU PODs (2D)",
    role: 5,
  },
  {
    name: "Bartosz Lee",
    siteId: 1,
    lcId: 4,
    location: "3B",
    role: 6,
  },
  {
    name: "Maci Mill",
    siteId: 1,
    lcId: 5,
    location: "4C",
    role: 7,
  },
  {
    name: "Anna-Marie Dunkley",
    siteId: 1,
    lcId: 1,
    location: "1A",
    role: 8,
  },
  {
    name: "Duane Osborn",
    siteId: 1,
    lcId: 2,
    location: "AAU Stock (2C)",
    role: 1,
  },
  {
    name: "Jed Calhoun",
    siteId: 1,
    lcId: 3,
    location: "AAU PODs (2D)",
    role: 2,
  },
  {
    name: "Havin Head",
    siteId: 1,
    lcId: 4,
    location: "3B",
    role: 3,
  },
  {
    name: "Yousuf Morley",
    siteId: 1,
    lcId: 5,
    location: "4C",
    role: 4,
  },
];

function AdminRules() {}
export default AdminRules;
