import { imprivataConfig } from "../config/imprivata";

const authClient = {
  PartnerName: imprivataConfig.partnerName,
  PartnerAppName: imprivataConfig.partnerAppName,
  Workflow: "UserVerification",
  Domain: imprivataConfig.domain,
  SameUserOnly: false,
  ColorScheme: "Default",
  Caption: "eCDR-Pro - User signing",
  OffsetX: 300,
  Offsety: 800,
};

export const imprivataSign = (
  currentTarget,
  signed,
  setSigned,
  showAlertMessage,
  dispatch
) => {
  const WITNESSED = "witnessed";

  authClient.Workflow =
    currentTarget.name === WITNESSED ? "DualSignOff" : "UserVerification";
  const onAuthFinished = (authResult, process) => {
    if (authResult.auth_result === 0 && authResult.display_name && authResult.display_name.length > 0) {
      if (
        signed
          .filter((item) => item.name !== currentTarget.name)
          .some((item) => item.value === authResult.display_name)
      ) {
        showAlertMessage(dispatch, {
          message: "Each signature must be a different user",
          colour: "warning",
        });
      } else {
        setSigned((signed) => [
          ...signed.filter((item) => item.name !== process),
          {
            name: process,
            value: authResult.display_name,
          },
        ]);
      }

    } else {
      showAlertMessage(dispatch, {
        message: "Authentication failed",
        colour: "danger",
      });
    }
  };

  const authCallback = (authResult) =>
    onAuthFinished(authResult, currentTarget.name);

  window.confirm_id_authenticate(authClient, authCallback);
};

export const imprivataLogin = () => {
  return new Promise((resolve, reject) => {
    const onAuthFinished = (authResult) => {
      if (authResult.auth_result === 0 && authResult.display_name && authResult.display_name.length > 0) {
        resolve(authResult);
      } else {
        reject(authResult);
      }
    };

    window.confirm_id_authenticate(authClient, onAuthFinished);
  });
};
