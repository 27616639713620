import React from "react";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

function CheckableInputs({
  className,
  disabled,
  inline,
  inlineInputs,
  inlineLegend,
  legend,
  list,
  required,
  type = "checkbox",
  ...inputProps
}) {
  return (
    <fieldset
      className={cx("CheckableInputs", className, {
        inline,
        "inline-inputs": inlineInputs,
        "inline-legend": inlineLegend,
      })}
    >
      {legend && (
        <span className={cx("legend", { required })}>
          <span>{legend}</span>
        </span>
      )}
      <ul>
        {list.map(
          ({
            className,
            disabled: itemDisabled,
            icon = faCheck,
            label,
            name,
            required: itemRequired,
            value,
            ...itemInputProps
          }) => (
            <li key={(name ?? "") + (value ?? "")}>
              <label
                className={cx("checkable-label", className, {
                  disabled: disabled || itemDisabled,
                })}
              >
                <Input
                  disabled={disabled || itemDisabled}
                  name={name}
                  required={required || itemRequired}
                  type={type}
                  value={value}
                  {...inputProps}
                  {...itemInputProps}
                />
                <span className="checkable-box">
                  <FontAwesomeIcon icon={icon} />
                </span>
                {label && (
                  <span
                    className={cx("checkable-text", { required: itemRequired })}
                  >
                    {label}
                  </span>
                )}
              </label>
            </li>
          )
        )}
      </ul>
    </fieldset>
  );
}

export default CheckableInputs;
