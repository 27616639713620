import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { CAlert, CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReplyAll,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { useSelector } from "react-redux";

import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import { initialStateSignedWitnessed } from "../../config/confirm";
import StockService from "../../services/StockService";
import CommentsBlock from "../../component/molecules/CommentsBlock";
import QuantityInput from "../../component/molecules/QuantityInput";

const inputs = [
  {
    name: "description",
    label: "Describe Syringe Content (Drug(s) and Amount(s)):",
    required: true,
    type: "textarea",
    tooltip: true,
  },
  {
    name: "Amount",
    label: "Amount:",
    required: true,
    type: "quantityInput",
    tooltip: false,
  },
  {
    name: "patient",
    label: "Patient Details:",
    required: false,
    type: "textarea",
    tooltip: false,
  },
];

const mainPagePath = "/ward/stock/disposal";
const formInitialState = inputs.reduce(
  (stateObj, input) => ({ ...stateObj, [input.name]: "" }),
  {}
);
const messageInitialState = {};
const patientDetails = "Patient Details: ";
const reasonsComments = "Reason/Comments: ";
let messageTimeoutId;
let redirectTimeoutId;
let serviceCancelSource;
let serviceConfig;

function AddClinicalWaste({ history, locationRoute, match, user }) {
  const settings = useSelector(
    (state) => state.settings
  );

  let ClinicalWasteUnits = settings?.ClinicalWasteUnits ?? [];
  const [unit, setUnit] = useState(settings?.ClinicalWasteUnits?.[0] || "");

  useEffect(() => {
    ClinicalWasteUnits = settings.ClinicalWasteUnits;
    setUnit(settings?.ClinicalWasteUnits?.[0] || "");
  }, [settings]);
  const formElement = useRef();

  const [alertMessage, setAlertMessage] = useState(messageInitialState);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmStaff, setConfirmStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [formIsReadOnly, setFormIsReadOnly] = useState(
    Boolean(match.params.id)
  );
  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);
  const [isLoading, setIsLoading] = useState(Boolean(match.params.id));
  const [cwComments, setCwComments] = useState();
  const [amount, setAmount] = useState(0);

  const inputTerm = (e) => {
    setCwComments(e.target.value);
  };

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageInitialState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    if (confirmed) {
      setConfirmedStaffErrors();

      if (formElement.current.checkValidity() && amount > 0) {
        addClinicalWaste();
      } else {
        if (amount <= 0) {
          setAlertMessage({
            message: "Please fill in a valid amount",
            colour: "warning",
            label: "Warning message",
          });
        } else {
          setAlertMessage({
            message: "Please fill in the required fields",
            colour: "warning",
            label: "Warning message",
          });
        }

        setConfirmed(false);
        setFormShowValidity(true);
      }
    }
  }, [confirmed]);

  function setupRedirect() {
    redirectTimeoutId = setTimeout(() => history.push(mainPagePath), 5000);
  }

  async function addClinicalWaste() {
    const comments = cwComments ?  (formState.patient ? reasonsComments + cwComments + "\n"+ patientDetails + formState.patient : reasonsComments + cwComments)  
    : (formState.patient ? patientDetails + formState.patient : ""); 
    const payload = {
      CreatedBy: user.username,
      Description: formState.description,
      LocationId: user.location.lcId,
      Comments: comments,
      SignedBy: confirmStaff.signed,
      Source: formState.source,
      WitnessedBy: confirmStaff.witnessed,
      DrugQuantity: amount,
      DrugQuantityUom: unit,
    };
    let isCancelled = false;

    setIsLoading(true);
    try {
      const response = await StockService.addClinicalWaste(
        user.location.lcId,
        payload,
        serviceConfig
      );
      if (response.status === 201) {
        setAlertMessage({
          message: "The Clinical Waste has been added",
          colour: "success",
          label: "Success message",
          timeout: 5000,
        });
        setupRedirect();
      } else {
        setAlertMessage({
          message: `There was a problem adding the Clinical Waste, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
          label: "Error message",
        });
        setConfirmed(false);
      }
    } catch (error) {
      if (StockService.isCancel(error)) {
        isCancelled = true;
      } else {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem adding the Clinical Waste, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }

        setConfirmed(false);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function inputChangeHandler({ target: { name, value } }) {
    setFormState((formState) => ({ ...formState, [name]: value }));
  }

  function formSubmitHandler(event) {
    event.preventDefault();
  }

  function renderFormGroup({ label, name, required, type,tooltip, ...inputProps }) {
    if (type === "quantityInput") {
      return (
        <FormGroup key={name}>
          <label
            className="form-label"
            disabled={formIsReadOnly}
            htmlFor={formIsReadOnly ? undefined : name}
          >
            {required && <span className="mandatory-label">*</span>}
            {label}
          </label>
          {formIsReadOnly ? (
            <p className="input-value">{formState[name]}</p>
          ) : (
            <QuantityInput
              aria-label="Quantity"
              name="Quantity"
              onUnitsChange={setUnit}
              onValueChange={setAmount}
              placeholder={amount}
              required
              unitsList={
                ClinicalWasteUnits.length === 1 ? null : ClinicalWasteUnits
              }
              unitsValue={unit}
            />
          )}
        </FormGroup>
      );
    } else {
      return (
        <FormGroup key={name}>
          <label
            className="form-label"
            disabled={formIsReadOnly}
            htmlFor={formIsReadOnly ? undefined : name}
          >
            {required && <span className="mandatory-label">*</span>}
            {label}
          </label>
          {formIsReadOnly ? (
            <p className="input-value">{formState[name]}</p>
          ) : (
            <React.Fragment>
              <textarea
                autoComplete="off"
                disabled={isLoading}
                id={name}
                name={name}
                onChange={inputChangeHandler}
                readOnly={isLoading}
                required={required}
                value={formState[name]}
                {...inputProps}
                className="clinical-waste-description"
              />
              {tooltip && (
              <div className="icon-block">
                <CTooltip
                  content="Midazolam 10mg / Morphine 10mg/ Cyclizine 150mg in 15mls
                            Volume to be disposed   7   mls"
                  placement="right"
                >
                  <span id="ward-stock" className="sidebar-bumper">
                    <FontAwesomeIcon icon={faQuestionCircle} color="white" />
                  </span>
                </CTooltip>
              </div>
              )}
            </React.Fragment>
          )}
        </FormGroup>
      );
    }
  }

  if (!settings || !settings.ClinicalWasteUnits){
    return (<></>);
  }

  return (
    <Col className={`AddClinicalWaste AddClinicalWaste-${locationRoute}`}>
      <CAlert
        aria-label={alertMessage.label}
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
      >
        {alertMessage.message}
      </CAlert>
      <Row className="mb-4">
        <Col sm="8">
          <h1>CD Syringe Waste following Patient Use</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="4"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to={mainPagePath}
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO DISPOSAL
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form
            className={cx("form", {
              loading: isLoading,
              "show-validity": formShowValidity,
            })}
            innerRef={formElement}
            onSubmit={formSubmitHandler}
          >
            {inputs.map(renderFormGroup)}
            {isLoading && (
              <div className="loader">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading…</span>
                </div>
              </div>
            )}
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <CommentsBlock comments={cwComments} setComments={inputTerm} />
        </Col>
      </Row>
      {!formIsReadOnly && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          confirmInitialState={initialStateSignedWitnessed}
          inputsConfirmed={confirmed}
          setConfirmedStaff={setConfirmStaff}
          setInputsConfirmed={setConfirmed}
          title="To Dispose / Destroy"
        />
      )}
    </Col>
  );
}

export default AddClinicalWaste;
