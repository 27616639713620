import React from "react";
import { Button } from "reactstrap";
import { CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const AuditLine = ({
  audit,
  toggleSelectItem,
  defaultChecked,
  deleteItem,
  view,
}) => {
  return (
    <li id={audit["saId"]} className="shadow-sm">
      {view === "create" ? (
        <span>{audit["name"]}</span>
      ) : (
        <span>{audit["stockAudit"]["name"]}</span>
      )}

      {view !== "create" && view !== "clone" ? (
        <CTooltip content="Remove">
          <Button
            className="btn-delete"
            onClick={(e) => {
              e.preventDefault();
              deleteItem(audit["saId"]);
            }}
            id={audit["saId"]}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </CTooltip>
      ) : (
        <input
          value={audit["saId"]}
          type="checkbox"
          onClick={() => {
            toggleSelectItem(view === "clone" ? audit : audit["saId"]);
          }}
          defaultChecked={defaultChecked}
        />
      )}
    </li>
  );
};

export default AuditLine;
