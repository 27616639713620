import React from "react";
import { Button, Input } from "reactstrap";
import { CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import QuantityInput from "../QuantityInput";

function StockTableRow({
  remove,
  setQuantity,
  setSupplierAddress,
  setSupplierName,
  showQuantityAlert,
  stockItem,
  disabled
}) {
  function supplierNameChangeHandler({ target }) {
    setSupplierName(stockItem, target.value);
  }

  function supplierAddressChangeHandler({ target }) {
    setSupplierAddress(stockItem, target.value);
  }

  function updateQuantityHandler(value) {
    setQuantity(stockItem, value);
  }

  function deleteButtonClickHandler() {
    remove(stockItem);
  }

  return (
    <tr className="StockTableRow shadow-sm">
      <td>{
        !stockItem.drug.cdCatalogue ?
          stockItem.drug.cdcatalogueNameOrFormularyName
          : stockItem.drug.cdCatalogue.cdcatalogueNameOrFormularyName
      }</td>
      {setSupplierName && (
        <td>
          <Input
            name="supplier-name"
            onChange={supplierNameChangeHandler}
            invalid={stockItem.showNameAlert}
          />
        </td>
      )}
      {setSupplierAddress && (
        <td>
          <Input
            name="supplier-address"
            onChange={supplierAddressChangeHandler}
            invalid={stockItem.showAddressAlert}
          />
        </td>
      )}
      <td>
        <QuantityInput
          alignCenter
          aria-label="Quantity"
          placeholder={stockItem.quantity}
          invalidate={stockItem.showQuantityAlert}
          name="quantity"
          onValueChange={updateQuantityHandler}
          required
          disabled={disabled}
          unitsValue={!stockItem.drug.cdCatalogue ?
            stockItem.drug.cdCataloguePackSize[0]?.uom
            : stockItem.drug.cdCatalogue.cdCataloguePackSize[0]?.uom
          }
        />
      </td>
      <td>
        {!disabled &&
          <CTooltip content="Remove">
            <Button
              aria-label="Delete item"
              className="icon-delete"
              color="transparent"
              onClick={deleteButtonClickHandler}
              outline
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </CTooltip>
        }
      </td>
    </tr>
  );
}

export default React.memo(StockTableRow);
