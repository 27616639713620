import { createStore, combineReducers } from "redux";
import messages from "./reducers/messages";
import sidebarShowReducer from "./reducers/sidebarShow";
import settingsReducer from "./reducers/settings";
import userdetailsReducer from "./reducers/userDetails";

const rootReducer = combineReducers({
  messages,
  sbs: sidebarShowReducer,
  ud: userdetailsReducer,
  settings: settingsReducer,
});

const store = createStore(rootReducer);

export default store;
