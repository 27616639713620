import React, { useContext, useEffect, useState } from "react";
import { Input } from "reactstrap";

import SystemContext from "../../context/SystemContext";
import LocationsService from "../../services/LocationsService";
import Quantity from "../atoms/Quantity";
import LocationDropDown from "./LocationDropDown";
import QuantityInput from "./QuantityInput";
import StockManagementDropdown from "./StockManagementDropdown";
import CheckableInputs from "././CheckableInputs";

const dropdownOptions = {
  patient: [
    { name: "Return to Pharmacy for Reuse" },
    { name: "Return to Pharmacy for Disposal" },
    { name: "To Dispose/Destroy" },
    { name: "Patient Transfer" },
    { name: "Leave/Discharge" },
  ],
  pharmacy: [{ name: "To Dispose/Destroy" }],
  ward: [
    { name: "Return to Pharmacy for Reuse" },
    { name: "Return to Pharmacy for Disposal" },
  ],
};

const StockManagementTableLine = ({
  line,
  stockView,
  setAlertMessage,
  setStockName,
  stockName,
  setQuantityValue,
  setDatIxNoValue,
  setNewBalanceValueFor,
  setSelectedLocation,
  setDrugsCollectedBy,
  setDrugsHandedTo,
  setAuthorisedWitnessRequired,
  setDisposalReference,
  discrepancyByBalance,
  disableDropdown,
  setClearDropDownOptions,
  defaultValue,
  setResetQuantity,
  resetQuantity,
  editDatix
}) => {
  const { locationRoute, user } = useContext(SystemContext);

  const [options] = useState(
    line.isPatientOwn ? dropdownOptions.patient : dropdownOptions[locationRoute]
  );
  const [error, seterror] = useState(false);
  const [balanceValue, setBalanceValue] = useState(0);
  const [newBalanceValue, setNewBalanceValue] = useState(
    defaultValue ? defaultValue : line.runningBalance
  );
  const [datixNo, setDatixNo] = useState(
    editDatix && editDatix.reference !== editDatix.disposalReference ? editDatix.reference: ""
  );
  const [locationList, setLocationList] = useState([]);
  const [quantityAdjusted, setQuantityAdjusted] = useState(
    defaultValue ? line.runningBalance - defaultValue : 0
  );
  const [checkStatus, setCheckStatus] = useState(
    initialiseAuthorisedWitnessRequired
  );
  const [resetValueAsNumber, setResetValueAsNumber] = useState(false);

  const updateQuantity = (value) => {
    setBalanceValue(value);
    setQuantityValue(value);
    setNewBalanceValueFor(
      Number((line.runningBalance - balanceValue).toFixed(4))
    );
  };

  const updateNewBalance = (value) => {
    setNewBalanceValueFor(value);
    setNewBalanceValue(value);
  };

  const updateQuantityAdjusted = (value) => {
    setQuantityAdjusted(value);
    setNewBalanceValueFor(
      Number((line.runningBalance + balanceValue).toFixed(4))
    );
  };

  const updateDatix = (value) => {
    setDatixNo(value);
    setDatIxNoValue(value);
  };

  useEffect(() => {
    LocationsService.getLocations(user.location.siteId)
      .then(({ data }) => {
        setLocationList(data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
          label: "Error message",
        });
      });
  }, []);

  useEffect(() => {
    setQuantityValue(balanceValue);

    if (defaultValue && balanceValue === 0) {
      setNewBalanceValueFor(defaultValue);
    } else {
      setNewBalanceValueFor(
        Number((line.runningBalance - balanceValue).toFixed(4))
      );
    }
  }, [balanceValue]);

  useEffect(() => {
    setBalanceValue(0);
    initialiseAuthorisedWitnessRequired();
    if (defaultValue && balanceValue === 0) {
      setNewBalanceValue(defaultValue);
    } else {
      setNewBalanceValue(line.runningBalance);
    }
  }, [stockName]);

  useEffect(() => {
    if (resetQuantity) {
      setStockName({
        name: options[0].name,
      });
      setResetValueAsNumber(true);
      setResetQuantity(false);
    } else {
      setResetValueAsNumber(false);
    }
  }, [resetQuantity]);

  function isMustAuthoriseWitness() {
    if (isSchedule2() && !isPatientOwn()) return true;
    else return false;
  }

  function isPatientOwn() {
    return line.isPatientOwn;
  }

  function isSchedule2() {
    return line.cdCatalogue.schedule
      ? line.cdCatalogue.schedule.toLowerCase().startsWith("schedule 2")
      : false;
  }

  function handleAuthorisedWitnessRequiredCheckboxChange({ target }) {
    if (target.checked) {
      const value = target.value === "true";
      setCheckStatus(value);
    } else {
      setCheckStatus();
    }

    setAuthorisedWitnessRequired(target.checked);
  }

  function initialiseAuthorisedWitnessRequired() {
    const initialStatus = isMustAuthoriseWitness();
    setAuthorisedWitnessRequired(initialStatus);
    return initialStatus;
  }

  function setDisposalReferenceValue({ target }) {
    target.value = target.value.replace(/[^A-Za-z0-9]/gi, "");
    setDisposalReference(target.value);
  }

  function renderCells(name) {
    switch (name) {
    case "Discrepancy/Incident":
      return (
        <>
          <td>
            <Input
              autoComplete="off"
              className="amount"
              name="incident-no"
              maxLength={50}
              onChange={(e) => updateDatix(e.target.value)}
              value={datixNo}
              placeholder="Optional"
            />
          </td>
          {discrepancyByBalance ? (
            <>
              <td>
                <Quantity
                  units={line.cdCatalogue.cdCataloguePackSize[0]?.uom}
                  value={Number(
                    (newBalanceValue - line.runningBalance).toFixed(4)
                  )}
                />
              </td>
              <td>
                <QuantityInput
                  placeholder={newBalanceValue}
                  onValueChange={updateNewBalance}
                  showEmptyValue
                  unitsValue={line.cdCatalogue.cdCataloguePackSize[0]?.uom}
                />
              </td>
            </>
          ) : (
            <>
              <td className="position-relative">
                <QuantityInput
                  allowNegative
                  placeholder={quantityAdjusted}
                  invalidate={
                    Number(
                      (line.runningBalance + quantityAdjusted).toFixed(4)
                    ) < 0
                  }
                  onValueChange={updateQuantityAdjusted}
                  unitsValue={line.cdCatalogue.cdCataloguePackSize[0]?.uom}
                />
                {Number((line.runningBalance + quantityAdjusted).toFixed(4)) <
                    0 && (
                  <p className="invalid-feedback" role="alert">
                      Error, unable to have a negative balance of stock, please
                      amend adjustment appropriately
                  </p>
                )}
              </td>
              <td>
                <Quantity
                  units={line.cdCatalogue.cdCataloguePackSize[0]?.uom}
                  value={Number(
                    (line.runningBalance + quantityAdjusted).toFixed(4)
                  )}
                />
              </td>
            </>
          )}
        </>
      );
    case "Patient Transfer":
      return (
        <>
          <td>
            <LocationDropDown
              dropDownList={locationList}
              hospitalItem={user.location}
              inputValue=""
              isHospital
              name="ward-transferred-to"
              placeholder="Choose ward"
              setdropDownItem={setSelectedLocation}
            />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="drugs-collected-by"
              onChange={(event) => setDrugsCollectedBy(event.target.value)}
            />
          </td>
        </>
      );
    case "Leave/Discharge":
      return (
        <td>
          <Input
            autoComplete="off"
            name="drugs-handed-to"
            onChange={(event) => setDrugsHandedTo(event.target.value)}
          />
        </td>
      );
    default:
      return null;
    }
  }

  return (
    <tr
      className={`shadow-sm ${error ? " drugline-error" : ""}${
        stockView ? " stockCheck" : ""
      }`}
      name="drugline"
      id={line["cdVpId"]}
    >
      <td>
        <StockManagementDropdown
          options={options}
          setStockName={setStockName}
          stockName={stockName?.name || ""}
          disabled={disableDropdown}
          setClearDropDownOptions={setClearDropDownOptions}
        />
      </td>
      {renderCells(stockName.name)}
      {stockName.name && stockName.name !== "Discrepancy/Incident" && (
        <>
          <td>
            <QuantityInput
              disabled={!stockView}
              max={line.runningBalance}
              onValueChange={setBalanceValue}
              unitsValue={line.cdCatalogue.cdCataloguePackSize[0]?.uom}
              placeholder={balanceValue}
              resetValueAsNumber={resetValueAsNumber}
              allowDecimals={true}
            />
          </td>
          {locationRoute === "pharmacy" && (
            <td>
              <CheckableInputs
                disabled={isPatientOwn() || isSchedule2()}
                inline
                list={[
                  {
                    checked: checkStatus === true,
                    className: "checked-yes",
                    value: true,
                  },
                ]}
                onChange={handleAuthorisedWitnessRequiredCheckboxChange}
              />
            </td>
          )}
          <td>
            <Input
              autoComplete="off"
              className="amount"
              name="disposal-reference"
              onChange={setDisposalReferenceValue}
              placeholder="Optional"
              maxLength="10"
              value={line.disposalReference}
            />
          </td>
          <td>{Number((line.runningBalance - balanceValue).toFixed(4))}</td>
        </>
      )}
    </tr>
  );
};

export default React.memo(StockManagementTableLine);
