import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

import { Label, Loader } from "../../component/atoms";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import { initialStateSignedWitnessed } from "../../config/confirm";
import { getTimestamp } from "../../helpers";
import IllicitDrugService from "../../services/IllicitDrugService";
import { showAlertMessage } from "../../store/actions";

const inputs = [
  {
    autoComplete: "off",
    id: "description",
    label: "Description (form, colour, quantity)",
    name: "description",
    required: true,
  },
  {
    autoComplete: "off",
    id: "datix",
    label: "Datix incident report ref. no.",
    name: "datixNo",
  },
  {
    autoComplete: "off",
    id: "reference",
    label: "Reference Number",
    name: "reference",
  },
  {
    autoComplete: "off",
    id: "source",
    label: "Patient details (or source description, if unknown)",
    name: "source",
    required: true,
  },
  {
    autoComplete: "off",
    id: "notes",
    label: "Notes",
    name: "notes",
    type: "textarea",
  },
];
const formInitialState = inputs.reduce(
  (obj, item) => ({ ...obj, [item.name]: "" }),
  {}
);
const mainPagePath = "/pharmacy/stock/illicit";
let serviceCancelSource;
let serviceConfig;

function AddIllicitDrug({
  history,
  location,
  locationId,
  locationRoute,
  match,
  user,
}) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [confirmed, setConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [formIsReadOnly, setFormIsReadOnly] = useState(
    match.params.id !== undefined
  );
  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);
  const [isLoading, setIsLoading] = useState(formIsReadOnly);

  useEffect(async () => {
    serviceCancelSource = IllicitDrugService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    if (formIsReadOnly) {
      let isCancelled = false;

      try {
        const { data } = await IllicitDrugService.getIllicitDrug(
          match.params.id,
          serviceConfig
        );

        setFormState({
          datixNo: data.datixNo,
          description: data.description,
          notes: data.notes,
          source: data.source,
        });
      } catch (error) {
        if (IllicitDrugService.isCancel(error)) {
          isCancelled = true;
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem getting the drug information, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    }

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (confirmed) {
      setConfirmedStaffErrors();

      if (formRef.current.checkValidity()) {
        addDrug();
      } else {
        showAlertMessage(dispatch, {
          message: "Please fill in the required fields",
          colour: "warning",
        });
        setConfirmed(false);
        setFormShowValidity(true);
      }
    }
  }, [confirmed]);

  async function addDrug() {
    const createdAt = getTimestamp();
    const payload = {
      createdAt,
      createdBy: user.username,
      locationId,
      signedAt: createdAt,
      signedBy: confirmedStaff.signed,
      witnessedAt: createdAt,
      witnessedBy: confirmedStaff.witnessed,
      ...formState,
    };
    let isCancelled = false;

    setIsLoading(true);

    try {
      const response = await IllicitDrugService.addIllicitDrug(
        locationRoute,
        payload,
        serviceConfig
      );

      if (response.status === 201) {
        showAlertMessage(dispatch, {
          message: "The illicit substance has been added",
          colour: "success",
        });
        history.push(mainPagePath);
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem adding the illicit substance, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setConfirmed(false);
      }
    } catch (error) {
      if (IllicitDrugService.isCancel(error)) {
        isCancelled = true;
      } else {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem adding the illicit substance, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setConfirmed(false);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function handleInputChange({ target: { name, value } }) {
    setFormState((formState) => ({ ...formState, [name]: value }));
  }

  function renderFormGroup(props) {
    return (
      <FormGroup key={props.id} className="AddIllicitDrug-Input">
        <Label disabled={formIsReadOnly || isLoading} {...props} />
        <Input
          disabled={formIsReadOnly || isLoading}
          onChange={handleInputChange}
          readOnly={formIsReadOnly}
          value={formState[props.name]}
          {...props}
        />
      </FormGroup>
    );
  }

  return (
    <Col className={`AddIllicitDrug AddIllicitDrug-${locationRoute}`}>
      <header className="page-header row">
        <h1 className="page-heading">Illicit Substance</h1>
        <Link
          className="btn btn-primary btn-icon"
          to={{ hash: location.state?.from?.hash, pathname: mainPagePath }}
        >
          <FontAwesomeIcon className="on-left" icon={faReplyAll} />
          Back to main page
        </Link>
      </header>
      <Row className="flex-column">
        <h2>Description of Substance</h2>
        {!(formIsReadOnly && isLoading) && (
          <Form
            className={cx("form", { "show-validity": formShowValidity })}
            innerRef={formRef}
            noValidate
            onSubmit={handleFormSubmit}
          >
            {inputs.map(renderFormGroup)}
          </Form>
        )}
        <Loader show={formIsReadOnly && isLoading} />
      </Row>
      {!formIsReadOnly && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          confirmInitialState={initialStateSignedWitnessed}
          inputsConfirmed={confirmed}
          setConfirmedStaff={setConfirmedStaff}
          setInputsConfirmed={setConfirmed}
          title="Received and recorded"
        />
      )}
    </Col>
  );
}

export default AddIllicitDrug;
