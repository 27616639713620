import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import CommentsBlock from "../../component/molecules/CommentsBlock";
import QuantityInput from "../../component/molecules/QuantityInput";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";
import { initialStateSignedWitnessed } from "../../config/confirm";
import StockService from "../../services/StockService";
import { showAlertMessage, hideAlertMessage } from "../../store/actions";
import StockManagementDropdown from "../../component/molecules/StockManagementDropdown";
import { getOrdersRules } from "../../config/_ordersrules";

const ReturnedDrugsForReuse = "Return to Pharmacy for Reuse";
const ReturnedDrugsForDisposal = "Return to Pharmacy for Disposal";

const mainPageSubPath = "/pharmacy/return";
const returnOptions = [
  { name: ReturnedDrugsForReuse },
  { name: ReturnedDrugsForDisposal },
];
let serviceCancelSource;
let serviceConfig;

function AddReturnedCD({ history, locationId, location, user }) {
  const dispatch = useDispatch();

  const [comments, setComments] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [datixNo, setDatixNo] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedDrug, setSelectedDrug] = useState();
  const [returnAction, setReturnAction] = useState("");

  let mainPagePath = mainPageSubPath + "#" + location.hash.split("#")[1];

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (isConfirmed) {
      setConfirmedStaffErrors();

      if (!comments) {
        showAlertMessage(dispatch, {
          message: "Please fill in the required Reason / Comments",
          colour: "warning",
        });
        setIsConfirmed(false);
      } else {
        if (amount <= 0) {
          showAlertMessage(dispatch, {
            message: "Please fill in a valid amount",
            colour: "warning",
          });
          setIsConfirmed(false);
        } else if (returnAction === "") {
          showAlertMessage(dispatch, {
            message: "Please choose a type of return",
            colour: "warning",
          });
          setIsConfirmed(false);
        } else {
          manageDrug();
        }
      }
    }
  }, [isConfirmed]);

  async function manageDrug() {
    const payload = {
      cdApId: !selectedDrug.cdCatalogue ? selectedDrug.Id : selectedDrug.cdCatalogue.Id,
        cdVpId: !selectedDrug.cdCatalogue ? selectedDrug.virtualId : selectedDrug.cdCatalogue.virtualId,
        catalogueId: selectedDrug.catalogueId,
        mgId: (returnAction.name === ReturnedDrugsForReuse ? 2 : 18 ),
      siteId: user.location.siteId,
      comments,
      createdBy: user.username,
      datixNo,
      qtySupplied: amount,
      signedBy: confirmedStaff.signed,
      witnessedBy: confirmedStaff.witnessed,
      isDisposalIntent: returnAction.name === ReturnedDrugsForDisposal,
    };

    setIsLoading(true);

    try {
      const response = await StockService.createReturnedCD(
        locationId,
        payload,
        serviceConfig
      );

      if (response.status === 202) {
        showAlertMessage(dispatch, {
          message: "Successfully added the Returned CD",
          colour: "success",
        });
        history.push(mainPagePath);
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem adding the Returned CD, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsConfirmed(false);
        setIsLoading(false);
      }
    } catch (error) {
      if (!StockService.isCancel(error)) {
        if (error.response?.status === 400) {
          showAlertMessage(dispatch, {
            message: `There was a problem adding the Returned CD, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        } else if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          showAlertMessage(dispatch, {
            message: `There was a problem adding the Returned CD, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setIsConfirmed(false);
        setIsLoading(false);
      }
    }
  }

  function resetPage() {
    setComments();
    setDatixNo();
    setAmount(0);
    setSelectedDrug();
    setReturnAction("");
  }

  function checkIfValidReturn() {
    if (returnAction === "") {
      showAlertMessage(dispatch, {
        message: "Please choose a type of return",
        colour: "warning",
      });
      setIsConfirmed(false);
    } else {
      hideAlertMessage(dispatch);
    }
  }

  function handleReturnTypeChange(value) {
    hideAlertMessage(dispatch);
    setReturnAction(value);
  }
  function handleCommentsChange({ target: { value } }) {
    checkIfValidReturn();
    setComments(value);
  }

  function handleDatixNoChange({ target: { value } }) {
    setDatixNo(value);
  }

  function handleDeleteButtonClick() {
    resetPage();
  }

  function handleAmountChange(value) {
    checkIfValidReturn();
    setAmount(value);
  }

  function renderRow() {
    return (
      <tr className="shadow-sm  stockCheck">
            <td>{!selectedDrug.cdCatalogue ?
                selectedDrug.cdcatalogueNameOrFormularyName
                : selectedDrug.cdCatalogue.cdcatalogueNameOrFormularyName}</td>
        <td>
          <StockManagementDropdown
            options={returnOptions}
            setStockName={handleReturnTypeChange}
            stockName={returnAction?.name || ""}
            disabled={false}
          />
        </td>
        <td>
          <Input
            autoComplete="off"
            onChange={handleDatixNoChange}
            placeholder="Optional"
          />
        </td>
        <td>
          <QuantityInput
            allowZero
            placholder={amount}
            onValueChange={handleAmountChange}
            required
            showEmptyValue
            unitsValue={!selectedDrug.cdCatalogue ?
                selectedDrug.cdCataloguePackSize[0]?.uom
                : selectedDrug.cdCatalogue.cdCataloguePackSize[0]?.uom
            }
          />
        </td>
        <td>
          <Button
            aria-label="Delete item"
            className="icon-delete"
            color="transparent"
            onClick={handleDeleteButtonClick}
            outline
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <div className="AdministerStock">
      <header className="page-header">
        <h1 className="page-heading">Add Returned CD</h1>
        <Link className="btn btn-primary btn-icon" to={mainPagePath}>
          <FontAwesomeIcon className="on-left" icon={faReplyAll} />
          Back to main screen
        </Link>
      </header>
      <Row>
        <Col sm="6" xl="4">
          <DrugSearchList
            searchListClass="search-list-fixed"
            setAlertMessage={(alertMessage) =>
              showAlertMessage(dispatch, alertMessage)
            }
            setSelectedDrug={setSelectedDrug}
            selectableSearch
          />
        </Col>
      </Row>
      <Table className="stock-management-table">
        <thead>
          <tr>
            <th>Drug Name / Strength / Form</th>
            <th>Type of return</th>
            <th>Incident Ref.</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>{selectedDrug && renderRow()}</tbody>
      </Table>
      {selectedDrug && (
        <>
          <CommentsBlock required setComments={handleCommentsChange} />
          <ConfirmBlock
            className="ml-0 mr-0"
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={initialStateSignedWitnessed}
            inputsConfirmed={isConfirmed}
            isLoading={isLoading}
            setConfirmedStaff={setConfirmedStaff}
            setInputsConfirmed={setIsConfirmed}
            title="Drugs arrived in Pharmacy"
          />
        </>
      )}
    </div>
  );
}

export default AddReturnedCD;
