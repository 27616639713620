import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";
import { Link } from "react-router-dom";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuditLine from "../../component/molecules/Pharmacy/AuditLine";
import { initialStateSigned } from "../../config/confirm";
import AuditService from "../../services/AuditService";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";

const StockAuditIndividual = (props) => {
  // messaging
  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [approved, setapproved] = useState(messageState);

  // create page
  const [areaName, setAreaName] = useState({ id: "", name: "" });
  const [auditItems, setAuditItems] = useState([]);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState([]);
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  // page props
  const locationName = props.location.state.locationName;
  const locationId = props.location.state.lcId;

  useEffect(() => {
    setAreaName({ id: locationId, name: locationName });
    AuditService.getWardStockAudit(locationId)
      .then((response) => setAuditItems(response.data))
      .catch(() => {
        setAlertMessage({
          message: `There was a problem retrieving the audit information, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  }, []);

  useEffect(() => {
    if (inputsConfirmed) {
      const payload = auditItems.map((item) => ({
        ...item,
        createdBy: props.user.username,
        signedBy: confirmedStaff.signed,
      }));

      setConfirmedStaffErrors();

      AuditService.createStockAudit(locationId, payload)
        .then((response) => {
          if (response.status === 201) {
            setAlertMessage({
              message: "The audit information has been updated",
              colour: "success",
            });
          } else {
            setAlertMessage({
              message: `There was a problem updating the audit information, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            setAlertMessage({
              message: `There was a problem updating the audit information, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        })
        .finally(() => setInputsConfirmed(false));
    }
  }, [inputsConfirmed]);

  const deleteItem = (saId) => {
    setAuditItems((items) => items.filter((item) => item.saId !== saId));
  };

  return (
    <Col className="StockAuditIndividual">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
          <h3>CD Compliance Audit location</h3>
          <h1>{areaName["name"]}</h1>
        </Col>
        <Col
          md={{ size: 3, offset: 3 }}
          className="d-flex align-items-end flex-column justify-content-end"
        >
          <Row>
            <Col className="mb-4 ">
              <Link className="shadow-sm btn btn-primary" to="/pharmacy/audit">
                <FontAwesomeIcon className="on-left" icon={faReplyAll} />
                BACK TO ALL LOCATIONS
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="StockAuditIndividual-list">
        {auditItems && (
          <ul>
            {auditItems.map((aut, adx) => (
              <AuditLine
                key={adx}
                audit={aut}
                deleteItem={deleteItem}
                view="edit"
              />
            ))}
          </ul>
        )}
      </Row>
      <ConfirmBlock
        confirmedStaffErrors={confirmedStaffErrors}
        confirmInitialState={initialStateSigned}
        inputsConfirmed={inputsConfirmed}
        setConfirmedStaff={setConfirmedStaff}
        setInputsConfirmed={setInputsConfirmed}
      />
    </Col>
  );
};

export default React.memo(StockAuditIndividual);
