import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProcedures, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

import { Loader } from "../../component/atoms";
import SystemContext from "../../context/SystemContext";
import { getPatientFullNameAndId } from "../../helpers";
import PatientService from "../../services/PatientService";
import { showAlertMessage } from "../../store/actions";

const formInitialState = {
  address: "",
  firstName: "",
  lastName: "",
};
let serviceCancelSource;
let serviceConfig;

function PatientDropdown({
  allowCreatePatient,
  hidden,
  invalid,
  isSearch,
  patient,
  setPatient,
  triggerPatient,
  alternateLocationId
}) {
  const { isPharmacy, locationId, user } = useContext(SystemContext);
  const dispatch = useDispatch();

  const [getPatient, setGetPatient] = useState([]);
  const [searchPatient, setSearchPatient] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [formState, setFormState] = useState(formInitialState);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const patientLocationId = alternateLocationId
    ? alternateLocationId
    : locationId;

  useEffect(() => {
    serviceCancelSource = PatientService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    getPatients();

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    setPatient();
    setTextValue("");
    getPatients();
  }, [patientLocationId]);

  useEffect(() => {
    if (patient) {
      patientLookUp(patient);
    } else {
      setFormState(formInitialState);
      setSearchPatient(getPatient);
      setTextValue("");
    }
  }, [patient]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const patientLookUp = (text) => {
    const splitText = text.trim().split(/\s+/);
    const lastName = splitText.pop();
    const firstName = splitText.join(" ");
    if(!isUpdate){
      setFormState((formState) => ({ ...formState, firstName, lastName }));
    }
    setTextValue(text);
    setSearchPatient(
      getPatient.filter((item) =>
        `${item["firstname"]} ${item["lastname"]}`
          .toLowerCase()
          .includes(text.toLowerCase())
      )
    );
  };

  async function getPatients() {
    let isCancelled = false;

    try {
      const { data } = await PatientService.getAllLocationPatient(
        patientLocationId,
        serviceConfig
      );
      const createdPatient = data.find((item) => {
        return (
          item.firstname === formState.firstName &&
          item.lastname === formState.lastName
        );
      });

      setGetPatient(data);
      setSearchPatient(data);

      if (createdPatient) {
        setTextValue(`${formState.firstName} ${formState.lastName}`);
        setPatient(createdPatient);
        setDropdownOpen(false);
      }
    } catch (error) {
      if (PatientService.isCancel(error)) {
        isCancelled = true;
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem getting the patient list, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  async function updatePatientCall() {
    setIsLoading(true);

    try {
      const response = await PatientService.updatePatient(
        {
          updateBy: user.username,
          isPharmacy,
          lcId: patientLocationId,
          ...formState,
        },
        serviceConfig
      );

      if (response.status === 201) {
        setFormState(formInitialState);
        getPatients();
        setIsUpdate(false);
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem updating the  patient, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        showAlertMessage(dispatch, {
          message: `Logged in User does not have the required permission.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
      else if (!PatientService.isCancel(error)) {
        showAlertMessage(dispatch, {
          message: `There was a problem creating the new patient, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
    }
  }

  async function createPatient() {
    setIsLoading(true);

    try {
      const response = await PatientService.createNewPatient(
        {
          createdBy: user.username,
          isPharmacy,
          lcId: patientLocationId,
          ...formState,
        },
        serviceConfig
      );

      if (response.status === 201) {
        setFormState(formInitialState);
        getPatients();
        setIsUpdate(false);
      } else {
        showAlertMessage(dispatch, {
          message: `There was a problem creating the new patient, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        showAlertMessage(dispatch, {
          message: `Logged in User does not have the required permission.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
      else if (!PatientService.isCancel(error)) {
        showAlertMessage(dispatch, {
          message: `There was a problem creating the new patient, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
    }
  }

  const clickPatient = (pat) => {
    if (!(pat.firstname && pat.lastname && pat.address && pat.uniqueIdentifier ) && isPharmacy)
    {
      setIsUpdate(true);
      triggerUpdatePatient(pat);
      showAlertMessage(dispatch, {
        message: `Patient missing mandatory fields and will require updating them.`,
        colour: "warning",
      });
    }
    else{
      setTextValue(`${pat.firstname} ${pat.lastname}`);
      setPatient(pat);
      setDropdownOpen(false);
    }
  };

  function handleSearchInputChange({ target: { value } }) {
    patientLookUp(value);
  }

  function handleInputChange({ target: { name, value } }) {
    setFormState((formState) => ({ ...formState, [name]: value }));
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    createPatient();
  }

  function handleFormSubmitUpdate(event) {
    event.preventDefault();
    updatePatientCall();
  }

  function renderCreatePatientForm() {
    return (
      <Form noValidate onSubmit={handleFormSubmit}>
        <strong>Create new patient:</strong>
        <FormGroup className="group-first-name">
          <label htmlFor="first-name">
            <sup className="mandatory-label">*</sup>
            First name(s):
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="first-name"
            name="firstName"
            value={formState.firstName}
            onChange={handleInputChange}
            maxLength="55"
            required
          />
        </FormGroup>
        <FormGroup className="group-last-name">
          <label htmlFor="last-name">
            <sup className="mandatory-label">*</sup>
            Last name:
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="last-name"
            name="lastName"
            value={formState.lastName}
            onChange={handleInputChange}
            maxLength="55"
            required
          />
        </FormGroup>
        <FormGroup className="group-uniqueidentifier">
          <label htmlFor="uniqueidentifier">
          {isPharmacy && <sup className="mandatory-label">*</sup>}
            Unique Identifier (MRN or NHS no):
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="uniqueIdentifier"
            name="uniqueIdentifier"
            value={formState.uniqueIdentifier}
            onChange={handleInputChange}
            maxLength="10"
            required={isPharmacy}
          />
        </FormGroup>
        <FormGroup className="group-dob">
          <label htmlFor="dob">DOB:</label>
          <Input
            type="date"
            autoComplete="off"
            disabled={isLoading}
            id="dob"
            name="dob"
            value={formState.dob}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup className="group-address">
          <label htmlFor="address">
           {isPharmacy && <sup className="mandatory-label">*</sup>}
          Address:
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="address"
            name="address"
            value={formState.address}
            onChange={handleInputChange}
            type="textarea"
            required={isPharmacy}
          />
        </FormGroup>
        {isPharmacy ? 
        <Button
        className="btn-create"
        color="primary"
        disabled={!(formState.firstName && formState.lastName && formState.address && formState.uniqueIdentifier) || isLoading}
        type="submit"
      >
        {!isLoading && "Create"}
        <Loader show={isLoading} size="sm" />
      </Button>
      :
      <Button
          className="btn-create"
          color="primary"
          disabled={!(formState.firstName && formState.lastName) || isLoading}
          type="submit"
        >
          {!isLoading && "Create"}
          <Loader show={isLoading} size="sm" />
        </Button>
        }
        
      </Form>
    );
  }
function triggerUpdatePatient(pat){
  setIsUpdate(true);
    setFormState(
        {
              address: pat.address,
              firstName: pat.firstname,
              lastName: pat.lastname,
              uniqueIdentifier:pat.uniqueIdentifier,
              dob: pat.dob ? pat.dob.replace(/(T.*)/,"") : null ,
              ptId:pat.ptId

        }
    )
}
    function updateCancel(){
      setIsUpdate(false);
      setFormState(formInitialState);
    }

    function renderUpdatePatientForm() {
    return (
      <Form noValidate>
            <Button
                className="btn-cancel"
                color="primary"
                type="cancel"
                onClick={() => updateCancel()}
            >
                Cancel
            </Button>
        <FormGroup className="group-first-name">
          <label htmlFor="first-name">
            <sup className="mandatory-label">*</sup>
            First name(s):
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="first-name"
            name="firstName"
            value={formState.firstName}
            onChange={handleInputChange}
            maxLength="55"
            required
          />
        </FormGroup>
        <FormGroup className="group-last-name">
          <label htmlFor="last-name">
            <sup className="mandatory-label">*</sup>
            Last name:
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="last-name"
            name="lastName"
            value={formState.lastName}
            onChange={handleInputChange}
            maxLength="55"
            required
          />
        </FormGroup>
        <FormGroup className="group-uniqueidentifier">
          <label htmlFor="uniqueidentifier">
          {isPharmacy && <sup className="mandatory-label">*</sup>}
            Unique Identifier (MRN or NHS no):
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="uniqueIdentifier"
            name="uniqueIdentifier"
            value={formState.uniqueIdentifier}
            onChange={handleInputChange}
            maxLength="10"
            required={isPharmacy}
          />
        </FormGroup>
        <FormGroup className="group-dob">
          <label htmlFor="dob">DOB:</label>
          <Input
            type="date"
            autoComplete="off"
            disabled={isLoading}
            id="dob"
            name="dob"
            value={formState.dob}
            onChange={handleInputChange}
            defaultValue={formState.dob}
          />
        </FormGroup>
        <FormGroup className="group-address">
          <label htmlFor="address">
           {isPharmacy && <sup className="mandatory-label">*</sup>}
          Address:
          </label>
          <Input
            autoComplete="off"
            disabled={isLoading}
            id="address"
            name="address"
            value={formState.address}
            onChange={handleInputChange}
            type="textarea"
            required={isPharmacy}
          />
        </FormGroup>
        {isPharmacy ? 
        <Button
        className="btn-create"
        color="primary"
        disabled={!(formState.firstName && formState.lastName && formState.address && formState.uniqueIdentifier) || isLoading}
        type="submit"
        onClick={handleFormSubmitUpdate}
      >
        {!isLoading && "Update"}
        <Loader show={isLoading} size="sm" />
      </Button>
      :
      <Button
          className="btn-create"
          color="primary"
          disabled={!(formState.firstName && formState.lastName) || isLoading}
          type="submit"
          onClick={handleFormSubmitUpdate}
        >
          {!isLoading && "Update"}
          <Loader show={isLoading} size="sm" />
        </Button>
        }
        
      </Form>
    );
  }

  return (
    <Dropdown
      className={cx({
        active: triggerPatient,
        hidden,
        search: isSearch,
        "shadow-lg": !isSearch,
        width: "100%",
      })}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        className="dropdown-input-block"
      >
        <Input
          aria-label="Patient search"
          className={cx({ invalid, valid: !invalid, "shadow-sm": isSearch })}
          disabled={isLoading}
          onChange={handleSearchInputChange}
          placeholder="Patient name"
          type="text"
          value={textValue}
        />
        {isSearch && (
          <Button className="search-button">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        )}
      </DropdownToggle>
      <DropdownMenu className="shadow">
        {getPatient && (
          <ul aria-label="Patient search results">
            { !(isUpdate) && (searchPatient.map((pat, pdx) => (
              <li
                className="dropdown-item"
                key={pdx}
                title={getPatientFullNameAndId(pat)}
                onClick={() => clickPatient(pat)}
              >
                    <Button
                        color="primary"
                        key={pdx}
                        className="check-patient-complete-btn btn-icon"
                        onClick={(event) => { event.stopPropagation(); triggerUpdatePatient(pat); }}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                {pat.patientName}
              </li>
            )))}
           {isUpdate && (
            renderUpdatePatientForm()
           )}
            {searchPatient.length === 0 &&  !isUpdate &&  (
              <>
                <li className="dropdown-empty-item">
                  No matching patients found
                </li>
                {allowCreatePatient && renderCreatePatientForm()}
              </>
            )}
          </ul>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default PatientDropdown;
