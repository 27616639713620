import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, InputGroup, Input, Button } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import AuditService from "../../services/AuditService";

import { useHistory } from "react-router";

let messageTimeoutId;

const CreateNewAuditLine = ({ user }) => {
  let history = useHistory();

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [auditOptions, setAuditOptions] = useState([]);
  const [auditSend, setauditSend] = useState([]);
  const [confirmNewItem, setConfirmNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");

  const updateNewItemName = (e) => {
    setNewItemName(e.target.value);
  };

  const updateNewItemDescription = (e) => {
    setNewItemDescription(e.target.value);
  };

  const clearNewItem = () => {
    setNewItemName("");
    setNewItemDescription("");
  };

  useEffect(() => {
    AuditService.getAllStockAudit().then((response) =>
      setAuditOptions(response.data)
    );
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [auditOptions, auditSend]);

  useEffect(() => {
    if (confirmNewItem)
      AuditService.addNewStockAuditItem({
        Name: newItemName,
        Description: newItemDescription,
        CreatedBy: user.username,
      })
        .then(() => {
          AuditService.getAllStockAudit().then((response) =>
            setAuditOptions(response.data)
          );
          setAlertMessage({
            message: "New stock audit added successfully",
            colour: "success",
            timeout: 5000,
          });
          clearNewItem();
          setConfirmNewItem(false);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setAlertMessage({
              message: `The signed in user has insufficient permissions to perform this action,
                if required please contact your IT help-desk to change`,
              colour: "danger",
            });
          } else {
            setAlertMessage({
              message: `Unable to add the new stock audit, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
          clearNewItem();
          setConfirmNewItem(false);
        });

    return () => {};
  }, [confirmNewItem]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="CreateNewAuditLine">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col sm="6" md="8">
          <h1>Master Compliance List</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="6"
          md="4"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to="/pharmacy/audit"
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MAIN SCREEN
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Add New Audit Line</h2>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              setConfirmNewItem(true);
            }}
            className="form"
          >
            <div className="d-flex flex-column">
              <InputGroup className="mb-3">
                <span className="w-auto SystemNewPerson-sub ml-2">Name :</span>
                <Input
                  className="SystemNewPerson-detail_name"
                  type="text"
                  name="new-item-title"
                  value={newItemName}
                  onChange={updateNewItemName}
                  autoComplete="off"
                  required
                />
              </InputGroup>
              <InputGroup>
                <span className="w-auto SystemNewPerson-sub ml-2 h-50">
                  Description :
                </span>
                <Input
                  className="SystemNewPerson-detail_name"
                  type="textarea"
                  name="new-item-description"
                  value={newItemDescription}
                  onChange={updateNewItemDescription}
                  autoComplete="off"
                />
              </InputGroup>
            </div>
            <div className="d-flex justify-content-end my-4">
              <Button
                className="shadow-sm"
                color="success"
                type="submit"
                id="btn-save-new"
              >
                SAVE
              </Button>
              <Button
                className="shadow-sm ml-3"
                color="danger"
                type="button"
                id="btn-close-new"
                onClick={(e) => {
                  e.preventDefault();
                  clearNewItem();
                  history.push("/pharmacy/audit");
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {auditOptions && (
        <Row className="CreateNewAuditLine-list">
          <Col>
            <h2>Current List</h2>
            <ul>
              {auditOptions.map((aud, adx) => (
                <li id={aud["saId"]} key={adx} className="shadow-sm">
                  <span>{aud["name"]}</span>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default React.memo(CreateNewAuditLine);
