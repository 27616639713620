import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Button, Table } from "reactstrap";
import { CAlert } from "@coreui/react";
import {
  faReplyAll,
  faEdit,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ASLTableHeader from "../../component/molecules/Pharmacy/ASLTableHeader";
import ApprovedTableLine from "../../component/molecules/Pharmacy/ApprovedTableLine";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";
import SystemContext from "../../context/SystemContext";
import { getDrugId } from "../../helpers";
import CDService from "../../services/CDService";
import ASL from "../../config/_ASL";

let messageTimeoutId;
let redirectTimeoutId;

const ApprovedLocationList = (props) => {
  const history = useHistory();
  const { user } = useContext(SystemContext);

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);

  // create page
  const [locationDrugs, setlocationDrugs] = useState([]);
  const [drugList, setdrugList] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState("");
  const [areaName, setareaName] = useState({ lcId: "", wardInfo: "" });
  const [editView, seteditView] = useState(true);
  const [checkDrug, setcheckDrug] = useState({ state: false, array: [] });

  // trigger viewstate
  const [showEdit, setshowEdit] = useState(true);
  const [showConfirm, setshowConfirm] = useState(false);
  const [showConfirmBlock, setshowConfirmBlock] = useState(false);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  // page props
  const location = useLocation();
  const locationName = props.location.state.locationName;
  const viewState = props.location.state.viewState;

  const editList = () => {
    setInputsConfirmed(false);
    seteditView(false);
    setshowEdit(false);
    setshowConfirmBlock(false);
    setshowConfirm(true);
  };

  const confirmNewList = () => {
    const completeLine =
      drugList.map((item) => ({ ...item, ...confirmedStaff })).length !== 0
        ? drugList.map((item) => ({ ...item, ...confirmedStaff }))
        : [{ ...confirmedStaff, isEmpty: true }];
    CDService.setPharmacyASL(areaName["lcId"], completeLine)
      .then((response) => {
        if (response.status === 201) {
          redirectTimeoutId = setTimeout(() => {
            history.push("/pharmacy/locations/approved");
          }, 5000);
          setAlertMessage({
            message: "The approved list has been updated for this location",
            colour: "success",
            timeout: 5000,
          });
        } else {
          setAlertMessage({
            message: `There was a problem updating the approved list for this location,
              the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
          setInputsConfirmed(false);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem updating the approved list for this location,
              the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setInputsConfirmed(false);
      });
  };

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();
      confirmNewList();
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    if (selectedDrug) {
      if (
        locationDrugs.some(
          (item) => getDrugId(item) === getDrugId(selectedDrug)
        )
      ) {
        setAlertMessage({
          message: "You have already added that drug to the list",
          colour: "warning",
        });
      } else {
        selectedDrug["origIsWitnessed"] = selectedDrug["isWitnessed"];
        selectedDrug["origIsSafeCustody"] = selectedDrug["isSafeCustody"];
        setlocationDrugs((locationDrugs) => [...locationDrugs, selectedDrug]);
      }
    }
  }, [selectedDrug]);

  useEffect(() => {
    const ward = location.pathname.split("/")[5];

    setareaName({ lcId: ward, wardInfo: locationName });

    if (viewState) {
      seteditView(false);
      setshowEdit(false);
    }

    CDService.getPharmacyASL(ward)
      .then((response) => setlocationDrugs(response.data))
      .catch((error) => {
        setAlertMessage({
          message: `Approved list for this location is currently unavailable, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });

    return () => {
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  const deleteDrug = (id) => {
    const newData = locationDrugs
      .slice()
      .filter((item) => item["catalogueId"] !== id);
    setlocationDrugs([...newData]);
  };

  const createList = () => {
    //   if (locationDrugs.every((line) => line["nsvCode"] && line["threshold"])) {
    const drugs = [];
    //filter through table for content
    for (let i = 0; i < locationDrugs.length; i++) {
        drugs[i] = {
            catalogueId: locationDrugs[i]["catalogueId"],
        cdVpId: locationDrugs[i]["cdVpId"],
        nsvcode: locationDrugs[i]["nsvCode"],
        //          threshold: locationDrugs[i]["threshold"],
        threshold: 1,
        createdBy: user.username,
        isWitnessed: locationDrugs[i]["isWitnessed"],
        isSafeCustody: locationDrugs[i]["isSafeCustody"],
      };
    }

    // create array of new drugs
    setdrugList(drugs);
    // add original list to
    setshowConfirmBlock(true);
    seteditView(true);
    setshowEdit(true);
    setshowConfirm(false);
    setcheckDrug({ state: false, array: [] });
    setAlertMessage(messageState);
  };

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="ApprovedList">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col>
          <h3>Approved stock location</h3>
          <h1>{areaName["wardInfo"]}</h1>
        </Col>
        <Col
          md={{ size: 3, offset: 3 }}
          className="d-flex align-items-end flex-column"
        >
          <Row>
            <Col className="mb-4 ">
              <Link
                className="shadow-sm btn btn-primary"
                to="/pharmacy/locations/approved"
              >
                <FontAwesomeIcon className="on-left" icon={faReplyAll} />
                BACK TO ALL LOCATIONS
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 3 }}>
          {!showEdit && (
            <DrugSearchList
              searchListClass="search-list-fixed"
              setAlertMessage={setAlertMessage}
              setSelectedDrug={setSelectedDrug}
            />
          )}
        </Col>
        {showEdit && (
          <Col className="d-flex justify-content-end">
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                editList();
              }}
            >
              <FontAwesomeIcon className="on-left" icon={faEdit} />
              Edit list
            </Button>
          </Col>
        )}
      </Row>
      <Row className="cd-list">
        <Table hover>
          <ASLTableHeader editView={editView} />
          <tbody key={locationDrugs.length}>
            {locationDrugs.map((nxt, idx) => (
              <ApprovedTableLine
                key={idx}
                nxt={nxt}
                deleteDrug={deleteDrug}
                drugList={locationDrugs}
                setdrugList={setlocationDrugs}
                view={editView}
                check={checkDrug}
              />
            ))}
          </tbody>
        </Table>
      </Row>
      {showConfirm && (
        <Row className="d-flex justify-content-end">
          <Button
            className="confirmButton shadow-sm"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              createList();
            }}
          >
            CONFIRM ITEMS{" "}
            <FontAwesomeIcon className="on-right" icon={faCheckCircle} />
          </Button>
        </Row>
      )}
      {showConfirmBlock && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          inputsConfirmed={inputsConfirmed}
          setInputsConfirmed={setInputsConfirmed}
          confirmInitialState={ASL.confirmInitialState}
          setConfirmedStaff={setConfirmedStaff}
        />
      )}
    </Col>
  );
};

export default React.memo(ApprovedLocationList);
