import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import cx from "classnames";

import { Label, Loader } from "../../../component/atoms";
import {
  getDateStrings,
  getTimestamp,
  replacePropertyValues,
  truncateString,
} from "../../../helpers";
import {
  getInitialState,
  getURLParams,
  getValidationMessage,
  setCustomErrorMessages,
  setValidities,
} from "../../../helpers/forms";
import ReportService from "../../../services/ReportService";
import { hideAlertMessage, showAlertMessage } from "../../../store/actions";

const formFields = {
  StartDate: {
    autoComplete: "off",
    id: "start-date",
    label: "Date from",
    name: "StartDate",
    pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
    placeholder: "yyyy-mm-dd",
    required: true,
    type: "date",
  },
  EndDate: {
    autoComplete: "off",
    id: "end-date",
    label: "Date to",
    name: "EndDate",
    pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
    placeholder: "yyyy-mm-dd",
    required: true,
    type: "date",
  },
};
let serviceCancelSource;
let serviceConfig;

function CDDisposal({ locationRoute, user }) {
  const dispatch = useDispatch();
  const REPORT_FORM_MIN_DATE = useSelector(
    (state) => state.settings.discrepancyModalEnabled
  );
  const REPORT_TABLE_MAX_ROWS = useSelector(
    (state) => state.settings.REPORT_TABLE_MAX_ROWS
  );
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );

  const [alertMessage, setAlertMessage] = useState();
  const [formState, setFormState] = useState(
    getInitialState(formFields, useRef)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isPartialReport, setIsPartialReport] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [report, setReport] = useState();

  const maxDate = getTimestamp().split("T")[0];

  useEffect(() => {
    serviceCancelSource = ReportService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    setValidities(setFormState);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isPartialReport) {
      setAlertMessage({
        message: `Only showing the first ${REPORT_TABLE_MAX_ROWS} records, please narrow your date search`,
        colour: "warning",
      });
    }
  }, [isPartialReport]);

  async function getReport() {
    const config = { ...serviceConfig, params: getURLParams(formState) };
    let isCancelled = false;

    setIsLoading(true);
    setIsPartialReport(false);
    setReport(null);

    try {
      const { data } = await ReportService.getPharmacyDisposed(
        user.location.lcId,
        config
      );

      if (data.length > REPORT_TABLE_MAX_ROWS) {
        data.splice(REPORT_TABLE_MAX_ROWS);
        setIsPartialReport(true);
      }

      setReport(data);
      setShowValidity(false);
    } catch (error) {
      if (ReportService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the log records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setCustomErrorMessages(setFormState, error.response);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function handleFieldChange({
    target: { name, validationMessage, validity, value },
  }) {
    if (formState[name].innerRef) {
      formState[name].innerRef.current.setCustomValidity("");
    }

    setFormState((formState) =>
      replacePropertyValues(formState, name, {
        customErrorMessage: validationMessage,
        validity,
        value,
      })
    );
  }

  function handleFormSubmit(event) {
    const message = getValidationMessage(formState);

    if (message) {
      setAlertMessage({
        message,
        colour: "warning",
      });
    } else {
      getReport();
    }

    setShowValidity(true);
    event.preventDefault();
  }

  function renderReport() {
    if (!report.length) {
      return (
        <p className="page-message">
          There are no logged records for that date range
        </p>
      );
    }

    const sortedList = report
      .sort((a, b) => {
        return new Date(a.eventDateTime) - new Date(b.eventDateTime);
      })
      .reverse();

    return (
      <Table className="report-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Item</th>
            <th>Ref</th>
            <th>Comments</th>
            <th>Disposed by</th>
            <th>Witnessed by</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item, index) => {
            const dateStrings = getDateStrings(item.eventDateTime);

            return (
              <tr key={index}>
                <td className="min-height">
                  <time dateTime={item.eventDateTime}>{dateStrings.date}</time>
                </td>
                <td>
                  <time dateTime={item.eventDateTime}>{dateStrings.time}</time>
                </td>
                <td className="break-words">{item.item}</td>
                <td>{item.reference}</td>
                <td className="break-words">{item.comments}</td>
                <td className="break-words" title={item.disposedBy}>
                  {truncateString(
                    item.disposedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td className="break-words" title={item.witnessedBy}>
                  {truncateString(
                    item.witnessedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td>
                  {item.amountDisposed} {item.amountDisposedUom}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <Col className={`CDDisposal CDDisposal-${locationRoute} report-page`}>
      <Row className="mb-4">
        <h1>Log: Disposal</h1>
      </Row>
      <Form
        className={cx("form horizontal", { "show-validity": showValidity })}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={
                formState.StartDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.StartDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.StartDate.innerRef}
                max={
                  (formState.EndDate.validity.valid &&
                    formState.EndDate.value) ||
                  maxDate
                }
                min={REPORT_FORM_MIN_DATE}
                onChange={handleFieldChange}
                {...formFields.StartDate}
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup
              title={
                formState.EndDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.EndDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.EndDate.innerRef}
                max={maxDate}
                min={
                  (formState.StartDate.validity.valid &&
                    formState.StartDate.value) ||
                  REPORT_FORM_MIN_DATE
                }
                onChange={handleFieldChange}
                {...formFields.EndDate}
              />
            </FormGroup>
          </Col>
          <Col className="buttons" md="2">
            <Button color="primary" disabled={isLoading} type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {report && renderReport()}
        <Loader show={isLoading} />
      </Row>
    </Col>
  );
}

export default CDDisposal;
