import React from "react";

const ASLTableHeader = ({ editView }) => {
  return (
    <thead>
      <tr>
        <th></th>
        {/* <th>NSVCode</th> */}
        <th>Unit Dose</th>
        {/*         <th>
          <span>min.</span>Threshold
        </th> */}
        <th>Schedule</th>
        <th>Witness</th>
        <th>Safe Custody</th>
        {!editView && <th></th>}
      </tr>
    </thead>
  );
};

export default React.memo(ASLTableHeader);
