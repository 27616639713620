import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { CFooter } from "@coreui/react";

function Footer(props) {
  const { VERSION, BUILD, privacyUrl } = props;

  return (
    <CFooter className="row" fixed={false}>
      <Col className="footer-content">
        <Row>
          <Col xs="3" md="3">
            eCDR-Pro Release {VERSION} ({BUILD})
          </Col>
          <Col xs="3" md="3">
            Solidsoft Reply &copy; {new Date().getFullYear()}
          </Col>
          <Col xs="3" md="3">
            <a
              href="https://www.reply.com/solidsoft-reply/en"
              rel="noreferrer"
              target="_blank"
            >
              Company Information
            </a>
          </Col>
          <Col xs="2" md="2">
            <a href={privacyUrl} rel="noreferrer" target="_blank">
              Privacy notice
            </a>
          </Col>
        </Row>
      </Col>
    </CFooter>
  );
}

function mapStateToProps(state) {
  return {
    VERSION: state.settings.VERSION,
    BUILD: state.settings.BUILD,
    privacyUrl: state.settings.privacyUrl,
  };
}

export default connect(mapStateToProps)(Footer);
