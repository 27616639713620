import axios from "axios";
import AuthService from "./AuthService";

const StockService = {};

StockService.getCancelSource = () => axios.CancelToken.source();
StockService.isCancel = axios.isCancel;

StockService.wardStock = async (siteId) => {
  return await AuthService.get(`/stock/WardStock/${siteId}`);
};

StockService.getPharmacyStock = async (locationId) => {
  return await AuthService.get(`/stock/PharmacyStock/${locationId}`);
};

StockService.getPharmacyStockByCd = async (locationId, catalogueId, config) => {
  return await AuthService.get(
    `/stock/PharmacyStockByCd/${locationId}`,
    catalogueId,
    config
  );
};

StockService.createPharmacyStock = async (locationId, stock, config) => {
  return await AuthService.post(
    `/stock/CreatePharmacyStock/${locationId}`,
    stock,
    config
  );
};

StockService.createWardStock = async (locationId, stock, config) => {
  return await AuthService.post(
    `/stock/CreateWardStock/${locationId}`,
    stock,
    config
  );
};
StockService.managePharmacyStock = async (stock, config) => {
  return await AuthService.post(
    "/stock/PharmacyStockManagement",
    stock,
    config
  );
};

StockService.editPharmayStock = async (stock, config) => {
  return await AuthService.patch(
    "/stock/PharmacyStockManagement",
    stock,
    config
  );
};

StockService.getDrugsReturnedFromWards = async (locationId, config) => {
  return await AuthService.get(
    `/stock/DrugsReturnedFromWards/${locationId}`,
    config
  );
};

StockService.updateDrugsFromWards = async (locationId, stock, config) => {
  return await AuthService.patch(
    `/stock/DrugsFromWards/${locationId}`,
    stock,
    config
  );
};

StockService.createStockCheck = async (locationId, isPharmacy, stock) => {
  return await AuthService.post(
    `/stock/StockCheck/${locationId}/${isPharmacy}`,
    stock
  );
};

StockService.getStockCheck = async (siteId, locationId, isPatientsOwn) => {
  return await AuthService.get(
    `/stock/StockCheck/${siteId}/${locationId}/${isPatientsOwn}`
  );
};

StockService.getStockHistory = async (stkId, page, config) => {
  return await AuthService.get(`/stock/StockHistory/${stkId}?page=${page}`, config);
};

StockService.getPharmacyStockHistory = async (stkPhId, page, config) => {
  return await AuthService.get(
    `/stock/StockPharmacyHistory/${stkPhId}?page=${page}`,
    config
  );
};

StockService.getPatientsOwnStock = async (locationId, config) => {
  return await AuthService.get(
    `/stock/GetPatientsOwnStock/${locationId}`,
    config
  );
};

StockService.createPatientsOwnStock = async (stock, config) => {
  return await AuthService.post("/stock/CreatePatientsOwnStock", stock, config);
};

StockService.managePatientsOwnStock = async (stock, config) => {
  return await AuthService.post("/stock/manage", stock, config);
};

StockService.managePatientsOwnStockPartial = async (stock, config) => {
  return await AuthService.post(
    "/stock/managepartial?isPatientsOwn=true",
    stock,
    config
  );
};

StockService.manageWardStockPartial = async (stock, config) => {
  return await AuthService.post(
    "/stock/managepartial?isPatientsOwn=false",
    stock,
    config
  );
};

StockService.managePharmacyStockPartial = async (stock, config) => {
  return await AuthService.post(
    "/stock/PharmacyManagePartial",
    stock,
    config
  );
};

StockService.forDisposal = async (locationRoute, locationId, config) => {
  return await AuthService.get(
    `/stock/${locationRoute}/fordisposal/${locationId}`,
    config
  );
};

StockService.dispose = async (
  locationRoute,
  locationId,
  isApproval,
  stock,
  config
) => {
  return await AuthService.post(
    `stock/${locationRoute}/dispose/${locationId}${
      isApproval ? `/approve` : ""
    }`,
    stock,
    config
  );
};

StockService.disposalHistory = async (locationRoute, locationId, page, config) => {
  return await AuthService.get(
    `stock/${locationRoute}/disposalhistory/${locationId}?page=${page}`,
    config
  );
};

StockService.discrepancy = async (locationId, stock, config) => {
  return await AuthService.post(
    `stock/ward/${locationId}/newstock/discrepancy`,
    stock,
    config
  );
};

StockService.pharmacyDiscrepancy = async (locationId, stock, config) => {
  return await AuthService.post(
    `stock/pharmacy/${locationId}/newstock/discrepancy`,
    stock,
    config
  );
};

StockService.addClinicalWaste = async (locationId, payload, config) => {
  return await AuthService.post(
    `stock/ward/${locationId}/clinicalwaste`,
    payload,
    config
  );
};

StockService.createReturnedCD = async (locationId, stock, config) => {
  return await AuthService.post(
    `stock/pharmacy/${locationId}/returned`,
    stock,
    config
  );
};

StockService.updateStockHistory = async (params) => {
  return await AuthService.patch("stock/Theatre/UpdateStockHistory", params);
};

export default StockService;
