export function getInitialState(formFields, useRef) {
  return Object.values(formFields).reduce(
    (obj, item) => ({
      ...obj,
      [item.name]: {
        innerRef: item.type === "date" ? useRef() : undefined,
        label: item.label,
        type: item.type,
        validity: {},
        value: item.value || "",
      },
    }),
    {}
  );
}

export function getURLParams(formState) {
  return Object.entries(formState).reduce((obj, item) => {
    if (typeof item[1].value === "object") {
      return {
        ...obj,
        ...Object.fromEntries(
          Object.entries(item[1].value).map(([key, val]) => [key, String(val)])
        ),
      };
    } else {
      return { ...obj, [item[0]]: String(item[1].value) };
    }
  }, {});
}

export function getValidationMessage(formState) {
  const messages = Object.values(formState)
    .filter((item) => !item.validity.valid)
    .map((item) => {
      let message;

      if (item.validity.customError) {
        message = item.customErrorMessage;
      } else if (item.validity.valueMissing) {
        message = "Please complete the required field";
      } else if (
        item.type === "date" &&
        (item.validity.patternMismatch ||
          item.validity.rangeOverflow ||
          item.validity.rangeUnderflow)
      ) {
        message = "Please enter a valid date";
      } else {
        message = "Please correct the field";
      }

      return `${item.label}: ${message}`;
    });

  return messages.length ? messages : null;
}

export function setCustomErrorMessages(setFormState, response) {
  if (response?.data?.errors && response.status === 400) {
    const { errors } = response.data;

    setFormState((formState) =>
      Object.fromEntries(
        Object.entries(formState).map(([key, val]) => {
          if (errors[key]) {
            const customErrorMessage = errors[key][0];

            if (val.innerRef) {
              val.innerRef.current.setCustomValidity(customErrorMessage);

              return [
                key,
                {
                  ...val,
                  customErrorMessage,
                  validity: val.innerRef.current.validity,
                },
              ];
            } else {
              return [key, { ...val, customErrorMessage }];
            }
          }

          return [key, val];
        })
      )
    );
  }
}

export function setValidities(setFormState) {
  setFormState((formState) =>
    Object.fromEntries(
      Object.entries(formState).map(([key, val]) => {
        if (val.innerRef) {
          return [key, { ...val, validity: val.innerRef.current.validity }];
        }

        return [key, val];
      })
    )
  );
}
