import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faGear, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { CTooltip } from "@coreui/react";

const Navigation = {
  ward: [
    {
      _tag: "CSidebarNavItem",
      name: <span className="sidebar-name">Current Stock</span>,
      to: "/ward/stock",
      icon: (
        <CTooltip content="Current Stock" placement="right">
          <span id="ward-stock" className="sidebar-bumper">
            <FontAwesomeIcon role="img" icon={faLayerGroup} />
          </span>
        </CTooltip>
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: <span className="sidebar-name">Reports</span>,
      to: "/ward/reports",
      icon: (
        <CTooltip content="Reports" placement="right">
          <span id="ward-reports" className="sidebar-bumper">
            <FontAwesomeIcon role="img" icon={faChartPie} />
          </span>
        </CTooltip>
      ),
    },
  ],
  pharmacy: [
    {
      _tag: "CSidebarNavItem",
      name: <span className="sidebar-name">Current Stock</span>,
      to: "/pharmacy/stock",
      icon: (
        <CTooltip content="Current Stock" placement="right">
          <span id="pharmacy-stock" className="sidebar-bumper">
            <FontAwesomeIcon icon={faLayerGroup} />
          </span>
        </CTooltip>
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: <span className="sidebar-name">Reports</span>,
      to: "/pharmacy/reports",
      icon: (
        <CTooltip content="Reports" placement="right">
          <span id="pharmacy-reports" className="sidebar-bumper">
            <FontAwesomeIcon icon={faChartPie} />
          </span>
        </CTooltip>
      ),
    },
    {
      _tag: "CSidebarNavItem",
      name: <span className="sidebar-name">Settings</span>,
      to: "/pharmacy/settings",
      icon: (
        <CTooltip content="Settings" placement="right">
          <span id="pharmacy-settings" className="sidebar-bumper">
            <FontAwesomeIcon icon={faGear} />
          </span>
        </CTooltip>
      ),
    },
  ],
};

export default Navigation;
