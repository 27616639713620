import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationBlock = ({ dataSet, pageSize = 20, renderChildren }) => {
  const history = useHistory();
  const location = useLocation();

  const pagesCount = Math.ceil(dataSet.length / pageSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
    history.push(index > 0 ? `#page-${index + 1}` : "#");
  };

  useEffect(() => {
    if (location.hash.startsWith("#page-")) {
      const pageIndex = parseInt(location.hash.split("-")[1]) - 1;
      if (pageIndex < pagesCount) {
        setCurrentPage(pageIndex);
      } else {
        history.replace("#");
      }
    }
  }, []);

  return (
    <React.Fragment>
      {dataSet
        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        .map((data, i) => renderChildren(data, i))}

      <div className="pagination-wrapper">
        <Pagination
          aria-label="Page navigation example"
          className="pagination-nav"
        >
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink
              onClick={(e) => handleClick(e, currentPage - 1)}
              href="#"
              previous
              className="arrow"
            >
              {/* <FontAwesomeIcon
                    className="on-right"
                    icon={faArrowLeft}
                  /> */}
            </PaginationLink>
          </PaginationItem>

          {[...Array(pagesCount)].map((page, i) => (
            <PaginationItem active={i === currentPage} key={i}>
              <PaginationLink onClick={(e) => handleClick(e, i)} href="#">
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem disabled={currentPage >= pagesCount - 1}>
            <PaginationLink
              onClick={(e) => handleClick(e, currentPage + 1)}
              next
              href="#"
              className="arrow"
            />
          </PaginationItem>
        </Pagination>
      </div>
    </React.Fragment>
  );
};

export default PaginationBlock;
