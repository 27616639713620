export const initialStateSigned = [
  {
    label: "Signed by",
    name: "signed",
  },
];

export const initialStateSignedCollected = [
  {
    label: "Signed by",
    name: "signed",
  },
  {
    label: "Collected by",
    name: "collected",
  },
];

export const initialStateSignedWitnessed = [
  {
    label: "Signed by",
    name: "signed",
  },
  {
    label: "Witnessed by",
    name: "witnessed",
  },
];
