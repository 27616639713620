import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { CAlert, CButton } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFirstAid, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { getDateStrings } from "../../helpers";
import IllicitDrugService from "../../services/IllicitDrugService";

const messageInitialState = {};

let serviceCancelSource;
let messageTimeoutId;

function IllicitDrugs({ locationId, locationRoute }) {
  const ILLICIT_STATUS_TEMP_STORE = useSelector(
    (state) => state.settings.ILLICIT_STATUS_TEMP_STORE
  );
  const ILLICIT_STATUS_RETURNED_TO_PHARMACY = useSelector(
    (state) => state.settings.ILLICIT_STATUS_RETURNED_TO_PHARMACY
  );

  const tableViews = [
    {
      buttonText: "Temp store",
      statusId: ILLICIT_STATUS_TEMP_STORE,
    },
    {
      buttonText: "Returned to pharmacy",
      statusId: ILLICIT_STATUS_RETURNED_TO_PHARMACY,
    },
  ];

  const [activeViewId, setActiveViewId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(messageInitialState);
  const [drugList, setDrugList] = useState([]);
  const [drugListForView, setDrugListForView] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    serviceCancelSource = IllicitDrugService.getCancelSource();
    const config = { cancelToken: serviceCancelSource.token };
    let isCancelled = false;

    try {
      const { data } = await IllicitDrugService.getIllicitDrugs(
        locationRoute,
        locationId,
        config
      );
      setDrugList(data);
    } catch (error) {
      if (IllicitDrugService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the illicit substance list, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
          label: "Error message",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(messageTimeoutId);
    };
  }, []);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageInitialState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    setDrugListForView(
      drugList.filter(
        (tableRow) =>
          activeViewId === null || tableRow.statusId === activeViewId
      )
    );
  }, [activeViewId, drugList]);

  function viewButtonClickHandler({ target: { dataset } }) {
    const statusId = Number(dataset.statusId);

    setActiveViewId((activeViewId) =>
      activeViewId === statusId ? null : statusId
    );
  }

  function renderViewButton({ buttonText, statusId }) {
    return (
      <CButton
        className={`shadow-sm ml-2 btn-${
          statusId === activeViewId ? "on" : "off"
        }`}
        color="primary"
        data-status-id={statusId}
        key={statusId}
        onClick={viewButtonClickHandler}
        shape="pill"
        variant="outline"
      >
        {buttonText}
      </CButton>
    );
  }

  function renderTableRow(item, index) {
    const dateStrings = getDateStrings(item.createdAt);

    return (
      <tr key={index}>
        <td>
          <Link
            className="table-link"
            to={`/ward/stock/illicit/add/${item.id}`}
          >
            {item.description}
          </Link>
        </td>
        <td>{item.datixNo}</td>
        <td>{dateStrings.date}</td>
        <td>{dateStrings.time}</td>
        <td>
          <span className="status-text">{item.statusDisplayText}</span>
        </td>
      </tr>
    );
  }

  function renderStatus() {
    if (isLoading) {
      return (
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading…</span>
          </div>
        </div>
      );
    } else if (drugList.length === 0) {
      return (
        <p className="page-message">
          There are currently no stored illicit substances
        </p>
      );
    } else if (drugListForView.length === 0) {
      return <p className="page-message">No matching illicit substances</p>;
    }

    return null;
  }

  return (
    <Col className={`IllicitDrugs IllicitDrugs-${locationRoute}`}>
      <CAlert
        aria-label={alertMessage.label}
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
      >
        {alertMessage.message}
      </CAlert>
      <Row className="mb-4">
        <Col sm="7">
          <h1>Illicit Substance Overview</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="5"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to="/ward/stock/illicit/add"
          >
            Add new illicit substance
            <FontAwesomeIcon className="on-right" icon={faFirstAid} />
            <sup>
              <FontAwesomeIcon icon={faPlus} />
            </sup>
          </Link>
        </Col>
      </Row>
      <Row className="view-buttons">
        <span>Set table view:</span>
        {tableViews.map(renderViewButton)}
      </Row>
      <Row>
        <Table hover>
          <thead>
            <tr>
              <th>Substance Description</th>
              <th>Datix Ref. No.</th>
              <th>Date Received</th>
              <th>Time Received</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{drugListForView.map(renderTableRow)}</tbody>
        </Table>
        {renderStatus()}
      </Row>
    </Col>
  );
}

export default IllicitDrugs;
