import React, { useState, useEffect } from "react";
import { Input, InputGroup } from "reactstrap";
import {
  faMapMarkerAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LocationsService from "../../services/LocationsService";

const WardPicker = ({
  siteId,
  inputID,
  setAlertMessage,
  areaName,
  setareaName,
  wardAlert,
  setwardAlert,
  includePharmacy,
}) => {
  const [listActive, setlistActive] = useState(false);
  const [wardList, setwardList] = useState([]);
  const [dwardList, setdwardList] = useState([]);

  //ward search/auto complete
  const typeWard = (e) => {
    setlistActive(true);
    setdwardList(
      wardList.filter((item) => item["name"].includes(e.target.dataset.wardinfo))
    );
    if (wardList.filter((item) => item["name"] === e.target.dataset.wardinfo)[0]) {
      setareaName({
        ...areaName,
        lcId: wardList.filter((item) => item["name"] === e.target.dataset.wardinfo)[0][
          "lcId"
        ],
        isOnline: wardList.filter((item) => item["name"] === e.target.dataset.wardinfo)[0][
          "isOnline"
        ],
        wardInfo: e.target.innerText,
      });
    } else {
      setareaName({
        ...areaName,
        lcId: 0,
        isOnline: true,
        wardInfo: e.target.value,
      });
      }
  };

  const chooseWard = (e) => {
    setwardAlert(false);
    setlistActive(false);
    setareaName({
      ...areaName,
      lcId: wardList.filter((item) => item["name"] === e.target.dataset.wardinfo)[0][
        "lcId"
      ],
      isOnline: wardList.filter((item) => item["name"] === e.target.dataset.wardinfo)[0][
        "isOnline"
      ],
      wardInfo: e.target.innerText,
    });
  };

  useEffect(() => {
    LocationsService.getLocations(siteId, {
      params: { incPharm: includePharmacy },
    })
      .then((response) => {
        setdwardList(response.data);
        setwardList(response.data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  }, []);

    return (
    <div className="WardPicker">
      <InputGroup
        className={`search-area-holder ${
          wardAlert ? "search-area-holder-missing" : ""
        }`}
      >
        <FontAwesomeIcon className="error-icon" icon={faChevronRight} />
        <Input
          id={inputID}
          name={inputID}
          type="text"
          placeholder="CHOOSE CLINICAL AREA"
          className="search-area-input"
          onChange={typeWard}
          onClick={(e) => {
            e.preventDefault();
            setlistActive(true);
          }}
          value={areaName["wardInfo"] + (areaName["isOnline"] ? "" : " (Offline)")}
          autoComplete="off"
        />
        <FontAwesomeIcon className="search-area-icon" icon={faMapMarkerAlt} />
        {listActive && (
          <div className="search-area-list">
            <ul>
              {dwardList.map((wrd, wdx) => (
                <li key={wdx} onClick={chooseWard} id={wrd["lcId"]} data-isOnline={wrd.isOnline} data-wardInfo={wrd.name}>
                  {wrd.name + (wrd.isOnline ? "" : " (Offline)")}
                </li>
              ))}
            </ul>
          </div>
        )}
      </InputGroup>
    </div>
  );
};

export default WardPicker;
