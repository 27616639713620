import React from "react";
import { Col, Input, InputGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function CommentsBlock({
  comments,
  id = "comments",
  required,
  setComments,
  disabled,
}) {
  return (
    <Col className="comments-block">
      <div className="icon-block">
        <FontAwesomeIcon icon={faQuestionCircle} color="white" />
      </div>
      <InputGroup>
        <label className="w-auto comments-sub ml-2 h-50" htmlFor={id}>
          {required && <sup className="mandatory-label">*</sup>}Reason /
          Comments :
        </label>
        <Input
          className="comments-input"
          id={id}
          type="textarea"
          name="comment"
          value={comments}
          onChange={setComments}
          autoComplete="off"
          required={required}
          disabled={disabled}
        />
      </InputGroup>
    </Col>
  );
}

export default CommentsBlock;
