import React, { useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  Input,
  ModalFooter,
} from "reactstrap";
import { CButton } from "@coreui/react";
import PatientService from "../../services/PatientService";

const PatientMainData = ({ updateField, patient, isPharmacy }) => {
  return (
    <>
      <Row className="modalPatient-dataline">
        <Col xs="4">
          <span className="mandatory-label">*</span>
          <span>First Name(s) :</span>
        </Col>
        <Col>
          <Input
            type="text"
            name="firstname"
            value={patient.firstname || ""}
            onChange={updateField}
            autoComplete="off"
            maxLength="55"
            required
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="4">
          <span className="mandatory-label">*</span>
          <span>Last Name :</span>
        </Col>
        <Col>
          <Input
            type="text"
            name="lastname"
            value={patient.lastname || ""}
            onChange={updateField}
            autoComplete="off"
            maxLength="55"
            required
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="4">
          {isPharmacy && <span className="mandatory-label">*</span>}
          <span>Unique Identifier (MRN or NHS no):</span>
        </Col>
        <Col xs="3">
          <Input
            type="text"
            name="uniqueIdentifier"
            value={patient.uniqueIdentifier || ""}
            onChange={updateField}
            autoComplete="off"
            maxLength="10"
            required={isPharmacy}
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="4">
          <span>DOB :</span>
        </Col>
        <Col xs="3">
          <Input
            className=""
            type="date"
            name="dob"
            onChange={updateField}
            value={patient.dob || ""}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="4">
          {isPharmacy && <span className="mandatory-label">*</span>}
          <span>Address :</span>
        </Col>
        <Col>
          <Input
            type="textarea"
            name="address"
            value={patient.address || ""}
            onChange={updateField}
            autoComplete="off"
            required={isPharmacy}
          />
        </Col>
      </Row>
    </>
  );
};

const NewPatientModal = ({
  isOpen,
  setIsOpen,
  locationId,
  setAlertMessage,
  isPharmacy,
  setRefresh,
  user,
}) => {
  const [patient, setPatient] = useState({});

  const handleClose = () => {
    setIsOpen(false);
  };

  const updateField = (e) => {
    const { name, value } = e.target;
    setPatient((prevPatient) => ({ ...prevPatient, [name]: value }));
  };

  const createNewPatient = () => {
    PatientService.createNewPatient({
      LcId: locationId,
      uniqueIdentifier: patient.uniqueIdentifier || "",
      FirstName: patient.firstname || "",
      LastName: patient.lastname || "",
      Dob: patient.dob || "",
      Address: patient.address || "",
      CreatedBy: user,
      isPharmacy,
    })
      .then(() => {
        setAlertMessage({
          message: "New Patient Created",
          colour: "success",
        });
        setPatient({});
        setRefresh(true);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setAlertMessage({
            message: `The logged in user has insufficient permissions to perform this action, 
            if required please contact your IT help-desk to change`,
            colour: "danger",
          });
        } else {
          setAlertMessage({
            message: `Unable to create the new patient, the eCDR-Pro system may be offline. 
            If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      });
  };

  return (
    <Modal
      centered
      className="PatientModal"
      fullscreen="lg"
      isOpen={isOpen}
      scrollable
      size="xl"
      toggle={handleClose}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          createNewPatient();
        }}
      >
        <ModalHeader toggle={handleClose}>New Patient</ModalHeader>
        <ModalBody className="modalPatient-datablock">
          <PatientMainData
            updateField={updateField}
            patient={patient}
            isPharmacy={isPharmacy}
          />
        </ModalBody>
        <ModalFooter>
          <CButton shape="pill" color="secondary" onClick={handleClose}>
            Cancel
          </CButton>
          <CButton shape="pill" color="primary" type="submit">
            Save
          </CButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NewPatientModal;
