import "core-js";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { Spinner } from "reactstrap";

import Login from "./pages/Login";
import PageMissing from "./pages/PageMissing";
import CDService from "./services/CDService";
import routes from "./routes/routes";
import PrivateRoute from "./routes/PrivateRoute";
import "./styles/index.scss";
import { setSettings } from "./store/actions/settingsActions";

function App({ cookies }) {
  const dispatch = useDispatch();

  useEffect(() => {
    CDService.getFrontEndConfiguration()
      .then((res) => res.data)
      .then((settings) => setSettings(dispatch, { ...settings, loaded: true }));
  }, []);

  return (
    <Switch>
      <Route component={Login} exact path="/" />
      {routes.map(({ component, exact, path }) => (
        <PrivateRoute
          exact={exact}
          key={path}
          path={path}
          component={component}
          cookies={cookies}
        />
      ))}
      <Route component={PageMissing} path="*" />
    </Switch>
  );
}

export default App;
