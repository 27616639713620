import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import cx from "classnames";

import { Dropdown, Label, Loader } from "../../../component/atoms";
import {
  getDateStrings,
  getTimestamp,
  replacePropertyValues,
  truncateString,
} from "../../../helpers";
import {
  getInitialState,
  getURLParams,
  getValidationMessage,
  setCustomErrorMessages,
  setValidities,
} from "../../../helpers/forms";
import ReportService from "../../../services/ReportService";
import { hideAlertMessage, showAlertMessage } from "../../../store/actions";

let serviceCancelSource;
let serviceConfig;

function CDOrders({ locationRoute, user }) {
  const dispatch = useDispatch();
  const REPORT_FORM_MIN_DATE = useSelector(
    (state) => state.settings.REPORT_FORM_MIN_DATE
  );
  const REPORT_TABLE_MAX_ROWS = useSelector(
    (state) => state.settings.REPORT_TABLE_MAX_ROWS
  );
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );
  const statusId = useSelector((state) => state.settings.statusId);

  const eventTypes = [
    { value: statusId.ORDER_CREATED, label: "Order created" },
    { value: statusId.ORDER_SUPPLIED, label: "Order supplied" },
    { value: statusId.ORDER_REJECTED_BY_WARD, label: "Order rejected by ward" },
    {
      value: statusId.ORDER_REJECTED_BY_PHARMACY,
      label: "Order rejected by pharmacy",
    },
    {
      value: statusId.ORDER_COLLECTED_FROM_PHARMACY,
      label: "Order collected from pharmacy",
    },
    {
      value: statusId.ORDER_ARRIVED_ON_THE_WARD,
      label: "Order arrived on the ward",
    },
    {
      value: statusId.ORDER_ACCEPTED_BY_THE_WARD,
      label: "Order accepted by the ward",
    },
    {
      value: statusId.OUTPATIENT_COLLECTED,
      label: "Order collected for outpatient",
    },
    {
      value: statusId.OFFLINE_WARD_COLLECTED,
      label: "Order collected by the ward (offline)",
    },
    {
      value: statusId.CANCELLED,
      label: "Cancelled",
    },
  ];

  const formFields = {
    StartDate: {
      autoComplete: "off",
      id: "start-date",
      label: "Date from",
      name: "StartDate",
      pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
      placeholder: "yyyy-mm-dd",
      required: true,
      type: "date",
    },
    EndDate: {
      autoComplete: "off",
      id: "end-date",
      label: "Date to",
      name: "EndDate",
      pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
      placeholder: "yyyy-mm-dd",
      required: true,
      type: "date",
    },
    OrderEventTypeIds: {
      id: "event-types",
      label: "Event types",
      list: eventTypes,
      multiple: true,
      name: "OrderEventTypeIds",
      placeholder: "Select event types",
      required: true,
    },
  };

  const [alertMessage, setAlertMessage] = useState();
  const [formState, setFormState] = useState(
    getInitialState(formFields, useRef)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isPartialReport, setIsPartialReport] = useState(false);
  const [showValidity, setShowValidity] = useState(false);
  const [report, setReport] = useState();

  const maxDate = getTimestamp().split("T")[0];

  useEffect(() => {
    serviceCancelSource = ReportService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    setValidities(setFormState);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isPartialReport) {
      setAlertMessage({
        message: `Only showing the first ${REPORT_TABLE_MAX_ROWS} records, please narrow your date search`,
        colour: "warning",
      });
    }
  }, [isPartialReport]);

  async function getReport() {
    const config = { ...serviceConfig, params: getURLParams(formState) };
    let isCancelled = false;

    setIsLoading(true);
    setIsPartialReport(false);
    setReport(null);

    try {
      const { data } = await ReportService.getPharmacyOrders(user.location.siteId, config);

      if (data.length > REPORT_TABLE_MAX_ROWS) {
        data.splice(REPORT_TABLE_MAX_ROWS);
        setIsPartialReport(true);
      }

      setReport(data);
      setShowValidity(false);
    } catch (error) {
      if (ReportService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the log records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setCustomErrorMessages(setFormState, error.response);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function handleFieldChange({
    target: { name, validationMessage, validity, value },
  }) {
    if (formState[name].innerRef) {
      formState[name].innerRef.current.setCustomValidity("");
    }

    setFormState((formState) =>
      replacePropertyValues(formState, name, {
        customErrorMessage: validationMessage,
        validity,
        value,
      })
    );
  }

  function handleFormSubmit(event) {
    const message = getValidationMessage(formState);

    if (message) {
      setAlertMessage({
        message,
        colour: "warning",
      });
    } else {
      getReport();
    }

    setShowValidity(true);
    event.preventDefault();
  }

  function renderReport() {
    if (!report.length) {
      return (
        <p className="page-message">
          There are no logged records for that date range
        </p>
      );
    }
    const sortedList = report
      .sort((a, b) => {
        return new Date(a.eventDateTime) - new Date(b.eventDateTime);
      })
      .reverse();

    return (
      <Table className="report-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Event</th>
            <th>Location</th>
            <th>Medicine</th>
            <th>Patient Name</th>
            <th>
              Quantity
              <br />
              Requested
            </th>
            <th>
              Quantity
              <br />
              Supplied
            </th>
            <th>
              Unit of
              <br />
              Measure
            </th>
            <th>
              Signed
              <br />
              by
            </th>
            <th>
              Witnessed
              <br />
              by
            </th>
            <th>
              Collected
              <br />
              by
            </th>
            <th>Order Ref</th>
          </tr>
        </thead>
        <tbody>
          {sortedList.map((item, index) => {
            const dateStrings = getDateStrings(item.eventDateTime);

            return (
              <tr key={index}>
                <td className="min-height">
                  <time dateTime={item.eventDateTime}>{dateStrings.date}</time>
                </td>
                <td>
                  <time dateTime={item.eventDateTime}>{dateStrings.time}</time>
                </td>
                <td>{item.eventText}</td>
                <td>{item.locationName}</td>
                <td>{item.drugName}</td>
                <td className="break-words">{item.patientName}</td>
                <td>{item.quantityRequested}</td>
                <td>{item.quantitySupplied}</td>
                <td>{item.drugUoM}</td>
                <td className="break-words" title={item.signedBy}>
                  {truncateString(item.signedBy, SIGNATURE_DISPLAY_MAX_LENGTH)}
                </td>
                <td className="break-words" title={item.witnessedBy}>
                  {truncateString(
                    item.witnessedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td className="break-words" title={item.collectedBy}>
                  {truncateString(
                    item.collectedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td>{item.reference}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <Col className={`CDOrders CDOrders-${locationRoute} report-page`}>
      <Row className="mb-4">
        <h1>Log: CD Orders</h1>
      </Row>
      <Form
        className={cx("form horizontal", { "show-validity": showValidity })}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={
                formState.StartDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.StartDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.StartDate.innerRef}
                max={
                  (formState.EndDate.validity.valid &&
                    formState.EndDate.value) ||
                  maxDate
                }
                min={REPORT_FORM_MIN_DATE}
                onChange={handleFieldChange}
                {...formFields.StartDate}
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup
              title={
                formState.EndDate.innerRef.current?.validationMessage ||
                undefined
              }
            >
              <Label disabled={isLoading} {...formFields.EndDate} />
              <Input
                disabled={isLoading}
                innerRef={formState.EndDate.innerRef}
                max={maxDate}
                min={
                  (formState.StartDate.validity.valid &&
                    formState.StartDate.value) ||
                  REPORT_FORM_MIN_DATE
                }
                onChange={handleFieldChange}
                {...formFields.EndDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="full-width-sm">
          <Col md="5">
            <FormGroup
              title={
                formState.OrderEventTypeIds.customErrorMessage || undefined
              }
            >
              <Label disabled={isLoading} {...formFields.OrderEventTypeIds} />
              <Dropdown
                disabled={isLoading}
                onChange={handleFieldChange}
                {...formFields.OrderEventTypeIds}
              />
            </FormGroup>
          </Col>
          <Col className="buttons offset-md-3" md="2">
            <Button color="primary" disabled={isLoading} type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {report && renderReport()}
        <Loader show={isLoading} />
      </Row>
    </Col>
  );
}

export default CDOrders;
