import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuditLine from "../../component/molecules/Pharmacy/AuditLine";
import { initialStateSigned } from "../../config/confirm";
import AuditService from "../../services/AuditService";
import WardPicker from "../../component/organisms/WardPicker";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";

let messageTimeoutId;

const StockAuditClone = ({ history, location, user }) => {
  // messaging
  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);

  // create page
  const [clonedAreaName, setClonedAreaName] = useState({
    lcId: "",
      wardInfo: "",
      isOnline: true,
  });
    const [areaName, setAreaName] = useState({ lcId: "", wardInfo: "", isOnline: true });
  const [auditItems, setAuditItems] = useState([]);
  const [cloneItems, setCloneItems] = useState([]);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [wardAlert, setWardAlert] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState([]);
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  // page props
  const locationName = location.state.locationName;
  const viewState = location.state.viewState;
    const ward = location.state.lcId;
    const isOnline = location.state.isOnline;
  const siteId = user.location.siteId;

    useEffect(() => {
        setAreaName({ lcId: ward, wardInfo: locationName, isOnline: isOnline });
    AuditService.getWardStockAudit(ward).then((response) => {
      setAuditItems(response.data);
      setCloneItems(response.data);
    });
  }, []);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      AuditService.createStockAudit(
        areaName["lcId"],
        cloneItems.map((el) => {
          delete el["lcSaRfId"];
          return {
            ...el,
            createdBy: user.username,
            signedBy: confirmedStaff.signed,
          };
        })
      )
        .then((response) => {
          if (response.status === 201) {
            setTimeout(() => {
              history.goBack();
            }, 5000);
            setAlertMessage({
              message: "The approved list has been cloned for this location",
              colour: "success",
              timeout: 5000,
            });
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            setAlertMessage({
              message: `There was a problem cloning the approved list, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }

          setInputsConfirmed(false);
        });
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  const toggleSelectItem = (saId) => {
    const data = cloneItems.slice();
    const index = data.findIndex((el) => el.saId === saId.saId);
    if (index !== -1) {
      data.splice(index, 1);
      setCloneItems(data);
    } else {
      setCloneItems([
        ...auditItems,
        {
          ...saId,
        },
      ]);
    }
  };

  return (
    <Col className="StockAuditClone">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
                  <h3>Copy compliance list to {clonedAreaName["wardInfo"] + (clonedAreaName["isOnline"] ? "" : " (Offline)")}</h3>
        </Col>
        <Col
          md={{ size: 3, offset: 3 }}
          className="d-flex align-items-end flex-column"
        >
          <Row>
            <Col>
              <Link className="shadow-sm btn btn-primary" to="/pharmacy/audit">
                <FontAwesomeIcon className="on-left" icon={faReplyAll} />
                BACK TO ALL LOCATIONS
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-4 mt-1 d-flex align-items-center">
          <WardPicker
            inputID="chooseauditarea"
            siteId={siteId}
            setAlertMessage={setAlertMessage}
            areaName={areaName}
            setareaName={setAreaName}
            wardAlert={wardAlert}
            setwardAlert={setWardAlert}
            includePharmacy={true}
          />
        </Col>
      </Row>
      <Row className="StockAuditClone-list">
        {auditItems && (
          <ul>
            {auditItems.map((aut, adx) => (
              <AuditLine
                key={adx}
                audit={aut}
                toggleSelectItem={toggleSelectItem}
                defaultChecked
                view="clone"
              />
            ))}
          </ul>
        )}
      </Row>
      <ConfirmBlock
        confirmedStaffErrors={confirmedStaffErrors}
        confirmInitialState={initialStateSigned}
        inputsConfirmed={inputsConfirmed}
        setConfirmedStaff={setConfirmedStaff}
        setInputsConfirmed={setInputsConfirmed}
      />
    </Col>
  );
};

export default React.memo(StockAuditClone);
