import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import axios from "axios";

import ErrorBoundary from "./component/organisms/ErrorBoundary";
import { msalConfig } from "./config/msal";
import store from "./store/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UrlString } from '@azure/msal-common';

const msalInstance = new PublicClientApplication(msalConfig);
const isServerSide = typeof window === "undefined";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

/**
 * We need to determine if we are in the auth popup
 * If so, we need to render a simple message
 * Otherwise, we render the app
 * [MSAL uses]{@link (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L343)} "UrlString.hashContainsKnownProperties" method to validate the hash
 * @returns true if the current window is an auth popup
 */

const isAuthPopup = () => {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
}

// check if we are in the auth popup
// if so, render a simple message
// otherwise, render the app
if (isAuthPopup()) {
  <div>
    <h1>Authenticating...</h1>
  </div>
} else {
  ReactDOM[isServerSide ? "hydrate" : "render"](
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <React.StrictMode>
          <ErrorBoundary>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ErrorBoundary>
        </React.StrictMode>
      </Provider>
    </MsalProvider>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
