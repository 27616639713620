import React, { useContext, useState } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SystemContext from "../../context/SystemContext";
import FormularyDrugSearchList from "../../component/molecules/FormularyDrugSearchList";
import CDService from "../../services/CDService";

const ManageFormulary = ({ location }) => {
  const {
    user: {
      location: { siteId },
    },
  } = useContext(SystemContext);

  const [searchDrugs, setSearchDrugs] = useState([]);
  const [modified, setModified] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchDrugsCall = (siteId, searchValue) => {
    return CDService.getDrug(siteId, searchValue);
  };

  const mapSearchDrugs = (searchDrugs) => {
    return searchDrugs.map((sd) => ({
      id: sd.formularyId,
      cdcatalogueNameOrFormularyName:
        sd.cdCatalogue.cdcatalogueNameOrFormularyName,
      isSafeCustody: sd.isSafeCustody,
      isWitnessed: sd.isWitnessed,
      apId: sd.apId,
      vpId: sd.vpId,
      formularyPacksizes: [
        ...sd.formularyPacksizes.sort((a, b) => {
          return a.quantity - b.quantity;
        }),
      ],
      modified: false,
    }));
  };

  return (
    <Col>
      <Row className="mb-3">
        <Col md="6">
          <h1>Manage Formulary</h1>
        </Col>
        <Col md="6 d-flex align-items-end flex-column">
          <Link
            className="shadow-sm btn btn-primary btn-icon ml-3"
            to="/pharmacy/formulary/add"
          >
            ADD CD FROM CATALOG
            <FontAwesomeIcon className="on-right" icon={faPlus} />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormularyDrugSearchList
            siteId={siteId}
            searchDrugsCall={searchDrugsCall}
            mapSearchDrugs={mapSearchDrugs}
            setSearchDrugs={setSearchDrugs}
            searchDrugs={searchDrugs}
            setModified={setModified}
            modified={modified}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showColumns={{ quantity: true, ap: true }}
            searchAtStart={true}
            drugSearchPlaceholder="Drug search"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ManageFormulary);
