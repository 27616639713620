const locale = "en-GB";
const dateTimeFormat = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZone: "Europe/London",
});

const longDatetimeStringOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
  hour: "2-digit",
  minute: "2-digit",
};

export const longDateStringOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
};
export const shortDateStringOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};
export const shortTimeStringOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

export function parseISODateAsLocalTime(str) {
  if (!str) return str;
  const values = str.split(/[^0-9]/);
  return new Date(
    values[0],
    values[1] - 1,
    values[2],
    values[3],
    values[4],
    values[5]
  );
}

export function getDateStrings(
  timestamp,
  dateStringOptions = shortDateStringOptions,
  timeStringOptions = shortTimeStringOptions
) {
  const dateObject = timestamp
    ? parseISODateAsLocalTime(timestamp)
    : new Date();

  return {
    date: dateObject.toLocaleDateString(locale, dateStringOptions),
    time: dateObject.toLocaleTimeString(locale, timeStringOptions),
    datetime: dateObject.toLocaleString(locale, longDatetimeStringOptions),
  };
}

export function getDateValue(timestamp) {
  return parseISODateAsLocalTime(timestamp).getTime();
}

export function getTimestamp(dateObject = new Date()) {
  const parts = dateTimeFormat
    .formatToParts(dateObject)
    .reduce((obj, item) => ({ ...obj, [item.type]: item.value }), {});

  return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}`;
}
