import React, { useEffect, memo } from "react";
import { Table } from "reactstrap";
import PatientLine from "./PatientLine";
import SearchInput from "./SearchInput";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../store/actions";

const PatientSearchList = ({
  locationId,
  setSearchPatients,
  mapSearchPatients,
  searchPatientsCall,
  searchPatients,
  searchValue,
  setSearchValue,
  showPatient,
  refresh,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const performSearch = async () => {
    try {
      const response = await searchPatientsCall(locationId, searchValue);
      setSearchPatients(mapSearchPatients(response.data));
    } catch (error) {
      showAlertMessage(dispatch, {
        message: `Approved list for this ward is unavailable right now, the eCDR-Pro system may be offline.
        If unable to resolve contact IT service desk.`,
        colour: "danger",
      });
    }
  };

  useEffect(() => {
    performSearch();
  }, [searchValue]);

  useEffect(() => {
    if (refresh) {
      performSearch();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <SearchInput
        inputClass="search-holder"
        name="patient search"
        type="text"
        placeholder="Patient Search"
        displayValue={searchValue}
        value={setSearchValue}
      />
      {searchPatients.length > 0 && (
        <Table className="CDTable DrugSearchList" hover>
          <thead>
            <tr>
              <th className="cd-fixedWidth">Name</th>
              <th className="cd--item-info">Date of Birth</th>
              <th>Current Bed</th>
            </tr>
          </thead>
          <tbody>
            {searchPatients.map((pt) => (
              <PatientLine key={pt.ptId} patient={pt} showPatient={showPatient} />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default memo(PatientSearchList);
