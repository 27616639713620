import React, { useState, useEffect } from "react";
import { Input, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const LocationDropDown = ({
  dropDownList,
  setdropDownItem,
  triggerItem,
  placeholder,
  inputValue,
  extraClass,
  isHospital,
  hospitalItem,
}) => {
  const [getItem, setgetItem] = useState([]);
  const [searchDropDown, setsearchDropDown] = useState([]);
  const [activeItemList, setactiveItemList] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [textValue, settextValue] = useState(inputValue);
  const [itemPicked, setItemPicked] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const itemLookUp = (e) => {
    setItemPicked(false);
    if (!activeItemList) setactiveItemList(true);

    settextValue(e.target.value);
    setsearchDropDown(
      getItem.filter((item) =>
        item["name"].toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const itemCheck = (e) => {
    if (!itemPicked) {
      settextValue("");
      setdropDownItem();
      setsearchDropDown(dropDownList);
    }
  };

  const pickItem = (itm) => {
    settextValue(itm);
    setItemPicked(true);
  };

  useEffect(() => {
    setgetItem(dropDownList);
    setsearchDropDown(dropDownList);
    return () => {};
  }, [dropDownList]);

  useEffect(() => {
    if (triggerItem) setDropdownOpen(!dropdownOpen);
    return () => {};
  }, [triggerItem]);

  const clickItem = (itm) => {
    setDropdownOpen(!dropdownOpen);
    pickItem(itm["name"]);
    if (isHospital) {
      setdropDownItem({
        wardInfo: itm["shortName"],
        siteId: itm["siteId"],
        siteName: itm["name"],
        locationId: itm.lcId,
      });
    } else {
      setdropDownItem({
        lcId: itm["lcId"],
        wardInfo: itm["wardInfo"] + (itm.isOnline ? "" : " (Offline)"),
        siteId: itm["site"]["siteId"],
        siteShortName: itm["site"]["shortName"],
        siteName: itm["site"]["name"],
        isPharmacy: itm.isPharmacy,
        isWard: !itm.isPharmacy,
        isTheatre:
          !itm.isPharmacy &&
          itm.locationTypes?.findIndex((el) => el.locationType === "Theatre") >
            -1,
        managePharmacyAsWard: itm.locationTypes?.findIndex((el) => el.locationType === "Ward") > -1,
        managePharmacyAsTheatre: itm.locationTypes?.findIndex((el) => el.locationType === "Theatre") > -1,
        isOnline: itm.isOnline,
        name: itm.name,
        locationTypes: itm.locationTypes,
      });

      pickItem(itm["name"] + (itm.isOnline ? "" : " (Offline)"));
    }
  };

  return (
    <Dropdown
      className={`LocationDropdown${triggerItem ? " active" : ""}`}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <Input
          className={extraClass}
          type="text"
          placeholder={placeholder}
          value={textValue}
          onChange={(e) => {
            e.preventDefault();
            itemLookUp(e);
          }}
          onBlur={(e) => {
        }}
        onKeyUp={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                settextValue(searchDropDown[0].name);
                clickItem(searchDropDown[0]);
            }
        }}
        />
      </DropdownToggle>
      <DropdownMenu>
        {getItem && (
          <ul>
            {searchDropDown.map((itm, idx) => (
              <li key={idx} onClick={() => clickItem(itm)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {isHospital
                  ? itm.name
                  : itm.name + (itm.isOnline ? "" : " (Offline)")}
              </li>
            ))}
          </ul>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LocationDropDown;
