import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import {
  CAlert,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CFormText,
  CButton,
} from "@coreui/react";
import Cookies from "js-cookie";
import { ReactComponent as Ecdr } from "../assets/icons/Ecdrlogo.svg";
import LocationDropDown from "../component/molecules/LocationDropDown";
import LocationsService from "../services/LocationsService";
import { imprivataConfig } from "../config/imprivata";
import { imprivataLogin } from "../services/ImprivataService";

const msalRequired = process.env.REACT_APP_AUTH_REQUIRED;
let serviceCancelSource;

const Login = () => {
  const authRequired = msalRequired || imprivataConfig.enabled;
  const history = useHistory();
  const { accounts, instance } = useMsal();

  const [alertMessage, setAlertMessage] = useState({
    message: "",
    colour: "",
  });
  // populate dropdown
  const [getlocations, setgetlocations] = useState();
  const [hospitals, setHospitals] = useState();
  const [triggerItem, settriggerItem] = useState(false);
  const [triggerHospitalItem, setTriggerHospitalItem] = useState(false);
  // username
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [password, setPassword] = useState("");

  // location item
  const [dropDownItem, setdropDownItem] = useState("");
  const [hospitalItem, setHospitalItem] = useState("");
  const [loadingLocation, setLoadingLocation] = useState(false);

  // temp validation
  const [isMissing, setisMissing] = useState(false);

  const [authUsername, setAuthUsername] = useState(accounts[0]?.username);

  const checkLogin = () => {
    if (authRequired) {
      if (dropDownItem && authUsername) {
        history.push(
          `/${dropDownItem.isPharmacy ? "pharmacy" : "ward"}/dashboard`
        );

        Cookies.set(
          "user",
          JSON.stringify({
            username: authUsername,
            location: dropDownItem,
          }),
          { SameSite: "strict", secure: true }
        );
      } else {
        setisMissing(true);
        setAlertMessage({
          message: "Are you forgetting something?",
          colour: "danger",
        });
      }
    } else if (firstName && lastName && dropDownItem && password) {
      history.push(
        `/${dropDownItem.isPharmacy ? "pharmacy" : "ward"}/dashboard`
      );

      Cookies.set(
        "user",
        JSON.stringify({
          username: firstName + " " + lastName,
          location: dropDownItem,
        }),
        { SameSite: "strict", secure: true }
      );
    } else {
      setisMissing(true);
      setAlertMessage({
        message: "Are you forgetting something?",
        colour: "danger",
      });
    }
  };

  useEffect(() => {
    // siteId is hardcoded here to Calderdale
    if (hospitalItem) {
      setLoadingLocation(false);
      const params = { incPharm: true };
      if (authRequired) {
        params.email = authUsername;
      }
      LocationsService.getLocations(hospitalItem["siteId"], {
        params: params,
      })
        .then((response) => {
          setgetlocations(response.data);
          setLoadingLocation(true);
        })
        .catch((error) => {
          setAlertMessage({
            message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        });
    }
  }, [hospitalItem]);

  useEffect(() => {
    serviceCancelSource = LocationsService.getCancelSource();
    // siteId is hardcoded here to Calderdale
    setLoadingLocation(false);

    LocationsService.getSites({ cancelToken: serviceCancelSource.token })
      .then((response) => {
        setHospitals(response.data);
      })
      .catch((error) => {
        if (!LocationsService.isCancel(error)) {
          setAlertMessage({
            message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      });
    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (authRequired) {
      if (accounts[0] && !authUsername) {
        setAuthUsername(accounts[0].username);
      }
    }
  }, [accounts]);

  function login() {
    if (imprivataConfig.enabled && window.confirm_id_authenticate) {
      imprivataLogin().then((authResult) => {
        setAuthUsername(authResult.display_name);
      });
    } else {
      msalLogin();
    }
  }

  function msalLogin() {
    instance
      .acquireTokenPopup({ prompt: "login" })
      .then((response) => {
        setAuthUsername(response.account.username);
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  return (
    <Col className="LoginUser d-flex align-items-center justify-content-center">
      <Col className="login-block shadow-lg col-sm-10 col-md-8 col-lg-6 col-xl-4">
        {alertMessage["message"] && (
          <CAlert color={alertMessage["colour"]} closeButton>
            {alertMessage["message"]}
          </CAlert>
        )}
        <h1>
          <Ecdr className="logo" aria-labelledby="logo-title" role="img" />
        </h1>
        <CForm>
          {authRequired ? (
            <CButton
              className="btn-auth-user"
              color={authUsername ? "success" : "primary"}
              disabled={Boolean(authUsername)}
              onClick={login}
            >
              {authUsername ? authUsername.split("@")[0] : "Login"}
            </CButton>
          ) : (
            <>
              <CFormGroup className="nf-firstname">
                <div className="d-flex">
                  <CLabel
                    htmlFor="nf-firstname"
                    className={isMissing && !firstName ? "is-invalid" : ""}
                  >
                    <span className="mandatory-label">*</span>
                  </CLabel>
                  <CInput
                    className={isMissing && !firstName ? "is-invalid" : ""}
                    type="firstname"
                    id="nf-firstname"
                    name="nf-firstname"
                    placeholder="First Name"
                    autoComplete="off"
                    onChange={(e) => setfirstName(e.target.value)}
                  />
                </div>
                <CFormText className="help-block">
                  Please enter your firstname
                </CFormText>
              </CFormGroup>
              <CFormGroup className="nf-lastname">
                <div className="d-flex">
                  <CLabel
                    htmlFor="nf-lastname"
                    className={isMissing && !lastName ? "is-invalid" : ""}
                  >
                    <span className="mandatory-label">*</span>
                  </CLabel>
                  <CInput
                    className={isMissing && !lastName ? "is-invalid" : ""}
                    type="lastname"
                    id="nf-lastname"
                    name="nf-lastname"
                    placeholder="Last Name"
                    autoComplete="off"
                    onChange={(e) => setlastName(e.target.value)}
                  />
                </div>
                <CFormText className="help-block">
                  Please enter your lastname
                </CFormText>
              </CFormGroup>
              <CFormGroup>
                <div className="d-flex">
                  <CLabel htmlFor="nf-password">
                    <span className="mandatory-label">*</span>
                  </CLabel>
                  <CInput
                    className={isMissing && !password ? "is-invalid" : ""}
                    type="password"
                    id="nf-password"
                    name="nf-password"
                    placeholder="Password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <CFormText className="help-block">
                  Please enter your password
                </CFormText>
              </CFormGroup>
            </>
          )}
          {hospitals && (
            <>
              {authRequired && authUsername && <legend>Select Location</legend>}
              {(!authRequired || authUsername) && (
                <CFormGroup>
                  <div className="d-flex">
                    <CLabel
                      htmlFor="nf-location"
                      onClick={(e) => {
                        e.preventDefault();
                        setTriggerHospitalItem(!triggerHospitalItem);
                      }}
                    >
                      <span className="mandatory-label">*</span>
                    </CLabel>
                    <LocationDropDown
                      extraClass={`nf-location ${
                        isMissing && !hospitalItem ? "is-invalid" : ""
                      }`}
                      triggerItem={triggerHospitalItem}
                      name="nf-hospital"
                      dropDownList={hospitals}
                      setdropDownItem={setHospitalItem}
                      placeholder="Hospital"
                      inputValue=""
                      isHospital
                      hospitalItem={hospitalItem}
                    />
                  </div>
                  <CFormText className="help-block">
                    Please enter your hospital
                  </CFormText>
                </CFormGroup>
              )}
            </>
          )}
          {getlocations && loadingLocation && (!authRequired || authUsername) && (
            <CFormGroup>
              <div className="d-flex">
                <CLabel
                  htmlFor="nf-location"
                  onClick={(e) => {
                    e.preventDefault();
                    settriggerItem(!triggerItem);
                  }}
                >
                  <span className="mandatory-label">*</span>
                </CLabel>
                <LocationDropDown
                  extraClass={`nf-location ${
                    isMissing && !dropDownItem ? "is-invalid" : ""
                  }`}
                  triggerItem={triggerItem}
                  name="nf-location"
                  dropDownList={getlocations}
                  setdropDownItem={setdropDownItem}
                  placeholder="Location"
                  inputValue=""
                  hospitalItem={hospitalItem}
                />
              </div>
              <CFormText className="help-block">
                Please enter your location
              </CFormText>
            </CFormGroup>
          )}
          {(!authRequired || authUsername) && (
            <CButton
              className="mb-4 mt-4"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                checkLogin();
              }}
            >
              {authRequired ? "Confirm" : "Login"}
            </CButton>
          )}
        </CForm>
      </Col>
    </Col>
  );
};

export default Login;
