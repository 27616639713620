import axios from "axios";

const AuthService = {};

async function request(config) {
  return await axios.request(config);
}

AuthService.get = async (url, config) => {
  return await request({ ...config, method: "get", url });
};

AuthService.patch = async (url, data, config) => {
  return await request({ ...config, method: "patch", data, url });
};

AuthService.post = async (url, data, config) => {
  return await request({ ...config, method: "post", data, url });
};

export default AuthService;
