import React, { useState, useEffect, useContext } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col, Table, Button, Form } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { CAlert, CTextarea } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faDotCircle,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";

import OrderTableHeader from "../component/molecules/OrderTableHeader";
import OrderTableBodyMultiselect from "../component/molecules/OrderTableBodyMultiselect";
import ConfirmBlockOrders from "../component/organisms/ConfirmBlockOrders";

import SystemContext from "../context/SystemContext";
import { getOrdersRules } from "../config/_ordersrules";
import OrdersService from "../services/OrdersService";
import CancelPrescriptionModal from "../component/organisms/CancelPrescriptionModal";
import StockService from "../services/StockService";

let messageTimeoutId;
let redirectTimeoutId;

const SingleOrder = (props) => {
  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  const history = useHistory();

  const messageState = {
    message: "",
    colour: "",
  };

  const { user, locationRoute } = useContext(SystemContext);
  const currentView = "single"; // || default

  const [loadOrders, setLoadOrders] = useState({ state: true, refresh: false });

  const [currentStatus, setcurrentStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(messageState); // message for general API on page
  const [valueMessage, setvalueMessage] = useState([]);
  const [writeMessage, setwriteMessage] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showMessages, setshowMessages] = useState({
    show: false,
    comments: [],
  });

  const [nxt, setnxt] = useState("");
  const [status, setstatus] = useState([]);
  const [orderSet, setorderSet] = useState([]);
  const [orderHistory, setorderHistory] = useState(false);

  const currentViewOrigin = props.location.state.currentView;
  const currentStatusOrigin = props.location.state.currentStatus;

  const { locationId } = props;
  const {
    ordersFilter,
    locationPath,
    locationHash,
    currentFilter,
  } = props.location.state;

  const sendMessage = () => {
    const newMessages = {
      ...showMessages,
      show: true,
      comments: [
        {
          text: valueMessage,
          createdBy: user.username,
          createdAt: moment().tz("Europe/London").format("llll"),
        },
        ...showMessages["comments"],
      ],
    };

    const payload = {
      text: valueMessage,
      createdBy: user.username,
      isPharmacy: props.isPharmacy,
    };

    OrdersService.createOrderMessage(nxt["odId"], payload)
      .then(() => {
        setshowMessages(newMessages);
        setvalueMessage();
      })
      .catch((error) => {
        setwriteMessage(true);
        setAlertMessage({
          message: `There was an error sending your message, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  };

  const updateRows = (row) => {
    setorderSet({
      odId: row["odId"],
      lcId: row["lcId"],
      cdApId: row["id"] ? row["id"] : row["cdApId"],
      cdVpId: row["virtualId"] ? row["virtualId"] : row["cdVpId"],
      catalogueId: row["catalogueId"],
      siteId: row["siteId"],
      stId: row["stId"],
      qtySupplied: row["qtySupplied"],
      reference: row["reference"],
      isInvalid: row["isInvalid"],
    });
  };

  const confirmRejectOrder = (isCancelOrder) => {
    if (isCancelOrder) {
      setShowCancelModal(true);
    } else {
      const options = {
        title: "Order Rejection",
        message: "Are you sure you would like to reject the order?",
        buttons: [
          {
            label: "Yes",
            onClick: () => rejectOrder(isCancelOrder),
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      };
      confirmAlert(options);
    }
  };

  const isPrescription = (nxt) => nxt?.isPatient && nxt?.location?.isPharmacy;

  const rejectOrder = (isCancelled, signed, witness, comment) => {
    const nxt = props["location"]["state"]["nxt"];
    const rejectArray = [
      {
        odId: nxt["odId"],
        lcId: nxt["lcId"],
        siteId: nxt["siteId"],
        cdApId: nxt["id"],
        cdVpId: nxt["virtualId"],
        catalogueId: nxt["catalogueId"],
        stId: isCancelled ? 11 : locationRoute === "ward" ? 6 : 7,
        createdBy: user.username,
        signedBy: signed ?? user.username,
        witnessedBy: witness,
        comment: comment ?? "",
        isPharmacy: props.isPharmacy,
      },
    ];

    OrdersService.updateOrder(rejectArray)
      .then(() => {
        let message, path;
        const orderType = isPrescription(nxt)? "Prescription": "Order"
        if (isCancelled) {
          message = `${orderType} has been cancelled successfully`;
          path = "/pharmacy/stock/administer";
          StockService.getPharmacyStockByCd(locationId, {
            params: { catalogueId: nxt["catalogueId"] },
          }).then((response) => {
            const state = {
              nxt: {
                ...nxt,
                stkPhId: response.data.stkPhId,
                runningBalance: response.data.runningBalance,
              },
            };
            redirectTimeoutId = setTimeout(() => {
              history.push(path, state);
            }, 2000);
          });
        } else {
          message =`${orderType} has been rejected successfully`;
          path = "/" + locationRoute + "/stock/orders";
          redirectTimeoutId = setTimeout(() => {
            history.push(path);
          }, 5000);
        }
        setAlertMessage({
          message: message,
          colour: "success",
          timeout: 5000,
        });
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setAlertMessage({
            message: `The signed in user has insufficient permissions to perform this action,
              if required please contact your IT help-desk to change`,
            colour: "danger",
          });
        } else {
          setAlertMessage({
            message: `Unable to reject the order, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  // setup page core information for individual order
  useEffect(() => {
    const formulary = nxt.cdCatalogue?.formulary?.find(
      (f) => f.siteId === nxt.siteId
    );
    setorderSet({
      odId: nxt["odId"],
      lcId: nxt["lcId"],
      siteId: nxt["siteId"],
      cdApId: nxt["id"],
      cdVpId: nxt["virtualId"],
      catalogueId: nxt["catalogueId"],
      stId: status["next"],
      isValid: true,
      isWitnessed: formulary
        ? formulary.isWitnessed
        : nxt.cdCatalogue?.isWitnessed,
    });
    return () => {};
  }, [nxt]);

  // setup page core information for individual order
  useEffect(() => {
    if (props) {
      const info = props["location"]["state"]["nxt"];
      setnxt(props["location"]["state"]["nxt"]);
      setstatus(
        ordersrules["initialStatusView"]["statusSet"].find(
          (obj) => obj["status"] === info["status"]["name"]
        )
      );
      setcurrentStatus(info["status"]["name"]);
      if (info["orderComments"].length > 0) {
        setshowMessages({
          show: true,
          comments: info["orderComments"],
        });
      }
    }
  }, [props]);

  useEffect(() => {}, [showMessages]);

  // on success send back to main orders page
  useEffect(() => {
    if (loadOrders.refresh) history.push(`/${locationRoute}/stock/orders`);
  }, [loadOrders]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  const checkIfValid = (qtySupplied, orderId) => {
    if (locationRoute === "pharmacy") {
      if (qtySupplied > nxt.qtyRequested) {
        nxt.isValid = false;
        setnxt(nxt);
        return false;
      }
      nxt.isValid = true;
      setnxt(nxt);
      return true;
    }
    return true;
  };

  // confirmation block details
  function renderConfirmBlock() {
    const isWard = locationRoute === "ward";
    const isPharmacy = locationRoute === "pharmacy";
    const isInterPharmacy = isPharmacy && nxt.pharmacyLocationFrom && nxt.pharmacyLocationFrom?.lcId !== null;
    const isInTransit = ( isWard || isInterPharmacy) && status["name"] === "InTransit";
    const isReadyToRegister = (isWard || isInterPharmacy) && status["name"] === "ReadyToRegister";
    const isReadyForCollection = isPharmacy && !isInterPharmacy && status["name"] === "ReadyForCollection";
    const isPharmacyInPharmacy = isPharmacy && !isInterPharmacy && status["name"] === "Pharmacy";

    if (isInTransit || isReadyToRegister || isReadyForCollection || isPharmacyInPharmacy) {
      return (
        <ConfirmBlockOrders
          col2StyleClass={`${
            status["name"] === "InTransit" || status["name"] === "Pharmacy"
              ? "d-flex align-items-center"
              : ""
          }`}
          setLoadOrders={setLoadOrders}
          loadOrders={loadOrders}
          orderArray={[orderSet]}
          blockTitle={status["confirmationBlock"]}
          disableSignedBy={!nxt.pharmacyStockRunningBalance && isPharmacyInPharmacy}
          currentStatus={status["name"]}
          confirmInitialState={ordersrules["confirmInitialState"]}
          messageState={messageState}
          setAlertMessage={setAlertMessage}
          isHasChanged={true}
          checkIfValid={checkIfValid}
          locationRoute={locationRoute}
        />
      );
    } else return null;
  }

  const historyStatus = (id) => {
    const stageID = ordersrules["initialStatusView"]["statusSet"].find(
      (obj) => obj["stId"] === id
    )["Route"];

    return stageID;
  };

  const historyColour = (id) => {
    const stageID = ordersrules["initialStatusView"]["statusSet"].find(
      (obj) => obj["stId"] === id
    )["colour"];
    return stageID;
  };

  return (
    <Col className={`SingleOrder SingleOrder-${locationRoute}`}>
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col>
          <h1>Single order: {status["text"]}</h1>
        </Col>
        {
          <Col
            className="d-flex justify-content-end align-items-end"
            md={{ size: 3, offset: 2 }}
          >
            <Link
              className="shadow-sm btn btn-primary"
              to={{
                pathname: locationPath + locationHash,
                state: {
                  ordersFilter: ordersFilter,
                  currentView: currentViewOrigin,
                  currentStatus: currentStatusOrigin,
                  currentFilter: currentFilter,
                  loadOrders: { state: true, refresh: false },
                },
              }}
            >
              <FontAwesomeIcon className="on-left" icon={faReplyAll} />
              BACK TO MAIN SCREEN
            </Link>
          </Col>
        }
      </Row>
      <Row>
        <Col>
          {
            <Table>
              <OrderTableHeader
                currentStatus={currentStatus}
                currentView={currentView}
                locationRoute={locationRoute}
                rejectOrder={confirmRejectOrder}
                isPatient={nxt["isPatient"]}
                isPrescription={isPrescription(nxt)}
              />
              {nxt && (
                <OrderTableBodyMultiselect
                  orders={[nxt]}
                  locationRoute={locationRoute}
                  status={status}
                  currentView={currentView}
                  currentStatus={currentStatus}
                  updateRows={updateRows}
                  deleteRow={() => {}}
                  setAlertMessage={setAlertMessage}
                />
              )}
            </Table>
          }
        </Col>
      </Row>
      {nxt["stId"] > 1 ? (
        <Row>
          <Col className="orderHistory">
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setorderHistory(!orderHistory);
              }}
              className="mt-2"
            >
              View order history
            </Button>
            {orderHistory ? (
              <Row className="mt-4">
                <Col>
                  {nxt &&
                    nxt["orderStatuses"].map((st, sx) => (
                      <div key={sx} className="orderHistory-status mb-3">
                        {st["stId"] > 1 ? (
                          <span
                            className={`order-${historyColour(st["stId"])}`}
                          >
                            <strong>
                              Status : {historyStatus(st["stId"])}
                            </strong>
                          </span>
                        ) : null}
                        {st["stId"] === 2 ? (
                          <>
                            <span>
                              Created at :{" "}
                              <Moment
                                format="dddd Do MMM YYYY, HH:mm"
                                date={st["createdAt"]}
                              />
                            </span>
                            <span>Signed by : {st["signedBy"]}</span>
                          </>
                        ) : null}
                        {st["stId"] === 3 ? (
                          <>
                            <span>
                              Created at :{" "}
                              <Moment
                                format="dddd Do MMM YYYY, HH:mm"
                                date={st["createdAt"]}
                              />
                            </span>
                            <span>Signed by : {st["signedBy"]}</span>
                            <span>Collected by : {st["collectedBy"]}</span>
                          </>
                        ) : null}
                        {st["stId"] === 4 ? (
                          <>
                            <span>
                              Created at :{" "}
                              <Moment
                                format="dddd Do MMM YYYY, HH:mm"
                                date={st["createdAt"]}
                              />
                            </span>
                            <span>Signed by : {st["signedBy"]}</span>
                          </>
                        ) : null}
                        {st["stId"] === 5 ? (
                          <>
                            <span>
                              Created at :{" "}
                              <Moment
                                format="dddd Do MMM YYYY, HH:mm"
                                date={st["createdAt"]}
                              />
                            </span>
                            <span>Signed by : {st["signedBy"]}</span>
                            <span>Witnessed by : {st["witnessedBy"]}</span>
                          </>
                        ) : null}
                        {st["stId"] > 5 ? (
                          <>
                            <span>
                              Created at :{" "}
                              <Moment
                                format="dddd Do MMM YYYY, HH:mm"
                                date={st["createdAt"]}
                              />
                            </span>
                            <span>Signed by : {st["createdBy"]}</span>
                          </>
                        ) : null}
                      </div>
                    ))}
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      ) : null}
      {
        <Row className="mb-4">
          <Col className="d-flex justify-content-end align-items-start">
            <Button
              className="shadow-sm order-messages_button"
              color="primary"
              disabled={writeMessage}
              onClick={(e) => {
                e.preventDefault();
                setwriteMessage(!writeMessage);
              }}
            >
              <FontAwesomeIcon className="on-left" icon={faEdit} />
              WRITE A MESSAGE
            </Button>
          </Col>
        </Row>
      }
      {
        <Row>
          <Col md="6">
            <div className="order-messages">
              {showMessages && showMessages["show"] && (
                <div className="order-messages_holder" id="o-messages-scroll">
                  {showMessages["comments"].map((msg, mdx) => (
                    <div
                      key={mdx}
                      className={`order-messages-item order-messages_location-${
                        msg["createdBy"] === user.username ? "right" : "left"
                      }`}
                    >
                      <div className="order-messages_stamp">
                        <FontAwesomeIcon
                          className="order-messages_ident"
                          icon={faDotCircle}
                        />
                        <span className="order-messages_time">
                          <Moment
                            format="h:mm a  ddd, Do MMM YYYY"
                            date={msg["createdAt"]}
                          />
                        </span>
                        <span className="order-messages_name">
                          : {msg["createdBy"]}
                        </span>
                      </div>
                      <div className="mt-2 order-messages_content shadow-sm">
                        <p>{msg["text"]}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {showMessages["comments"] && showMessages["comments"].length > 1 && (
                <Button
                  className="shadow-lg order-messages_more_double_farleft d-flex align-items-center justify-content-center flex-column"
                  onClick={(e) => {
                    e.preventDefault();
                    const messages = document.getElementsByClassName(
                      "order-messages-item"
                    );
                    const firstMessage = messages[0];
                    firstMessage.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                  <FontAwesomeIcon icon={faChevronUp} />
                </Button>
              )}
              {showMessages["comments"] && showMessages["comments"].length > 1 && (
                <Button
                  className="shadow-lg order-messages_more_left d-flex align-items-center justify-content-center flex-column"
                  onClick={(e) => {
                    e.preventDefault();
                    const htmlElement =
                      document.getElementById("o-messages-scroll");
                    const elementPosition = htmlElement.getBoundingClientRect();
                    htmlElement.scrollBy({
                      top: -elementPosition.height * 0.9,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </Button>
              )}
              {showMessages["comments"] && showMessages["comments"].length > 1 && (
                <Button
                  className="shadow-lg order-messages_more_right d-flex align-items-center justify-content-center flex-column"
                  onClick={(e) => {
                    e.preventDefault();
                    const htmlElement =
                      document.getElementById("o-messages-scroll");
                    const elementPosition = htmlElement.getBoundingClientRect();
                    htmlElement.scrollBy({
                      top: elementPosition.height * 0.9,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              )}
              {showMessages["comments"] && showMessages["comments"].length > 1 && (
                <Button
                  className="shadow-lg order-messages_more_double_farright d-flex align-items-center justify-content-center flex-column"
                  onClick={(e) => {
                    e.preventDefault();
                    const messages = document.getElementsByClassName(
                      "order-messages-item"
                    );
                    const lastMessage = messages[messages.length - 1];
                    lastMessage.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              )}
            </div>
          </Col>

          {writeMessage && (
            <Col md="6">
              <div className="order-drug_message">
                <Form
                  onSubmit={() => {
                    setwriteMessage(!writeMessage);
                    sendMessage();
                  }}
                >
                  <CTextarea
                    className="order-drug_message-input"
                    placeholder="Message :"
                    value={valueMessage}
                    onChange={(e) => {
                      e.preventDefault();
                      setvalueMessage(e.target.value);
                    }}
                    required
                  />
                  <Button
                    className="shadow-sm order-drug_message-send"
                    color="primary"
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </Button>
                </Form>
                <p className="mt-3">
                  * Please call the pharmacy, to discuss in more detail
                </p>
              </div>
            </Col>
          )}
        </Row>
      }
      {renderConfirmBlock()}

      <CancelPrescriptionModal
        rejectOrder={rejectOrder}
        isPharmacy={true}
        isOpen={showCancelModal}
        setModal={setShowCancelModal}
        isPrescription={isPrescription(nxt)}
      />
    </Col>
  );
};

export default React.memo(SingleOrder);
