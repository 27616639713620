import React, { useState, useEffect, useRef, useContext } from "react";
import cx from "classnames";

import SystemContext from "../../context/SystemContext";
import Dropdown from "../atoms/Dropdown";
import SearchInput from "../molecules/SearchInput";
import CDService from "../../services/CDService";
import { getDrugId } from "../../helpers";

const SEARCH_CATALOGUE = "Search CD Catalogue";
const SEARCH_FORMULARY = "Search Formulary";
const VALIDATION_MESSAGE = "Please select an item from the list";
const dropdownList = [{ value: SEARCH_FORMULARY }, { value: SEARCH_CATALOGUE }];
let serviceCancelSource;
let serviceConfig;

const DrugSearchList = ({
  disableButton,
  name,
  onChange,
  searchListClass,
  setAlertMessage,
  setSelectedDrug,
  showDrugName,
  showPatientOwn,
  isDrugSearchFromCatalogue = false,
  selectableSearch = false,
  siteId,
  ...inputProps
}) => {
  const inputRef = useRef();

  const { user } = useContext(SystemContext);

  //search value to filter drug list
  const [searchValue, setsearchValue] = useState("");
  // list of searched drugs from API
  const [searchDrugs, setsearchDrugs] = useState([]);
  const [selected, setSelected] = useState();
  const [clear, setclear] = useState(false);
  const [isCatalogueSearch, setIsCatalogueSearch] = useState(
    isDrugSearchFromCatalogue
  );

  const closeSearch = () => {
    setsearchDrugs("");
    setsearchValue("");

    if (!showDrugName) {
      setclear(true);
    }
  };

  const addDrug = (drugId) => {
    const drug = searchDrugs.filter((item) => getDrugId(item) === drugId)[0];

    inputRef.current.setCustomValidity("");
    setSelected(drug);

    if (setSelectedDrug) {
      setSelectedDrug(drug);
    }

    closeSearch();
  };

  useEffect(() => {
    serviceCancelSource = CDService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    inputRef.current.setCustomValidity(VALIDATION_MESSAGE);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(async () => {
    if (searchValue) {
      let response;

      setclear(false);

      try {
        if (isCatalogueSearch) {
          response = await CDService.getDrugFromCatalogue(
            searchValue,
            serviceConfig
          );
        } else {
          response = await CDService.getDrug(
            siteId ?? user.location.siteId,
            searchValue,
            serviceConfig
          );
        }

        setsearchDrugs(response.data);
      } catch (error) {
        if (
          !CDService.isCancel(error) &&
          (!error.response || !error.response.status === 404)
        ) {
          setAlertMessage({
            message: `CD list is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          name,
          validationMessage: inputRef.current.validationMessage,
          validity: inputRef.current.validity,
          value: selected
            ? selected.cdApId
              ? {
                  catalogueId: selected.catalogueId,
                  cdApId: selected.Id,
                  cdVpId: selected.VirtualId,
                }
              : {
                  catalogueId: selected.catalogueId,
                  cdVpId: selected.VirtualId,
                }
            : {},
        },
      });
    }
  }, [selected]);

  function handleDropdownChange({ target: { value } }) {
    setIsCatalogueSearch(value === SEARCH_CATALOGUE);
  }

  function handleInputChange() {
    inputRef.current.setCustomValidity(VALIDATION_MESSAGE);
    setSelected();
  }

  return (
    <div className={cx("DrugSearchList", { selectableSearch })}>
      <SearchInput
        aria-label="Drug search"
        disableButton={disableButton}
        displayValue={
          showDrugName &&
          selected &&
          (!selected.cdCatalogue
            ? selected.cdcatalogueNameOrFormularyName
            : selected.cdCatalogue.cdcatalogueNameOrFormularyName)
        }
        inputClass="search-holder"
        inputRef={inputRef}
        name={name || "drug-search"}
        onChange={handleInputChange}
        placeholder="Drug search"
        value={setsearchValue}
        clear={clear}
        {...inputProps}
      />
      {selectableSearch && (
        <Dropdown
          list={dropdownList}
          onChange={handleDropdownChange}
          value={SEARCH_FORMULARY}
        />
      )}
      {searchDrugs.length > 0 && (
        <div className={searchListClass} onClick={closeSearch}>
          <ul aria-label="Drug search results">
            {searchDrugs.map((sch, sdx) => {
              const drugId = getDrugId(sch);

              return (
                <li
                  key={sdx}
                  onClick={(e) => {
                    e.preventDefault();
                    addDrug(getDrugId(sch));
                  }}
                >
                  <label htmlFor={drugId}>
                    <span className="name">
                      {sch["cdCatalogue"]
                        ? sch["cdCatalogue"]["cdcatalogueNameOrFormularyName"]
                        : sch["cdcatalogueNameOrFormularyName"]}
                    </span>{" "}
                    <span className="category">
                      {sch["cdCatalogue"]
                        ? sch["cdCatalogue"]["schedule"]
                        : sch["schedule"]}{" "}
                    </span>
                    <input type="checkbox" value={drugId} id={drugId} />
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DrugSearchList;
