import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";
import DashboardPanel from "../component/molecules/DashboardPanel";
import DashboardHalfPanel from "../component/molecules/DashboardHalfPanel";
import DashboardButton from "../component/molecules/DashboardButton";
import { useSelector } from "react-redux";

import Dashconfig from "../config/_dashconfig";
import OrdersService from "../services/OrdersService";

const REFRESH_INTERVAL = 30000;
const authRequired = process.env.REACT_APP_AUTH_REQUIRED;
let intervalId;
let messageTimeoutId;

const Dashboard = ({ isPharmacy, isWard, locationId, locationRoute, user }) => {
  const messageState = {
    message: "",
    colour: "",
  };

  const settings = useSelector(
    (state) => state.settings
  );

  let ShowAllOfType = settings.ShowAllOfType ?? [];

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [dashPanels, setdashPanels] = useState(
    Dashconfig[locationRoute]["panels"]
  );
  const [dashButtons, setdashButtons] = useState(
    Dashconfig[locationRoute]["lines"]
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isPharmacy) {
      fetchPharmacyOrders();
      intervalId = setInterval(() => fetchPharmacyOrders(), REFRESH_INTERVAL);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isWard && settings.loaded) {
      fetchWardOrders();
      clearInterval(intervalId);
      intervalId = setInterval(() => fetchWardOrders(), REFRESH_INTERVAL);
    }
  }, [locationId, settings]);

  useEffect(() => {
    if (!dashButtons) setdashButtons(Dashconfig[locationRoute]["lines"]);
  }, [dashButtons]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  function fetchWardOrders() {
    OrdersService.getWardDashboard(locationId, ShowAllOfType)
      .then((response) => {
        const panelData = [...Dashconfig[locationRoute]["panels"]];

        panelData[0]["quantity"] = response.data["pharmacyTotal"];
        panelData[0]["urgent"] = response.data["pharmacyIsUrgent"];
        panelData[1][0]["quantity"] = response.data["readyForCollectionTotal"];
        panelData[1][0]["urgent"] = response.data["readyForCollectionIsUrgent"];
        panelData[1][1]["quantity"] = response.data["inTransitTotal"];
        panelData[1][1]["urgent"] = response.data["inTransitIsUrgent"];
        panelData[2]["quantity"] = response.data["arrivedTotal"];
        panelData[2]["urgent"] = response.data["arrivedIsUrgent"];

        setdashPanels(panelData);
        setAlertMessage({});
      })
      .catch(() => {
        setAlertMessage({
          message: `Unable to connect, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function fetchPharmacyOrders() {
    OrdersService.getPharmacyDashboard(locationId)
      .then((response) => {
        const panelData = [...Dashconfig[locationRoute]["panels"]];

        panelData[0]["quantity"] = response.data["pharmacyTotal"];
        panelData[0]["urgent"] = response.data["pharmacyIsUrgent"];
        panelData[1]["quantity"] = response.data["readyForCollectionTotal"];
        panelData[1]["urgent"] = response.data["readyForCollectionIsUrgent"];
        panelData[2]["quantity"] = response.data["inTransitTotal"];
        panelData[2]["urgent"] = response.data["inTransitIsUrgent"];

        setdashPanels(panelData);
        setAlertMessage({});
      })
      .catch(() => {
        setAlertMessage({
          message: `Unable to connect, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Col className="Dashboard">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
          <h1>
            Hi{" "}
            {authRequired
              ? user.username.split("@")[0]
              : user.username.split(" ")[0]}
            ,
          </h1>
          <h3>Today in review</h3>
        </Col>
      </Row>
      <Row className="dash-row d-flex justify-content-between">
        {dashPanels &&
          dashPanels.map((item, idx) => {
            if (item instanceof Array){
              return (
                <Col className="dash-row-subcolumn" key={idx} md="3">
                  {item.map((subitem, sidx) => (
                    <DashboardHalfPanel
                      key={sidx}
                      quantity={subitem.quantity}
                      urgent={subitem.urgent}
                      text={subitem.text}
                      url={subitem.url}
                      colour={subitem.colour}
                      isLoading={isLoading}
                    />))}
                </Col> );
            } else {
              return (<DashboardPanel
                key={idx}
                quantity={item.quantity}
                urgent={item.urgent}
                text={item.text}
                url={item.url}
                colour={item.colour}
                isLoading={isLoading}
              />);
            }
          })}
      </Row>
      {dashButtons.map((dashL1, dsx) => (
        <Row key={dsx} className="dash-row d-flex justify-content-between">
          {dashL1.map((lines, lx) => (
            <DashboardButton
              key={lx}
              name={lines.name}
              iconName={lines.iconName}
              url={lines.url}
            />
          ))}
        </Row>
      ))}
    </Col>
  );
};

export default Dashboard;
