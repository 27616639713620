import {
  faClipboardList,
  faExclamationTriangle,
  faFirstAid,
  faLayerGroup,
  faNotesMedical,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

const Dashconfig = {
  ward: {
    panels: [
      {
        quantity: 0,
        urgent: 0,
        name: "New",
        text: "Orders with the pharmacy",
        url: "/ward/stock/orders/#Pharmacy",
        colour: "#4611B0",
      },
      [
        {
          quantity: 0,
          urgent: 0,
          name: "ReadyForCollection",
          text: "Orders ready",
          url: "/ward/stock/orders/#ReadyForCollection",
          colour: "#0ED400",
        },
        {
          quantity: 0,
          urgent: 0,
          name: "InTransit",
          text: "Orders in transit",
          url: "/ward/stock/orders/#InTransit",
          colour: "#ec9b00",
        }
      ],
      {
        quantity: 0,
        urgent: 0,
        name: "ReadyToRegister",
        text: "Orders arrived",
        url: "/ward/stock/orders/#ReadyToRegister",
        colour: "#007f3b",
      },
    ],
    lines: [
      [
        {
          name: "Create new order",
          iconName: faNotesMedical,
          url: "/ward/stock/createorder",
        },
        {
          name: "Manage patients",
          iconName: faUserAlt,
          url: "/ward/patients/manage",
        },
        {
          name: "Create new Order for Named Patient",
          iconName: faFirstAid,
          url: "/ward/stock/CreateOrderNamedPatient",
        },
      ],
      [
        {
          name: "Current stock",
          iconName: faLayerGroup,
          url: "/ward/stock",
        },
        {
          name: "CD Compliance Audit",
          iconName: faClipboardList,
          url: "/ward/stock/audit",
        },
        {
          name: "Illicit substances",
          iconName: faExclamationTriangle,
          url: "/ward/stock/illicit",
        },
      ],
      [
        {
          name: "Patient’s Own Register",
          iconName: faLayerGroup,
          url: "/ward/patient/stock",
        },
        {
          name: "Dispose of CDs",
          iconName: faLayerGroup,
          url: "/ward/stock/disposal",
        },
      ],
    ],
  },
  pharmacy: {
    panels: [
      {
        quantity: 0,
        urgent: 0,
        name: "New",
        text: "New orders",
        url: "/pharmacy/stock/orders/#Pharmacy",
        colour: "#4611B0",
      },
      {
        quantity: 0,
        urgent: 0,
        name: "ReadyForCollection",
        text: "Orders ready for collection",
        url: "/pharmacy/stock/orders/#ReadyForCollection",
        colour: "#0ED400",
      },
      {
        quantity: 0,
        urgent: 0,
        name: "InTransit",
        text: "Orders in transit",
        url: "/pharmacy/stock/orders/#InTransit",
        colour: "#ec9b00",
      },
    ],
    lines: [
      [
        {
          name: "Inter Pharmacy Transfers",
          iconName: faLayerGroup,
          url: "/pharmacy/stock/interpharmacy/",
        },
        {
          name: "Manage patients",
          iconName: faUserAlt,
          url: "/pharmacy/patients/manage",
        },
        {
          name: "Current stock",
          iconName: faLayerGroup,
          url: "/pharmacy/stock",
        },
      ],
      [
        {
          name: "Returned CDs",
          iconName: faLayerGroup,
          url: "/pharmacy/return",
        },
        {
          name: "Dispose of CDs & Illicit substances",
          iconName: faLayerGroup,
          url: "/pharmacy/stock/disposal",
        },
        {
          name: "Dispense prescription",
          iconName: faFirstAid,
          url: "/pharmacy/stock/CreateOrderNamedPatient",
        },
      ],
      [
        {
          name: "CD Compliance Audit",
          iconName: faClipboardList,
          url: "/pharmacy/stock/audit",
        },
        {
          name: "Illicit substances",
          iconName: faExclamationTriangle,
          url: "/pharmacy/stock/illicit",
        },
      ],
    ],
  },
};

export default Dashconfig;
