import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { CAlert, CButton } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import OrderTableHeader from "../../component/molecules/OrderTableHeader";
import OrderTableBody from "../../component/molecules/OrderTableBody";
import OrderTableBodyMultiselect from "../../component/molecules/OrderTableBodyMultiselect";
import ConfirmBlockOrders from "../../component/organisms/ConfirmBlockOrders";

import OrdersService from "../../services/OrdersService";
import { getOrdersRules } from "../../config/_ordersrules";
import { getDateValue } from "../../helpers";

let intervalId;
let messageTimeoutId;

const AllOrders = ({ isPharmacy, locationId, locationRoute, user }) => {
  const isWard = true;
  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  const settings = useSelector(
    (state) => state.settings
  );

  let ShowAllOfType = settings.ShowAllOfType ?? [];

  const hashFilter = useLocation();
  const history = useHistory();

  const messageState = useState({
    message: "",
    colour: "",
  });

  const initialFilters = [
    { name: "default", text: "All", state: true },
    { name: "notpatient", text: "Stock Orders", state: false },
    { name: "patient", text: "Patient Orders", state: false },
    { name: "urgent", text: "Urgent", state: false },
  ];
  const interPharmacyFilters = [
    { name: "default", text: "All", state: true },
    { name: "urgent", text: "Urgent", state: false },
  ];

  const [filters, setFilters] = useState(
    isPharmacy ? interPharmacyFilters : initialFilters
  );
  const [currentFilter, setCurrentFilter] = useState("default"); // default || patient || notpatient || urgent
  const [filterClass, setFilterClass] = useState("default"); // filter class

  const [status, setStatus] = useState(
    isPharmacy
      ? ordersrules.initialStatusView["pharmacyTransfer"]
      : ordersrules.initialStatusView[locationRoute]
  );
  const [loadOrders, setLoadOrders] = useState({ state: true, refresh: false });
  const [loadStatus, setLoadStatus] = useState("");

  const [currentView, setCurrentView] = useState("default"); // default || single
  const [currentStatus, setCurrentStatus] = useState("default"); // default transit || register || pharmacy || rejected

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [isHasChanged, setIsHasChanged] = useState(false);

  const [orderSet, setOrderSet] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [siteID, setSiteID] = useState(user["location"]["siteId"]);
  const [username, setUsername] = useState(user["username"]);

  const viewStatus = currentStatus.split("-")[0];

  // orders list ordering
  async function getAllOrderItems(locationId) {
    const responseWard = await OrdersService.getWardOrder(locationId, ShowAllOfType);
    const rejOrders = responseWard.data.filter((drg) => filterByRejected(drg));
    const traOrders = responseWard.data.filter(
      (drg) => drg["status"]["name"] === "InTransit"
    );
    const reaOrders = responseWard.data.filter(
      (drg) => drg["status"]["name"] === "ReadyToRegister"
    );
    const regOrders = responseWard.data.filter(
      (drg) => drg["status"]["name"] === "Registered"
    );
    const rcOrders = responseWard.data.filter(
      (drg) => drg["status"]["name"] === "ReadyForCollection"
    );
    const phaOrders = responseWard.data.filter(
      (drg) => drg["status"]["name"] === "Pharmacy"
    );

    const allOrders = [
      ...phaOrders,
      ...rcOrders,
      ...traOrders,
      ...reaOrders,
      ...rejOrders,
      ...regOrders,
    ];

    setOrders(
      allOrders
        .filter((o) => !isPharmacy || o.pharmacyLocationFrom)
        .map((drg) => {
          return {
            odId: drg["odId"],
            cdApId: drg["cdCatalogue"]["id"],
            cdVpId: drg["cdCatalogue"]["virtualId"],
            catalogueId: drg["catalogueId"],
            lcId: drg["lcId"],
            siteId: drg["siteId"],
            stId: drg["stId"],
            qtyRequested: drg["qtyRequested"],
            qtySupplied: drg["qtySupplied"],
            isPatient: drg["isPatient"],
            isUrgent: drg["isUrgent"],
            reference: drg["reference"],
            createdAt: drg["createdAt"],
            createdBy: drg["createdBy"],
            cdCatalogue: drg["cdCatalogue"],
            status: drg["status"],
            location: drg["location"],
            prescribedLocation: drg["prescribedLocation"],
            pharmacyLocationFrom: isPharmacy ? drg["pharmacyLocationFrom"] : "",
            orderComments: drg["orderComments"],
            orderStatuses: drg["orderStatuses"],
            patient: drg["patient"],
            pharmacyStockRunningBalance: drg["pharmacyStockRunningBalance"],
          };
        })
    );
    setIsHasChanged(false);
  }

  // get order list when location is updated
  useEffect(() => {
    if (loadOrders.refresh) {
      history.push(hashFilter.pathname + hashFilter.hash);
    }
    if (loadOrders.state && isWard && settings.loaded) {
      getAllOrderItems(locationId);
      setLoadOrders({ state: false, refresh: false });
      setIsHasChanged(false);
      setOrderSet([]);
    }
  }, [loadOrders, settings]);

  // can be removed after we implement ward rules
  useEffect(() => {
    if (isWard && settings.loaded) {
      getAllOrderItems(locationId);
    }
  }, [locationId, settings]);

  // get page loaded status
  useEffect(() => {
    const ordersFilter = history.location?.state?.ordersFilter;
    let loadedStatus = hashFilter.hash.split("#")[1];
    setIsHasChanged(false);
    if (ordersFilter) {
      if (
        ordersFilter.currentStatus &&
        ordersFilter.currentStatus !== "default"
      ) {
        const historyCurrentStatusName =
          ordersFilter.currentStatus.split("-")[0];

        status.map((nm) =>
          nm["name"] === historyCurrentStatusName
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );
        setCurrentStatus(ordersFilter.currentStatus);

        if (
          historyCurrentStatusName === "RejectedByPharmacy" ||
          historyCurrentStatusName === "RejectedByWard"
        ) {
          setLoadStatus("Rejected");
        } else {
          setLoadStatus(historyCurrentStatusName);
        }
      }
      if (ordersFilter.currentFilter) {
        const historyCurrentFilterName =
          ordersFilter.currentFilter.split("-")[0];
        const viewFilters = filters;

        viewFilters.map((nm) =>
          nm["name"] === historyCurrentFilterName
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );

        setFilters(viewFilters);
        setCurrentFilter(`${historyCurrentFilterName}-true`);
      }
    } else {
      if (loadedStatus) {
        setLoadStatus(
          loadedStatus.charAt(0).toUpperCase() + loadedStatus.slice(1)
        );

        if (loadedStatus === "Rejected") {
          loadedStatus = "RejectedByPharmacy";
        }

        status.map((nm) =>
          nm["name"] === loadedStatus
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );
        setCurrentStatus(loadedStatus + "-true");
      } else {
        status.map((nm) => (nm["state"] = false));
      }
    }

    // intervalId = setInterval(() => setLoadOrders({ state: true, refresh: false }), REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const filterByRejected = (item) =>
    item["status"]["name"] === "RejectedByPharmacy" ||
    item["status"]["name"] === "RejectedByWard" ||
    item["status"]["name"] === "Rejected";

  // update filtered orders on orders change
  useEffect(() => {
    setSiteID(user["location"]["siteId"]);
    setUsername(user["username"]);
    setFilteredOrders([]);

    const currentViewStatus = currentStatus.split("-")[0];

    if (currentViewStatus !== "default") {
      if (currentViewStatus === "Rejected") {
        setTimeout(() => {
          setFilteredOrders(
            filterByRejected((orders) =>
              orders.filter((item) => filterByRejected(item))
            )
          );
        }, 100);
      } else {
        setTimeout(() => {
          setFilteredOrders(applyFilterPharmacy(currentViewStatus));
        }, 100);
      }
    } else {
      setTimeout(() => {
        setFilteredOrders(
          orders.filter((item) => item["status"]["name"] !== "Registered")
        );
      }, 100);
    }
    return () => {};
  }, [orders]);

  useEffect(() => {
    if (currentFilter) {
      setFilterClass(
        currentFilter.split("-")[1] === "true"
          ? "show-" + currentFilter.split("-")[0]
          : ""
      );
      return () => {};
    }
  }, [currentFilter]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  // signOff box states
  function renderConfirmBlock() {
    if (viewStatus === "InTransit" || viewStatus === "ReadyToRegister") {
      return (
        <ConfirmBlockOrders
          col2StyleClass={`${
            viewStatus === "InTransit" ? "d-flex align-items-center" : ""
          }`}
          setLoadOrders={setLoadOrders}
          orderArray={orderSet}
          blockTitle={
            ordersrules["initialStatusView"]["statusSet"].find(
              (obj) => obj["name"] === viewStatus
            )["confirmationBlock"]
          }
          currentStatus={currentStatus}
          confirmInitialState={ordersrules.confirmInitialState}
          messageState={messageState}
          setAlertMessage={setAlertMessage}
          isHasChanged={isHasChanged}
          locationRoute={locationRoute}
        />
      );
    } else return null;
  }

  // updated the page filters
  const updateFilter = (set) => {
    if (currentFilter !== `${set["name"]}-${set["state"]}`) {
      const viewFilters = filters;

      viewFilters.map((nm) =>
        nm["name"] === set["name"]
          ? (nm["state"] = !set["state"])
          : (nm["state"] = false)
      );

      setCurrentFilter(`${set["name"]}-${set["state"]}`);
    }
  };

  // update status using buttons
  const updateStatus = (set) => {
    // Back out if we are already selected
    if (currentStatus.split("-")[0] === set["name"]) return;

    // gets array of status used in buttons
    const viewStatus = status;

    // sets the current status as true
    viewStatus.map((nm) =>
      nm["name"] === set["name"]
        ? (nm["state"] = !set["state"])
        : (nm["state"] = false)
    );

    const newStatus =
      set["state"] && set["name"] !== "default"
        ? set["name"] + "-" + set["state"]
        : "default";
    setCurrentStatus(newStatus);

    const current = newStatus;
    const look = set["name"];
    setFilteredOrders([]);
    setOrderSet([]);

    if (look && current !== "default") {
      if (
        set["name"] === "RejectedByPharmacy" ||
        set["name"] === "RejectedByWard"
      ) {
        history.push(hashFilter.pathname + "#Rejected");
        setTimeout(() => {
          setFilteredOrders(
            orders.filter((item) => filterByRejected(item))
          );
        }, 50);
      } else {
        history.push(hashFilter.pathname + "#" + set["name"]);
        setTimeout(() => {
          const filteredOrders = applyFilterPharmacy(look);
          setFilteredOrders(filteredOrders);
        }, 50);
      }
    } else {
      history.push(hashFilter.pathname);
      setTimeout(() => {
        setFilteredOrders(
          orders.filter((item) => item["status"]["name"] !== "Registered")
        );
      }, 100);
    }
  };

  const applyFilterPharmacy = (look) =>
    isPharmacy
      ? orders.filter((item) => {
        const status = item["status"]["name"];
        switch (look) {
        case "Pharmacy":
          return status === "Pharmacy" && item.lcId !== locationId;
        case "InPharmacy":
          return status === "Pharmacy" && item.lcId === locationId;
        case "Rejected":
          return filterByRejected(item);
        default:
          return status === look;
        }
      })
      : orders.filter((item) => item["status"]["name"] === look);

  const updateRows = (row) => {
    setOrderSet([row]);
    setIsHasChanged(true);
  };

  const deleteRow = (odId) => {
    setIsHasChanged(false);
    setOrderSet([]);
  };

  // update order content when a table row is changed
  const updateRowsMultiple = (row) => {
    if (orderSet) {
      if (orderSet.find((od) => od["odId"] === row["odId"])) {
        const item = orderSet.map((item) => {
          if (item["odId"] === row["odId"]) {
            const updatedItem = {
              ...item,
              qtySupplied: row["qtySupplied"],
            };
            setIsHasChanged(true);
            return updatedItem;
          }
          return item;
        });
        setOrderSet(item);
      } else {
        setOrderSet([...orderSet, row]);
        setIsHasChanged(true);
      }
    } else {
      setOrderSet(row);
      setIsHasChanged(true);
    }
  };

  const deleteRowMultiple = (odId) => {
    const gone = orderSet.filter((od) => od["odId"] !== odId);
    setIsHasChanged(false);
    setOrderSet(gone);
  };

  return (
    <Col className={`AllOrders AllOrders-${locationRoute}`}>
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          <span className="alert-icon_holder">
            <FontAwesomeIcon icon={faBell} />
          </span>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col md="4" className="AllOrders-set-title">
          <h1>{isPharmacy && "Inter-Pharmacy "} Orders Overview</h1>
        </Col>
        <Col
          md="8"
          className="d-flex align-items-center justify-content-end flex-row AllOrders-set-view"
        >
          Set table view :
          {status.map((vit, vdx) => (
            <CButton
              key={vdx}
              className={`shadow-sm ml-2 ${
                vit["state"] ? "btn-on" : "btn-off"
              }`}
              variant="outline"
              shape="pill"
              color="primary"
              name={vit["name"]}
              onClick={(e) => {
                e.preventDefault();
                updateStatus(vit);
              }}
            >
              {vit["button"]}
            </CButton>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className={filterClass}>
          <Table hover>
            <OrderTableHeader
              isPharmacy={false}
              filters={filters}
              currentStatus={currentStatus}
              updateFilter={updateFilter}
              currentView={currentView}
              locationRoute={locationRoute}
              showLocations={isPharmacy || (ShowAllOfType.some(o => user.location.locationTypes.some(m => m.locationType === o)) 
                && (currentStatus.split("-")[0] ==="default" || currentStatus.split("-")[0]  === "Pharmacy" || currentStatus.split("-")[0] === "ReadyForCollection"))}
            />
            {viewStatus === "ReadyToRegister" ? (
              <OrderTableBody
                isPharmacy={false}
                orders={filteredOrders}
                locationRoute={locationRoute}
                ordersrules={ordersrules}
                currentView={currentView}
                currentStatus={currentStatus}
                currentFilter={currentFilter}
                updateRows={updateRows}
                deleteRow={deleteRow}
                showLocations={isPharmacy || (ShowAllOfType.some(o => user.location.locationTypes.some(m => m.locationType === o)) 
                  && (currentStatus.split("-")[0] ==="default" || currentStatus.split("-")[0]  === "Pharmacy" || currentStatus.split("-")[0] === "ReadyForCollection"))}  
                ordersFilter={{ currentFilter, currentStatus }}
              />
            ) : (
              <OrderTableBodyMultiselect
                isPharmacy={false}
                orders={filteredOrders}
                locationRoute={locationRoute}
                ordersrules={ordersrules}
                currentView={currentView}
                currentStatus={currentStatus}
                currentFilter={currentFilter}
                updateRows={updateRowsMultiple}
                deleteRow={deleteRowMultiple}
                showLocations={isPharmacy || (ShowAllOfType.some(o => user.location.locationTypes.some(m => m.locationType === o)) 
                  && (currentStatus.split("-")[0] ==="default" || currentStatus.split("-")[0]  === "Pharmacy" || currentStatus.split("-")[0] === "ReadyForCollection"))}  
                ordersFilter={{ currentFilter, currentStatus }}
              />
            )}
          </Table>
          {orders.length === 0 && (
            <span className="h4 mt-4">
              * There are currently no orders related to this ward *
            </span>
          )}
        </Col>
      </Row>
      {renderConfirmBlock()}
    </Col>
  );
};

export default React.memo(AllOrders);
