import React, { useState, useEffect } from "react";
import { ButtonToggle, Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { CAlert, CButton } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import cx from "classnames";

import ASL from "../config/_ASL";
import {
  ADMINISTRATION_EVENT_TYPE,
  MANAGEMENT_EVENT_TYPE,
  historyEvents,
} from "../config/historyEvents";
import StockTableLine from "../component/molecules/StockTableLine";
import PatientDropdown from "../component/molecules/PatientDropdown";
import AdministerPatientTableLine from "../component/molecules/Ward/AdministerPatientTableLine";
import StockManagementTableLine from "../component/molecules/StockManagementTableLine";
import CommentsBlock from "../component/molecules/CommentsBlock";
import ConfirmBlock from "../component/organisms/ConfirmBlock";
import { getDateStrings, truncateString, getTimestamp, parseISODateAsLocalTime } from "../helpers";
import OrdersService from "../services/OrdersService";
import StockService from "../services/StockService";
import DiscrepancyModal from "../component/organisms/DiscrepancyModal";
import CheckableInputs from "../component/molecules/CheckableInputs";
import PatientModal from "../component/organisms/PatientModal";
import InfiniteScroll from "react-infinite-scroll-component";

export const partialAdministerEventTypes = {
  SUPPLIED: "ADMINISTER_TO_PATIENT_S",
  FORCE_SUPPLIED: "ADMINISTER_TO_PATIENT_SF",
  ADMINISTERED: "ADMINISTER_TO_PATIENT_A",
  DISPOSED: "ADMINISTER_TO_PATIENT_D",
};

const messageInitialState = {};
let isPatientOwn = false;
let mainStockPagePath = "/";
let serviceCancelSource;
let messageTimeoutId;
const ReturnedDrugsForReuse = "Return to Pharmacy for Reuse";
const ReturnedDrugsForDisposal = "Return to Pharmacy for Disposal";

const AdministerStock = ({ history, location, locationRoute, user }) => {
  const settings = useSelector(
    (state) => state.settings
  );

  let discrepancyByBalance = settings.discrepancyByBalance;
  let SIGNATURE_DISPLAY_MAX_LENGTH = settings.SIGNATURE_DISPLAY_MAX_LENGTH;
  let mgId = settings.mgId;

  useEffect(() => {
    discrepancyByBalance = settings.discrepancyByBalance;
    SIGNATURE_DISPLAY_MAX_LENGTH = settings.SIGNATURE_DISPLAY_MAX_LENGTH;
    mgId = settings.mgId;
  }, [settings]);

  const isOrManageWard = user.location.isWard ||
    (user.location.isPharmacy && user.location.managePharmacyAsWard);
  const isOrManageTheatre =
    user.location.isTheatre || user.location.managePharmacyAsTheatre;

  const [alertMessage, setAlertMessage] = useState(messageInitialState);
  const [nxt, setNxt] = useState("");
  const [isShowAdminister, setIsShowAdminister] = useState(isOrManageWard || isOrManageTheatre);
  const [isShowStockManagement, setIsShowStockManagement] = useState(false);
  const [stockName, setStockName] = useState("");
  const [stockComments, setStockComments] = useState("");
  const [stockHistory, setStockHistory] = useState([]);
  const [page, setPage] = useState(1); // initial page number
  const [hasMore, setHasMore] = useState(true);
  const [showOptionalHistoryEvents, setShowOptionalHistoryEvents] =
    useState(false);

  const [isDiscrepancyOpen, setIsDiscrepancyOpen] =
    useState(false);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [validate, setValidate] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  const [showModalPatient, setShowModalPatient] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState("");

  // administer to patient
  const [patient, setPatient] = useState("");
  const [amountSupplied, setAmountSupplied] = useState(0);
  const [datIxNoValue, setDatIxNoValue] = useState();
  const [newBalanceValue, setNewBalanceValue] = useState();
  const [doseAdministered, setDoseAdministered] = useState(0);
  const [doseAdministeredUom, setDoseAdministeredUom] = useState("");
  const [amountDisposed, setAmountDisposed] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [drugsCollectedBy, setDrugsCollectedBy] = useState("");
  const [drugsHandedTo, setDrugsHandedTo] = useState("");
  const [authorisedWitnessRequired, setAuthorisedWitnessRequired] = useState();
  const [disposalReference, setDisposalReference] = useState(null);
  const [witnessDisabled, setWitnessedDisabled] = useState(false);
  const [signDisabled, setSignDisabled] = useState(false);
  const [partialManagementId, setPartialManagementId] = useState("");
  const [partialAdministerEventType, setPartialAdministerEventType] =
    useState("");
  const [
    partialAdministerConfirmationFields,
    setPartialAdministerConfirmationFields,
  ] = useState({});
  const [isPartialAdministerInProgress, setIsPartialAdministerInProgress] =
    useState(false);
  const [showSuppliedError, setShowSuppliedError] = useState(false);

  const [clearDropDownOptions, setClearDropDownOptions] = useState(true);

  const [resetQuantity, setResetQuantity] = useState(false);

  const [modalStock, setModalStock] = useState();
  const [editDiscrepancyStock, setEditDiscrepancyStock] = useState();
  const [newBalanceModal, setNewBalanceModal] = useState();

  const [signWitnessCheckboxDisabled, setSignWitnessCheckboxDisabled] =
    useState(false);
  const [signOnlyCheckboxDisabled, setSignOnlyCheckboxDisabled] =
    useState(false);
  const [witnessOnlyCheckboxDisabled, setWitnessOnlyCheckboxDisabled] =
    useState(false);

  const [isCommentsInputDisabled, setIsCommentsInputDisabled] = useState(false);

  const [confirmBlockInitialState, setConfirmBlockInitialState] = useState(
    ASL.confirmAdministerState
  );
  const disableWitnessList = [
    {
      label: "Yes",
      name: "disableWitness",
      value: true,
      defaultChecked: witnessDisabled,
    },
    {
      defaultChecked: !witnessDisabled,
      label: "No",
      name: "disableWitness",
      value: false,
    },
  ];

  const inputTerm = (e) => {
    setStockComments(e.target.value);
  };

  useEffect(() => {
    const { state } = location;

    serviceCancelSource = StockService.getCancelSource();

    if (state?.nxt) {
      isPatientOwn = state.nxt.isPatientOwn ?? false;
      mainStockPagePath = `/${locationRoute}${
        isPatientOwn ? "/patient" : ""
      }/stock`;
      loadData(state.nxt);
    } else {
      history.replace(mainStockPagePath);
    }

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(messageTimeoutId);
    };
  }, []);

  const loadData = (nxt) => {
    if (user.location.isPharmacy){
      StockService.getPharmacyStockByCd(nxt.lcId, {
        params: { catalogueId: nxt.catalogueId },
      }).then((response) => {
        const stock = {
          ...response.data,
          cdCatalogue: nxt.cdCatalogue
        };
        setNxt(stock);
        setModalStock(stock);
      });
    }
    fetchHistory(nxt);
  };

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageInitialState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  // setup page core information for individual order
  useEffect(() => {
    setNewBalanceValue(nxt.runningBalance);
    setDoseAdministeredUom(
      nxt.cdCatalogue?.cdCatalogueStrength[0]?.strengthNumeratorUoM || ""
    );
  }, [nxt]);

  // setup page core information for individual order
  useEffect(() => {
    if (location.state) {
      setNxt(location.state.nxt);
      setIsShowStockManagement(false);
      setStockName("");
      setPatient(location.state.nxt.patient);
      setPartialManagementId("");
      setDoseAdministeredUom(
        location.state.nxt.cdCatalogue?.cdCatalogueStrength[0]
          ?.strengthNumeratorUoM || ""
      );
      setPartialAdministerEventType("");
      setPartialAdministerConfirmationFields({});
    }
  }, [location]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (newBalanceValue < 0) {
        setAlertMessage({
          message:
            "Please amend the stock adjustment, cannot submit a negative balance of stock",
          colour: "warning",
          label: "Warning message",
        });
        setInputsConfirmed(false);
      } else if (stockName === "" && !amountSupplied) {
        setAlertMessage({
          message: "Please fill in the required fields",
          colour: "warning",
          label: "Warning message",
        });
        setInputsConfirmed(false);
      } else if (
        stockName === "" &&
        isPartialAdministerInProgress &&
        partialAdministerEventType !== partialAdministerEventTypes.SUPPLIED &&
        !amountDisposed &&
        !doseAdministered
      ) {
        setAlertMessage({
          message: "Please fill in the required fields",
          colour: "warning",
          label: "Warning message",
        });
        setInputsConfirmed(false);
      } else if (
        stockName === "" &&
        !isPartialAdministerInProgress &&
        amountSupplied > nxt.runningBalance
      ) {
        setAlertMessage({
          message:
            "The specified Amount Supplied must not be greater than the Running Balance",
          colour: "warning",
          label: "Warning message",
        });
        setInputsConfirmed(false);
      } else if (stockName !== "Discrepancy/Incident" && amountSupplied <= 0) {
        setAlertMessage({
          message: "Please fill in a valid amount",
          colour: "warning",
          label: "Warning message",
        });
        setInputsConfirmed(false);
      } else if (isPatientOwn) {
        if (stockName.name === "Patient Transfer" && !selectedLocation) {
          setAlertMessage({
            message: "Please select the ward transferred to",
            colour: "warning",
            label: "Warning message",
          });
          setInputsConfirmed(false);
        } else {
          updatePatientStock();
        }
      } else if (locationRoute === "pharmacy" && !(isShowAdminister && partialManagementId === "")) {
        updatePharmacyStock();
      } else {
        updateWardStock();
      }
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    setIsPartialAdministerInProgress(partialManagementId !== "");
    setValidate(false);

    if (partialManagementId === "") {
      setAmountSupplied(0);
      setAmountDisposed(0);
      setDoseAdministered(0);
      setStockComments("");
      setIsCommentsInputDisabled(false);
      setDoseAdministeredUom("");
      setSignWitnessCheckboxDisabled(false);
      setSignOnlyCheckboxDisabled(false);
      setWitnessOnlyCheckboxDisabled(false);
      enableSignWitness();
    }
  }, [partialManagementId]);

  const showPatient = () => {
    setSelectedPatientId(patient.ptId);
    setShowModalPatient(true);
    return () => {
      setShowModalPatient(false);
    };
  };

  const updatePatientStock = () => {
    const payload = {
      StkId: nxt.stkId,
      PtId: nxt.ptId,
      Comments: stockComments,
      CreatedBy: user.username,
      SignedBy: confirmedStaff.signed,
      WitnessedBy: confirmedStaff.witnessed,
      IgnoreWitnessField: witnessDisabled,
    };
    let alertMessageSuccess;

    if (stockName.name === ReturnedDrugsForDisposal) {
      payload.MgId = mgId.RETURN_TO_PHARMACY_FOR_DISPOSAL;
      payload.AmountSupplied = amountSupplied;
      payload.isDisposalIntent = true;
      alertMessageSuccess = "Stock Return to Pharmacy confirmed";
    } else if (stockName.name === ReturnedDrugsForReuse) {
      payload.MgId = mgId.RETURN_TO_PHARMACY_FOR_REUSE;
      payload.AmountSupplied = amountSupplied;
      payload.isDisposalIntent = false;
      alertMessageSuccess = "Stock Return to Pharmacy confirmed";
    } else if (stockName.name === "Discrepancy/Incident") {
      payload.MgId = mgId.DISCREPANCY_INCIDENT;
      payload.DatixNo = datIxNoValue;
      payload.NewBalance = newBalanceValue;
      payload.AmountSupplied = nxt.runningBalance;
      alertMessageSuccess = "Discrepency/Incident recorded";
    } else if (stockName.name === "To Dispose/Destroy") {
      payload.MgId = mgId.DISPOSE_IN_WARD;
      payload.AmountSupplied = amountSupplied;
      alertMessageSuccess = "Stock Dispose/Destroy confirmed";
    } else if (stockName.name === "Patient Transfer") {
      payload.MgId = mgId.PATIENT_TRANSFER;
      payload.AmountSupplied = amountSupplied;
      payload.CollectedBy = drugsCollectedBy;
      payload.TransferLocationId = selectedLocation.locationId;
      alertMessageSuccess = "Stock Patient Transfer confirmed";
    } else if (stockName.name === "Leave/Discharge") {
      payload.MgId = mgId.LEAVE_DISCHARGE;
      payload.AmountSupplied = amountSupplied;
      payload.CollectedBy = drugsHandedTo;
      alertMessageSuccess = "Patient Leave/Discharge confirmed";
    } else {
      payload.MgId = mgId.ADMINISTER_TO_PATIENT;
      payload.DoseAdministered = doseAdministered;
      payload.DoseAdministeredUom = doseAdministeredUom;
      payload.AmountDisposed = amountDisposed;
      payload.AmountSupplied = amountSupplied;
      payload.ParentStockHistoryId = partialManagementId;
      alertMessageSuccess = "Stock Administered To Patient confirmed";
    }

    if (
      !isPartialAdministerInProgress ||
      !payload.MgId === mgId.ADMINISTER_TO_PATIENT
    ) {
      StockService.managePatientsOwnStock(payload, {
        cancelToken: serviceCancelSource.token,
      })
        .then((response) => {
          if (response.status === 201) {
            setAlertMessage({
              message: alertMessageSuccess,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();

            if (locationRoute === "ward" && isShowAdminister) {
              const { catalogueId } = nxt;
              const serviceConfig = { cancelToken: serviceCancelSource.token };
              StockService.getPatientsOwnStock(
                user.location.lcId,
                serviceConfig
              ).then((res) => {
                const pharmacyStock = res.data;
                const foundItemIndex = pharmacyStock.findIndex(
                  (el) => el.catalogueId === catalogueId
                );

                if (foundItemIndex < 0) {
                  setAlertMessage({
                    message:
                      "Unable to redirect because no matching drug was found in stock",
                    colour: "warning",
                  });
                  setInputsConfirmed(false);

                  return;
                }

                history.push("/ward/patient/stock/administer", {
                  nxt: pharmacyStock[foundItemIndex],
                });
              });
            }
          } else {
            setAlertMessage({
              message: `There was a problem updating the patient’s stock, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
              colour: "danger",
              label: "Error message",
            });
          }
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: `There was a problem updating the patient’s stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
                colour: "danger",
                label: "Error message",
              });
            }
          }
        })
        .finally(() => setInputsConfirmed(false));
    } else {
      StockService.managePatientsOwnStockPartial(payload, {
        cancelToken: serviceCancelSource.token,
      })
        .then((response) => {
          if (response.status === 201) {
            setAlertMessage({
              message: alertMessageSuccess,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();
          } else {
            setAlertMessage({
              message: `There was a problem updating the patient’s stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
              label: "Error message",
            });
          }
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: `There was a problem updating the patient’s stock, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                colour: "danger",
                label: "Error message",
              });
            }
          }
        })
        .finally(() => setInputsConfirmed(false));
    }
  };

  const updatePharmacyStock = () => {
    const payload = {
      StkPhId: nxt.stkPhId,
      CatalogueId: nxt.catalogueId,
      DatixNo: datIxNoValue,
      Comments: stockComments,
      CreatedBy: user.username,
      SignedBy: confirmedStaff.signed,
      WitnessedBy: confirmedStaff.witnessed,
    };
    let alertMessageSuccess = "";


    const service = (payload) =>
      partialManagementId
        ? StockService.managePharmacyStockPartial(payload = {...payload, IgnoreWitnessField:witnessDisabled,}, {
          cancelToken: serviceCancelSource.token,
        })
        : StockService.managePharmacyStock(payload, {
          cancelToken: serviceCancelSource.token,
        });

    if (partialManagementId) {
      payload.MgId = mgId.ADMINISTER_TO_PATIENT;
      payload.DoseAdministered = doseAdministered;
      payload.DoseAdministeredUom = doseAdministeredUom;
      payload.AmountDisposed = amountDisposed;
      payload.AmountSupplied = amountSupplied;
      payload.ParentStockPharmacyHistoryId = partialManagementId;
      alertMessageSuccess = "Stock Administered To Patient confirmed";
    } else if (stockName.name === "Discrepancy/Incident") {
      payload.MgId = mgId.DISCREPANCY_INCIDENT;
      payload.NewBalance = newBalanceValue;
      payload.amountSupplied = nxt.runningBalance;
      alertMessageSuccess = "Discrepency/Incident recorded";
    } else {
      payload.MgId = mgId.DISPOSE_DESTROY;
      payload.AmountSupplied = amountSupplied;
      payload.NewBalance = newBalanceValue;
      payload.DisposalReference = disposalReference;
      payload.AuthorisedWitnessRequired = authorisedWitnessRequired;
      alertMessageSuccess = "Stock Dispose/Destroy confirmed";
    }
    if (
      isPartialAdministerInProgress &&
      [
        partialAdministerEventTypes.ADMINISTERED,
        partialAdministerEventTypes.DISPOSED,
        partialAdministerEventTypes.SUPPLIED,
      ].includes(partialAdministerEventType)
    ) {
      updateHistory(null, partialManagementId, alertMessageSuccess);
    } else {
      service(payload)
        .then((response) => {
          if (response.status === 201) {
            setAlertMessage({
              message: alertMessageSuccess,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();
          } else {
            setAlertMessage({
              message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
              colour: "danger",
              label: "Error message",
            });
          }
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
                colour: "danger",
                label: "Error message",
              });
            }
          }
        })
        .finally(() => setInputsConfirmed(false));
    }
  };

  const updateHistory = (stkHistoryId, stkPhHistoryId, alertMessage) => {
    const timestamp = getTimestamp();
    const payload = {
      StkHistoryId: stkHistoryId,
      StkPhHistoryId: stkPhHistoryId,
      SignedAt: partialAdministerConfirmationFields.signedAt || timestamp,
      SignedBy: partialAdministerConfirmationFields.signedBy || confirmedStaff.signed,
      WitnessedAt: partialAdministerConfirmationFields.witnessedAt &&
        partialAdministerConfirmationFields.witnessedBy &&
        partialAdministerConfirmationFields.witnessedBy !== "Witness disabled"
        ? partialAdministerConfirmationFields.witnessedAt
        : timestamp,
      WitnessedBy: partialAdministerConfirmationFields.witnessedBy &&
        partialAdministerConfirmationFields.witnessedBy !== "Witness disabled"
        ? partialAdministerConfirmationFields.witnessedBy
        : confirmedStaff.witnessed,
    };

    StockService.updateStockHistory(payload)
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: alertMessage,
            colour: "success",
            label: "Success message",
            timeout: 5000,
          });
          resetPage();
          setIsShowAdminister(true);
        } else {
          setAlertMessage({
            message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }
      })
      .catch((error) => {
        if (!StockService.isCancel(error)) {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            setAlertMessage({
              message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
              colour: "danger",
              label: "Error message",
            });
          }
        }
      })
      .finally(() => setInputsConfirmed(false));
  };
  const updateWardStock = () => {
    let mgIdValue, amountSuppliedValue, alertMessage, isDisposalIntent;

    if (stockName.name === "Discrepancy/Incident") {
      mgIdValue = mgId.DISCREPANCY_INCIDENT;
      amountSuppliedValue = nxt.runningBalance;
      alertMessage = "Discrepency / Incident recorded";
    } else if (stockName.name === "To Dispose/Destroy") {
      mgIdValue = mgId.DISPOSE_IN_WARD;
      amountSuppliedValue = amountSupplied;
      alertMessage = "Stock Dispose/Destroy confirmed";
      // TODO Make configurable
    } else if (stockName.name === ReturnedDrugsForReuse) {
      mgIdValue = mgId.RETURN_TO_PHARMACY_FOR_REUSE;
      amountSuppliedValue = amountSupplied;
      isDisposalIntent = false;
      alertMessage = "Stock Return to Pharmacy confirmed";
    } else if (stockName.name === ReturnedDrugsForDisposal) {
      mgIdValue = mgId.RETURN_TO_PHARMACY_FOR_DISPOSAL;
      amountSuppliedValue = amountSupplied;
      isDisposalIntent = true;
      alertMessage = "Stock Return to Pharmacy confirmed";
    } else {
      mgIdValue = mgId.ADMINISTER_TO_PATIENT;
      amountSuppliedValue = amountSupplied;
      alertMessage = "Stock Administered To Patient confirmed";
    }

    if (
      !isPartialAdministerInProgress ||
      mgIdValue !== mgId.ADMINISTER_TO_PATIENT
    ) {
      if (user.location.isPharmacy){
        OrdersService.adminToPatientPharmacy(
          {
            StkId: nxt["stkId"],
            StkPhId: nxt["stkPhId"],
            MgId: mgIdValue,
            PtId: patient["ptId"],
            AmountSupplied: amountSuppliedValue,
            DoseAdministered: doseAdministered,
            DoseAdministeredUom: doseAdministeredUom,
            AmountDisposed: amountDisposed,
            DatixNo: datIxNoValue,
            NewBalance: newBalanceValue,
            Comments: stockComments,
            CreatedBy: user.username,
            SignedBy: confirmedStaff.signed,
            WitnessedBy: confirmedStaff.witnessed,
            IgnoreWitnessField: witnessDisabled,
            IsDisposalIntent: isDisposalIntent,
          },
          { cancelToken: serviceCancelSource.token }
        )
          .then(() => {
            setAlertMessage({
              message: alertMessage,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();
          })
          .catch((error) => {
            if (!StockService.isCancel(error)) {
              if (error.response?.status === 401 ) {
                setConfirmedStaffErrors(error.response.data);
              } else if (error.response?.status === 400 && error.response.data) {
                setAlertMessage({
                  message: error.response.data,
                  colour: "danger",
                  label: "Error message",
                });
              } else {
                setAlertMessage({
                  message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                  label: "Error message",
                });
              }
            }
          })
          .finally(() => setInputsConfirmed(false));
      }
      else{
        OrdersService.adminToPatient(
          {
            StkId: nxt["stkId"],
            StkPhId: nxt["stkPhId"],
            MgId: mgIdValue,
            PtId: patient["ptId"],
            AmountSupplied: amountSuppliedValue,
            DoseAdministered: doseAdministered,
            DoseAdministeredUom: doseAdministeredUom,
            AmountDisposed: amountDisposed,
            DatixNo: datIxNoValue,
            NewBalance: newBalanceValue,
            Comments: stockComments,
            CreatedBy: user.username,
            SignedBy: confirmedStaff.signed,
            WitnessedBy: confirmedStaff.witnessed,
            IgnoreWitnessField: witnessDisabled,
            IsDisposalIntent: isDisposalIntent,
          },
          { cancelToken: serviceCancelSource.token }
        )
          .then(() => {
            setAlertMessage({
              message: alertMessage,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();
          })
          .catch((error) => {
            if (!StockService.isCancel(error)) {
              if (error.response?.status === 401 ) {
                setConfirmedStaffErrors(error.response.data);
              } else if (error.response?.status === 400 && error.response.data) {
                setAlertMessage({
                  message: error.response.data,
                  colour: "danger",
                  label: "Error message",
                });
              } else {
                setAlertMessage({
                  message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                  label: "Error message",
                });
              }
            }
          })
          .finally(() => setInputsConfirmed(false));
      }
    } else if (
      isPartialAdministerInProgress &&
      [
        partialAdministerEventTypes.ADMINISTERED,
        partialAdministerEventTypes.DISPOSED,
        partialAdministerEventTypes.SUPPLIED,
      ].includes(partialAdministerEventType)
    ) {
      updateHistory(partialManagementId, null, alertMessage);
    } else {
      const payload = {
        MgId: mgId.ADMINISTER_TO_PATIENT,
        StkId: nxt.stkId,
        PtId: nxt.ptId,
        Comments: stockComments,
        CreatedBy: user.username,
        SignedBy: confirmedStaff.signed,
        WitnessedBy: confirmedStaff.witnessed,
        IgnoreWitnessField: witnessDisabled,
        IgnoreSignedField: signDisabled,
        DoseAdministered: doseAdministered,
        DoseAdministeredUom: doseAdministeredUom,
        AmountDisposed: amountDisposed,
        AmountSupplied: amountSupplied,
        ParentStockHistoryId: partialManagementId,
      };

      StockService.manageWardStockPartial(payload, {
        cancelToken: serviceCancelSource.token,
      })
        .then((response) => {
          if (response.status === 201) {
            setAlertMessage({
              message: alertMessage,
              colour: "success",
              label: "Success message",
              timeout: 5000,
            });
            resetPage();
          } else {
            setAlertMessage({
              message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
              label: "Error message",
            });
          }
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: `There was a problem updating the selected stock, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                colour: "danger",
                label: "Error message",
              });
            }
          }
        })
        .finally(() => setInputsConfirmed(false));
    }
  };

  const setLineModalStock = (nx, item) => {
    setEditDiscrepancyStock(item);
    setModalStock(nx);
    setIsDiscrepancyOpen(true);
  };

  function refreshHistory (setStock = null) {
    const resetValues ={
      stockHistory: [],
      page: 1
    };
    setStockHistory(resetValues.stockHistory);
    setPage(resetValues.page);
    fetchHistory(nxt, resetValues, setStock );

  }

  function fetchHistoryInfiniteScroll() {
    fetchHistory(nxt);
  }

  function fetchHistory(nxt, resetValues, setStock = null) {
    const nextPage = resetValues?.page ?? page;
    if (locationRoute === "pharmacy") {
      StockService.getPharmacyStockHistory(nxt.stkPhId, nextPage, {
        cancelToken: serviceCancelSource.token,
      })
        .then((response) => {
          const sh = resetValues?.stockHistory ?? stockHistory;
          setStockHistory([].concat(sh, response.data));
          setPage((prevPage) => prevPage+1);
          setHasMore(response.data.length > 0);
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            setAlertMessage({
              message: `Unable to fetch complete stock history, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "warning",
              label: "Warning message",
            });
          }
        });
    } else {
      StockService.getStockHistory(nxt.stkId, nextPage, {
        cancelToken: serviceCancelSource.token,
      })
        .then((response) => {
          const sh = resetValues?.stockHistory ?? stockHistory;
          setStockHistory([].concat(sh, response.data));
          setPage((prevPage) => prevPage+1);
          setHasMore(response.data.length > 0);
          if (setStock) {
            const newBalance= response.data[0].balance;
            updateStockFromHistory(newBalance, setStock);
          }
        })
        .catch((error) => {
          if (!StockService.isCancel(error)) {
            setAlertMessage({
              message: `Unable to fetch complete stock history, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "warning",
              label: "Warning message",
            });
          }
        });
    }
  }

  function setPartialAdministerEvent(item, force) {
    const newItemEventType = force ? item.event + "F" : item.event;
    const isOneRowEvents =
      item.stockHistoryId === partialManagementId &&
      newItemEventType !== partialAdministerEventType;

    if (item.stockHistoryId !== partialManagementId || isOneRowEvents) {
      // pull across the data we need
      setPartialManagementId(item.stockHistoryId);
      setPartialAdministerEventType(newItemEventType);
      setPartialAdministerConfirmationFields({
        witnessedBy: item.witnessedBy,
        witnessedAt: item.witnessedAt,
        signedBy: item.signedBy,
        signedAt: item.signedAt,
      });

      switch (item.event) {
      case partialAdministerEventTypes.SUPPLIED:
        setStockComments(force ? "" : item.comments);
        setAmountSupplied(item.drugQuantity);
        setAmountDisposed(0);
        setDoseAdministered(0);
        setDoseAdministeredUom(item.doseAdministeredUom);
        break;
      case partialAdministerEventTypes.ADMINISTERED:
        setStockComments(item.comments);
        setAmountSupplied(item.amountSupplied);
        setDoseAdministered(item.drugQuantity);
        setDoseAdministeredUom(item.doseAdministeredUom);
        setAmountDisposed(0);
        break;
      case partialAdministerEventTypes.DISPOSED:
        setStockComments(item.comments);
        setAmountSupplied(item.amountSupplied);
        setAmountDisposed(item.drugQuantity);
        setDoseAdministeredUom(item.doseAdministeredUom);
        setDoseAdministered(0);
        break;
      default:
        break;
      }

      if (force) {
        enableConfirmationFieldCheckboxes();
        setIsCommentsInputDisabled(false);
      } else {
        setIsCommentsInputDisabled(true);
        disableConfirmationFieldCheckboxes(item.witnessedBy, item.signedBy);
      }

      if (!isPatientOwn) {
        // This is a bit of a hack (as we dont have full patient info in a partial admin but this is
        // only used for display purposes
        const tempPatient = {
          patientName: item.patientName,
          firstname: item.patientName,
          ptId: item.ptId,
          lastname: "",
        };
        setPatient(tempPatient);
      }
    } else {
      setPartialManagementId("");
      setPartialAdministerEventType("");
      setPartialAdministerConfirmationFields({});
    }
  }

  function enableConfirmationFieldCheckboxes() {
    enableSignWitness();
    setSignOnlyCheckboxDisabled(false);
    setWitnessOnlyCheckboxDisabled(false);
    setSignWitnessCheckboxDisabled(false);
    setConfirmBlockInitialState(ASL.confirmAdministerState);
  }

  function disableConfirmationFieldCheckboxes(witnessedBy, signedBy) {
    if (witnessedBy && witnessedBy !== "Witness disabled" && signedBy) {
      enableSignWitness();
      setSignOnlyCheckboxDisabled(true);
      setWitnessOnlyCheckboxDisabled(true);
      setSignWitnessCheckboxDisabled(false);
      setConfirmBlockInitialState(ASL.confirmAdministerState);
    } else if (witnessedBy === "Witness disabled" && signedBy) {
      const newConfirmBlockInitialState = ASL.confirmAdministerState.reduce(
        (acc, el) => {
          acc.push(el.name === "signed" ? { ...el, value: signedBy } : el);
          return acc;
        },
        []
      );
      enableOnlyWitnessBox();
      setSignDisabled(true);
      setSignWitnessCheckboxDisabled(true);
      setSignOnlyCheckboxDisabled(true);
      setWitnessOnlyCheckboxDisabled(false);
      setConfirmBlockInitialState(newConfirmBlockInitialState);
    } else if (witnessedBy && !signedBy) {
      const newConfirmBlockInitialState = ASL.confirmAdministerState.reduce(
        (acc, el) => {
          acc.push(
            el.name === "witnessed" ? { ...el, value: witnessedBy } : el
          );
          return acc;
        },
        []
      );
      enableOnlySignBox();
      setWitnessedDisabled(true);
      setSignWitnessCheckboxDisabled(true);
      setWitnessOnlyCheckboxDisabled(true);
      setSignOnlyCheckboxDisabled(false);
      setConfirmBlockInitialState(newConfirmBlockInitialState);
    }
  }

  function resetPage() {
    history.replace({
      state: {
        ...location.state,
        nxt: { ...location.state.nxt, runningBalance: newBalanceValue },
      },
    });
    refreshHistory();
    setAmountDisposed(0);
    setAmountSupplied(0);
    setConfirmedStaff();
    setDatIxNoValue();
    setDoseAdministered(0);
    setDrugsCollectedBy();
    setDrugsHandedTo();
    if (isPatientOwn) {
      setIsShowAdminister(true);
    } else {
      setPatient("");
    }
    setSelectedLocation(null);
    setStockComments("");
    // Only clear if multiple dropdown items (set on stockManagementDropdown)
    if (clearDropDownOptions) {
      setStockName("");
    } else {
      setResetQuantity(true);
    }
    setAuthorisedWitnessRequired(null);
    setDisposalReference(null);
    setValidate(false);
    setWitnessedDisabled(false);
    setPartialManagementId("");
    setPartialAdministerEventType("");
    setPartialAdministerConfirmationFields({});
  }

  function handleHistoryButtonClick() {
    fetchHistory(nxt);
    setShowOptionalHistoryEvents(!showOptionalHistoryEvents);
  }

  function renderStockManagementHeaders(name) {
    switch (name) {
    case "Discrepancy/Incident":
      return (
        <>
          <th>Datix Report Ref No.</th>
          <th>Quantity Adjusted</th>
          <th>New Balance</th>
        </>
      );
    case "Patient Transfer":
      return (
        <>
          <th>Ward Transferred To</th>
          <th>Drugs Collected By</th>
        </>
      );
    case "Leave/Discharge":
      return <th>Drugs Handed To</th>;
    default:
      return null;
    }
  }

  function renderWitnessBox() {
    setWitnessedDisabled(!witnessDisabled);
  }

  function enableOnlyWitnessBox() {
    setSignDisabled(true);
    setWitnessedDisabled(false);
  }

  function enableOnlySignBox() {
    setSignDisabled(false);
    setWitnessedDisabled(true);
  }

  function enableSignWitness() {
    setSignDisabled(false);
    setWitnessedDisabled(false);
  }

  // comments block and confirmation block details
  function renderViewBlock() {
    if ((isShowStockManagement && stockName) || (isShowAdminister && patient)) {
      return (
        <>
          <Row>
            <Col>
              <CommentsBlock
                comments={stockComments}
                setComments={inputTerm}
                disabled={isCommentsInputDisabled}
              />
            </Col>
          </Row>
          {isShowAdminister &&
            (user.location.managePharmacyAsWard ||
            (user.location.managePharmacyAsTheatre && isPatientOwn)) && (
            <Row>
              <CheckableInputs
                className="disable-witness-toggle"
                inline
                legend="Disable Witnessing"
                list={disableWitnessList}
                onChange={renderWitnessBox}
                type="radio"
              />
            </Row>
          )}
          {isShowAdminister && isOrManageTheatre && !isPatientOwn && (
            <>
              <Row>
                <CheckableInputs
                  className="disable-witness-toggle"
                  inline
                  legend="Signing & Witnessing"
                  list={[
                    {
                      name: "disableNothing",
                      value: "disableNothing",
                      checked: !signDisabled && !witnessDisabled,
                      defaultChecked: !signDisabled && !witnessDisabled,
                    },
                  ]}
                  onChange={enableSignWitness}
                  type="radio"
                  disabled={signWitnessCheckboxDisabled}
                />
              </Row>
              <Row>
                <CheckableInputs
                  className="disable-witness-toggle"
                  inline
                  legend="Signing Only"
                  list={[
                    {
                      checked: witnessDisabled,
                      defaultChecked: witnessDisabled,
                      name: "disableSign",
                      value: "disableSign",
                    },
                  ]}
                  onChange={enableOnlySignBox}
                  type="radio"
                  disabled={signOnlyCheckboxDisabled}
                />
              </Row>
              <Row>
                <CheckableInputs
                  className="disable-witness-toggle"
                  inline
                  legend="Witnessing Only"
                  list={[
                    {
                      checked: signDisabled,
                      defaultChecked: signDisabled,
                      name: "disableWitness",
                      value: "disableWitness",
                    },
                  ]}
                  onChange={enableOnlyWitnessBox}
                  type="radio"
                  disabled={witnessOnlyCheckboxDisabled}
                />
              </Row>
            </>
          )}
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={confirmBlockInitialState}
            inputsConfirmed={inputsConfirmed}
            setInputsConfirmed={setInputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
            title={
              isShowAdminister
                ? `Administer drug to ${patient["firstname"]} ${patient["lastname"]}`
                : `${stockName["name"]}`
            }
            setValidate={setValidate}
            disableWitness={witnessDisabled && isShowAdminister}
            disableSign={signDisabled && isShowAdminister}
          />
        </>
      );
    }
    return null;
  }

  function renderQuantity(value, units) {
    return (
      <>
        {value}
        <span className="quantity-units" title={units}>
          {units}
        </span>
      </>
    );
  }

  // history block
  const renderHistory = () => {
    const isWardLocation = locationRoute === "ward";

    return (
      <InfiniteScroll
        dataLength={stockHistory.length}
        next={fetchHistoryInfiniteScroll}
        hasMore={hasMore}
        refreshFunction={refreshHistory}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        scrollableTarget="history-table"
      >
        <Table className="history-table">
          <colgroup>
            <col span="2" />
            <col className="management" span="4" />
            {isWardLocation && <col className="administration" span="3" />}
          </colgroup>
          <thead>
            <tr>
              <th colSpan="2" />
              <th className="management align-left" colSpan="4">
                  CD Management
              </th>
              {isWardLocation && (
                <th className="administration align-left" colSpan="3">
                  Administered to Patient
                </th>
              )}
              {!isWardLocation && <th colSpan="2" />}
              <th colSpan="5" />
            </tr>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th className="management align-left">Event</th>
              <th className="management">Quantity</th>
              <th className="management">Ref</th>
              <th className="management">Location</th>
              {isWardLocation && (
                <>
                  <th className="administration align-left">Patient</th>
                  <th className="administration" colSpan="2">
                    Quantities
                  </th>
                </>
              )}
              {!isWardLocation && (
                <>
                  <th className="align-left">Patient</th>
                  <th className="align-left">Prescriber</th>
                </>
              )}
              <th className="align-left">Comments</th>
              <th>Confirmed by</th>
              <th>Witnessed by</th>
              <th>Collected by</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {stockHistory.map((item, index) => {
              const dateStrings = item.signedAt
                ? getDateStrings(item.signedAt)
                : {};
              const eventConfig = historyEvents[item.event];

              if (!eventConfig) {
                console.error(
                  "Unrecognised stock history event type",
                  item.event
                );
                return null;
              }

              if (!showOptionalHistoryEvents && eventConfig.optionalEvent) {
                return null;
              }

              return (
                <tr
                  className={eventConfig.groupBody ? "group-body" : undefined}
                  key={index}
                >
                  <td>
                    <time dateTime={item.signedAt}>{dateStrings.date}</time>
                  </td>
                  <td>
                    <time dateTime={item.signedAt}>{dateStrings.time}</time>
                  </td>
                  {eventConfig.type === MANAGEMENT_EVENT_TYPE ? (
                    <>
                      <td className="align-left">
                        <div>
                          {item.event === "DISCREPANCY_INCIDENT" &&
                            (item.reference || item.disposalReference) && (
                            <CButton
                              className="shadow-sm btn-xs"
                              size="sm"
                              name="editDiscrepancy"
                              onClick={(e) => {
                                e.preventDefault();
                                setLineModalStock(nxt, item);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </CButton>
                          )}
                          {item.eventDisplayText}
                        </div>
                        <div>{item.supplierName}</div>
                        <div>{item.supplierAddress}</div>
                      </td>
                      <td className="quantity">
                        {!eventConfig.noQuantity &&
                          renderQuantity(item.drugQuantity, item.drugQuantityUom)}
                      </td>
                      <td>
                        {item.event === "DISCREPANCY_INCIDENT"
                          ? item.disposalReference
                          : item.reference}
                      </td>
                      <td>{item.locationName}</td>
                    </>
                  ) : (
                    <td colSpan="4" />
                  )}
                  {isWardLocation &&
                  (eventConfig.type === ADMINISTRATION_EVENT_TYPE ? (
                    <>
                      <td className="align-left break-words">
                        {eventConfig.groupHead && (
                          <>
                            {isShowAdminister && (
                              <CButton
                                className="shadow-sm btn-xs"
                                size="sm"
                                color={`${
                                  partialManagementId === item.stockHistoryId &&
                                  partialAdministerEventType !==
                                    partialAdministerEventTypes.SUPPLIED
                                    ? "success"
                                    : "primary"
                                }`}
                                name="administerToPatient"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPartialAdministerEvent(item, true);
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </CButton>
                            )}
                            <span>{item.patientName}</span>
                          </>
                        )}
                      </td>
                      <td className="no-wrap" style={{ textAlign: "left" }}>
                        {isShowAdminister &&
                          isOrManageTheatre &&
                          !isPatientOwn &&
                          (item.witnessedBy === "Witness disabled" ||
                            !item.signedBy) && (
                          <CButton
                            className="shadow-sm btn-xs"
                            size="sm"
                            color={`${
                              partialManagementId === item.stockHistoryId &&
                              partialAdministerEventType !==
                                partialAdministerEventTypes.FORCE_SUPPLIED
                                ? "success"
                                : !item.signedBy ||
                                  (item.witnessedBy === "Witness disabled" &&
                                    item.event !==
                                      partialAdministerEventTypes.ADMINISTERED)
                                  ? "danger"
                                  : "primary"
                            }`}
                            name="administerToPatient"
                            onClick={(e) => {
                              e.preventDefault();
                              setPartialAdministerEvent(item);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </CButton>
                        )}
                        <abbr title={eventConfig.title}>
                          {item.eventDisplayText}
                        </abbr>
                      </td>
                      <td className="align-right quantity">
                        {renderQuantity(
                          item.drugQuantity,
                          eventConfig.isDoseUom
                            ? item.doseAdministeredUom
                            : item.drugQuantityUom
                        )}
                      </td>
                    </>
                  ) : (
                    <td colSpan="3" />
                  ))}
                  {!isWardLocation && (
                    <>
                      <td className="align-left persist-linebreaks">
                        {item.patientFirstname} {item.patientLastname}
                        {item.patientUniqueIdentifier && <br />}
                        {item.patientUniqueIdentifier}
                        {item.patientAddress && <br />}
                        {item.patientAddress}
                      </td>
                      <td className="align-left">{item.prescribedBy}</td>
                    </>
                  )}
                  <td className="align-left break-words">
                    {(!eventConfig.groupBody || item.parentStockHistoryId) &&
                    (item.event === "DISCREPANCY_INCIDENT"
                      ? item.comments
                        ? (item.reference && item.reference !== item.disposalReference)
                          ? `${item.reference} - ${item.comments}`
                          : item.comments
                        : item.reference
                      : item.comments)}
                  </td>
                  <td
                    className={cx("break-words", { bold: !item.signedBy })}
                    title={item.signedBy}
                  >
                    {!item.signedBy && isOrManageTheatre
                      ? "PENDING"
                      : truncateString(
                        item.signedBy,
                        SIGNATURE_DISPLAY_MAX_LENGTH
                      )}
                    <br />
                    {item.signedBy &&
                      (item.witnessedBy && parseISODateAsLocalTime(item.signedAt).setSeconds(0,0)  - parseISODateAsLocalTime(item.witnessedAt).setSeconds(0,0) > 60000) &&
                      isOrManageTheatre &&
                    getDateStrings(item.signedAt).time}
                  </td>
                  <td
                    className={cx("break-words", {
                      bold:
                      item.witnessedBy === "Witness disabled" &&
                      isOrManageTheatre &&
                      item.event !== partialAdministerEventTypes.ADMINISTERED,
                    })}
                    title={item.witnessedBy}
                  >
                    {item.witnessedBy === "Witness disabled" && isOrManageTheatre
                      ? item.event === partialAdministerEventTypes.ADMINISTERED
                        ? "not required"
                        : "PENDING"
                      : truncateString(
                        item.witnessedBy,
                        SIGNATURE_DISPLAY_MAX_LENGTH
                      )}
                    <br />
                    {item.witnessedAt && item.witnessedBy !== "Witness disabled" &&
                      (item.signedBy && parseISODateAsLocalTime(item.witnessedAt).setSeconds(0,0)  - parseISODateAsLocalTime(item.signedAt).setSeconds(0,0)  > 60000) &&
                    isOrManageTheatre &&
                    isOrManageTheatre &&
                    item.witnessedBy &&
                    getDateStrings(item.witnessedAt).time}
                  </td>
                  <td className="break-words" title={item.collectedBy}>
                    {truncateString(
                      item.collectedBy,
                      SIGNATURE_DISPLAY_MAX_LENGTH
                    )}
                  </td>
                  <td className="align-right quantity">
                    {!eventConfig.noBalance &&
                    item.balance !== null &&
                    renderQuantity(item.balance, item.drugQuantityUom)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
    );
  };

  useEffect(() => {
    setNewBalanceValue(newBalanceModal);
  }, [newBalanceModal]);

  useEffect(() => {
    if (newBalanceValue < 0) {
      setAlertMessage({
        message:
          "Please amend the stock adjustment, cannot submit a negative balance of stock",
        colour: "warning",
        label: "Warning message",
      });
      setShowSuppliedError(true);
    } else {
      setAlertMessage({});
      setShowSuppliedError(false);
    }
  }, [newBalanceValue]);

  function handleModalComplete(stock, newBalance) {
    if (newBalance){
      history.replace({
        state: {
          ...location.state,
          nxt: { ...location.state.nxt, runningBalance: newBalance },
        },
      });
      setNxt({ ...nxt, runningBalance: newBalance });
      setNewBalanceModal(newBalance - (nxt.runningBalance - newBalanceValue));
      refreshHistory();
      handleModalToggle();
    } else {
      loadData(nxt);
    }
  }

  function handleModalError(setStock) {
    refreshHistory(setStock);
  }

  function updateStockFromHistory(newBalance, setStock){
    history.replace({
      state: {
        ...location.state,
        nxt: { ...location.state.nxt, runningBalance: newBalance },
      },
    });
    setNxt({ ...nxt, runningBalance: newBalance });
    setStock({ ...nxt, runningBalance: newBalance });
    setModalStock({ ...nxt, runningBalance: newBalance });
    setNewBalanceModal(newBalance);
  }

  function handleModalToggle() {
    setModalStock();
    setIsDiscrepancyOpen(false);
    setEditDiscrepancyStock();
  }

  if (!discrepancyByBalance || !SIGNATURE_DISPLAY_MAX_LENGTH || !mgId){
    return (<></>);
  }

  return (
    <Col className={`AdministerStock AdministerStock-${locationRoute}`}>
      <CAlert
        aria-label={alertMessage.label}
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
      >
        {alertMessage.message}
      </CAlert>
      <Row>
        <Col></Col>
        <Col
          className="d-flex justify-content-end align-items-end"
          md={{ size: 3, offset: 2 }}
        >
          <Link className="shadow-sm btn btn-primary" to={mainStockPagePath}>
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO STOCK
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          {(isOrManageWard || isOrManageTheatre) && (
            <CButton
              className={`shadow-sm ml-2 ${
                isShowAdminister ? "btn-on" : "btn-off"
              }`}
              variant="outline"
              shape="pill"
              color="primary"
              name="administerToPatient"
              onClick={(e) => {
                e.preventDefault();
                setIsShowAdminister(true);
                setIsShowStockManagement(false);
                setStockName("");
                setPatient(nxt.patient);
                setPartialManagementId("");
                setDoseAdministeredUom(
                  nxt.cdCatalogue.cdcatalogueNameOrFormularyName.startsWith("Potassium")
                    ? "ml"
                    : nxt.cdCatalogue?.cdCatalogueStrength[0]
                      ?.strengthNumeratorUoM || ""
                );
                setPartialAdministerEventType("");
                setPartialAdministerConfirmationFields({});
              }}
            >
              Administer To Patient
            </CButton>
          )}
          <CButton
            className={`shadow-sm ml-2 ${
              isShowStockManagement ? "btn-on" : "btn-off"
            }`}
            variant="outline"
            shape="pill"
            color="primary"
            name="stockManagement"
            onClick={(e) => {
              e.preventDefault();
              setIsShowStockManagement(true);
              setIsShowAdminister(false);
              setPatient("");
            }}
          >
            Stock Management
          </CButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                {isPatientOwn && <><th>Patient</th><th>Bag Reference</th></>}
                {locationRoute === "pharmacy" && <th></th>}
                <th>Unit Dose</th>
                <th>Running Balance</th>
                <th>Record Discrepancy</th>
              </tr>
            </thead>
            {nxt ? (
              <tbody>
                <StockTableLine
                  disabled
                  stock={nxt}
                  showCategory={locationRoute === "pharmacy"}
                  onDiscrepancyClick={() => {
                    setModalStock(nxt);
                    setIsDiscrepancyOpen(true);
                  }}
                  showInLineDiscrepancy={true}
                />
              </tbody>
            ) : null}
          </Table>
        </Col>
      </Row>
      {isShowAdminister && !nxt.isPatientOwn && (
        <>
          {!isPartialAdministerInProgress && (
            <Row>
              <Col className="patient-picker">
                <PatientDropdown
                  allowCreatePatient
                  setPatient={setPatient}
                  isSearch
                  patient={
                    patient
                      ? `${patient["firstname"]} ${patient["lastname"]}`
                      : ""
                  }
                />
              </Col>
            </Row>
          )}
          {nxt && patient && (
            <Row>
              <Col>
                <Table className="administer-patient-table">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>
                        <sup className="mandatory-label">*</sup>
                        Amount Supplied
                      </th>
                      <th>Amount Administered</th>
                      <th>Amount Disposed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <AdministerPatientTableLine
                      patient={{
                        ...nxt,
                        name: patient.patientName,
                      }}
                      stockView
                      setAmountSupplied={setAmountSupplied}
                      setAmountDisposed={setAmountDisposed}
                      setDoseAdministered={setDoseAdministered}
                      setDoseAdministeredUom={setDoseAdministeredUom}
                      setNewBalanceValue={setNewBalanceValue}
                      amountSupplied={amountSupplied}
                      doseAdministered={doseAdministered}
                      validate={validate}
                      isPartialAdminister={isPartialAdministerInProgress}
                      isQTYInputsDisabled={
                        isPartialAdministerInProgress &&
                        partialAdministerEventType !==
                          partialAdministerEventTypes.FORCE_SUPPLIED
                      }
                      amountDisposed={amountDisposed}
                      doseAdministeredUom={doseAdministeredUom}
                      showSuppliedError={showSuppliedError}
                      showPatient={showPatient}
                    />
                    <tr className="notes-row">
                      <td />
                      <td>
                        {!isPartialAdministerInProgress && (
                          <small>
                            Must not be greater than the Running Balance
                          </small>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      )}
      {isShowAdminister && nxt.isPatientOwn && (
        <>
          <Row>
            <Col>
              <Table className="administer-patient-table">
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>
                      <sup className="mandatory-label">*</sup>
                      Amount Supplied
                    </th>
                    <th>Amount Administered</th>
                    <th>Amount Disposed</th>
                  </tr>
                </thead>
                <tbody>
                  <AdministerPatientTableLine
                    patient={{
                      ...nxt,
                      name: patient.patientName,
                    }}
                    stockView
                    setAmountSupplied={setAmountSupplied}
                    setAmountDisposed={setAmountDisposed}
                    setDoseAdministered={setDoseAdministered}
                    setDoseAdministeredUom={setDoseAdministeredUom}
                    setNewBalanceValue={setNewBalanceValue}
                    amountSupplied={amountSupplied}
                    doseAdministered={doseAdministered}
                    validate={validate}
                    isPartialAdminister={isPartialAdministerInProgress}
                    amountDisposed={amountDisposed}
                    doseAdministeredUom={doseAdministeredUom}
                    showSuppliedError={showSuppliedError}
                    showPatient={showPatient}
                  />
                  <tr className="notes-row">
                    <td />
                    <td>
                      {!isPartialAdministerInProgress && (
                        <small>
                          Must not be greater than the Running Balance
                        </small>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      {isShowStockManagement && (
        <>
          <Row>
            <Col>
              <Table className="stock-management-table">
                <thead>
                  <tr>
                    <th>Type Of Stock Management</th>
                    {renderStockManagementHeaders(stockName.name)}
                    {stockName.name &&
                      stockName.name !== "Discrepancy/Incident" && (
                      <>
                        <th>Quantity</th>
                        {locationRoute === "pharmacy" && (
                          <th>Authorised Witness Required</th>
                        )}
                        <th>Reference</th>
                        <th>New Balance</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <StockManagementTableLine
                    line={{ ...nxt, name: "" }}
                    active={true}
                    stockView
                    stockName={stockName}
                    setAlertMessage={setAlertMessage}
                    setStockName={setStockName}
                    setQuantityValue={setAmountSupplied}
                    setDatIxNoValue={setDatIxNoValue}
                    setNewBalanceValueFor={setNewBalanceValue}
                    setSelectedLocation={setSelectedLocation}
                    setDrugsCollectedBy={setDrugsCollectedBy}
                    setDrugsHandedTo={setDrugsHandedTo}
                    setAuthorisedWitnessRequired={setAuthorisedWitnessRequired}
                    setDisposalReference={setDisposalReference}
                    discrepancyByBalance={discrepancyByBalance}
                    setClearDropDownOptions={setClearDropDownOptions}
                    setResetQuantity={setResetQuantity}
                    resetQuantity={resetQuantity}
                  />
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      {renderViewBlock()}
      {stockHistory.length > 0 && (
        <Row className="stock-history">
          <h5>History</h5>
          <ButtonToggle
            active={showOptionalHistoryEvents}
            className="history-btn btn-pill"
            color="primary"
            onClick={handleHistoryButtonClick}
            outline
          >
            {showOptionalHistoryEvents ? "Hide" : "Show"} Order History
          </ButtonToggle>
          {renderHistory()}
        </Row>
      )}
      <DiscrepancyModal
        isOpen={isDiscrepancyOpen}
        onComplete={handleModalComplete}
        onError={handleModalError}
        stock={modalStock}
        toggle={handleModalToggle}
        setBalanceValue={setNewBalanceValue}
        editDatix={editDiscrepancyStock}
      />
      <PatientModal
        isOpen={showModalPatient}
        setIsOpen={setShowModalPatient}
        patientId={selectedPatientId}
        setAlertMessage={setAlertMessage}
      />
    </Col>
  );
};

export default React.memo(AdministerStock);
