import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import Moment from "react-moment";
import "moment-timezone";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import SignInput from "../molecules/SignInput";
import { CAlert } from "@coreui/react";
import { useHistory } from "react-router-dom";

import SystemContext from "../../context/SystemContext";
import { truncateString } from "../../helpers";
import OrdersService from "../../services/OrdersService";
import StockService from "../../services/StockService";
import { showAlertMessage } from "../../store/actions";
import { imprivataConfig } from "../../config/imprivata";
import { imprivataSign } from "../../services/ImprivataService";

import { msalConfig } from "../../config/msal";

const msalRequired = process.env.REACT_APP_AUTH_REQUIRED;

let serviceCancelSource;
let timeoutId;

const forDisposal = "ForDisposal";
const forReuse = "ForReuse";

const ConfirmBlockOrders = ({
  col2StyleClass,
  setLoadOrders,
  loadStatus,
  orderArray,
  blockTitle,
  currentStatus,
  confirmInitialState,
  messageState,
  setAlertMessage,
  isHasChanged,
  isReturnOrders,
  checkIfValid,
  disableCollectedBy,
  disableSignedBy,
  formState,
  setFormShowValidity,
  checkIfValidBalance,
  location,
  locationRoute,
  completionAction,
}) => {
  const authRequired = msalRequired || imprivataConfig.enabled;
  const { isPharmacy, user, locationId } = useContext(SystemContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );
  const mgId = useSelector((state) => state.settings.mgId);
  const supplyCheckEnabled = useSelector(
    (state) => state.settings.supplyCheckEnabled
  );
  const skipDisposalApproval = useSelector(
    (state) => state.settings.skipDisposalApproval
  );

  const { instance } = useMsal();

  const [pageStatus, setpageStatus] = useState(currentStatus.split("-")[0]);
  const [showConfirmInputs, setshowConfirmInputs] = useState([]);
  const [isDisabled, setIsdisabled] = useState(false);
  const [confirmInputs, setconfirmInputs] = useState([]);
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [approved] = useState(messageState); // message related to sign boxes
  const [disableConfirm, setDisableConfirm] = useState(false);

  const [dropDownItem] = useState("");
  const [dropDownError] = useState();
  const [isWitnessed, setisWitnessed] = useState(false);
  const [resetInputs, setResetInputs] = useState(false);

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const status = currentStatus.split("-")[0];

    if (status) {
      setshowConfirmInputs(getConfirmInitialState(status));
      setpageStatus(status);
    }

    return () => {};
  }, [currentStatus]);

  useEffect(() => {
    if (orderArray.some((item) => item["isWitnessed"])) {
      setisWitnessed(true);
    } else {
      setisWitnessed(false);
    }
    return () => {};
  }, [orderArray]);

  const areAllSigned = () =>
    confirmInputs.length === getConfirmInitialState(pageStatus).length ||
    (disableCollectedBy &&
      confirmInputs.length === getConfirmInitialState(pageStatus).length - 1);

  useEffect(() => {
    if (!authRequired) {
      if (areAllSigned())
      {
        if (isHasChanged) {
          const inputValues = confirmInputs.reduce(
            (obj, item) => ({ ...obj, [item.name]: item.value }),
            {}
          );

          if (
            inputValues.signedBy &&
            inputValues.witnessedBy &&
            inputValues.signedBy === inputValues.witnessedBy
          ) {
            showAlertMessage(dispatch, {
              message:
                "The “Witnessed by” and “Signed by” fields cannot have the same value",
              colour: "warning",
            });
            setResetInputs(true);
          } else {
            completeOrder();
          }
        } else {
          setAlertMessage({
            message:
              pageStatus === "Pharmacy"
                ? "Please enter a quantity supplied"
                : "You need to select an item in your list",
            colour: "warning",
          });
          setResetInputs(true);
        }
      }
    } else {
      if (
        confirmInputs.length > 0 &&
        areAllSigned() &&
        !confirmInputs.some((i) => !i.value)
      ) {
        confirm();
      }
    }
  }, [confirmInputs]);

  useEffect(() => {
    // make input active when dropdown is on the page and not selected
    if (dropDownItem && isDisabled) {
      setIsdisabled(false);
    }

    return () => {};
  }, [dropDownItem]);

  useEffect(() => {
    if (confirmedStaffErrors) {
      showAlertMessage(dispatch, {
        message: `A user has insufficient permissions to perform this action,
          if required please contact your IT help-desk to change`,
        colour: "danger",
      });
      setconfirmInputs((signed) => {
        if (signed.find((item) => item.value === confirmedStaffErrors)) {
          return signed.map((item) => {
            if (item.value !== confirmedStaffErrors) {
              return item;
            }
          }).filter((item) => item !== undefined );
        } else {
          return signed.map((item) => ({ ...item, error: true }));
        }
      });
    }
  }, [confirmedStaffErrors]);

  useEffect(() => {
    if (resetInputs === true) {
      if (!confirmedStaffErrors) {
        setconfirmInputs([]);
      }
      setDisableConfirm(false);

      setResetInputs(false);
    }
  }, [resetInputs]);

  const getErrorMessage = (errorResponse) => {
    const errorPrefix = "There was a problem updating the orders, ";
    const defaultErrorMessage = `${errorPrefix}the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`;

    if (errorResponse && errorResponse.status === 400) {
      if (typeof errorResponse.data === "string") {
        return `${errorPrefix}${errorResponse.data}`;
      } else if (
        typeof errorResponse.data === "object" &&
        errorResponse.data.errors
      ) {
        const errors = Object.values(errorResponse.data.errors);
        const firstError = errors[0];
        const errorMessage =
          (firstError && firstError[0]) ||
          errorResponse?.data?.title ||
          defaultErrorMessage;
        return `${errorPrefix} ${errorMessage}`;
      }
    }
    return defaultErrorMessage;
  };

  function completeOrder() {
    // create signd/witness/etc array
    const signed = confirmInputs.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {}
    );

    // make drug array
    const sendArray = orderArray.map((item) => {
      if (item["qtySupplied"])
        return {
          odId: item["odId"],
          cdApId: item["cdApId"],
          cdVpId: item["cdVpId"],
          catalogueId: item["catalogueId"],
          lcId: item["lcId"],
          siteId: item["siteId"],
          stId: item["stId"],
          qtySupplied: item["qtySupplied"],
          reference: item["reference"],
          isInvalid: item["isInvalid"],
          ptId: item["ptId"],
          isPatient: item["isPatient"],
        };
      else
        return {
          odId: item["odId"],
          cdApId: item["cdApId"],
          cdVpId: item["cdVpId"],
          catalogueId: item["catalogueId"],
          lcId: item["lcId"],
          siteId: item["siteId"],
          stId: item["stId"],
          ptId: item["ptId"],
          isPatient: item["isPatient"],
        };
    });

    // add signed by items to array
    let completeLine = sendArray.map((item) => {
      return { ...item, isPharmacy, ...signed, createdBy: user.username };
    });

    // if dropdown item (collectedBy) to array
    if (dropDownItem) {
      const collected = dropDownItem.map((oj) => {
        const item = {};
        item[oj["name"]] = oj["value"];
        return item;
      });
      completeLine = completeLine.map((item) => {
        return { ...item, ...collected };
      });
    }
    if (pageStatus !== "ReadyForCollection") {
      if (isReturnOrders) {
        const signedObj = confirmInputs.reduce((obj, confirmInput) => {
          obj[confirmInput.name] = confirmInput.value;
          return obj;
        }, {});
        let updatedOrders;

        if (pageStatus === forDisposal) {
          if (skipDisposalApproval) {
            updatedOrders = orderArray.map((order) => ({
              id: order.stkMgId,
              createdBy: user.username,
              signedBy: signedObj.signedBy,
              witnessedBy: signedObj.witnessedBy,
              disposalReference: order.disposalReference,
              typeId: 2,
              AuthorisedWitnessRequired: order.authorisedWitnessRequired,
            }));
          } else {
            updatedOrders = orderArray.map((order) => ({
              ...order,
              mgId: mgId.FOR_DISPOSAL,
              ...signedObj,
            }));
          }
        } else if (pageStatus === forReuse) {
          updatedOrders = orderArray.map(
            ({ disposalReference, authorisedWitnessRequired, ...order }) => ({
              ...order,
              catalogueId: order.catalogueId,
              mgId: mgId.REGISTERED_FOR_REUSE,
              ...signedObj,
            })
          );
        } else {
          updatedOrders = orderArray.map((order) => ({
            ...order,
            ...signedObj,
          }));
        }
        if (pageStatus === forDisposal && skipDisposalApproval) {
          StockService.dispose(locationRoute, location, true, updatedOrders, {
            cancelToken: serviceCancelSource.token,
          })
            .then((response) => {
              if (response.status === 202) {
                setAlertMessage({
                  message: "Orders signed off",
                  colour: "success",
                  timeout: 5000,
                });
                setResetInputs(true);
                setLoadOrders(loadStatus);
              } else {
                setAlertMessage({
                  message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
                setResetInputs(true);
              }
            })
            .catch((error) => {
              if (!StockService.isCancel(error)) {
                if (error.response?.status === 401) {
                  setConfirmedStaffErrors(error.response.data);
                } else {
                  setAlertMessage({
                    message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                    colour: "danger",
                  });
                }

                setResetInputs(true);
              }
            });
        } else {
          StockService.updateDrugsFromWards(user.location.lcId, updatedOrders, {
            cancelToken: serviceCancelSource.token,
          })
            .then((response) => {
              if (response.status === 201) {
                setAlertMessage({
                  message: "Orders signed off",
                  colour: "success",
                  timeout: 5000,
                });

                if (isPharmacy && pageStatus === forReuse) {
                  const { catalogueId } = completeLine[0];

                  StockService.getPharmacyStock(locationId).then((res) => {
                    const pharmacyStock = res.data;
                    const foundItemIndex = pharmacyStock.findIndex(
                      (el) => el.catalogueId === catalogueId
                    );

                    if (foundItemIndex < 0) {
                      setAlertMessage({
                        message:
                          "Unable to redirect because no matching drug was found in stock",
                        colour: "warning",
                      });
                      setResetInputs(true);

                      return;
                    }

                    history.push("/pharmacy/stock/administer", {
                      nxt: pharmacyStock[foundItemIndex],
                    });
                  });
                }

                if (completionAction) {
                  completionAction();
                }
              } else {
                setAlertMessage({
                  message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
                setResetInputs(true);
              }
            })
            .catch((error) => {
              if (!StockService.isCancel(error)) {
                if (error.response?.status === 401) {
                  setConfirmedStaffErrors(error.response.data);
                } else {
                  setAlertMessage({
                    message: `There was a problem updating the orders, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                    colour: "danger",
                  });
                }

                setResetInputs(true);
              }
            });
        }
      } else {
        if (supplyCheckEnabled && pageStatus === "Pharmacy") {
          let isValid = true;

          completeLine.forEach((element) => {
            const check = checkIfValid(element.qtySupplied, element.odId);

            if (!check) {
              isValid = false;
            }
          });

          if (!isValid) {
            setAlertMessage({
              message: "Amount supplied cannot be greated then requested",
              colour: "warning",
            });
            setResetInputs(true);
            return;
          }
        }
        if (pageStatus === "Pharmacy") {
          let isValid = true;
          const invalidItemList = [];

          if (checkIfValidBalance) {
            completeLine.forEach((element) => {
              const check = checkIfValidBalance(completeLine, element.odId);

              if (!check.valid) {
                isValid = false;
                invalidItemList.push(check);
              }
            });
          } else {
            if (completeLine.some((e) => e.isInvalid === true)) {
              setAlertMessage({
                message: "Not enough stock in balance to supply quantity",
                colour: "warning",
              });
              setResetInputs(true);
              return;
            }
          }

          if (!isValid) {
            const invalidDrugs = invalidItemList.map((o) => o.drugName);
            const filtered = invalidItemList.filter(
              ({ drugName }, index) =>
                !invalidDrugs.includes(drugName, index + 1)
            );
            const alertMessage =
              "Unable to complete supply, as not enough stock in balance to fulfil one or more orders:";
            const invalidItemDetails = [];
            filtered.forEach((invalidItem) => {
              invalidItemDetails.push({
                message: `${invalidItem.drugName} has a request of ${invalidItem.combinedQuantitySupplied}
                                                  ${invalidItem.uom} to be supplied against ${invalidItem.runningBalance} ${invalidItem.uom} balance`,
              });
            });
            setAlertMessage({
              message: alertMessage,
              dataList: invalidItemDetails,
              colour: "warning",
            });
            setResetInputs(true);
            return;
          }
        }

        OrdersService.updateOrder(completeLine)
          .then(() => {
            setTimeout(() => {
              setLoadOrders(loadStatus ?? { state: true, refresh: true });
              setAlertMessage({
                message: "",
                colour: "",
              });
            }, 2000);

            setAlertMessage({
              message: "Orders signed off",
              colour: "success",
              timeout: 5000,
            });

            setResetInputs(true);

            if (pageStatus === "ReadyToRegister") {
              const { catalogueId } = completeLine[0];
              if (isPharmacy) {
                StockService.getPharmacyStock(locationId).then((res) => {
                  const pharmacyStock = res.data;
                  const foundItemIndex = pharmacyStock.findIndex(
                    (el) => el.catalogueId === catalogueId
                  );

                  if (foundItemIndex < 0) {
                    setAlertMessage({
                      message:
                        "Unable to redirect because no matching drug was found in stock",
                      colour: "warning",
                    });
                    setResetInputs(true);

                    return;
                  }

                  history.push("/pharmacy/stock/administer", {
                    nxt: pharmacyStock[foundItemIndex],
                  });
                });
              } else {
                StockService.wardStock(locationId).then((res) => {
                  const wardStock = res.data;
                  const foundItemIndex = wardStock.findIndex(
                    (el) => el.catalogueId === catalogueId
                  );

                  if (foundItemIndex < 0) {
                    setAlertMessage({
                      message:
                        "Unable to redirect because no matching drug was found in stock",
                      colour: "warning",
                    });
                    setResetInputs(true);
                    return;
                  }

                  history.push(`/${locationRoute}/stock/administer`, {
                    nxt: wardStock[foundItemIndex],
                  });
                });
              }
            }
            // single page response push to stock page
            if (isPharmacy && pageStatus === "Pharmacy") {
              const { catalogueId } = completeLine[0];

              StockService.getPharmacyStock(locationId).then((res) => {
                const pharmacyStock = res.data;
                const foundItemIndex = pharmacyStock.findIndex(
                  (el) => el.catalogueId === catalogueId
                );

                if (foundItemIndex < 0) {
                  setAlertMessage({
                    message:
                      "Unable to redirect because no matching drug was found in stock",
                    colour: "warning",
                  });
                  setResetInputs(true);

                  return;
                }

                history.push("/pharmacy/stock/administer", {
                  nxt: pharmacyStock[foundItemIndex],
                });
              });
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setAlertMessage({
                message: getErrorMessage(error.response),
                colour: "danger",
              });
            }
            setResetInputs(true);
          });
      }
    } else {
      if (disableCollectedBy && !formState.valid) {
        setAlertMessage({
          message: "Please fill in the required fields",
          colour: "warning",
        });
        setFormShowValidity(true);
        setResetInputs(true);
      } else {
        if (disableCollectedBy) {
          completeLine = completeLine.map((item) => ({
            ...item,
            ...formState,
            valid: undefined,
            NonActiveDirectoryUser: true,
          }));
        }

        OrdersService.updateOrder(completeLine)
          .then(() => {
            setTimeout(() => {
              setLoadOrders(loadStatus ?? { state: true, refresh: true });
              setAlertMessage({
                message: "",
                colour: "",
              });
            }, 2000);
            setAlertMessage({
              message: "Orders signed off",
              colour: "success",
            });

            if (isPharmacy && pageStatus === "ReadyForCollection") {
              const { catalogueId } = completeLine[0];

              StockService.getPharmacyStock(locationId).then((res) => {
                const pharmacyStock = res.data;
                const foundItemIndex = pharmacyStock.findIndex(
                  (el) => el.catalogueId === catalogueId
                );

                if (foundItemIndex < 0) {
                  setAlertMessage({
                    message:
                      "Unable to redirect because no matching drug was found in stock",
                    colour: "warning",
                  });

                  return;
                }

                history.push("/pharmacy/stock/administer", {
                  nxt: pharmacyStock[foundItemIndex],
                });
              });
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              setTimeout(() => {
                setAlertMessage({
                  message: "",
                  colour: "",
                });
              }, 2000);
              setAlertMessage({
                message: getErrorMessage(error.response),
                colour: "danger",
              });
            }
          })
          .finally(() => {
            setResetInputs(true);
          });
      }
    }
  }

  const getConfirmInitialState = (pageStatus) => {
    if (pageStatus === "ReadyToRegister" && isWitnessed) {
      return confirmInitialState[pageStatus + "IsWitnessed"];
    }
    return confirmInitialState[pageStatus];
  };

  function confirm() {
    if (!disableConfirm) {
      setDisableConfirm(true);
      setConfirmedStaffErrors();

      if (isHasChanged) {
        completeOrder();
      } else {
        setAlertMessage({
          message:
            pageStatus === "Pharmacy"
              ? "Please enter a quantity supplied"
              : "You need to select an item in your list",
          colour: "warning",
        });
        setResetInputs(true);
      }
    }
  }

  function trimUsername(str) {
    return truncateString(str.split("@")[0], SIGNATURE_DISPLAY_MAX_LENGTH);
  }

  function handleSignButtonClick({ currentTarget }) {
    if (imprivataConfig.enabled && window.confirm_id_authenticate) {
      imprivataSign(currentTarget, confirmInputs, setconfirmInputs, showAlertMessage, dispatch);
    } else {
      msalSign(currentTarget);
    }
  }

  function msalSign(currentTarget) {

    instance
      .acquireTokenPopup({ prompt: "login" })
      .then((response) => {
        if (msalConfig.auth.autoLogOut && response.account.username !== user.username){
          instance.logoutPopup({ account: response.account});
        }

        if (
          confirmInputs
            .filter((item) => item.name !== currentTarget.name)
            .some((item) => item.value === response.account.username)
        ) {
          showAlertMessage(dispatch, {
            message: "Each signature must be a different user",
            colour: "warning",
          });
        } else {
          setconfirmInputs((signed) => [
            ...signed.filter((item) => item.name !== currentTarget.name),
            {
              name: currentTarget.name,
              value: response.account.username,
            },
          ]);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  function renderSignButtons() {
    const initialState = getConfirmInitialState(pageStatus);

    return (
      <div className="sign-buttons">
        {initialState.map((item, index) => {
          const signedItem = confirmInputs.find(
            (signedItem) => signedItem.name === item.name
          );

          return (
            <Row key={index}>
              <label>{item.label}:</label>
              <Button
                color={
                  signedItem?.error
                    ? "danger"
                    : signedItem
                      ? "success"
                      : "primary"
                }
                disabled={
                  (item.name === "collectedBy" && disableCollectedBy) ||
                  (item.name === "signedBy" &&
                    disableSignedBy &&
                    !confirmInputs.find((item) => item.name === "collectedBy")?.name)
                }
                name={item.name}
                onClick={handleSignButtonClick}
              >
                {signedItem ? trimUsername(signedItem.value) : "Sign"}
              </Button>
            </Row>
          );
        })}
      </div>
    );
  }

  function renderSignInputs() {
    return getConfirmInitialState(pageStatus).map((item, index) => (
      <SignInput
        id={index}
        inputInfo={showConfirmInputs}
        isChangeClass={resetInputs || dropDownError}
        isDisabled={
          isDisabled || (item.name === "collectedBy" && disableCollectedBy)
        }
        key={index}
        label={item.label}
        name={item.name}
        placeholder="Enter username"
        setInputs={setconfirmInputs}
        stId={item.stId}
        type="text"
        updateinputs={confirmInputs}
      />
    ));
  }

  return (
    <Row className="confirmation-block mt-4">
      <Col>
        <Row>
          <Col className="mb-4 mb-md-0" md="6">
            <h4>{blockTitle}</h4>
            <p>
              <strong>Date</strong> :{" "}
              {/* {orderArray.length} {pageStatus} {String(isWitnessed)} */}
              <Moment tz="Europe/London" format="dddd Do MMMM YYYY"></Moment>
            </p>
            <p>
              <strong>Timestamp</strong> :{" "}
              <Moment tz="Europe/London" format="h:mm:ss a"></Moment>
            </p>
            {approved["message"] && (
              <CAlert color={approved["colour"]} closeButton>
                <span className="alert-icon_holder">
                  {approved["colour"] === "danger" ? (
                    <FontAwesomeIcon icon={faBullhorn} />
                  ) : (
                    <FontAwesomeIcon icon={faBell} />
                  )}
                </span>
                {approved["message"]}
              </CAlert>
            )}
          </Col>
          <Col md="6" className={authRequired ? undefined : col2StyleClass}>
            {pageStatus &&
              (authRequired ? renderSignButtons() : renderSignInputs())}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfirmBlockOrders;
