import React from "react";
import cx from "classnames";

function Label({ disabled, id, label, required }) {
  return (
    <label className={cx("form-label", { disabled, required })} htmlFor={id}>
      {label}:
    </label>
  );
}

export default Label;
