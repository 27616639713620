import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import QuantityInput from "./QuantityInput";

function StockTableLine({
  addStock,
  disabled,
  locationRoute,
  removeStock,
  stock,
  stockCheck,
  stockCheckComplete,
  showCategory,
  onDiscrepancyClick,
  showInLineDiscrepancy,
  hasLiquidDrug,
  user
}) {
  const [showValidity, setShowValidity] = useState(false);
  const [stockCheckValue, setStockCheckValue] = useState();
  const discrepancyModalEnabled = useSelector(
    (state) => state.settings.discrepancyModalEnabled
  );

  useEffect(() => {
    if (!stockCheck) {
      setStockCheckValue();
    }
  }, [stockCheck]);

  function handleQuantityInputBlur() {
    setShowValidity(true);
  }

  function handleQuantityValueChange(value) {
    if (isNaN(value)) {
      removeStock({
        stkId: stock.stkId,
        stkPhId: stock.stkPhId,
      });
    } else {
      addStock({
        cdApId: stock.cdCatalogue.id,
        cdVpId: stock.cdCatalogue.virtualId,
        catalogueId: stock.catalogueId,
        runningBalance: stock.runningBalance,
        stkId: stock.stkId,
        stkPhId: stock.stkPhId,
        stockCheckBalance: value,
        stockCheckValid: value === stock.runningBalance,
        stockCheckValue: value,
        bagReference: stock.bagReference
      });
    }

    setStockCheckValue(value);
  }

  return (
    <tr>
      {(user?.location?.isTheatre ||
        user?.location?.managePharmacyAsTheatre) && (
        <td className="alert-theatre">
          {stock.alertTheatreOutstandingActions && (
            <>
              <CTooltip content="Patient admin events require signing or witnessing">
                <FontAwesomeIcon
                  className="alert-theatre-icon"
                  icon={faTimesCircle}
                />
              </CTooltip>
            </>
          )}
        </td>
      )}
      <td>
        {disabled ? (
          stock.cdCatalogue.cdcatalogueNameOrFormularyName
        ) : (
          <Link
            className="table-link"
            to={{
              pathname: `/${locationRoute}/${
                stock.isPatientOwn ? "patient/" : ""
              }stock/administer`,
              state: { nxt: stock },
            }}
          >
            {stock.cdCatalogue.cdcatalogueNameOrFormularyName}
          </Link>
        )}
      </td>
      {stock.isPatientOwn && (
        <td>{stock.patient && stock.patient.patientName}</td>
      )}
      {stock.isPatientOwn && <td>{stock.bagReference}</td>}
      {showCategory && <td>{stock.cdCatalogue.schedule}</td>}
      <td>{stock.cdCatalogue.cdCataloguePackSize[0]?.uom}</td>
      {(!stockCheck || stockCheckComplete) && (
        <td>
          <Input disabled value={stock.runningBalance} />
        </td>
      )}
      {stockCheck && hasLiquidDrug && (
        <td>
          {stock.cdCatalogue.cdCataloguePackSize[0]?.uom === "ml" &&
            `${stock.runningBalance} ${stock.cdCatalogue.cdCataloguePackSize[0]?.uom}`}
        </td>
      )}
      {stockCheck && (
        <td>
          <QuantityInput
            alignCenter
            allowNaN
            disabled={stockCheckComplete}
            onBlur={handleQuantityInputBlur}
            onValueChange={handleQuantityValueChange}
            placeholder={undefined}
            unitsValue={stock.cdCatalogue.cdCataloguePackSize[0]?.uom}
          />
        </td>
      )}
      {stockCheck && (
        <td className="icons">
          {showValidity &&
            !isNaN(stockCheckValue) &&
            stockCheckValue !== stock.runningBalance &&
            (discrepancyModalEnabled ? (
              <CTooltip content="Discrepancy exists, click to amend balance">
                <Button
                  className="status-btn"
                  color="transparent"
                  onClick={onDiscrepancyClick}
                >
                  <span className="status-icon" role="alert">
                    <FontAwesomeIcon icon={faExclamation} />
                  </span>
                </Button>
              </CTooltip>
            ) : (
              <CTooltip content="Discrepancy exists">
                <span className="status-icon" role="alert">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
              </CTooltip>
            ))}
        </td>
      )}
      {showInLineDiscrepancy && (
        <td className="icons">
          <CTooltip content="Click to record a discrepancy or incident">
            <Button
              className="status-btn"
              color="blue"
              onClick={onDiscrepancyClick}
            >
              <span className="status-icon" role="alert">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
            </Button>
          </CTooltip>
        </td>
      )}
    </tr>
  );
}

export default React.memo(StockTableLine);
