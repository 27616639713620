import React, { useEffect, useState, useContext } from "react";
import { CSwitch, CTooltip, CButton } from "@coreui/react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faUserAlt,
  faUserPlus,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import QuantityInput from "./QuantityInput";
import { getPatientFullNameAndId } from "../../helpers";
import { useSelector } from "react-redux";
import SystemContext from "../../context/SystemContext";

const InTransit = "InTransit";
const RejectedByWard = "RejectedByWard";
const RejectedByPharmacy = "RejectedByPharmacy";

const OrderTableLine = ({
  isPharmacy,
  nxt,
  locationRoute,
  status,
  currentView,
  currentStatus,
  updateRows,
  deleteRow,
  ordersFilter,
  setAlertMessage,
  actionRow,
  isSwitchChecked,
  disabledSwitch,
  currentFilter,
  showLocations
}) => {
  const [filterClass, setfilterClass] = useState([]);
  const [trClass, settrClass] = useState("shadow-sm");
  const [localNXT, setlocalNXT] = useState(nxt);
  const [singleLink, setsingleLink] = useState();
  const [chosenRow, setchosenRow] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const { siteId } = useContext(SystemContext).user.location;

  const history = useHistory();
  const Pharmacy = "pharmacy";
  const NewOrders = "New Orders";
    const Ready = "Ready";

  if (isPharmacy === undefined){
    isPharmacy = (locationRoute === Pharmacy);
  }
  const location = useLocation();
  const clinicalCheckCompleteRegex = useSelector(
    (state) => new RegExp(state.settings.clinicalCheckCompleteRegex)
  );

  useEffect(() => {
    if (isInvalid) {
      setAlertMessage({
        message: "Not enough stock in balance to supply quantity",
        colour: "warning",
      });
    }
  }, [isInvalid]);

  useEffect(() => {
    setsingleLink("/" + locationRoute + "/stock/orders/single");
    return () => {};
  }, [locationRoute]);

  useEffect(() => {
    if (status){
      settrClass(
        (currentView !== "single" ? "shadow-sm " : "") +
        "order-" +
        status["colour"].toLowerCase()
      );
    }
    return () => {};
  }, [status]);

  useEffect(() => {
    if (!nxt) setlocalNXT(nxt);

    if (nxt["isUrgent"] === true)
      setfilterClass((filterClass) => [...filterClass, "filter-urgent"]);

    if (nxt["isPatient"] === true) {
      if (nxt["location"]["isPharmacy"] === true || localNXT.prescribedLocation )
        setfilterClass((filterClass) => [
          ...filterClass,
          "filter-prescription",
        ]);
      else setfilterClass((filterClass) => [...filterClass, "filter-patient"]);
    } else {
      setfilterClass((filterClass) => [...filterClass, "filter-notpatient"]);
    }

    if (nxt["orderComments"].length > 0)
      setfilterClass((filterClass) => [...filterClass, "filter-messages"]);

    return () => {};
  }, [nxt]);

  useEffect(() => {
    if (!localNXT.isValid && localNXT.isValid !== undefined) {
      settrClass(
        (currentView !== "single" ? "shadow-sm " : "") + "order-status-red"
      );
    } else {
      if (status){
        settrClass(
          (currentView !== "single" ? "shadow-sm " : "") +
          "order-" +
          status["colour"].toLowerCase()
        );
      }
    }
  }, [localNXT.isValid]);

  useEffect(() => {
    setchosenRow(isSwitchChecked);
  }, [isSwitchChecked]);

  const qtyUpdate = (quantitySupplied) => {
    if (quantitySupplied === 0) {
      setIsInvalid(false);
      deleteRow(localNXT["odId"]);
    } else {
      setIsInvalid(quantitySupplied > nxt["pharmacyStockRunningBalance"]);
      updateRows({
        odId: localNXT["odId"],
        lcId: localNXT["lcId"],
        siteId: localNXT["siteId"],
        cdApId: nxt["cdApId"],
        cdVpId: localNXT["cdVpId"],
        catalogueId: localNXT["catalogueId"],
        stId: status["next"],
        qtySupplied: quantitySupplied,
        isWitnessed: localNXT["cdCatalogue"]["isWitnessed"],
        reference: localNXT["reference"],
        isInvalid: quantitySupplied > nxt["pharmacyStockRunningBalance"],
        patient: localNXT["patient"],
        isPatient: localNXT["isPatient"],
        ptId: localNXT.patient? localNXT.patient.ptId : null,

      });
    }
  };

  function getPatientTooltipContent() {
    const patientStr =
      `${localNXT.patient.firstname} ${localNXT.patient.lastname}` +
      `${localNXT.patient.address ? `, ${localNXT.patient.address}` : ""}`;
    if (localNXT.orderComments.length > 0 && !localNXT.location.isPharmacy){
      if (localNXT.orderComments.filter((x) => clinicalCheckCompleteRegex.test(x.text)).length > 0 ) {
        const textStr = "Clinical check complete: ";
        return textStr + patientStr;
      } else {
        const textStr = "Named Patient Order for: ";
        return textStr + patientStr;
      }
    } else {
      const textStr = localNXT.location.isPharmacy || localNXT.prescribedLocation
        ? "Prescription for: "
        : isPharmacy && currentStatus.split("-")[0] === "Pharmacy"
          ? "Clinical Check Required: "
          : "Named Patient Order for: ";
      return textStr + patientStr;
    }
  }

  function getPatientIcon(){
    if (localNXT.orderComments.length > 0 && !localNXT.location.isPharmacy ){
      if (localNXT.orderComments.filter((x) => clinicalCheckCompleteRegex.test(x.text)).length > 0){
        return "check";
      }
    }
    return localNXT.location.isPharmacy || localNXT.prescribedLocation ? "out" : "on";
  }

  const formatLocation = (r) => {
    if (r.prescribedLocation) {
      const { wardInfo, isOnline } = r.prescribedLocation;
      return `${wardInfo}${isOnline ? "" : " (Offline)"}`;
    } else if (r.pharmacyLocationFrom) {
      const {
        name,
        site: { shortName },
      } = r.pharmacyLocationFrom;
      return `${name} (${shortName})`;
    } else {
      const { wardInfo, isOnline, site } = r.location;
      return `${wardInfo}${
        site ?
        (siteId !== r.location.siteId ? ` -- ${site.shortName}` : "" ):""
      } ${isOnline ? "" : " (Offline)"}`;
    }
  };

  if (localNXT) {
    return (
      <tr
        className={`${trClass} ${
          filterClass.length > 1 ? filterClass.join(" ") : filterClass
        }`}
        id={localNXT["catalogueId"]}
      >
        <td>
          <div
            className={`d-flex ${
              currentView !== "single" && "align-items-center"
            } justify-content-between`}
          >
            {currentView === "single" ? (
              <div className="d-flex align-items-start flex-column order-drug">
                <span className="order-drug_name">
                  {localNXT["cdCatalogue"]["cdcatalogueNameOrFormularyName"]}
                </span>
                {localNXT["isPatient"] ? (
                  <span className="order-drug_patient">
                    <FontAwesomeIcon
                      className="mr-2"
                      color="primary"
                      icon={faUserAlt}
                    />
                    {localNXT["patient"] ? (
                      <strong title={getPatientFullNameAndId(localNXT.patient)}>
                        {localNXT.patient.patientName}
                      </strong>
                    ) : null}
                  </span>
                ) : null}
                <div className="mt-4 order-drug_details">
                  <span>{`Dosage form: ${localNXT["cdCatalogue"]["cdCataloguePackSize"][0]["uom"]}`}</span>
                  <span>{`Category: ${localNXT["cdCatalogue"]["schedule"]}`}</span>
                  <span className="mt-4 mb-2">
                    Pack size:{" "}
                    {localNXT["cdCatalogue"]["cdCataloguePackSize"].map(
                      (dg, dx) => (
                        <span key={dx} className="order-drug_pack">
                          {dg["quantity"]}
                        </span>
                      )
                    )}
                  </span>
                  <span>
                    <strong>Signed by : </strong>
                    {localNXT["orderStatuses"][localNXT["orderStatuses"].length -1]["signedBy"]}
                  </span>
                  <span>
                    <strong>Date : </strong>
                    <Moment
                      format="dddd Do MMM YYYY, HH:mm"
                      date={localNXT["createdAt"]}
                    />
                  </span>
                  <span>
                    <strong>Order reference : </strong>
                    {localNXT["reference"]}
                  </span>
                </div>
              </div>
            ) : (
              currentStatus.split("-")[0] === "ReadyToRegister" && localNXT.prescribedLocation ?
                <span className="order-drug_name">
                  {localNXT["cdCatalogue"]["cdcatalogueNameOrFormularyName"]}
                </span>
                :
                <Link
                  className="order-drug_name"
                  to={{
                    pathname: singleLink,
                    state: {
                      nxt: nxt,
                      ordersFilter: ordersFilter,
                      currentView: currentView,
                      currentStatus: currentStatus,
                      locationPath: location.pathname,
                      locationHash: location.hash,
                      currentFilter: currentFilter,
                    },
                  }}
                >
                  {localNXT["cdCatalogue"]["cdcatalogueNameOrFormularyName"]}
                </Link>
            )}
            <div className="d-flex justify-content-between position-relative">
              {localNXT.isUrgent ? (
                <CTooltip content="Urgent Order">
                  <span className="status-icon status-on ml-3">
                    <FontAwesomeIcon icon={faExclamation} />
                  </span>
                </CTooltip>
              ) : (
                <span className="status-icon status-off ml-3">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
              )}
              {localNXT.isPatient ? (
                getPatientTooltipContent() ? (
                  <CTooltip content={getPatientTooltipContent()}>
                    <span
                      className={`status-icon ml-3 status-${getPatientIcon()}`}
                    >
                      <FontAwesomeIcon icon={faUserPlus} />
                    </span>
                  </CTooltip>
                ) : (
                  <span
                    className={`status-icon ml-3 status-${getPatientIcon()}`}
                  >
                    <FontAwesomeIcon icon={faUserPlus} />
                  </span>
                )
              ) : (
                <span className="status-icon status-off ml-3">
                  <FontAwesomeIcon icon={faUserPlus} />
                </span>
              )}
              {localNXT.orderComments.length > 0 ? (
                <CTooltip content="Messages Exist">
                  <span className="status-icon status-on ml-3">
                    <FontAwesomeIcon icon={faCommentAlt} />
                  </span>
                </CTooltip>
              ) : (
                <span className="status-icon status-off ml-3">
                  <FontAwesomeIcon icon={faCommentAlt} />
                </span>
              )}
            </div>
          </div>
        </td>
        {!isPharmacy && showLocations && (
          <td>
            <span>{formatLocation(localNXT)}</span>
          </td>
        )}
        <td>
          {/* Order reference / Location */}
          {isPharmacy ? (
            <span className="order-drug_loc">{formatLocation(localNXT)}</span>
          ) : (
<>
              <span className="order-drug_ref">{localNXT["reference"]}</span>
            </> 
          )}
        </td>
    {isPharmacy && (
          <td>
            <Moment format="Do MMM YYYY, HH:mm " date={localNXT["createdAt"]} />
          </td>
        )}
        {isPharmacy &&
          currentStatus.split("-")[0] !== InTransit &&
          currentStatus.split("-")[0] !== RejectedByWard &&
          currentStatus.split("-")[0] !== RejectedByPharmacy && (
            <td>
              {/* Running Balance  */}
              {/* Only show balance on new orders in pharmacy  */}
              {status && status["row"] === NewOrders ? (
                <span>{localNXT["pharmacyStockRunningBalance"]}</span>
              ) : (
                <span></span>
              )}
            </td>
          )}
        <td>
          {/* Quantity requested  */}
          <span className="order-number action-in">
            {localNXT["qtyRequested"]}
          </span>
        </td>
        <td>
          {/* Quatity supplied */}
          {localNXT["qtySupplied"] ? (
            <span className="order-number action-out">
              {localNXT["qtySupplied"]}
            </span>
          ) : isPharmacy && currentStatus.split("-")[0] === "Pharmacy" ? (
            <QuantityInput
              alignCenter
              name="quantity-supplied"
              placeholder={0}
              onValueChange={qtyUpdate}
              unitsValue={localNXT.cdCatalogue.cdCataloguePackSize[0]?.uom}
              disabled={!localNXT["pharmacyStockRunningBalance"]}
              invalidate={isInvalid}
            />
          ) : null}
        </td>
        <td>
          {/* Order status || select item */}
          {currentView === "single" ? (
            <span className="order-status">{status["row"]}</span>
          ) : isPharmacy ? (
            currentStatus.split("-")[0] === "ReadyForCollection" ? (
              !disabledSwitch && (
                <CSwitch
                  variant="3d"
                  value={1}
                  shape="pill"
                  onChange={() => {
                    actionRow(!chosenRow, localNXT, nxt, status, setchosenRow);
                  }}
                  checked={chosenRow}
                />
              )
            ) : (
              <span className="order-status">{status && status["row"]}</span>
            )
          ) : currentStatus.split("-")[0] === "ReadyToRegister" &&
            localNXT.prescribedLocation ? (
              <CButton
                variant="outline"
                shape="pill"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/" + locationRoute + "/patient/stock/add", {
                    nxt: nxt,
                  });
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </CButton>
            ) : currentStatus.split("-")[1] === "true" &&
            currentStatus.split("-")[0] !== "RejectedByWard" &&
            currentStatus.split("-")[0] !== "RejectedByPharmacy" &&
            currentStatus.split("-")[0] !== "Pharmacy" &&
            currentStatus.split("-")[0] !== "ReadyForCollection" &&
            currentStatus.split("-")[0] !== "InPharmacy" ? (
            <CSwitch
              variant="3d"
              value={1}
              shape="pill"
              onChange={() => {
                actionRow(!chosenRow, localNXT, nxt, status, setchosenRow);
              }}
              checked={chosenRow}
            />
          ) : currentStatus.split("-")[0] === "Pharmacy" ? (
            <span className="order-status">Pharmacy</span>
          ) : (
            <span className="order-status">{status["row"]}</span>
          )}
        </td>
      </tr>
    );
  }

  return (
    <tr
      className={`spacer ${
        filterClass.length > 1 ? filterClass.join(" ") : filterClass
      }`}
    >
      <td></td>
    </tr>
  );
};

export default OrderTableLine;
