import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import { Button, Col, Row } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import cx from "classnames";

import SystemContext from "../../context/SystemContext";
import { truncateString } from "../../helpers";
import SignInput from "../molecules/SignInput";
import { showAlertMessage } from "../../store/actions";
import { imprivataConfig } from "../../config/imprivata";
import { imprivataSign } from "../../services/ImprivataService";

import { msalConfig } from "../../config/msal";

const msalRequired = process.env.REACT_APP_AUTH_REQUIRED;

function ConfirmBlock({
  className,
  confirmedStaffErrors,
  confirmInitialState,
  inputsConfirmed,
  isLoading,
  setConfirmedStaff,
  setInputsConfirmed,
  setValidate,
  title,
  disableWitness,
  disableSign,
  hideWitness,
}) {
  const authRequired = msalRequired || imprivataConfig.enabled;
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { user } = useContext(SystemContext);
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );

  const [clearSigned, setClearSigned] = useState(false);
  const [signed, setSigned] = useState([]);

  const SIGNED = "signed";
  const WITNESSED = "witnessed";

  useEffect(() => {
    if (clearSigned === true) {
      setClearSigned(false);

      if (!confirmedStaffErrors) {
        setSigned([]);
      }
    }
  }, [clearSigned]);

  useEffect(() => {
    if (confirmedStaffErrors) {
      showAlertMessage(dispatch, {
        message: `A user has insufficient permissions to perform this action,
          if required please contact your IT help-desk to change`,
        colour: "danger",
      });
      setSigned((signed) => {
        if (signed.find((item) => item.value === confirmedStaffErrors)) {
          return signed.map((item) => {
            if (item.value !== confirmedStaffErrors) {
              return item;
            }
          }).filter((item) => item !== undefined );
        } else {
          return signed.map((item) => ({ ...item, error: true }));
        }
      });
    }
  }, [confirmedStaffErrors]);

  useEffect(() => {
    if (inputsConfirmed === false) {
      setClearSigned(true);
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    const checkConfirm = () => {
      if ((signed.length === getConfirmInitialState().length &&
        (!disableWitness || !disableSign)) ||
        (signed.length === getConfirmInitialState().length - 1 &&
          (disableWitness || disableSign))) {
        confirm();
      }
    };

    if (!authRequired) {
      if (
        signed.some(
          (item1) =>
            signed.filter((item2) => item2.value === item1.value).length > 1
        )
      ) {
        showAlertMessage(dispatch, {
          message: "Each signature must be a different user",
          colour: "warning",
        });
        setClearSigned(true);
      } else checkConfirm();
    } else {
      if (signed.length > 0 ) {
        checkConfirm();
      }
    }
  }, [signed]);

  function confirm() {
    setConfirmedStaff(
      signed.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {})
    );
    setInputsConfirmed(true);

    if (setValidate) {
      setValidate(true);
    }
  }

  function trimUsername(str) {
    return truncateString(str.split("@")[0], SIGNATURE_DISPLAY_MAX_LENGTH);
  }

  function handleSignButtonClick({ currentTarget }) {
    if (imprivataConfig.enabled && window.confirm_id_authenticate) {
      imprivataSign(currentTarget, signed, setSigned, showAlertMessage, dispatch);
    } else {
      msalSign(currentTarget);
    }
  }

  function msalSign(currentTarget) {
    instance
      .acquireTokenPopup({ prompt: "login" })
      .then((response) => {
        if (msalConfig.auth.autoLogOut && response.account.username !== user.username){
          instance.logoutPopup({ account: response.account});
        }
        if (
          signed
            .filter((item) => item.name !== currentTarget.name)
            .some((item) => item.value === response.account.username)
        ) {
          showAlertMessage(dispatch, {
            message: "Each signature must be a different user",
            colour: "warning",
          });
        } else {
          setSigned((signed) => [
            ...signed.filter((item) => item.name !== currentTarget.name),
            {
              name: currentTarget.name,
              value: response.account.username,
            },
          ]);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  const getConfirmInitialState = () => {
    if (hideWitness) {
      return confirmInitialState.filter((x) => x.name !== WITNESSED);
    }
    return confirmInitialState;
  };

  function renderSignButtons() {
    const initialState = getConfirmInitialState();
    return (
      <div className="sign-buttons">
        {initialState.map((item, index) => {
          const signedItem = signed.find(
            (signedItem) => signedItem.name === item.name
          );

          return (
            <Row key={index}>
              <label>{item.label}:</label>
              <Button
                color={
                  signedItem?.error
                    ? "danger"
                    : signedItem
                      ? "success"
                      : "primary"
                }
                disabled={
                  isLoading ||
                  (disableWitness && item.name === WITNESSED) ||
                  (disableSign && item.name === SIGNED)
                }
                name={item.name}
                onClick={handleSignButtonClick}
              >
                {signedItem ? trimUsername(signedItem.value) : "Sign"}
              </Button>
            </Row>
          );
        })}
      </div>
    );
  }

  function renderSignInputs() {
    return getConfirmInitialState().map((item, index) => {
      const isDisabled =
        isLoading ||
        (disableWitness && item.name === WITNESSED) ||
        (disableSign && item.name === SIGNED);

      return (
        <SignInput
          id={index}
          isChangeClass={clearSigned}
          key={index}
          label={item.label}
          name={item.name}
          disabledModeValue={item.value}
          placeholder="Enter username"
          setInputs={setSigned}
          type="text"
          updateinputs={signed}
          isDisabled={isDisabled}
        />
      );
    });
  }

  return (
    <Row className={cx("confirmation-block", className)}>
      <Col className="mb-4 mb-md-0" md="6">
        <Row className="d-block">
          {title && <h4>{title}</h4>}
          <p>
            <strong>Date:</strong>{" "}
            <Moment tz="Europe/London" format="dddd Do MMMM YYYY" />
          </p>
          <p>
            <strong>Timestamp:</strong>{" "}
            <Moment tz="Europe/London" format="h:mm:ss a" />
          </p>
        </Row>
      </Col>
      <Col md="6">
        {authRequired ? renderSignButtons() : renderSignInputs()}
      </Col>
    </Row>
  );
}

export default ConfirmBlock;
