import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import settings from "../config/settings";

function Settings({ locationRoute }) {
  const [reportLinks, setReportLinks] = useState([]);

  useEffect(() => {
    setReportLinks(settings[locationRoute]);
  }, []);

  return (
    <Col className="Reports">
      <Row>
        <Col>
          <Row className="mb-3">
            <h1>Settings</h1>
          </Row>
        </Col>
      </Row>
      <Row>
        <nav className="report-links-container">
          <ul className="report-links">
            {reportLinks.map((link) => (
              <li key={link.name}>
                <Link className="report-link" to={link.path}>
                  <span>{link.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </Row>
    </Col>
  );
}

export default Settings;