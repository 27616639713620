import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import ASL from "../../config/_ASL";
import SystemContext from "../../context/SystemContext";
import { getDrugId } from "../../helpers";
import StockService from "../../services/StockService";

import StockTableRow from "../../component/molecules/Pharmacy/StockTableRow";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";

let serviceCancelSource;
let messageTimeoutId;

function AddStock() {
  const history = useHistory();
  const disableWitnessFieldAddStock = useSelector(
    (state) => state.settings.disableWitnessFieldAddStock
  );
  const authRequired = disableWitnessFieldAddStock;

  const messageState = {
    message: "",
    colour: "",
  };

  const { user, locationId, locationRoute } = useContext(SystemContext);

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [tableRows, setTableRows] = useState([]);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const siteId = user["location"]["siteId"];

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(messageTimeoutId);
    };
  }, []);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (validateFields()) {
        createStock();
      } else {
        setInputsConfirmed(false);
      }
    }
  }, [inputsConfirmed]);

  function addTableRow(drug) {
    if (
      tableRows.some((tableRow) => getDrugId(tableRow.drug) === getDrugId(drug))
    ) {
      setAlertMessage({
        message: "You have already added that drug",
        colour: "warning",
      });
    } else if(tableRows.length>0){
      setAlertMessage({
        message: "Only one drug can be added at a time",
        colour: "warning",
      });
    } 
    else {
      setTableRows((tableRows) => [
        ...tableRows,
        { drug, quantity: 0, showQuantityAlert: false },
      ]);
    }
  }

  function removeTableRow(stockItem) {
    setTableRows((tableRows) => {
      return tableRows.filter(
        (tableRow) => getDrugId(tableRow.drug) !== getDrugId(stockItem.drug)
      );
    });
  }

  function updateTableRowValue(stockItem, updateObj) {
    setTableRows((tableRows) =>
      tableRows.map((tableRow) => {
        if (getDrugId(tableRow.drug) === getDrugId(stockItem.drug)) {
          tableRow = { ...tableRow, ...updateObj };
        }
        return tableRow;
      })
    );
  }

  function setQuantity(stockItem, quantity) {
    updateTableRowValue(stockItem, { quantity, showQuantityAlert: false });
  }

  function setSupplierName(stockItem, supplierName) {
    updateTableRowValue(stockItem, { supplierName, showNameAlert: false });
  }

  function setSupplierAddress(stockItem, supplierAddress) {
    updateTableRowValue(stockItem, {
      supplierAddress,
      showAddressAlert: false,
    });
  }

  function validateFields() {
    let isValid = true;

    if (!tableRows.length) {
      isValid = false;

      setAlertMessage({
        message: "Please select a drug",
        colour: "warning",
      });
    } else {
      const invalidQuantityCount = tableRows.filter(
        (tableRow) => !tableRow.quantity
      ).length;
      const invalidSupplierName =
        tableRows.filter((tableRow) => tableRow.supplierName).length <= 0;
      const invalidSupplierAddress =
        tableRows.filter((tableRow) => tableRow.supplierAddress).length <= 0;

      if (
        invalidQuantityCount ||
        invalidSupplierName ||
        invalidSupplierAddress
      ) {
        isValid = false;
        const messageConstructorQuantity = invalidQuantityCount
          ? "required quantit" + (invalidQuantityCount > 1 ? "ies" : "y")
          : "";
        const messageContructorName = invalidSupplierName
          ? "Supplier Name"
          : "";
        const messageContructorAddress = invalidSupplierAddress
          ? "Supplier address"
          : "";

        // Builds the alert string depending on how many items are needed in the string (adding punctuation as required depending on item count)
        const buildAlertString =
          messageConstructorQuantity +
          (messageConstructorQuantity.length > 0 && invalidSupplierName
            ? messageContructorAddress.length > 0
              ? ", "
              : " and "
            : "") +
          messageContructorName +
          ((messageContructorName.length > 0 || invalidQuantityCount) &&
          invalidSupplierAddress
            ? " and "
            : "") +
          messageContructorAddress;

        setAlertMessage({
          message: `Please enter the ${buildAlertString}`,
          colour: "warning",
        });

        setTableRows((tableRows) =>
          tableRows.map((tableRow) => ({
            ...tableRow,
            showQuantityAlert: !tableRow.quantity,
            showNameAlert: !tableRow.supplierName,
            showAddressAlert: !tableRow.supplierAddress,
          }))
        );
      }
    }

    return isValid;
  }

  const isPharmacyRoute = () => locationRoute === "pharmacy";

  const createStockPromise = (lcId, stock, cancelToken) => 
    isPharmacyRoute() ? 
      StockService.createPharmacyStock(lcId, stock, cancelToken) :
      StockService.createWardStock(lcId, stock, cancelToken) 

  const getStockPromise = (locationId) => 
    isPharmacyRoute() ? 
      StockService.getPharmacyStock(locationId) :
      StockService.wardStock(locationId) 

  function createStock() {
    const stock = tableRows.map(
        ({ drug, quantity, supplierName, supplierAddress }) => ({
            CdApId: drug.cdCatalogue ? drug.cdCatalogue.id : drug.id,
            CdVpId: drug.cdCatalogue ? drug.cdCatalogue.virtualId : drug.virtualId,
        CatalogueId: drug.catalogueId,
        LcId: user.location.lcId,
        RunningBalance: quantity,
        SupplierName: supplierName,
        SupplierAddress: supplierAddress,
        CreatedBy: user.username,
        SignedBy: confirmedStaff.signed,
        WitnessedBy: confirmedStaff.witnessed,
        SiteId: siteId,
        stId: 1
      })
    );

    createStockPromise(user.location.lcId, stock, {
      cancelToken: serviceCancelSource.token,
    })
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: "The selected stock has been added",
            colour: "success",
            timeout: 5000,
          });
          setTableRows([]);

          const { CatalogueId, CdVpId, CdApId } = stock[0];
          getStockPromise(locationId).then((res) => {
            const stock = res.data;
            const foundItemIndex = stock.findIndex(
              (el) => el.catalogueId === CatalogueId
            );

            if (foundItemIndex < 0) {
              setAlertMessage({
                message:
                  "Unable to redirect because no matching drug was found in stock",
                colour: "warning",
              });
              setInputsConfirmed(false);

              return;
            }
            history.push(`/${locationRoute}/stock/administer`, {
              nxt: stock[foundItemIndex],
            });
          });
        } else {
          setAlertMessage({
            message: `There was a problem adding the selected stock, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
        setInputsConfirmed(false);
      })
      .catch((error) => {
        if (!StockService.isCancel(error)) {
          if (error.response?.status === 401) {
            setConfirmedStaffErrors(error.response.data);
          } else {
            setAlertMessage({
              message: error?.response?.data ||
              `There was a problem adding the selected stock, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }

          setInputsConfirmed(false);
        }
      });
  }
  return (
    <Col className="AddStock">
      <CAlert
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
        onClick={() => setAlertMessage(messageState)}
      >
        {alertMessage.message}
      </CAlert>
      <Row>
        <Col sm="6">
          <h1>Add new stock</h1>
        </Col>
        <Col
          sm="6"
          className="d-flex align-items-baseline justify-content-end flex-row"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon ml-3"
            to="/pharmacy/stock"
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MAIN SCREEN
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md="6" xl="4">
          <DrugSearchList
            searchListClass="search-list-fixed"
            setAlertMessage={setAlertMessage}
            setSelectedDrug={addTableRow}
            selectableSearch
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Drug Name / Strength / Form</th>
                <th>
                  <sup className="mandatory-label">*</sup>Supplier’s Name
                </th>
                <th>
                  <sup className="mandatory-label">*</sup>Supplier’s Address
                </th>
                <th>
                  <sup className="mandatory-label">*</sup>Quantity
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableRows.map((item) => (
                <StockTableRow
                  key={getDrugId(item.drug)}
                  remove={removeTableRow}
                  setQuantity={setQuantity}
                  setSupplierAddress={setSupplierAddress}
                  setSupplierName={setSupplierName}
                  stockItem={item}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ConfirmBlock
        confirmedStaffErrors={confirmedStaffErrors}
        confirmInitialState={ASL.confirmAdministerState}
        inputsConfirmed={inputsConfirmed}
        setInputsConfirmed={setInputsConfirmed}
        setConfirmedStaff={setConfirmedStaff}
        title="Received and registered into safe custody"
        hideWitness={authRequired}
      />
    </Col>
  );
}

export default React.memo(AddStock);
