import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import ProgressBar from "./ProgressBar";

const DashboardHalfPanel = ({
  url,
  quantity,
  urgent,
  text,
  colour,
  isLoading,
}) => {
  const progress = isLoading || urgent === 0 ? 0 : (urgent / quantity) * 100;

  return (
    <Col className="dash-item" md="12">
      <Link to={url}>
        <Row>
          <Col md="8" xl="9">
            <p className="dash-item-title">
              {quantity} {text}
            </p>
          </Col>
          <Col md="4" xl="3">
            <div className="dash-urgent">
              <div className="dash-urgent-percentage-half">
                <div className="dash-urgent-bumper">
                  {isLoading ? "…" : urgent}
                </div>
                <ProgressBar
                  className="ProgressBar-half"
                  progress={progress}
                  size={50}
                  strokeWidth={9}
                  circleTwoStroke={colour}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Link>
    </Col>
  );
};

export default DashboardHalfPanel;
