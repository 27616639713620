import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row, Table, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faReplyAll } from "@fortawesome/free-solid-svg-icons";

import PatientDropdown from "../../component/molecules/PatientDropdown";
import StockTableRow from "../../component/molecules/Pharmacy/StockTableRow";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";
import { initialStateSignedWitnessed } from "../../config/confirm";
import SystemContext from "../../context/SystemContext";
import { getDrugId } from "../../helpers";
import StockService from "../../services/StockService";
import { hideAlertMessage, showAlertMessage } from "../../store/actions";
import OrdersService from "../../services/OrdersService";

const mainPagePath = "/ward/patient/stock";
let serviceCancelSource;
let serviceConfig;

function AddPatientsOwnStock({ history, location: { state: { nxt: stock } = {} } = {} }) {
  const { user } = useContext(SystemContext);
  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [bagReference, setBagReference] = useState("");
  const [confirmStaff, setConfirmStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [patient, setPatient] = useState(null);
  const [patientSearchIsValid, setPatientSearchIsValid] = useState(true);
  const [patientSearchValue, setPatientSearchValue] = useState("");
  const [stockList, setStockList] = useState([]);
  const [onlyEditDataMode, setOnlyEditDataMode] = useState(false);

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };
    setOnlyEditDataMode(stock);
    return () => {
      serviceCancelSource.cancel();
    };


  }, []);

  useEffect(() => {
    if (onlyEditDataMode){
      setPatient(stock.patient);
      setStockList(() => [
        { drug: stock.cdCatalogue, quantity: stock.qtySupplied, showQuantityAlert: false }
      ]);
    }

  }, [onlyEditDataMode])

  useEffect(() => {
    if (alertMessage?.message) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    setPatientSearchIsValid(true);
  }, [patient]);

  useEffect(async () => {
    if (confirmed) {
      setConfirmedStaffErrors();
      if (validateFields()) {
        const ownStock = stockList.map((item) => ({
          odId: stock?.odId,
          CdApId: item.drug.id,
          CdVpId: item.drug.virtualId,
          CatalogueId: item.drug.catalogueId,
          CreatedBy: user.username,
          IsPatientOwn: true,
          LcId: user.location.lcId,
          PtId: patient.ptId,
          RunningBalance: item.quantity,
          SignedBy: confirmStaff.signed,
          SiteId: user.location.siteId,
          WitnessedBy: confirmStaff.witnessed,
          BagReference: bagReference,
          stId: 5,
          isPatient: true,
          isPharmacy: false
        }));
        let isCancelled = false;
        const patientOwnStockCall = () =>
          onlyEditDataMode
            ? OrdersService.updateOrder(ownStock, serviceConfig)
            : StockService.createPatientsOwnStock(ownStock, serviceConfig);

        try {
          const response = await patientOwnStockCall();

          if (response.status === 201) {
            setAlertMessage({
              message: "The selected drugs have been added",
              colour: "success",
            });

            const { CatalogueId } = ownStock[0];

            await StockService.getPatientsOwnStock(
              user.location.lcId,
              serviceConfig
            ).then((res) => {
              const patientsOwnStock = res.data;
              const foundItemIndex = patientsOwnStock.findIndex(
                (el) => el.catalogueId === CatalogueId
                        && el.patient.ptId === patient.ptId
                        && el.bagReference === bagReference
              );

              if (foundItemIndex < 0) {
                showAlertMessage({
                  message:
                    "Unable to redirect because no matching drug was found in stock",
                  colour: "warning",
                });

                return;
              }

              history.push("/ward/patient/stock/administer", {
                nxt: patientsOwnStock[foundItemIndex],
              });
            });
          } else {
            setAlertMessage({
              message: `There was a problem adding the selected drugs, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        } catch (error) {
          if (StockService.isCancel(error)) {
            isCancelled = true;
          } else {
            if (error.response?.status === 401) {
              setConfirmedStaffErrors(error.response.data);
            } else {
              const message =
                error.response?.status === 400 && error.response?.data
                  ? getErrorMessage(error.response.data, stockList)
                  : `There was a problem adding the selected drugs, the eCDR-Pro system may be offline.
                  If unable to resolve contact IT service desk.`;
              setAlertMessage({
                message: message,
                colour: "danger",
              });
            }
          }
        } finally {
          if (!isCancelled) {
            setConfirmed(false);
          }
        }
      } else {
        setConfirmed(false);
      }
    }
  }, [confirmed]);

  const getErrorMessage = (responseData, stockList) => {
    const [errorMessage] = Object.values(responseData)[0] || [];
    if (!errorMessage) return responseData;

    // Extract index of drug that failed the check from response key. (The key is in the format 'order[1]')
    const drugKeyString = Object.keys(responseData)[0];
    const drugIndex = +(drugKeyString.match(/\[(\d+)\]/)?.[1] || -1);
    if (drugIndex === -1) return errorMessage;

    const drugName =
      stockList[drugIndex].drug.drugName ??
      stockList[drugIndex].drug.cdCatalogue.drugName.toLowerCase();
    return errorMessage.replace("{%1}", drugName).replace("{%2}", bagReference);
  };

  function addStockItem(drug) {
    if (stockList.some((item) => getDrugId(item.drug) === getDrugId(drug))) {
      setAlertMessage({
        message: "You have already added that drug",
        colour: "warning",
      });
    } else {
      setStockList((stockList) => [
        ...stockList,
        { drug, quantity: 0, showQuantityAlert: false },
      ]);
    }
  }

  function removeStockItem(oldItem) {
    setStockList((stockList) =>
      stockList.filter(
        (item) => getDrugId(item.drug) !== getDrugId(oldItem.drug)
      )
    );
  }

  function setQuantity(stockItem, quantity) {
    setStockList((stockList) =>
      stockList.map((item) => {
        if (getDrugId(item.drug) === getDrugId(stockItem.drug)) {
          item = { ...item, quantity, showQuantityAlert: false };
        }
        return item;
      })
    );
  }
  function updateBagReference(e) {
    setBagReference(e.target.value);
  }

  function validateFields() {
    if (!patient) {
      setPatientSearchIsValid(false);
      setAlertMessage({
        message: "Please choose a patient",
        colour: "warning",
      });

      return false;
    }

    const invalidQuantityCount = stockList.filter(
      (item) => !item.quantity
    ).length;

    if (invalidQuantityCount) {
      setStockList((stockList) =>
        stockList.map((item) => ({
          ...item,
          showQuantityAlert: !item.quantity,
        }))
      );
      setAlertMessage({
        message: `Please enter the required quantit${
          invalidQuantityCount > 1 ? "ies" : "y"
        }`,
        colour: "warning",
      });

      return false;
    }

    return true;
  }

  function patientEditButtonClickHandler() {
    setPatientSearchValue(`${patient.firstname} ${patient.lastname}`);
    setPatient(null);
  }

  return (
    <Col className="AddPatientsOwnStock">
      <Row>
        <Col sm="6" md="8">
          <h1>{onlyEditDataMode? "Update":"Add"} Patient’s Own Controlled Drugs</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="6"
          md="4"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to={mainPagePath}
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MAIN SCREEN
          </Link>
        </Col>
      </Row>
      {!onlyEditDataMode && (
        <Row className="search-row">
          <Col className="patient-picker" sm="6" xl="4">
            <PatientDropdown
              allowCreatePatient
              hidden={Boolean(patient)}
              invalid={!patientSearchIsValid}
              isSearch
              patient={patientSearchValue}
              setPatient={setPatient}
            />
            {patient && (
              <p className="patient">
                {"Patient: "}
                <Button
                  color="transparent"
                  onClick={patientEditButtonClickHandler}
                >
                  {patient.patientName}
                  <FontAwesomeIcon className="on-right" icon={faEdit} />
                </Button>

              </p>
            )}
          </Col>
          <Col className="drug-search" sm="6" xl="4">
            <DrugSearchList
              searchListClass="search-list-fixed"
              setAlertMessage={setAlertMessage}
              setSelectedDrug={addStockItem}
              selectableSearch
            />
          </Col>
        </Row>
      )}
      { stockList.length > 0 && (
        <div>
          {onlyEditDataMode && (
            <Row className="search-row">
              <Col sm="2">
                <label className="patient">
                Patient:
                </label>
              </Col>
              <Col>
                <Input
                  className="bagReference-input"
                  type="text"
                  name="PatientName"
                  disabled="true"
                  value={patient.patientName}
                  maxLength="150"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="2">
              <label className="bagReference-label">
              Bag Reference:
              </label>
            </Col>
            <Col>
              <Input
                className="bagReference-input"
                type="text"
                name="BagReference"
                value={bagReference}
                onChange={updateBagReference}
                autoComplete="off"
                maxLength="150"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Table>
                <tbody>
                  {stockList.map((item) => (
                    <StockTableRow
                      key={getDrugId(item.drug)}
                      remove={removeStockItem}
                      setQuantity={setQuantity}
                      stockItem={item}
                      disabled={onlyEditDataMode}
                    />
                  ))}
                </tbody>
              </Table>
            </Col>

          </Row>


          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={initialStateSignedWitnessed}
            inputsConfirmed={confirmed}
            setConfirmedStaff={setConfirmStaff}
            setInputsConfirmed={setConfirmed}
            title="Add Drugs"
          />
        </div>
      )}
    </Col>

  );
}

export default AddPatientsOwnStock;
