import React from "react";

function Quantity({ units, value }) {
  return (
    <>
      {value}
      <span className="quantity-units" title={units}>
        {units}
      </span>
    </>
  );
}

export default Quantity;
