import React, { useEffect, useState } from "react";
import { Input, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

const StockManagementDropdown = ({
  options,
  setStockName,
  stockName,
  triggerStock,
  disabled,
  setClearDropDownOptions,
}) => {
  const [searchStocks, setSearchStocks] = useState(options);
  const [activeStocksList, setActiveStocksList] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [textValue, setTextValue] = useState(stockName);

  useEffect(() => {
    setTextValue(stockName);
  }, [stockName]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Check if should be dropdown or not
  useEffect(() => {
    if (searchStocks.length === 1 && stockName !== "Discrepancy/Incident") {
      setStockName({
        name: searchStocks[0].name,
      });
      if (typeof setClearDropDownOptions !== "undefined") {
        setClearDropDownOptions(false);
      }
    } else {
      if (typeof setClearDropDownOptions !== "undefined") {
        setClearDropDownOptions(true);
      }
    }
  }, []);

  const stocksLookUp = (e) => {
    if (!activeStocksList) setActiveStocksList(true);
    setTextValue(e.target.value);
    setSearchStocks(
      options.filter((item) =>
        item["name"].toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  if (searchStocks.length > 1) {
    return (
      <Dropdown
        className={cx("stocks-dropdown", { active: triggerStock, disabled })}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle
          tag="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          className="dropdown-input-block"
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          <Input
            readOnly
            aria-label="Select stock management"
            disabled={disabled}
            type="text"
            placeholder="Select"
            value={textValue}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            onChange={(e) => {
              e.preventDefault();
              stocksLookUp(e);
            }}
          />
        </DropdownToggle>
        <DropdownMenu className="shadow">
          <ul aria-label="Stock management options">
            {searchStocks.map((pat, pdx) => (
              <li
                key={pdx}
                onClick={() => {
                  toggle();
                  setTextValue(pat["name"]);
                  setStockName({
                    name: pat["name"],
                  });
                }}
              >
                {pat["name"]}
              </li>
            ))}
          </ul>
        </DropdownMenu>
      </Dropdown>
    );
  } else {
    return stockName;
  }
};

export default StockManagementDropdown;
