import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Location from "../../component/molecules/Pharmacy/Location";
import { CAlert } from "@coreui/react";
import SearchInput from "../../component/molecules/SearchInput";

import LocationsService from "../../services/LocationsService";

let messageTimeoutId;

const ApprovedLocations = ({ user }) => {
  const [alertMessage, setAlertMessage] = useState({});
  const [locations, setlocations] = useState([]);
  const [searchResults, setsearchResults] = useState([]);
  const [searchValue, setsearchValue] = useState("");

  useEffect(() => {
    LocationsService.getLocations(user["location"]["siteId"])
      .then((response) => {
        setlocations(response.data);
        setsearchResults(response.data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  }, []);

  useEffect(() => {
    searchValue
      ? setsearchResults(
          locations.filter((item) =>
            item["name"].toLowerCase().includes(searchValue.toLowerCase())
          )
        )
      : setsearchResults(locations);
  }, [searchValue]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage({}),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="ApprovedLocations">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
          <h1>Approved Stock Lists</h1>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 3 }}>
          <SearchInput
            name="location search"
            type="text"
            placeholder="Ward search"
            value={setsearchValue}
          />
        </Col>
      </Row>
      <Row className="location-list">
        {searchResults.map((loc, ldx) => (
          <Col md="3" key={ldx}>
            <Location
              key={ldx}
              name={loc.name + (loc.isOnline ? "" : " (Offline)") }
              isOnline={loc.isOnline}
              url={`/pharmacy/locations/approved/location/${loc.lcId}`}
              copyUrl={`/pharmacy/locations/clone/location/${loc.lcId}`}
            />
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default React.memo(ApprovedLocations);
