export const getOrdersRules = (mgId) => ({
  initialStatusView: {
    statusSet: [
      {
        stId: 1,
        status: "Pharmacy",
        name: "Pharmacy",
        text: "New Orders",
        row: "New Orders",
        confirmationBlock: "Order fulfilled",
        colour: "status-purple",
        next: 2,
      },
      {
        stId: 2,
        status: "ReadyForCollection",
        name: "ReadyForCollection",
        text: "Ready for collection",
        row: "Ready",
        Route: "Order fulfilled",
        confirmationBlock: "Order collected by",
        colour: "status-green",
        next: 3,
      },
      {
        stId: 3,
        status: "InTransit",
        name: "InTransit",
        button: "In Transit",
        row: "In Transit",
        text: "In transit",
        Route: "Order collected from pharmacy",
        confirmationBlock: "Order arrived on the ward",
        colour: "status-amber",
        next: 4,
      },
      {
        stId: 4,
        status: "ReadyToRegister",
        name: "ReadyToRegister",
        button: "Arrived",
        row: "Arrived",
        text: "To be registered",
        Route: "Order arrived on the ward",
        confirmationBlock: "Order is correct",
        colour: "status-green",
        next: 5,
      },
      {
        stId: 5,
        status: "Registered",
        name: "Registered",
        button: "Registered",
        row: "Registered",
        Route: "Order is correct and registered",
        confirmationBlock: "Order registered",
        text: "Registered",
        colour: "status-blue",
      },
      {
        stId: 6,
        status: "RejectedByWard",
        name: "RejectedByWard",
        button: "Rejected",
        row: "REJECTED",
        Route: "Ward Rejected order",
        text: "Ward Rejected order",
        colour: "status-black",
      },
      {
        stId: 7,
        status: "RejectedByPharmacy",
        name: "RejectedByPharmacy",
        button: "Rejected",
        row: "REJECTED",
        Route: "Pharmacy Rejected order",
        text: "Pharmacy Rejected order",
        colour: "status-black",
      },
      {
        stId: 8,
        status: "OutpatientCollected",
        name: "OutpatientCollected",
        button: "Collected",
        row: "COLLECTED",
        Route: "Outpatient collected order",
        text: "Outpatient collected order",
        colour: "status-light-purple",
      },
      {
        stId: 11,
        status: "Cancelled",
        name: "Cancelled",
        button: "Cancelled",
        row: "CANCELLED",
        Route: "Cancelled",
        text: "Cancelled",
        colour: "status-black",
      },
    ],
    ward: [
      {
        status: "All",
        name: "default",
        button: "All",
        state: false,
      },
      {
        stId: 1,
        status: "Pharmacy",
        name: "Pharmacy",
        button: "In Pharmacy",
        state: false,
      },
      {
        stId: 2,
        status: "ReadyForCollection",
        name: "ReadyForCollection",
        button: "Ready",
        state: false,
      },
      {
        stId: 3,
        status: "InTransit",
        name: "InTransit",
        button: "In Transit",
        state: false,
      },
      {
        stId: 4,
        status: "ReadyToRegister",
        name: "ReadyToRegister",
        button: "Arrived",
        state: false,
      },
      {
        stId: 7,
        status: "RejectedByPharmacy",
        name: "RejectedByPharmacy",
        button: "Rejected",
        state: false,
      },
    ],
    pharmacy: [
      {
        status: "All",
        name: "default",
        button: "All",
        state: false,
      },
      {
        stId: 1,
        status: "Pharmacy",
        name: "Pharmacy",
        button: "New Orders",
        state: false,
      },
      {
        stId: 2,
        status: "ReadyForCollection",
        name: "ReadyForCollection",
        button: "Ready",
        state: false,
      },
      {
        stId: 3,
        status: "InTransit",
        name: "InTransit",
        button: "In Transit",
        state: false,
      },
      {
        stId: 6,
        status: "RejectedByWard",
        name: "RejectedByWard",
        button: "Rejected",
        state: false,
      },
    ],
    pharmacyTransfer: [
      {
        status: "All",
        name: "default",
        button: "All",
        state: false,
      },
      {
        stId: 1,
        status: "InPharmacy",
        name: "InPharmacy",
        button: "In Pharmacy",
        state: false,
      },
      {
        stId: 2,
        status: "ReadyForCollection",
        name: "ReadyForCollection",
        button: "Ready",
        state: false,
      },
      {
        stId: 3,
        status: "InTransit",
        name: "InTransit",
        button: "In Transit",
        state: false,
      },
      {
        stId: 4,
        status: "ReadyToRegister",
        name: "ReadyToRegister",
        button: "Arrived",
        state: false,
      },
      {
        stId: 6,
        status: "Rejected",
        name: "Rejected",
        button: "Rejected",
        state: false,
      }
    ],
    return: [
      {
        stId: 1,
        name: "ForReuse",
        button: "For Reuse",
        label: "For Reuse",
        state: false,
        colour: "status-green",
        mgId: mgId.RETURN_TO_PHARMACY_FOR_REUSE,
        nextMgId: mgId.REGISTERED_FOR_REUSE,
        isDisposalIntent: false,
      },
      {
        stId: 2,
        name: "ForDisposal",
        button: "For Disposal",
        label: "For Disposal",
        state: false,
        colour: "status-black",
        mgId: mgId.RETURN_TO_PHARMACY_FOR_DISPOSAL,
        nextMgId: mgId.DISPOSE_DESTROY,
        isDisposalIntent: true
      },
      {
        stId: 3,
        name: "DisposedDestroyed",
        label: "Disposal",
        colour: "status-amber",
        mgId: mgId.DISPOSE_DESTROY,
      },
      {
        stId: 4,
        name: "DisposedDestroyed",
        label: "Disposed",
        colour: "status-green",
        mgId: mgId.DISPOSED,
      },
    ],
  },
  confirmInitialState: {
    Pharmacy: [
      {
        stId: 2,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    ReadyForCollection: [
      {
        stId: 3,
        label: "Collected by",
        name: "collectedBy",
        value: "",
        state: false,
      },
      {
        stId: 3,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    InTransit: [
      {
        stId: 4,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    ReadyToRegister: [
      {
        stId: 5,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    ReadyToRegisterIsWitnessed: [
      {
        stId: 5,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
      {
        stId: 5,
        label: "Witnessed by",
        name: "witnessedBy",
        value: "",
        state: false,
      },
    ],
    ForAction: [
      {
        stId: 5,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    DisposedDestroyed: [
      {
        stId: 5,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
    CreateOrder: [
      {
        stId: 5,
        label: "Signed by",
        name: "signedBy",
        value: "",
        state: false,
      },
    ],
  },
  confirmInitialStateForReturn: {
    ForReuse: [
      {
        label: "Signed by",
        name: "signedBy",
      },
      {
        label: "Witnessed by",
        name: "witnessedBy",
      },
    ],
    ForDisposal: [
      {
        label: "Signed by",
        name: "signedBy",
      },
      {
        label: "Witnessed by",
        name: "witnessedBy",
      },
    ],
    DisposedDestroyed: [
      {
        label: "Signed by",
        name: "signedBy",
      },
      {
        label: "Witnessed by",
        name: "witnessedBy",
      },
    ],
  },
});


export default getOrdersRules;

// 1   Pharmacy                when a new order is created by ward
// 2   ReadyForCollection      Pharmacy prepared the order, then status will be set to ReadyForCollection
// 3   InTransit               Once the order is collected and signed by pharmacy, status will be set to InTransit
// 4   ReadyToRegister         The order is collected by ward, set the order status to ReadyToRegister
// 5   Registered              once the order has been secured in cupboard, set the status to Registered
// 6   RejectedByWard          order is rejected by ward somehow.
// 7   RejectedByPharmacy      order is rejected by ward somehow.
// 11  PrescriptionCancelled   Prescription Cancelled somehow.
