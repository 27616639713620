import React, { useState, useEffect } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputGroup, Input } from "reactstrap";

const SearchInput = ({
  disabled,
  disableButton,
  displayValue,
  inputClass,
  inputRef,
  onChange,
  value,
  clear,
  ...inputProps
}) => {
  const [searchValue, setsearchValue] = useState("");

  const searchTerm = (e) => {
    value(e.target.value);
    setsearchValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (clear) setsearchValue("");

    return () => {};
  }, [clear]);

  useEffect(() => {
    if (displayValue !== undefined) {
      setsearchValue(displayValue);
    }
  }, [displayValue]);

  return (
    <>
      <div className={`${inputClass} ${disabled ? "disabled" : ""}`}>
        <InputGroup>
          <Input
            className="search-input shadow-sm"
            innerRef={inputRef}
            value={searchValue}
            onChange={searchTerm}
            disabled={disabled}
            autoComplete="off"
            {...inputProps}
          />
          <Button className="search-button" disabled={disableButton}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

export default SearchInput;
