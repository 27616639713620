import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBullhorn,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import ASLDrugSearchList from "../../component/organisms/ASLDrugSearchList";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import CreateOrderPadLine from "../../component/molecules/CreateOrderPadLine";

import { getOrdersRules } from "../../config/_ordersrules";
import OrdersService from "../../services/OrdersService";

let messageTimeoutId;
let redirectTimeoutId;

const CreateOrder = ({ history, isPharmacy, locationId, user }) => {
  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [disable, setDisable] = useState(false);
  const [ward, setWard] = useState(user["location"]["lcId"]);
  const [site, setSite] = useState(user["location"]["siteId"]);
  const [username, setUsername] = useState(user.username);
  const [order, setOrder] = useState([]);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  useEffect(() => {
    return () => {
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (!username) setUsername(user.username);

    if (!ward) setWard(user["location"]["lcId"]);

    if (!site) setSite(user["location"]["siteId"]);

    return () => {};
  }, [locationId]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();
      if (!disable) {
        if (
          order.filter(
            (item) => item["qtyRequested"] <= 0 || !item["qtyRequested"]
          ).length > 0
        ) {
          setAlertMessage({
            message: "Please enter an amount for each order item",
            colour: "warning",
          });
          setInputsConfirmed(false);
        } else {
          sendOrder();
        }
      } else {
        setAlertMessage({
          message: "Please fill in all the required fields",
          colour: "warning",
        });
        setInputsConfirmed(false);
      }
    }
  }, [inputsConfirmed]);

  const getUoM = (selectedDrug) => {
    return selectedDrug.doseUom ??
              (selectedDrug.formulary && 
              selectedDrug.formulary.length > 0 && 
              selectedDrug.formulary[0].formularyPacksizes && 
              selectedDrug.formulary[0].formularyPacksizes.length > 0) ?
            selectedDrug.formulary[0].formularyPacksizes[0].uom
            : null
  }
  const addToOrder = (selectedDrug) => {
    const newItem = selectedDrug["catalogueId"]
        ? {
            catalogueId: selectedDrug["catalogueId"],

          cdVpId: selectedDrug["virtualId"],
            cdcatalogueNameOrFormularyName: selectedDrug["cdcatalogueNameOrFormularyName"],
          siteId: site,
          locationId: user.location.lcId,
          qtyRequested: selectedDrug["qtyRequested"],
          createdby: username,
          cdApId: selectedDrug["id"],
          doseUom: getUoM (selectedDrug),
          isUrgent: false,
        }
      : {
            catalogueId: selectedDrug["catalogueId"],
          cdVpId: selectedDrug["virtualId"],
            cdcatalogueNameOrFormularyName: selectedDrug["cdcatalogueNameOrFormularyName"],
          siteId: site,
          locationId: user.location.lcId,
          qtyRequested: selectedDrug["qtyRequested"],
          createdby: username,
          doseUom: getUoM (selectedDrug),
          isUrgent: false,
        };
    setOrder((order) => [
      ...order,
      {
        ...newItem,
      },
    ]);
  };

    const showAlertMessage = (cdcatalogueNameOrFormularyName) => {
    setAlertMessage({
        message: "You already have " + cdcatalogueNameOrFormularyName + ", in your list",
      colour: "warning",
    });
  };

  useEffect(() => {
    if (selectedDrug) {
        if (
          order.find(
            (item) =>
              item["catalogueId"] === selectedDrug["catalogueId"]
          )
        ) {
            showAlertMessage(selectedDrug["cdcatalogueNameOrFormularyName"]);
        } else {
          addToOrder(selectedDrug);
        }
      setSelectedDrug(null);
    }
  }, [selectedDrug]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  const deleteDrug = (drug) => {
    setOrder((order) =>
        order.filter((item) => item["cdcatalogueNameOrFormularyName"] !== drug["cdcatalogueNameOrFormularyName"])
    );
    setDisable(false);
  };

  const deleteAllDrugs = () => {
    setOrder([]);
  };

  const updateDrugUrgent = (drug) => {
    const urgentItem = order.map((item) => {
        if (item["cdcatalogueNameOrFormularyName"] === drug["cdcatalogueNameOrFormularyName"]) {
        const updatedItem = {
          ...item,
          isUrgent: !item["isUrgent"],
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(urgentItem);
  };

  const updateDrugPatient = (drug, patietInfo) => {
    const patientRelated = order.map((item) => {
        if (item["catalogueId"] === drug["catalogueId"]
        ) {
        const updatedItem = {
          ...item,
          isPatient: true,
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(patientRelated);
  };

  const updateDrugAmount = (drug, amount) => {
    const newItem = order.map((item) => {
        if (item["cdcatalogueNameOrFormularyName"] === drug["cdcatalogueNameOrFormularyName"]) {
        const updatedItem = {
          ...item,
          qtyRequested: parseFloat(amount),
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(newItem);
  };

  const sendOrder = () => {
    OrdersService.createOrder(
      ward,
      order.map((item) => ({
        ...item,
        isPharmacy,
        signedBy: confirmedStaff.signedBy,
      }))
    )
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: "Your order has been sent to pharmacy",
            colour: "success",
            timeout: 5000,
          });
          redirectTimeoutId = setTimeout(() => {
            history.push("/ward/dashboard");
          }, 5000);
        } else {
          setAlertMessage({
            message: `There was a problem creating the order, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
          setInputsConfirmed(false);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem creating the order, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setInputsConfirmed(false);
      });
  };

  return (
    <Col className="CreateOrder">
      {alertMessage["message"] && (
        <CAlert
          color={alertMessage["colour"]}
          className="shadow"
          closeButton
          onClick={() =>
            setAlertMessage({
              message: "",
              colour: "",
            })
          }
        >
          {alertMessage["colour"] === "danger" ? (
            <FontAwesomeIcon className="mr-4" icon={faBullhorn} />
          ) : (
            <FontAwesomeIcon className="mr-4" icon={faBell} />
          )}
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col md="6">
          <h1>Create new order</h1>
        </Col>
        <Col md="6 d-flex align-items-end flex-column">
          <Button
            color="primary"
            className="shadow-sm"
            onClick={(e) => {
              e.preventDefault();
              deleteAllDrugs();
            }}
          >
            CLEAR ALL ITEMS
            <FontAwesomeIcon className="on-right" icon={faTrashAlt} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <ASLDrugSearchList
            disabled={false}
            ward={ward}
            list={order}
            setAlertMessage={setAlertMessage}
            setSelectedDrug={setSelectedDrug}
          />
        </Col>
        <Col md={{ size: 8 }}>
          <Table hover>
            <thead>
              <tr>
                <th colSpan="5">Order items</th>
              </tr>
            </thead>
            <tbody>
              {order.map((od, odx) => (
                <CreateOrderPadLine
                  key={odx}
                  nxt={od}
                  deleteDrug={deleteDrug}
                  updateDrugAmount={updateDrugAmount}
                  updateDrugUrgent={updateDrugUrgent}
                  updateDrugPatient={updateDrugPatient}
                  setDisable={setDisable}
                  isPatient={false}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {order.length > 0 && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          confirmInitialState={ordersrules.confirmInitialState.CreateOrder}
          inputsConfirmed={inputsConfirmed}
          setInputsConfirmed={setInputsConfirmed}
          setConfirmedStaff={setConfirmedStaff}
        />
      )}
    </Col>
  );
};

export default React.memo(CreateOrder);
