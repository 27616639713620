import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React, { memo } from "react";

const LocationLine = ({ nxt }) => {
  const { lcId, name, wardInfo, isOnline, siteId, locationTypes } = nxt;

  return (
    <tr className="shadow-sm">
      <td className="drugline cd-item-info">
        <Link
          className="table-link"
          to={{
            pathname: "/pharmacy/location",
            state: {
              siteId: siteId,
              lcId: lcId,
            },
          }}
        >
          {name}
        </Link>
      </td>
      <td className="drugline wardinfo">{wardInfo}</td>
      <td className="location-type">{locationTypes.map((lt) => lt.locationType).join("\n") }</td>
      <td>
        {isOnline && (
          <FontAwesomeIcon className="on-right" icon={faCheck} alt="Online" aria-label="Is Online"/>
        )}
      </td>
    </tr>
  );
};

export default memo(LocationLine);
