import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import SystemContext from "../../context/SystemContext";
import SearchInput from "../../component/molecules/SearchInput";

import LocationLine from "../../component/molecules/LocationLine";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../store/actions";
import LocationsService from "../../services/LocationsService";

const ManageLocations = () => {
  const {
    user: {
      location: { siteId },
    },
  } = useContext(SystemContext);

  const [searchLocations, setSearchLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const performSearch = async () => {
    try {
      const response = await LocationsService.getLocations(siteId, {
        params: { incPharm: true },
      });
      setSearchLocations(response.data);
      setFilteredLocations(response.data);
    } catch (error) {
      const message = `Approved list for this ward is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`;
      showAlertMessage(dispatch, { message, colour: "danger" });
    }
  };

  const handleSearch = (value) => {
    const searchExpression = value.replace(" ", ".*");
    const regex = new RegExp(searchExpression, "i");
    const filtered = searchLocations.filter(({ name }) => regex.test(name));
    setFilteredLocations(filtered);
  };

  useEffect(() => {
    performSearch();
  }, [siteId]);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  return (
    <Col>
      <Row className="mb-3">
        <Col md="6">
          <h1>Manage Locations</h1>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <SearchInput
            inputClass="search-holder"
            name="location search"
            type="text"
            placeholder="Search locations"
            displayValue={searchValue}
            value={setSearchValue}
          />
          {filteredLocations.length > 0 && (
            <Table className="CDTable DrugSearchList" hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="wardinfo">Ward Info</th>
                  <th>Type</th>
                  <th>Is Online</th>
                </tr>
              </thead>
              <tbody>
                {filteredLocations.map((l) => (
                  <LocationLine key={`loc-${l.lcId}`} nxt={l} />
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ManageLocations);
