import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { faEdit, faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CAlert } from "@coreui/react";
import ASLTableHeader from "../../component/molecules/Pharmacy/ASLTableHeader";
import ApprovedTableLine from "../../component/molecules/Pharmacy/ApprovedTableLine";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import DrugSearchList from "../../component/organisms/DrugSearchList";
import WardPicker from "../../component/organisms/WardPicker";

import SystemContext from "../../context/SystemContext";
import CDService from "../../services/CDService";
import ASL from "../../config/_ASL";

let messageTimeoutId;

const CreateApprovedList = () => {
  const { user } = useContext(SystemContext);

  const messageState = {
    message: "",
    colour: "",
  };

  const siteId = user["location"]["siteId"];

  const [alertMessage, setAlertMessage] = useState(messageState);

  const [wardAlert, setwardAlert] = useState(false);
  const [areaName, setareaName] = useState({ lcId: "", wardInfo: "" });

  // list of searched drugs from API
  const [drugList, setdrugList] = useState([]);
  const [drugArray, setdrugArray] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState("");
  const [checkDrug, setcheckDrug] = useState({ state: false, array: [] });

  const [showEdit, setshowEdit] = useState(false);
  const [showConfirm, setshowConfirm] = useState(false);
  const [showConfirmBlock, setshowConfirmBlock] = useState(false);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  const deleteDrug = (id) => {
    setdrugList((drugList) =>
      drugList.filter(
        (item) => item["cdVpId"] + "_" + (item["cdApId"] ?? 0) !== id
      )
    );
  };

  const checkWard = () => {
    if (areaName["wardInfo"]) {
      createList();
    } else {
      setwardAlert(true);
    }
  };

  const createList = () => {
    // if (drugList.every((line) => line["nsvCode"] && line["threshold"])) {
    const drugs = [];
    //filter through table for content and create array of drugs and their attached info
    for (let i = 0; i < drugList.length; i++) {
      drugs[i] = {
        cdVpId: drugList[i]["cdVpId"],
        nsvcode: drugList[i]["nsvCode"],
        // threshold: drugList[i]["threshold"],
        threshold: 1,
        createdBy: user.username,
        isWitnessed: drugList[i]["isWitnessed"],
        isSafeCustody: drugList[i]["isSafeCustody"],
      };
      if (drugList[i]["cdApId"]) {
        drugs[i]["cdApId"] = drugList[i]["cdApId"];
      }
      setAlertMessage(messageState);
    }

    setshowEdit(true);
    setshowConfirm(false);
    setwardAlert(false);
    setdrugArray(drugs);
    setshowConfirmBlock(true);
    setcheckDrug({ state: false, array: [] });
    /*     } else {
      const list = [];
      for (let j = 0; j < drugList.length; j++) {
        if (!drugList[j]["nsvCode"] || !drugList[j]["threshold"])
          list.push(drugList[j]["cdVpId"]);
      }
      setcheckDrug({ state: true, array: list });
      setAlertMessage({
        message: "Please fill all fields",
        colour: "danger",
      });
    } */
  };

  const editList = () => {
    setInputsConfirmed(false);
    setshowEdit(false);
    setshowConfirmBlock(false);
    setshowConfirm(true);
  };
  const confirmNewList = () => {
    const completeLine =
      drugArray.map((item) => ({ ...item, ...confirmedStaff })).length !== 0
        ? drugArray.map((item) => ({ ...item, ...confirmedStaff }))
        : [{ ...confirmedStaff, isEmpty: true }];

    CDService.setPharmacyASL(areaName["lcId"], completeLine)
      .then((response) => {
        if (response.status === 201) {
          setAlertMessage({
            message: "The approved list has been created for this location",
            colour: "success",
            timeout: 5000,
          });
        } else {
          setAlertMessage({
            message: `There was a problem creating an approved list for this location,
              the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem creating an approved list for this location,
              the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setInputsConfirmed(false);
      });
  };

  useEffect(() => {
    if (drugList.length > 0) setshowConfirm(true);
  }, [drugList]);

  useEffect(() => {
    if (selectedDrug) {
      selectedDrug["origIsWitnessed"] = selectedDrug["isWitnessed"];
      selectedDrug["origIsSafeCustody"] = selectedDrug["isSafeCustody"];
      setdrugList((drugList) => [...drugList, selectedDrug]);
    }
  }, [selectedDrug]);

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();
      confirmNewList();
    }
  }, [inputsConfirmed]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="CreateApprovedList">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col>
          <h3>Create new approved list</h3>
        </Col>
        <Col
          md={{ size: 3, offset: 3 }}
          className="d-flex align-items-end flex-column"
        >
          <Row>
            <Col>
              <Link
                className="shadow-sm btn btn-primary"
                to="/pharmacy/locations/approved"
              >
                <FontAwesomeIcon className="on-left" icon={faReplyAll} />
                BACK TO ALL LOCATIONS
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-4 mt-1 d-flex align-items-center">
          <WardPicker
            inputID="chooseasl"
            siteId={siteId}
            setAlertMessage={setAlertMessage}
            areaName={areaName}
            setareaName={setareaName}
            wardAlert={wardAlert}
            setwardAlert={setwardAlert}
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 3 }}>
          {!showEdit && (
            <DrugSearchList
              searchListClass="search-list-fixed"
              setAlertMessage={setAlertMessage}
              setSelectedDrug={setSelectedDrug}
            />
          )}
        </Col>
        {showEdit && (
          <Col
            md={{ size: 2, offset: 3 }}
            className="d-flex justify-content-end"
          >
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                editList();
              }}
            >
              <FontAwesomeIcon className="on-left" icon={faEdit} />
              Edit list
            </Button>
          </Col>
        )}
      </Row>
      <Row className="cd-list">
        <Table hover>
          <ASLTableHeader editView={showConfirmBlock} />
          <tbody>
            {drugList.map((nxt, idx) => (
              <ApprovedTableLine
                key={idx}
                nxt={nxt}
                deleteDrug={deleteDrug}
                drugList={drugList}
                setdrugList={setdrugList}
                view={showConfirmBlock}
                check={checkDrug}
              />
            ))}
          </tbody>
        </Table>
      </Row>
      {showConfirm && (
        <Row className="d-flex justify-content-end">
          <Button
            className="confirmButton shadow-sm"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              checkWard();
            }}
          >
            CONFIRM ITEMS{" "}
            <FontAwesomeIcon className="on-right" icon={faCheckCircle} />
          </Button>
        </Row>
      )}
      {showConfirmBlock && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          confirmInitialState={ASL.confirmInitialState}
          inputsConfirmed={inputsConfirmed}
          setInputsConfirmed={setInputsConfirmed}
          setConfirmedStaff={setConfirmedStaff}
        />
      )}
    </Col>
  );
};

export default React.memo(CreateApprovedList);
