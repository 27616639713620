import React from "react";
import Cookies from "js-cookie";
import { Redirect, Route } from "react-router-dom";
import MainLayout from "../templates/MainLayout";

const PrivateRoute = ({ component, exact, path, cookies, ...rest }) => {
  const user = JSON.parse(Cookies.get("user") || cookies?.user || null);
  const isLoggedIn = () => !!user;

  return isLoggedIn() ? (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => (
        <MainLayout component={component} cookies={cookies} {...props} />
      )}
    />
  ) : (
    <Route>
      <Redirect to={{ pathname: "/" }} />
    </Route>
  );
};

export default PrivateRoute;
