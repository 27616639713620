import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Table, Button } from "reactstrap";
import SystemContext from "../../context/SystemContext";
import StockService from "../../services/StockService";
import { hideAlertMessage, showAlertMessage } from "../../store/actions";
import { CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faReplyAll } from "@fortawesome/free-solid-svg-icons";
import DiscrepancyModal from "../../component/organisms/DiscrepancyModal";
import { getOrdersRules } from "../../config/_ordersrules";
import ConfirmBlockOrders from "../../component/organisms/ConfirmBlockOrders";
import { Link, useHistory } from "react-router-dom";

import QuantityInput from "../../component/molecules/QuantityInput";

function StockCheckReturn(props) {
  const { locationRoute, user } = useContext(SystemContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const modifiedOrders = props.location.state.modifiedOrders;

  const [alertMessage, setAlertMessage] = useState();
  const [newBalanceValue, setNewBalanceValue] = useState(0);
  const [localStock, setLocalStock] = useState(props.location.state.stock);
  const [modalStock, setModalStock] = useState();
  const [sortedPharmacyStock, setSortedPharmacyStock] = useState([]);
  const [loadOrders, setLoadOrders] = useState({
    state: false,
    refresh: false,
  });
  const [pharmacyStocks, setPharmacyStocks] = useState();

  const messageState = {
    message: "",
    colour: "",
  };

  const mainPagePath = "/pharmacy/return#ForReuse";
  const registerPath = "/pharmacy/stock/administer";

  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  useEffect(() => {
    calculateRunningBalance();
  }, [sortedPharmacyStock]);

  useEffect(() => {
    setLocalStock(localStock);
    getPharmacyStocks(localStock);
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  function getPharmacyStocks(stock, triggerCompleteAction) {
    if (!pharmacyStocks || triggerCompleteAction) {
      StockService.getPharmacyStock(user.location.lcId)
        .then((response) => {
          setSortedPharmacyStock(
              response.data.filter(
                  (x) => x.cdCatalogue.catalogueId === stock.catalogueId
            )[0]
          );
          setPharmacyStocks(response.data);
          if (triggerCompleteAction) {
            completionAction(
                response.data.filter(
                    (x) => x.cdCatalogue.catalogueId === stock.catalogueId
              )[0]
            );
          }
        })
        .catch((error) => {
          setAlertMessage({
            message: `Unable to fetch complete stock history, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "warning",
            label: "Warning message",
          });
        });
    } else {
      setSortedPharmacyStock(
        pharmacyStocks.filter(
          (x) => x.cdCatalogue.cdcatalogueNameOrFormularyName === stock.drugName
        )[0]
      );
    }
  }

  function calculateRunningBalance() {
    if (sortedPharmacyStock) {
      setNewBalanceValue(
        sortedPharmacyStock.runningBalance + localStock.amountSupplied
      );
    } else {
      setNewBalanceValue(localStock.amountSupplied);
    }
  }

  function onDiscrepancyClick() {
    const sortedStock = sortedPharmacyStock;
    sortedPharmacyStock.runningBalance = newBalanceValue;
    setModalStock(sortedStock);
  }

  function handleModalToggle() {
    setModalStock();
  }

  function completionAction(stock = null) {
    if (stock) {
      history.push(registerPath, { nxt: stock });
    } else if (sortedPharmacyStock) {
      const sortedPharmacy = sortedPharmacyStock;
      sortedPharmacy.runningBalance = newBalanceValue;
      history.push(registerPath, { nxt: sortedPharmacy });
    } else {
      getPharmacyStocks(localStock, true);
    }
  }

  function handleModalComplete(stock, newBalance) {
    stock.runningBalance = newBalance;
    completionAction(stock);
  }

  function getStockInformation() {
    return (
      <tr>
        <td>{localStock.drugName}</td>
        <td>{localStock.category}</td>
        <td>{localStock.amountSupplied}</td>
        <td>{localStock.doseUom}</td>
        <td>
          <QuantityInput
            alignCenter
            allowNaN
            disabled={true}
            placeholder={newBalanceValue}
            unitsValue={localStock.doseUom}
          />
        </td>
        {sortedPharmacyStock && (
          <td className="icons">
            <CTooltip content="Discrepancy exists, click to amend balance">
              <Button
                className="status-btn"
                color="blue"
                onClick={onDiscrepancyClick}
              >
                <span className="status-icon" role="alert">
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
              </Button>
            </CTooltip>
          </td>
        )}
      </tr>
    );
  }

  return (
    <div className="AdministerStock">
      <header className="page-header">
        <h1 className="page-heading">Reuse Stock Check</h1>
        <Col
          md="6"
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="8"
        >
          <Link className="btn btn-primary btn-icon" to={mainPagePath}>
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            Back to main screen
          </Link>
        </Col>
      </header>
      {localStock && (
        <>
          <Table className="stock-management-table">
            <thead>
              <tr>
                <th>Drug Name</th>
                <>
                  <th>Drug Category</th>
                  <th>Quantity</th>
                  <th>Unit Dose</th>
                  <th>New Balance</th>
                  {sortedPharmacyStock && <th>Discrepancy</th>}
                </>
              </tr>
            </thead>
            <tbody>{getStockInformation()}</tbody>
          </Table>
          <>
            <ConfirmBlockOrders
              blockTitle={"Reuse Stock Check"}
              confirmInitialState={ordersrules.confirmInitialStateForReturn}
              currentStatus={"ForReuse"}
              isHasChanged={true}
              isReturnOrders
              loadStatus={{ state: true, refresh: false }}
              messageState={messageState}
              orderArray={modifiedOrders}
              setAlertMessage={setAlertMessage}
              setLoadOrders={setLoadOrders}
              action={"ForReuse"}
              location={user.location.lcId}
              user={user}
              locationRoute={locationRoute}
              completionAction={completionAction}
            />
          </>
        </>
      )}
      <DiscrepancyModal
        isOpen={Boolean(modalStock)}
        onComplete={handleModalComplete}
        stock={modalStock}
        toggle={handleModalToggle}
        defaultValue={newBalanceValue}
        modifiedOrders={modifiedOrders}
      />
    </div>
  );
}

export default StockCheckReturn;
