import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import QuantityInput from "../QuantityInput";

function buildUnitsList(defaultUnitsList, doseUom, drugForm, isMl) {
  if (drugForm === "Continuous" || isMl) {
    if (
      defaultUnitsList.filter((item) => item.includes(`(${doseUom})`)).length >
      0
    ) {
      return defaultUnitsList
        .filter((item) => item.includes(`(${doseUom})`))
        .map((filteredItem) => {
          return [
            filteredItem,
            ...defaultUnitsList.filter((listItem) => listItem !== filteredItem),
          ];
        })[0];
    }
    return defaultUnitsList;
  } else {
    return [doseUom, ...defaultUnitsList];
  }
}

const AdministerPatientTableLine = ({
  patient,
  stockView,
  setAmountSupplied,
  setAmountDisposed,
  setDoseAdministered,
  setDoseAdministeredUom,
  amountSupplied,
  doseAdministered,
  setNewBalanceValue,
  validate,
  isPartialAdminister,
  isQTYInputsDisabled,
  amountDisposed,
  doseAdministeredUom,
  showSuppliedError,
  showPatient
}) => {

  const settings = useSelector(
    (state) => state.settings
  );

  let DEFAULT_UNITS_LIST = settings.DEFAULT_UNITS_LIST;
  useEffect(() => {
    DEFAULT_UNITS_LIST = settings.DEFAULT_UNITS_LIST;
  }, [settings]);

  useEffect(() => {
    if (unitsList){
      const uom =
        doseAdministeredUom !== ""
          ? unitsList.find((el) => el.toLowerCase().includes(doseAdministeredUom?.toLowerCase()))
          : unitsList[0];
      setDoseAdministeredUom(uom);
    }
  }, [doseAdministeredUom]);

  if (!DEFAULT_UNITS_LIST){
    return (<></>);
  }

  const unitsList = buildUnitsList(
    DEFAULT_UNITS_LIST,
    patient.cdCatalogue.cdCataloguePackSize[0]?.uom,
    patient.cdCatalogue.drugForm,
    (patient.cdCatalogue.cdCatalogueStrength.length > 0
      ? patient.cdCatalogue.cdCatalogueStrength[0].strengthDenominatorUoM
        ? patient.cdCatalogue.cdCatalogueStrength[0].strengthDenominatorUoM
        : patient.cdCatalogue.cdCatalogueStrength[0].strengthNumeratorUoM
      : patient.cdCatalogue.cdCataloguePackSize[0]?.uom) === "ml"
  );

  function updateAmountSupplied(value) {
    setAmountSupplied(value);
    setNewBalanceValue(patient.runningBalance - value);
  }

  function tempUnitChanged(value) {
    setDoseAdministeredUom(value);
  }

  return (
    <tr
      className={`shadow-sm${stockView ? " stockCheck" : ""}`}
      name="drugline"
      id={patient["catalogueId"]}
      key="patient-line-tr"
    >
      <td>
        <Link
          className="order-drug_name"
          onClick={(e) => {
            e.preventDefault();
            showPatient();
          }}
        >
          {patient["name"]}
        </Link></td>
      <td>
        <QuantityInput
          aria-label="Amount supplied"
          invalidate={(validate && !amountSupplied) || showSuppliedError}
          name="amount-supplied"
          onValueChange={updateAmountSupplied}
          required
          unitsValue={
            unitsList.filter((item) =>
              item.includes(`(${doseAdministeredUom})`)
            ).length > 0
              ? unitsList.filter((item) =>
                item.includes(
                  `(${patient.cdCatalogue.cdCataloguePackSize[0]?.uom})`
                )
              )[0]
              : patient.cdCatalogue.cdCataloguePackSize[0]?.uom
          }
          disabled={isPartialAdminister}
          placeholder={amountSupplied}
        />
      </td>
      <td key="administered-td">
        <QuantityInput
          aria-label="Amount administered"
          name="amount-administered"
          onUnitsChange={tempUnitChanged}
          onValueChange={setDoseAdministered}
          required
          unitsList={unitsList}
          unitsValue={
            unitsList.filter((item) =>
              item.includes(`(${doseAdministeredUom})`)
            ).length > 0
              ? unitsList.filter((item) =>
                item.includes(`(${doseAdministeredUom})`)
              )[0]
              : doseAdministeredUom
          }
          placeholder={doseAdministered}
          unitsDisabled={isQTYInputsDisabled}
          disabled={isQTYInputsDisabled}
        />
      </td>
      <td key="disposed-td">
        <QuantityInput
          aria-label="Amount disposed"
          name="amount-disposed"
          onUnitsChange={tempUnitChanged}
          onValueChange={setAmountDisposed}
          unitsDisabled={Boolean(doseAdministered) || isQTYInputsDisabled}
          unitsList={unitsList}
          unitsValue={
            unitsList.filter((item) =>
              item.includes(`(${doseAdministeredUom})`)
            ).length > 0
              ? unitsList.filter((item) =>
                item.includes(`(${doseAdministeredUom})`)
              )[0]
              : doseAdministeredUom
          }
          placeholder={amountDisposed}
          disabled={isQTYInputsDisabled}
        />
      </td>
    </tr>
  );
};

export default React.memo(AdministerPatientTableLine);
