import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  CSidebarBrand,
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavItem,
  CTooltip,
} from "@coreui/react";
import { ReactComponent as EcdrLogo } from "../../assets/icons/Ecdrlogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as DashboardIcon } from "../../assets/icons/DashboardIcon.svg";
import * as actionTypes from "../../store/actions";

// sidebar nav config
import Navigation from "../../config/_nav";

import SystemContext from "../../context/SystemContext";

const authRequired = process.env.REACT_APP_AUTH_REQUIRED;

const LeftSideMenu = () => {
  const isServerSide = typeof window === "undefined";

  const { locationRoute } = useContext(SystemContext);
  const history = useHistory();
  const { instance } = useMsal();

  const dispatch = useDispatch();
  const show = useSelector((state) => state.sbs.sidebarShow);
  const [position, setposition] = useState(locationRoute);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)");

    mediaQuery.addListener((mq) => {
      if (mq.matches) {
        dispatch({ type: actionTypes.SET, sidebarShow: true });
      } else {
        dispatch({ type: actionTypes.SET, sidebarShow: "responsive" });
      }
    });

    if (!position) {
      setposition(locationRoute);
    }
  }, []);

  const logOut = () => {
    dispatch({ type: actionTypes.SET, sidebarShow: "responsive" });
    history.push("/");
    Cookies.remove("user");

    if (authRequired) {
      instance.logout();
    }
  };

  function handleMenuToggle() {
    dispatch({
      type: actionTypes.SET,
      sidebarShow: show === true ? "responsive" : true,
    });
  }

  function handleMenuLinkClick() {
    dispatch({ type: actionTypes.SET, sidebarShow: "responsive" });
  }

  if (isServerSide) {
    return null;
  }

  return (
    <>
      <CTooltip content="Menu" placement="right">
        <Button
          className="menu-open-btn"
          color="transparent"
          onClick={handleMenuToggle}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </CTooltip>
      <CSidebar
        show={show}
        onShowChange={(val) =>
          dispatch({ type: actionTypes.SET, sidebarShow: val })
        }
      >
        <CTooltip content="Close menu" placement="right">
          <Button
            className="menu-close-btn"
            color="transparent"
            onClick={handleMenuToggle}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </CTooltip>
        <EcdrLogo className="sidebar-logo" />
        <CSidebarBrand className="d-md-down-none"></CSidebarBrand>
        <CSidebarNav>
          <div className="flex-top">
            <CSidebarNavItem
              tag="CSidebarNavItem"
              name={<span className="sidebar-name">Dashboard</span>}
              onClick={handleMenuLinkClick}
              to={`/${position}/dashboard`}
              icon={
                <CTooltip content="Dashboard" placement="right">
                  <span id="sb-dashboard" className="sidebar-bumper">
                    <DashboardIcon />
                  </span>
                </CTooltip>
              }
            />
          </div>
          <div className="flex-central">
            <CCreateElement
              items={Navigation[position].map((item) => ({
                ...item,
                onClick: handleMenuLinkClick,
              }))}
              components={{ CSidebarNavItem }}
            />
          </div>
          <div className="flex-bottom">
            <CSidebarNavItem
              tag="CSidebarNavItem"
              name={<span className="sidebar-name">Logout</span>}
              onClick={(e) => {
                e.preventDefault();
                logOut();
              }}
              icon={
                <CTooltip content="Logout" placement="right">
                  <span id="logout" className="sidebar-bumper">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                </CTooltip>
              }
            />
          </div>
        </CSidebarNav>
      </CSidebar>
    </>
  );
};
export default React.memo(LeftSideMenu);
