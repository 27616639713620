import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Table, Input } from "reactstrap";
import { CAlert, CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBullhorn,
  faTrashAlt,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";

import DrugSearchList from "../../component/organisms/DrugSearchList";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import QuantityInput from "../../component/molecules/QuantityInput";
import OrdersService from "../../services/OrdersService";
import PharmaciesDropDown from "../../component/molecules/PharmaciesDropDown";
import LocationsService from "../../services/LocationsService";
import SystemContext from "../../context/SystemContext";
import { initialStateSignedWitnessed } from "../../config/confirm";
import StockService from "../../services/StockService";

const DEFAULT_ERROR_KEY = "";
const STOCK_EXISTS_ERROR = "Stock already exists for location";

const TransferCD = () => {
  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [locations, setLocations] = useState([]);
  const [order, setOrder] = useState([]);
  const [pharmacy, setPharmacy] = useState({});
  const [pharmacyName, setPharmacyName] = useState("");
  const { user, locationId } = useContext(SystemContext);
  //const siteId = user?.location?.siteId;
  const [selectedDrug, setSelectedDrug] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchListDisabled, setSearchListDisabled] = useState(true);

  function setPharmacies (data) {
    const pharmacies = data.filter(
      ({ isPharmacy, lcId }) => isPharmacy && lcId !== locationId
    );
    if (pharmacies.length < 1) {
      setAlertMessage({
        message: "At least two pharmacies are needed to request a transfer.",
        colour: "danger",
      });
    } else {
      setLocations(pharmacies);
      if (pharmacies.length === 1) {
        setPharmacy({
          lcId: pharmacies[0].lcId,
          siteId: pharmacies[0].site.siteId,
          siteShortName: pharmacies[0].site.shortName,
          name: pharmacies[0].name,
        });
      }
    }
  }

  async function fetchPharmacies() {
    try {
      const { data } = await LocationsService.getAllLocations();
      setPharmacies(data);
    } catch (error) {
      setAlertMessage({
        message: `Pharmacy list is unavailable right now, the eCDR-Pro system may be offline.
        If unable to resolve contact IT service desk.`,
        colour: "danger",
      });
    }
  }

  useEffect(() => {
    fetchPharmacies();
  }, []);

  useEffect(() => {
    setSearchListDisabled(!pharmacy || !pharmacy.name);
    setPharmacyName(
      pharmacy && pharmacy.name ? `${pharmacy.name} (${pharmacy.siteShortName})` : ""
    );
  }, [pharmacy]);

  function resetScreen() {
    setOrder([]);
    setPharmacy({});
    setPharmacies(locations);
  }

  async function transferOrder() {
    const payload = order.map((ol) => {
      return {
        cdApId: ol.cdCatalogue.Id,
        cdVpId: ol.cdCatalogue.virtualId,
        catalogueId: ol.catalogueId,
        cdcatalogueNameOrFormularyName: ol.cdcatalogueNameOrFormularyName,
        qtyRequested: ol.qtyRequested,
        doseUom: ol.cdCatalogue.cdCataloguePackSize[0]?.uom,
        isUrgent: ol.isUrgent,
        siteId: user.location.siteId,
        locationId: user.location.lcId,
        createdBy: user.username,
        signedBy: confirmedStaff.signed,
        witnessedBy: confirmedStaff.witnessed,
      };
    });

    setIsLoading(true);

    try {
      const response = await OrdersService.createOrder(
        locationId,
        payload,
        pharmacy.lcId
      );

      if (response.status === 202 || response.status === 201) {
        setAlertMessage({
          message: "Successfully added the CD",
          colour: "success",
        });

        StockService.getPharmacyStock(locationId).then(() => {
          setAlertMessage({
            message: "Order created successfully",
            colour: "success",
          });
          setIsConfirmed(false);
          setIsLoading(false);
        });
      } else {
        setAlertMessage({
          message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsConfirmed(false);
        setIsLoading(false);
      }
    } catch (error) {
      if (!StockService.isCancel(error)) {
        if (error.response?.status === 400) {
          if (
            error.response.data?.[DEFAULT_ERROR_KEY]?.[0] === STOCK_EXISTS_ERROR
          ) {
            setAlertMessage({
              message:
                "Stock item already exists, please perform a stock discrepancy to amend the balance",
              colour: "warning",
            });
          } else {
            setAlertMessage({
              message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
                If unable to resolve contact IT service desk.`,
              colour: "danger",
            });
          }
        } else if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem adding the CD, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setIsConfirmed(false);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isConfirmed) {
      setConfirmedStaffErrors();

      let message = "";

      if (!pharmacy || !pharmacy.lcId) {
        message = "Please select a pharmacy to place the order";
      } else if (order.some((o) => o.qtyRequested === 0)) {
        message = "Please fill in the quantity for all order lines";
      } else {
        transferOrder();
        return;
      }

      setAlertMessage({
        message: message,
        colour: "warning",
      });
      setIsConfirmed(false);
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (selectedDrug) {
      if (
        order.find(
          (item) => item["catalogueId"] === selectedDrug["catalogueId"]
        )
      ) {
        setAlertMessage({
          message: `You already have ${
            selectedDrug.cdCatalogue?.cdcatalogueNameOrFormularyName
              ? selectedDrug.cdCatalogue.cdcatalogueNameOrFormularyName
              : selectedDrug.cdcatalogueNameOrFormularyName
          }, in your list`,
          colour: "warning",
        });
      } else {
        addToOrder(selectedDrug);
      }
      setSelectedDrug(null);
    }
  }, [selectedDrug]);

  const addToOrder = (selectedDrug) => {
    setOrder((order) => [
      ...order,
      {
        qtyRequested: 0,
        isUrgent: false,
        ...selectedDrug,
      },
    ]);
  };

  const handleDeleteButtonClick = (odx) => {
    setOrder(order.filter((_ol, olx) => olx !== odx));
  };

  const updateQtyRequested = (odx, value) => {
    const newOrder = order.map((ol, i) => {
      if (i === odx) {
        ol.qtyRequested = value;
      }
      return ol;
    });
    setOrder(newOrder);
  };

  const updateUrgent = (odx) => {
    const newOrder = order.map((ol, i) => {
      if (i === odx) {
        ol.isUrgent = !ol.isUrgent;
      }
      return ol;
    });
    setOrder(newOrder);
  };
  const renderRow = (od, odx) => (
    <tr className="TransferOrder_line">
      <td>{od.cdCatalogue.cdcatalogueNameOrFormularyName}</td>
      <td>
        <QuantityInput
          className="TransferOrder_line_qty"
          allowZero
          value={od.qtyRequested}
          onValueChange={(e) => updateQtyRequested(odx, e)}
          required
          showEmptyValue
        />
      </td>
      <td className="TransferOrder_line_uom">
        {od.cdCatalogue.cdCataloguePackSize[0]?.uom}
      </td>
      <td>
        <CTooltip content="Urgent">
          <Button
            color="transparent"
            className={`icon-urgent ${od.isUrgent ? "icon-urgent-active" : ""}`}
            onClick={(e) => updateUrgent(odx, e)}
          >
            <FontAwesomeIcon icon={faExclamation} />
          </Button>
        </CTooltip>
      </td>
      <td>
        <Button
          aria-label="Delete item"
          className="icon-delete"
          color="transparent"
          onClick={() => handleDeleteButtonClick(odx)}
          outline
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </td>
    </tr>
  );

  return (
    <Col className="TransferCD">
      {alertMessage["message"] && (
        <CAlert
          color={alertMessage["colour"]}
          className="shadow"
          closeButton
          onClick={() =>
            setAlertMessage({
              message: "",
              colour: "",
            })
          }
        >
          {alertMessage["colour"] === "danger" ? (
            <FontAwesomeIcon className="mr-4" icon={faBullhorn} />
          ) : (
            <FontAwesomeIcon className="mr-4" icon={faBell} />
          )}
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col md="6">
          <h1>Inter-Pharmacy Transfer</h1>
        </Col>
        <Col md="6 d-flex align-items-end flex-column">
          <Button
            color="primary"
            className="shadow-sm"
            onClick={(e) => {
              e.preventDefault();
              resetScreen();
            }}
          >
            CLEAR ALL ITEMS
            <FontAwesomeIcon className="on-right" icon={faTrashAlt} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          {locations?.length === 1 ? (
            <Input
              className="LocationDropdown"
              type="text"
              value={pharmacy && pharmacy.name ? pharmacy.name : ""}
              disabled
            />
          ) : (
            <PharmaciesDropDown
              dropDownList={locations}
              setdropDownItem={setPharmacy}
              textValue={pharmacyName}
              setTextValue={setPharmacyName}
              placeholder="Select pharmacy to transfer from"
              disabled={order.length > 0}
            />
          )}
        </Col>
        <Col md="8">
          <DrugSearchList
            searchListClass="search-list-fixed"
            setAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
            setSelectedDrug={setSelectedDrug}
            siteId={pharmacy && pharmacy.siteId}
            disabled={searchListDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Drug Name / Strength / Form</th>
                <th className="TransferOrder_header_qty">Qty Requested</th>
                <th />
              </tr>
            </thead>
            <tbody>{order.map((od, odx) => renderRow(od, odx))}</tbody>
          </Table>
        </Col>
      </Row>
      {order.length > 0 && (
        <>
          <ConfirmBlock
            className="ml-0 mr-0"
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={initialStateSignedWitnessed}
            inputsConfirmed={isConfirmed}
            isLoading={isLoading}
            setConfirmedStaff={setConfirmedStaff}
            setInputsConfirmed={setIsConfirmed}
            title="Transfer CD"
            hideWitness={true}
          />
        </>
      )}
    </Col>
  );
};

export default React.memo(TransferCD);
