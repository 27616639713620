import React, { useState, useEffect } from "react";
import { CTooltip } from "@coreui/react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import NumberInput from "../../atoms/NumberInput";

const ApprovedTableLine = ({
  nxt,
  deleteDrug,
  drugList,
  setdrugList,
  view,
  check,
}) => {
  const YES_NO_FLAGS_LIST = [
    { optionValue: "true", optionText: "Yes" },
    { optionValue: "false", optionText: "No" },
  ];
  const preNsvCode = nxt["nsvCode"] ? nxt["nsvCode"] : "";
  const preThreshold = nxt["threshold"] ? nxt["threshold"] : "";
  const preIsWitnessed = nxt["isWitnessed"] ? nxt["isWitnessed"] : false;
  const preIsSafeCustody = nxt["isSafeCustody"] ? nxt["isSafeCustody"] : false;
  const [nsv, setnsv] = useState(preNsvCode);
  const [thresh, setthresh] = useState(preThreshold);
  const [error, seterror] = useState(false);
  const [iswitnessed, setiswitnessed] = useState(preIsWitnessed);
  const [issafecustody, setissafecustody] = useState(preIsSafeCustody);

  const updateNsvCode = (e) => {
    setnsv(e.target.value);
    setdrugList(
      drugList.map((drug) =>
        drug["catalogueId"] === nxt["catalogueId"]
          ? { ...drug, nsvCode: e.target.value }
          : drug
      )
    );
  };

  const updateMinThreshold = (value) => {
    setthresh(value);
    setdrugList(
      drugList.map((drug) =>
        drug["catalogueId"] === nxt["catalogueId"] ? { ...drug, threshold: value } : drug
      )
    );
  };

  const updateIsWitnessed = (value) => {
    setiswitnessed(value);
    setdrugList(
      drugList.map((drug) =>
        drug["catalogueId"] === nxt["catalogueId"]
          ? { ...drug, isWitnessed: value }
          : drug
      )
    );
  };

  const updateIsSafeCustody = (value) => {
    setissafecustody(value);
    setdrugList(
      drugList.map((drug) =>
        drug["catalogueId"] === nxt["catalogueId"]
          ? { ...drug, isSafeCustody: value }
          : drug
      )
    );
  };

  function renderDropdown(itemValue, disabled, onChange) {
    return (
      <div className="SimpleDropdown">
        <UncontrolledDropdown>
          <DropdownToggle caret color="transparent" disabled={disabled}>
            <span>{itemValue === true ? "Yes" : "No"}</span>
          </DropdownToggle>
          <DropdownMenu right>
            {YES_NO_FLAGS_LIST.map((item) => (
              <DropdownItem
                active={item.optionValue === itemValue.toString()}
                key={item.optionValue}
                name={item.optionValue}
                onClick={onChange}
              >
                {item.optionText}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }

  function handleIsWitnessItemClick({ currentTarget }) {
    updateIsWitnessed(currentTarget.name === "true");
  }

  function handleIsSafeCustodyItemClick({ currentTarget }) {
    updateIsSafeCustody(currentTarget.name === "true");
  }

  useEffect(() => {
    if (check["state"] === true)
      seterror(
        check["array"].includes(nxt["catalogueId"])
      );
    else seterror(false);

    return () => {};
  }, [check]);

  return (
    <tr
      className={`shadow-sm ${error && "drugline-error"}`}
      id={nxt["catalogueId"]}
      name="drugline"
    >
      <td>
              {!nxt["cdCatalogue"]
                  ? nxt["cdcatalogueNameOrFormularyName"]
                  : nxt["cdCatalogue"]["cdcatalogueNameOrFormularyName"]
              }
      </td>
      {/*       <td>
        <Input
          type="text"
          className="nsvcode"
          name="nsvCode"
          value={nsv}
          onChange={updateNsvCode}
          readOnly={view}
          autoComplete="off"
        />
      </td> */}
      {(
        <td>
                  {!nxt["cdCatalogue"]
                      ? nxt["cdCataloguePackSize"][0]["uom"]
                      : nxt["cdCatalogue"]["cdCataloguePackSize"][0]["uom"]
                  }
        </td>
      )}
      <td>
              {!nxt["cdCatalogue"]
                  ? nxt["schedule"]
                  : nxt["cdCatalogue"]["schedule"]
              }
      </td>
      <td>
              {!nxt["cdCatalogue"]
                  ? nxt["origIsWitnessed"]
                    ? "Yes"
                      : renderDropdown(iswitnessed, view, handleIsWitnessItemClick)
                  : nxt["isWitnessed"]
                      ? "Yes"
                      : renderDropdown(iswitnessed, view, handleIsWitnessItemClick)
                  }
      </td>
      <td>
              {!nxt["cdCatalogue"]
                  ? nxt["origIsSafeCustody"]
                      ? "Yes"
                      : renderDropdown(issafecustody, view, handleIsSafeCustodyItemClick)
                  : nxt["isSafeCustody"]
                      ? "Yes"
                      : renderDropdown(issafecustody, view, handleIsSafeCustodyItemClick)
                  }
      </td>
      {!view && (
        <td>
          <CTooltip content="Remove">
            <Button
              className="btn-delete"
              onClick={(e) => {
                e.preventDefault();
                deleteDrug(nxt["catalogueId"]);
              }}
              id={nxt["catalogueId"]}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </CTooltip>
        </td>
      )}
    </tr>
  );
};

export default React.memo(ApprovedTableLine);
