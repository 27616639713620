import axios from "axios";
import AuthService from "./AuthService";

const LocationsService = {};

LocationsService.getCancelSource = () => axios.CancelToken.source();
LocationsService.isCancel = axios.isCancel;

LocationsService.getAllLocations = async ( config) => {
  return await AuthService.get("/location/", config);
};

LocationsService.getLocations = async (siteId, config) => {
  return await AuthService.get(`/location/${siteId}`, config);
};

LocationsService.getLocation = async (siteId, locationId, config) => {
  return await AuthService.get(`/location/${siteId}/${locationId}`, config);
};

LocationsService.updateLocation = async (siteId, locationId, updatedLocation) => {
  return await AuthService.patch(`/location/${siteId}/${locationId}`, updatedLocation);
};

LocationsService.getSites = async (config) => {
  return await AuthService.get("/Site", config);
};

export default LocationsService;
