import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { CAlert } from "@coreui/react";

const Help = ({ user, locationRoute }) => {
  const messageState = {
    message: "",
    colour: "danger",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Col className="Help">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row>
        <Col md="6">
          <h1>How to use eCDR PRO</h1>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(Help);
