import React from "react";

import OrderTableLine from "../../component/molecules/OrderTableLine";

const OrderTableBodyMultiselect = ({
  isPharmacy,
  orders,
  status,
  locationRoute,
  ordersrules,
  currentView,
  currentStatus,
  currentFilter,
  updateRows,
  deleteRow,
  ordersFilter,
  setAlertMessage,
  isPrescriptionOrdersSelected,
  isNamedOrdersSelected,
  showLocations
}) => {
  const actionRow = (isChecked, localNXT, nxt, status, setchosenRow) => {
    if (isChecked) {
      updateRows({
        odId: localNXT["odId"],
        lcId: localNXT["lcId"],
        siteId: localNXT["siteId"],
        cdApId: localNXT["cdCatalogue"]["id"],
        cdVpId: localNXT["cdCatalogue"]["virtualId"],
        catalogueId: localNXT["catalogueId"],
        stId: status["next"],
        isWitnessed: localNXT["cdCatalogue"]["isWitnessed"],
        isPharmacyLocation: localNXT.location.isPharmacy,
        isPatient: localNXT.isPatient,
      });
    } else {
      deleteRow(localNXT["odId"]);
    }
    setchosenRow(isChecked);
  };

  return (
    <tbody>
      {orders.length > 0 ? (
        orders.map((dg, dgx) => (
          <OrderTableLine
            isPharmacy={isPharmacy}
            key={dgx}
            nxt={dg}
            locationRoute={locationRoute}
            status={
              status ||
              ordersrules["initialStatusView"]["statusSet"].find(
                (obj) => obj["status"] === dg["status"]["name"]
              )
            }
            currentView={currentView}
            currentStatus={currentStatus}
            currentFilter={currentFilter}
            updateRows={updateRows}
            deleteRow={deleteRow}
            ordersFilter={ordersFilter}
            setAlertMessage={setAlertMessage}
            actionRow={actionRow}
            showLocations={showLocations}
            disabledSwitch={
              (isPrescriptionOrdersSelected &&
                ((dg.isPatient && !dg.location.isPharmacy) || !dg.isPatient)) ||
              (isNamedOrdersSelected && dg.isPatient && dg.location.isPharmacy)
            }
          />
        ))
      ) : (
        <></>
      )}
    </tbody>
  );
};

export default OrderTableBodyMultiselect;
