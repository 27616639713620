import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";

import Footer from "../component/organisms/Footer";
import Header from "../component/organisms/Header";
import LeftSideMenu from "../component/organisms/LeftSideMenu";
import MainContent from "../containers/MainContent";
import SystemContext from "../context/SystemContext";
import { hideAlertMessage } from "../store/actions";

const authRequired = process.env.REACT_APP_AUTH_REQUIRED;

const MainLayout = (props) => {
  const locationRoute = props.location.pathname.split("/")[1];
  const user = JSON.parse(Cookies.get("user") || props.cookies.user || null);

  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.messages.alertMessage);
  const { instance } = useMsal();

  useEffect(() => {
    if (user) {
      if (
        (user.location.isPharmacy && locationRoute === "ward") ||
        (user.location.isWard && locationRoute === "pharmacy")
      ) {
        logout();
      }
    } else {
      logout();
    }
  }, []);

  function logout() {
    props.history.push("/");
    Cookies.remove("user");

    if (authRequired) {
      instance.logout();
    }
  }

  function handleAlertToggle() {
    if (alertMessage.show) {
      hideAlertMessage(dispatch);
    }
  }

  function renderAlertMessage(message) {
    if (Array.isArray(message)) {
      return message.map((item, index) => <div key={index}>{item}</div>);
    }

    return message;
  }

  return (
    <div className="c-app c-default-layout">
      {user && (
        <SystemContext.Provider
          value={{
            locationId: user.location.lcId,
            locationRoute,
            isPharmacy: user.location.isPharmacy,
            isWard: user.location.isWard,
            user,
          }}
        >
          <LeftSideMenu />
          <div className="c-wrapper routes">
            <Header />
            <div className="c-body">
              <Alert
                aria-label={alertMessage.label}
                className="shadow"
                color={alertMessage.colour}
                isOpen={alertMessage.show}
                toggle={handleAlertToggle}
              >
                {renderAlertMessage(alertMessage.message)}
              </Alert>
              <MainContent
                locationId={user.location.lcId}
                locationRoute={locationRoute}
                isPharmacy={user.location.isPharmacy}
                isWard={user.location.isWard}
                user={user}
                {...props}
              />
            </div>
            <Footer />
          </div>
        </SystemContext.Provider>
      )}
    </div>
  );
};

export default MainLayout;
