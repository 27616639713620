import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import cx from "classnames";

import NumberInput from "../atoms/NumberInput";

function QuantityInput({
  alignCenter,
  alignEnd,
  alignStart,
  allowZero = true,
  className,
  id,
  initialValue,
  onUnitsChange,
  unitsDisabled,
  unitsList,
  unitsValue,
  value,
  allowDecimals = false,
  resetValueAsNumber,
  ...inputProps
}) {
  function removeZeroValue(value) {
    return !allowZero && (value === 0 || value === "0") ? "" : value;
  }

  function handleUnitsItemClick({ currentTarget }) {
    onUnitsChange(currentTarget.textContent);
  }

  function renderDropdown() {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          color="transparent"
          disabled={unitsDisabled}
          title={unitsValue}
        >
          <span>{unitsValue}</span>
        </DropdownToggle>
        <DropdownMenu right>
          {unitsList.map((item) => (
            <DropdownItem
              active={item === unitsValue}
              key={item}
              onClick={handleUnitsItemClick}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  function renderLabel() {
    return (
      <label className="quantity-units" htmlFor={id} title={unitsValue}>
        {unitsValue}
      </label>
    );
  }

  return (
    <div
      className={cx("QuantityInput", className, {
        "align-center": alignCenter,
        "align-end": alignEnd,
        "align-start": alignStart,
      })}
    >
      <NumberInput
        id={id}
        initialValue={removeZeroValue(initialValue)}
        placeholder="0"
        value={removeZeroValue(value)}
        {...inputProps}
        resetValueAsNumber={resetValueAsNumber}
        allowDecimals={allowDecimals}
      />
      {unitsList ? renderDropdown() : renderLabel()}
    </div>
  );
}

export default QuantityInput;
