import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ButtonToggle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import { CSwitch } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFirstAid, faPlus } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

import { Label, Loader } from "../../component/atoms";
import CheckableInputs from "../../component/molecules/CheckableInputs";
import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import {
  initialStateSigned,
  initialStateSignedWitnessed,
} from "../../config/confirm";
import { getDateStrings } from "../../helpers";
import IllicitDrugService from "../../services/IllicitDrugService";
import StockService from "../../services/StockService";
import { hideAlertMessage, showAlertMessage } from "../../store/actions";

const UNSELECTED_VIEW_ID = 0;
const toPoliceValue = "send-police";
const asDisposalValue = "send-disposal";
const policeInputs = [
  {
    autoComplete: "off",
    id: "police-name",
    label: "Name of officer",
    maxLength: 150,
    name: "policeTransferredName",
    required: true,
  },
  {
    autoComplete: "off",
    id: "police-identification",
    label: "Identification & Comments",
    maxLength: 150,
    name: "policeTransferredIdentification",
  },
];
const policeActions = [
  {
    Name: "action",
    label: "Hand To Police",
    checked: false,
    value: toPoliceValue,
  },
  {
    Name: "action",
    label: "Handle as Disposal",
    checked: false,
    value: asDisposalValue,
  },
];
const formInitialState = policeInputs.reduce(
  (obj, item) => ({ ...obj, [item.name]: "" }),
  {}
);
let serviceCancelSource;
let serviceConfig;
let serviceCancelSourceStock;

function IllicitDrugs({ history, location, locationRoute, user }) {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state) => state.settings
  );

  const [illicitStatusIds,setIllicitStatusIds] = useState(null);
  const [disablePoliceWitnessing,setDisablePoliceWitnessing] = useState(null);
  const [skipDisposalApproval,setSkipDisposalApproval] = useState(null);

  const formRef = useRef();

  const [DEFAULT_VIEW_ID, setDEFAULT_VIEW_ID] = useState(null);
  const [viewStates, setViewStates] = useState([])
  const [actionList, setActionList]= useState([]);

  const [activeViewId, setActiveViewId] = useState(getViewStateId(location));
  const [alertMessage, setAlertMessage] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [drugList, setDrugList] = useState();
  const [drugListForView, setDrugListForView] = useState();
  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedActionId, setSelectedActionId] = useState();
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState([]);
  const [forPoliceActionsChecked, setForPoliceActionsChecked] =
    useState(policeActions);

  function getViewState(statusId) {
    return viewStates.find((item) => item.statusId === statusId);
  }

  function getViewStateHash(statusId) {
    const viewState = getViewState(statusId);

    return viewState ? viewState.hash : "";
  }

  function getViewStateId(location) {
    const viewState = viewStates.find(
      (item) => item.hash === location.hash.substring(1)
    );

    return viewState ? viewState.statusId : DEFAULT_VIEW_ID;
  }

  useEffect(() => {
    if (settings.loaded) {
      setIllicitStatusIds(settings.illicitStatusIds);
      setDisablePoliceWitnessing(settings.disablePoliceWitnessing);
      setSkipDisposalApproval(settings.skipDisposalApproval);
    }
  }, [settings]);

  useEffect(() => {
    if (illicitStatusIds) {
      setDEFAULT_VIEW_ID(illicitStatusIds.RETURNED_TO_PHARMACY);

      setViewStates([
        {
          buttonText: "In Transit",
          hash: "InTransit",
          statusId: illicitStatusIds.RETURNED_TO_PHARMACY,
          statusIdNext: illicitStatusIds.STORE_IN_PHARMACY,
        },
        {
          buttonText: "For Action",
          hash: "ForAction",
          statusId: illicitStatusIds.STORE_IN_PHARMACY,
        },
        {
          buttonText: "Police",
          hash: "Police",
          statusId: illicitStatusIds.AWAITING_POLICE,
          statusIdNext: illicitStatusIds.TRANSFERED_TO_POLICE,
          statusIdForDisposal: illicitStatusIds.DISPOSE_DESTROY,
        },
      ]);
      setActionList([
        {
          label: "To transfer to police",
          name: "action",
          value: illicitStatusIds.AWAITING_POLICE,
        },
        {
          label: "To dispose",
          name: "action",
          value: illicitStatusIds.DISPOSE_DESTROY,
        },
      ]);
    }
  }, [illicitStatusIds]);

  useEffect(() => {
    serviceCancelSource = IllicitDrugService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    serviceCancelSourceStock = StockService.getCancelSource();

    getDrugList();

    return () => {
      serviceCancelSource.cancel();
      serviceCancelSourceStock.cancel();
    };
  }, []);

  useEffect(() => {
    history.replace({ hash: getViewStateHash(activeViewId) });
  }, [activeViewId]);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else if (alertMessage !== undefined) {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (drugList) {
      if (activeViewId === UNSELECTED_VIEW_ID) {
        setDrugListForView([...drugList]);
      } else {
        setDrugListForView(
          drugList.filter((item) => item.statusId === activeViewId)
        );
      }

      setFormShowValidity(false);
      setFormState(formInitialState);
      setSelectedDrugs([]);
    }
  }, [drugList]);

  useEffect(() => {
    if (isConfirmed) {
      setConfirmedStaffErrors();

      if (!selectedDrugs.length) {
        setAlertMessage({
          message: "Please select an illicit substance",
          colour: "warning",
        });
        setIsConfirmed(false);
      } else if (
        (activeViewId === illicitStatusIds.STORE_IN_PHARMACY ||
          activeViewId === illicitStatusIds.AWAITING_POLICE) &&
        !formRef.current.checkValidity()
      ) {
        setAlertMessage({
          message:
            activeViewId === illicitStatusIds.STORE_IN_PHARMACY
              ? "Please select an action for the selected substances"
              : forPoliceActionsChecked.filter((x) => x.checked === true)
                  .length === 0
              ? "Please select an action."
              : "Please complete the required fields",
          colour: "warning",
        });
        setFormShowValidity(true);
        setIsConfirmed(false);
      } else if (
        forPoliceActionsChecked.filter((x) => x.checked === true).length ===
          0 &&
        activeViewId === illicitStatusIds.AWAITING_POLICE
      ) {
        setAlertMessage({
          message: "Please select an action.",
          colour: "warning",
        });
        setIsConfirmed(false);
      } else {
        if (
          skipDisposalApproval &&
          (forPoliceActionsChecked.filter(
            (x) => x.value === asDisposalValue && x.checked === true
          ).length > 0 ||
            selectedActionId === illicitStatusIds.DISPOSE_DESTROY)
        ) {
          manageDrugsDisposal();
        } else {
          manageDrugs();
        }
      }
    }
  }, [isConfirmed]);

  async function getDrugList() {
    let isCancelled = false;

    setIsLoading(true);

    try {
      const { data } = await IllicitDrugService.getIllicitDrugs(
        locationRoute,
        user.location.lcId,
        serviceConfig
      );
      setDrugList(data);
      setReferences(data);
    } catch (error) {
      if (IllicitDrugService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the illicit substance records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsConfirmed(false);
        setIsLoading(false);
      }
    }
  }

  async function manageDrugsDisposal() {
    const payload = selectedDrugs.map((item) => ({
      id: item.illicitDrugId,
      createdBy: user.username,
      signedBy: confirmedStaff.signed,
      witnessedBy: confirmedStaff.witnessed,
      SkipDisposalApproval:true,
      disposalReference: referenceNumber.some(
        (m) => m.drugId === item.illicitDrugId
      )
        ? referenceNumber.filter((m) => m.drugId === item.illicitDrugId)[0][
            "refNumber"
          ]
        : null,
      typeId: 3,
    }));

    setIsLoading(true);

    try {
      const response = await StockService.dispose(
        locationRoute,
        user.location.lcId,
        true,
        payload,
        serviceConfig
      );

      if (response.status === 202) {
        getDrugList();
        setReferenceNumber([]);
        setAlertMessage({
          message: "Successfully updated the illicit substances",
          colour: "success",
        });
      } else {
        setAlertMessage({
          message: `There was a problem updating the illicit substances, the eCDR-Pro system may be offline.
         If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsConfirmed(false);
        setIsLoading(false);
        setReferenceNumber([]);
      }
    } catch (error) {
      if (!IllicitDrugService.isCancel(error)) {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem updating the illicit substances, the eCDR-Pro system may be offline.
           If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
        setIsConfirmed(false);
        setIsLoading(false);
        setReferenceNumber([]);
      }
    }
  }

  async function manageDrugs() {
    const payload = selectedDrugs.map((item) => ({
      ...item,
      ...(activeViewId === illicitStatusIds.AWAITING_POLICE && formState),
      signedBy: confirmedStaff.signed,
      statusId:
        activeViewId === illicitStatusIds.STORE_IN_PHARMACY
          ? selectedActionId
          : forPoliceActionsChecked.filter(
              (x) => x.value === asDisposalValue && x.checked === true
            ).length > 0
          ? getViewState(activeViewId).statusIdForDisposal
          : getViewState(activeViewId).statusIdNext,
      witnessedBy: confirmedStaff.witnessed,
      referenceNumber: referenceNumber.some(
        (m) => m.drugId === item.illicitDrugId
      )
        ? referenceNumber.filter((m) => m.drugId === item.illicitDrugId)[0][
            "refNumber"
          ]
        : null,
    }));

    setIsLoading(true);

    try {
      const response = await IllicitDrugService.manageIllicitDrug(
        user.location.lcId,
        payload,
        serviceConfig
      );

      if (response.status === 201) {
        getDrugList();
        setReferenceNumber([]);
        setAlertMessage({
          message: "Successfully updated the illicit substances",
          colour: "success",
        });
      } else {
        setAlertMessage({
          message: `There was a problem updating the illicit substances, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsConfirmed(false);
        setIsLoading(false);
        setReferenceNumber([]);
      }
    } catch (error) {
      if (!IllicitDrugService.isCancel(error)) {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem updating the illicit substances, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }
        setIsConfirmed(false);
        setIsLoading(false);
        setReferenceNumber([]);
      }
    }
  }

  function setReferences(data) {
    const referenceNumberItems = data
      .filter((item) => item.referenceNumber !== "")
      .map((item) => ({ drugId: item.id, refNumber: item.referenceNumber }));
    setReferenceNumber(referenceNumberItems);
  }

  function handleViewButtonClick({ currentTarget: { name } }) {
    const statusId = Number(name);

    if (statusId === activeViewId) {
      setActiveViewId(UNSELECTED_VIEW_ID);
      setDrugListForView([...drugList]);
    } else {
      setActiveViewId(statusId);
      setDrugListForView(drugList.filter((item) => item.statusId === statusId));
    }

    setForPoliceActionsChecked(policeActions);
    setSelectedActionId();
    setFormShowValidity(false);
    setFormState(formInitialState);
    setSelectedDrugs([]);
  }

  function handleSwitchChange({ target }) {
    const drugId = target.name;

    setSelectedDrugs((selectedDrugs) => {
      if (selectedDrugs.some((item) => item.illicitDrugId === drugId)) {
        return selectedDrugs.filter((item) => item.illicitDrugId !== drugId);
      } else {
        return [...selectedDrugs, { illicitDrugId: drugId }];
      }
    });
  }

  function handleActionChange({ target: { value } }) {
    setSelectedActionId(Number(value));
  }

  function handleInputChange({ target: { name, value } }) {
    setFormState((formState) => ({ ...formState, [name]: value }));
  }

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function handlePoliceActionsChange({ target: { value } }) {
    setForPoliceActionsChecked(
      policeActions.map((x) => ({ ...x, checked: x.value === value }))
    );
  }

  function handleReferenceChange(event) {
    const drugId = event.target.name;
    if (referenceNumber.some((item) => item.drugId === drugId)) {
      let fixedArray = referenceNumber.filter((m) => m.drugId !== drugId);

      setReferenceNumber([
        ...fixedArray,
        { drugId: drugId, refNumber: event.target.value },
      ]);
    } else {
      setReferenceNumber([
        ...referenceNumber,
        { drugId: drugId, refNumber: event.target.value },
      ]);
    }
  }

  function renderViewButton({ buttonText, statusId }) {
    return (
      <ButtonToggle
        active={statusId === activeViewId}
        className="btn-pill"
        color="primary"
        disabled={isLoading}
        key={statusId}
        name={statusId}
        onClick={handleViewButtonClick}
        outline
        title={`${statusId === activeViewId ? "Remove" : "Select"} this filter`}
      >
        {buttonText}
      </ButtonToggle>
    );
  }

  function renderPageMessage() {
    if (isLoading) {
      return null;
    }

    if (!drugList) {
      return (
        <p className="page-message">
          Failed to load illicit substances, please reload the page
        </p>
      );
    }

    if (!drugList.length) {
      return (
        <p className="page-message">
          There are currently no stored illicit substances
        </p>
      );
    }

    if (!drugListForView.length) {
      return <p className="page-message">No matching illicit substances</p>;
    }
  }

  function renderTableRow(drug) {
    const active = activeViewId !== UNSELECTED_VIEW_ID;
    const dateStrings = getDateStrings(drug.createdAt);
    const linkLocation = {
      pathname: `/pharmacy/stock/illicit/add/${drug.id}`,
      state: { from: { hash: location.hash } },
    };

    return (
      <tr key={drug.id}>
        <td>
          <Link className="table-link" to={linkLocation}>
            {drug.description}
          </Link>
        </td>
        <td>{drug.datixNo}</td>
        {/* <td>{drug.reference}</td> */}
        <td>
          <time dateTime={drug.createdAt} title={dateStrings.datetime}>
            {dateStrings.date}
          </time>
        </td>
        <td>
          <time dateTime={drug.createdAt} title={dateStrings.datetime}>
            {dateStrings.time}
          </time>
        </td>
        <td>
          <Input
            name={drug.id}
            value={
              referenceNumber.some((m) => m.drugId === drug.id)
                ? referenceNumber.filter((m) => m.drugId === drug.id)[0][
                    "refNumber"
                  ]
                : ""
            }
            onChange={handleReferenceChange}
            disabled={
              !selectedDrugs.some((item) => item.illicitDrugId === drug.id) ||
              activeViewId !== illicitStatusIds.RETURNED_TO_PHARMACY
            }
          ></Input>
        </td>
        <td>
          {active ? (
            <CSwitch
              aria-label="Select this substance"
              color="success"
              disabled={isLoading}
              name={drug.id}
              onChange={handleSwitchChange}
              shape="pill"
            />
          ) : (
            <span className="status-text">{drug.statusDisplayText}</span>
          )}
        </td>
      </tr>
    );
  }

  function renderForm() {
    if (
      activeViewId === illicitStatusIds.STORE_IN_PHARMACY ||
      activeViewId === illicitStatusIds.AWAITING_POLICE
    ) {
      return (
        <Row>
          <Form
            className={cx("form align-right", {
              "show-validity": formShowValidity,
            })}
            innerRef={formRef}
            noValidate
            onSubmit={handleFormSubmit}
          >
            {activeViewId === illicitStatusIds.STORE_IN_PHARMACY ? (
              <FormGroup>
                <CheckableInputs
                  disabled={isLoading}
                  inlineLegend
                  legend="Action"
                  list={actionList}
                  onChange={handleActionChange}
                  required
                  type="radio"
                />
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <CheckableInputs
                    required
                    legend="Action"
                    list={forPoliceActionsChecked}
                    inlineLegend
                    onChange={handlePoliceActionsChange}
                    type="radio"
                  />
                </FormGroup>
                {policeInputs.map((item) => (
                  <FormGroup key={item.id}>
                    <Label disabled={isLoading} {...item} />
                    <Input
                      disabled={
                        isLoading ||
                        forPoliceActionsChecked.filter(
                          (x) => x.value === toPoliceValue && x.checked === true
                        ).length === 0
                      }
                      onChange={handleInputChange}
                      value={formState[item.name]}
                      {...item}
                    />
                  </FormGroup>
                ))}
              </>
            )}
          </Form>
        </Row>
      );
    }

    return null;
  }

  return (
    <Col className={`IllicitDrugs IllicitDrugs-${locationRoute}`}>
      <header className="page-header row">
        <h1 className="page-heading">Illicit Substance Overview</h1>
        <Link
          className="btn btn-primary btn-icon"
          to="/pharmacy/stock/illicit/add"
        >
          Add new illicit substance
          <FontAwesomeIcon className="on-right" icon={faFirstAid} />
          <sup>
            <FontAwesomeIcon icon={faPlus} />
          </sup>
        </Link>
      </header>
      <Row className="view-buttons">
        <span>Set table view:</span>
        {viewStates.map(renderViewButton)}
      </Row>
      <Row>
        {drugListForView?.length > 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>Substance Description</th>
                <th>Incident Ref.&nbsp;No.</th>
                <th>Date Received</th>
                <th>Time Received</th>
                <th>Reference</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{drugListForView.map(renderTableRow)}</tbody>
          </Table>
        ) : (
          <>
            {renderPageMessage()}
            <Loader show={isLoading} />
          </>
        )}
      </Row>
      {drugListForView?.length > 0 && activeViewId !== UNSELECTED_VIEW_ID && (
        <>
          {renderForm()}
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={
              activeViewId === illicitStatusIds.STORE_IN_PHARMACY ||
              (activeViewId === illicitStatusIds.AWAITING_POLICE &&
                disablePoliceWitnessing)
                ? initialStateSigned
                : initialStateSignedWitnessed
            }
            inputsConfirmed={isConfirmed}
            isLoading={isLoading}
            setConfirmedStaff={setConfirmedStaff}
            setInputsConfirmed={setIsConfirmed}
            title="Illicit substance arrived in Pharmacy"
          />
        </>
      )}
    </Col>
  );
}

export default IllicitDrugs;
