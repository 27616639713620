import axios from "axios";
import AuthService from "./AuthService";

const CDService = {};

CDService.getCancelSource = () => axios.CancelToken.source();
CDService.isCancel = axios.isCancel;

CDService.getAllDrugs = async () => {
  return await AuthService.get("/ecdr/drugs");
};

CDService.getDrug = async (siteId, drug, config) => {
  return await AuthService.get(`/formulary/drugs/${siteId}/${encodeURIComponent(drug)}`, config);
};

CDService.getDrugFromCatalogue = async (drug, config) => {
  return await AuthService.get(`/ecdr/drugs/${encodeURIComponent(drug)}`, config);
};

CDService.getDrugsFromCatalogueRulingOutFormulary = async (
  siteId,
  drug,
  config
) => {
  return await AuthService.get(
    `/ecdr/drugs/${encodeURIComponent(drug)}?excludingSiteId=${siteId}`,
    config
  );
};

CDService.getWardASL = async (locationId) => {
  return await AuthService.get(`/ecdr/ASL/${locationId}`);
};

CDService.getPharmacyASL = async (locationId) => {
  return await AuthService.get(`/ecdr/PharmacyASL/${locationId}`);
};

CDService.setPharmacyASL = async (locationId, drugArray) => {
  return await AuthService.post(`/ecdr/ASL/${locationId}`, drugArray);
};

CDService.getFrontEndConfiguration = async () => {
  return await AuthService.get(`/ecdr/FrontEndConfiguration`);
};

CDService.updateFormulary = async (siteId, updatedDrug) => {
  return await AuthService.patch(`/formulary/drug/${siteId}`, updatedDrug);
};

CDService.addFormulary = async (siteId, drug) => {
  return await AuthService.post(`/formulary/drug/${siteId}`, drug);
};

CDService.getAllUoM = async (siteId, config) => {
  return await AuthService.get(`/formulary/uom/${siteId}`, config);
};

CDService.getFormularyCD = async (formularyId, config) => {
  return await AuthService.get(`/formulary/drug/${formularyId}`, config);
};

CDService.getCDFromCatalogue = async (catalogueId) => {
  return await AuthService.get(`/ecdr/Drug/${catalogueId}`);
};

export default CDService;
