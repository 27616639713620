import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import {
  faReplyAll,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckableInputs from "../component/molecules/CheckableInputs";
import QuantityInput from "../component/molecules/QuantityInput";
import CommentsBlock from "../component/molecules/CommentsBlock";
import CDService from "../services/CDService";
import SystemContext from "../context/SystemContext";
import ConfirmBlock from "../component/organisms/ConfirmBlock";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../store/actions";
import { getDateStrings } from "../helpers";

const ManageFormularyCD = ({ location, user }) => {
  const [currentCD, setCurrentCD] = useState({});
  const [allUoM, setAllUoM] = useState([]);
  const { siteId, locationId } = useContext(SystemContext).user.location;

  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors] = useState();
  const [comments, setComments] = useState("");

  const dispatch = useDispatch();

  const initialState = [
    {
      label: "Signed by",
      name: "signed",
      value: "",
      state: false,
    },
    {
      label: "Witnessed by",
      name: "witnessed",
      value: "",
      state: false,
    },
  ];

  const handleQuantityChange = (formularyPackSizesId, newQuantity) => {
    const updatedFormularyPackSizes = currentCD.formularyPacksizes.map(
      (packSize) => {
        if (packSize.formularyPackSizesId === formularyPackSizesId) {
          return { ...packSize, quantity: newQuantity };
        }
        return packSize;
      }
    );
    setCurrentCD({
      ...currentCD,
      formularyPacksizes: updatedFormularyPackSizes,
    });
  };

  const handleFieldChange = (fieldName, newValue) => {
    setCurrentCD({
      ...currentCD,
      [fieldName]: newValue,
    });
  };

  const getAllUom = () => {
    CDService.getAllUoM(siteId).then((response) => {
      setAllUoM(response.data);
    });
  };

  const getCDFromCatalogue = (catalogueId) => {
    CDService.getCDFromCatalogue(catalogueId).then((response) => {
      setCurrentCD({
        ...response.data,
        cdcatalogueNameOrFormularyName:
          response.data?.cdcatalogueNameOrFormularyName,
        vpId: response.data?.virtualId,
        apId:
          response.data.id === response.data.virtualId ? "" : response.data.id,
        uom: response.data?.cdCataloguePackSize[0]?.uom,
        formularyPacksizes: [
          ...(response.data?.cdCataloguePackSize ?? []).sort((a, b) => {
            return a.quantity - b.quantity;
          }),
        ],
        history: [],
      });
    });
  };

  const getCDFromFormulary = (formularyId) => {
    CDService.getFormularyCD(formularyId).then((response) => {
      const history = buildHistory(response.data);
      setComments("");
      setCurrentCD({
        ...response.data,
        cdcatalogueNameOrFormularyName:
          response.data?.cdCatalogue?.cdcatalogueNameOrFormularyName,
        formularyPacksizes: [
          ...response.data.formularyPacksizes.sort((a, b) => {
            return a.quantity - b.quantity;
          }),
        ],
        uom: response.data?.formularyPacksizes[0]?.uom,
        history: history,
      });
    });
  };

  const buildHistory = (cd) => {
    const history = [];
    cd.formularyPackSizeHistories?.forEach((packsize) => {
      history.push({
        createdAt: packsize.createdAt,
        date: getDateStrings(packsize.createdAt).date,
        time: getDateStrings(packsize.createdAt).time,
        historyEvent: packsize.historyEvent,
        cdcatalogueNameOrFormularyName: cd.cdcatalogueNameOrFormularyName,
        vpId: "",
        apId: "",
        uom: packsize.uom,
        quantity: packsize.quantity,
        isSafeCustody: "",
        isWitnessed: "",
        signedBy: packsize.signedBy,
        witnessedBy: packsize.witnessedBy,
        comments: packsize.comments,
      });
    });
    cd.formularyHistories?.forEach((formulary) => {
      history.push({
        createdAt: formulary.createdAt,
        date: getDateStrings(formulary.createdAt).date,
        time: getDateStrings(formulary.createdAt).time,
        historyEvent: formulary.historyEvent,
        cdcatalogueNameOrFormularyName: cd.cdcatalogueNameOrFormularyName,
        vpId: formulary.vpId,
        apId: formulary.apId,
        uom: "",
        quantity: "",
        isSafeCustody: formulary.isSafeCustody
          ? "Yes"
          : formulary.isSafeCustody === false
            ? "No"
            : "",
        isWitnessed: formulary.isWitnessed
          ? "Yes"
          : formulary.isWitnessed === false
            ? "No"
            : "",
        signedBy: formulary.signedBy,
        witnessedBy: formulary.witnessedBy,
        comments: formulary.comments,
      });
    });

    return history.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  const handleAddQuantity = () => {
    const updatedFormularyPackSizes = [
      ...currentCD.formularyPacksizes,
      { quantity: currentCD.addedQuantity },
    ];
    setCurrentCD({
      ...currentCD,
      addedQuantity: "",
      formularyPacksizes: updatedFormularyPackSizes,
    });
  };

  const handleRemoveQuantity = (index) => {
    const updatedFormularyPackSizes = [
      ...currentCD.formularyPacksizes.slice(0, index),
      ...currentCD.formularyPacksizes.slice(index + 1),
    ];
    setCurrentCD({
      ...currentCD,
      formularyPacksizes: updatedFormularyPackSizes,
    });
  };

  const handleCommentsChange = ({ target: { value } }) => {
    setComments(value);
  };

  const saveModifications = () => {
    if (currentCD && !currentCD !== {} && inputsConfirmed) {
      const updatedDrug = {
        formularyId: currentCD.formularyId,
        catalogueId: currentCD.catalogueId,
        isSafeCustody: currentCD.isSafeCustody,
        vpId: currentCD.vpId,
        isWitnessed: currentCD.isWitnessed,
        signedBy: confirmedStaff.signed,
        witnessedBy: confirmedStaff.witnessed,
        createdBy: user.username,
        locationId: locationId,
        comments: comments,
        formularyPackSizes: currentCD.formularyPacksizes.map((packsize) => {
          return {
            formularyPackSizesId: packsize.formularyPackSizesId,
            formularyId: packsize.formularyId,
            quantity: packsize.quantity,
            uom: currentCD.uom,
          };
        }),
      };

      (updatedDrug.formularyId
        ? CDService.updateFormulary(siteId, updatedDrug)
        : CDService.addFormulary(siteId, updatedDrug)
      )
        .then((result) => {
          showAlertMessage(dispatch, {
            message: "Formulary updated successfully",
            colour: "success",
          });
          getCDFromFormulary(result.data.formularyId);
        })
        .catch(() => {
          showAlertMessage(dispatch, {
            message: `Formulary update failed, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        })
        .finally(() => {
          setInputsConfirmed(false);
        });
    }
  };

  useEffect(() => {
    saveModifications();
  }, [inputsConfirmed]);

  useEffect(() => {
    if (location.state) {
      getAllUom();
      if (location.state.nxt.catalogueId) {
        getCDFromCatalogue(location.state.nxt.catalogueId);
      } else {
        getCDFromFormulary(location.state.nxt.id);
      }
    }
  }, [location]);

  const displayHistoryBlock = (
    <Row className="pb-3">
      <Col>
        <h5>History</h5>
        <table className="history-table w-100">
          <thead>
            <th>Date</th>
            <th>Time</th>
            <th className="management">Event</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th>Safe Custody</th>
            <th>Witnessed</th>
            <th>Signed by</th>
            <th>Witnessed by</th>
            <th>Comments</th>
          </thead>
          <tbody>
            {currentCD?.history &&
              currentCD.history.map((item, index) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td className="management">{item.historyEvent}</td>
                  <td>{item.quantity}</td>
                  <td>{item.uom}</td>
                  <td>{item.isSafeCustody}</td>
                  <td>{item.isWitnessed}</td>
                  <td>{item.signedBy}</td>
                  <td>{item.witnessedBy}</td>
                  <td>{item.comments}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );

  const displayCDBlock = (
    <Row>
      <Col>
        <Table className="mt-5">
          <thead>
            <tr>
              <th>Name</th>
              <th>VMP</th>
              <th>AMP</th>
              <th>UoM</th>
              <th>Safe Custody</th>
              <th>Witnessed</th>
            </tr>
          </thead>
          <tbody>
            <tr className="shadow-sm">
              <td className="drug-name">
                <span>{currentCD.cdcatalogueNameOrFormularyName}</span>
              </td>
              <td>
                <span> {currentCD.vpId}</span>
              </td>
              <td>
                <span> {currentCD.apId}</span>
              </td>
              <td>
                <Input
                  id="uom"
                  list="UoMs"
                  className="align-left custom-select-sm text-left"
                  onChange={(e) => handleFieldChange("uom", e.target.value)}
                  type="text"
                  value={currentCD.uom ?? ""}
                  maxLength="50"
                />
                <datalist id="UoMs">
                  {allUoM.map((UoM, index) => (
                    <option key={`uom-${index}`} value={UoM} />
                  ))}
                </datalist>
              </td>
              <td>
                <span className="d-flex justify-content-center">
                  <CheckableInputs
                    disabled={currentCD?.cdCatalogue?.isSafeCustody}
                    inline
                    list={[
                      {
                        checked: currentCD.isSafeCustody === true,
                        id: "isSafeCustody",
                        className: "compliant-yes",
                      },
                    ]}
                    onChange={(e) =>
                      handleFieldChange("isSafeCustody", e.target.checked)
                    }
                  />
                </span>
              </td>
              <td>
                <span className="d-flex justify-content-center">
                  <CheckableInputs
                    disabled={currentCD?.cdCatalogue?.isWitnessed}
                    inline
                    list={[
                      {
                        checked: currentCD.isWitnessed === true,
                        id: "isWitnessed",
                        className: "compliant-yes",
                      },
                    ]}
                    onChange={(e) =>
                      handleFieldChange("isWitnessed", e.target.checked)
                    }
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );

  const displayPackSizesBlock = (
    <>
      <Row>
        <Col>
          <h5>Pack Sizes</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table HistoryCDTable history-cd w-25">
            <tbody>
              {currentCD.formularyPacksizes?.map((item, index) => (
                <tr key={index}>
                  <td className="text-right QuantityEditTable">
                    <QuantityInput
                      className="pull-right d-inline-block "
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(
                          item.formularyPackSizesId,
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <span>
                      <Button
                        color="primary"
                        type="checkbox"
                        variant="secondary"
                        onClick={() => handleRemoveQuantity(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </span>
                  </td>
                </tr>
              ))}
              <tr>
                <td className="text-right QuantityEditTable">
                  <QuantityInput
                    className="pull-right d-inline-block "
                    value={currentCD.addedQuantity}
                    onChange={(e) =>
                      handleFieldChange("addedQuantity", e.target.value)
                    }
                  />
                </td>
                <td>
                  <span>
                    <Button
                      color="primary"
                      type="checkbox"
                      variant="secondary"
                      onClick={handleAddQuantity}
                    >
                      <FontAwesomeIcon size="xs" icon={faPlus} />
                    </Button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );

  return (
    <Col>
      <Row>
        <Col>
          <h1>
            {currentCD.formularyId
              ? "Update CD in Formulary"
              : "Add CD to Formulary"}
          </h1>
        </Col>
        <Col
          className="d-flex justify-content-end align-items-end"
          md={{ size: 3, offset: 2 }}
        >
          <Link
            className="shadow-sm btn btn-primary"
            to="/pharmacy/formulary/manage"
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            BACK TO MANAGE FORMULARY
          </Link>
        </Col>
      </Row>
      {displayCDBlock}
      {displayPackSizesBlock}
      <Row>
        <Col>
          <CommentsBlock
            comments={comments}
            setComments={handleCommentsChange}
            required={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-4">
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            setInputsConfirmed={setInputsConfirmed}
            confirmInitialState={initialState}
            inputsConfirmed={inputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
          />
        </Col>
      </Row>
      { currentCD.formularyId && displayHistoryBlock }
    </Col>
  );
};

export default ManageFormularyCD;
