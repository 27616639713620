import {
  faPlus,
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const FormularyDrugLine = ({ nxt, showColumns }) => {
  const {
    id,
    isSafeCustody,
    isWitnessed,
    cdcatalogueNameOrFormularyName,
    vpId,
    apId,
    formularyPacksizes,
  } = nxt;

  const history = useHistory();
  const [showDetails, setShowDetails] = React.useState(false);
  const [quantity, setQuantity] = React.useState("");

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    setQuantityUom();
  }, [showDetails]);

  const setQuantityUom = () => {
    if (!formularyPacksizes || formularyPacksizes.length === 0) {
      return;
    }
    if (!showDetails) {
      setQuantity(
        `${formularyPacksizes[0].quantity} ${formularyPacksizes[0].uom}`
      );
    } else {
      const quantityUom = formularyPacksizes.map((item) => {
        return `${item.quantity} ${item.uom}`;
      });
      setQuantity(quantityUom.join("\n"));
    }
  };

  if (formularyPacksizes && formularyPacksizes.length > 0 && !quantity) {
    setQuantityUom();
  }

  const handleAddCD = () => {
    history.push("/pharmacy/formulary/CD", { nxt: nxt });
  };

  return (
    <tr className="shadow-sm" key={id}>
      {showColumns.add && (
        <td className="cd-fixedWidth py-2 align-top">
          <Button
            color="primary"
            type="checkbox"
            variant="secondary"
            value="1"
            onClick={handleAddCD}
          >
            <FontAwesomeIcon size="xs" icon={faPlus} />
          </Button>
        </td>
      )}
      <td className="drug-name cd-item-info break-words align-top drugline">
        <Link
          className="table-link"
          to={{
            pathname: "/pharmacy/formulary/CD",
            state: { nxt: nxt },
          }}
        >
          {cdcatalogueNameOrFormularyName}
        </Link>
      </td>
      <td className="align-top drugline">{vpId}</td>
      {showColumns.ap && <td className="align-top drugline">{apId}</td>}
      <td className="align-top drugline">
        {isSafeCustody && (
          <FontAwesomeIcon className="on-right" icon={faCheck} />
        )}
      </td>
      <td className="align-top drugline">
        {isWitnessed && <FontAwesomeIcon className="on-right" icon={faCheck} />}
      </td>
      {showColumns.quantity && (
        <td className="cd-fixedWidth py-2 align-top">
          {formularyPacksizes && formularyPacksizes.length > 1 && (
            <Button
              color="primary"
              type="checkbox"
              variant="secondary"
              value="1"
              onClick={handleShowDetails}
            >
              <FontAwesomeIcon
                icon={showDetails ? faChevronLeft : faChevronRight}
              />
            </Button>
          )}
        </td>
      )}
      {showColumns.quantity && (
        <td className="cd-detail align-top"> {quantity}</td>
      )}
    </tr>
  );
};

export default memo(FormularyDrugLine);
