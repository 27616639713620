import React, { useState, useEffect } from "react";
import { Input, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const PharmaciesDropDown = ({
  dropDownList,
  setdropDownItem,
  triggerItem,
  placeholder,
  textValue,
  setTextValue,
  extraClass,
  disabled,
}) => {
  const [getItem, setGetItem] = useState([]);
  const [searchDropDown, setSearchDropDown] = useState([]);
  const [activeItemList, setActiveItemList] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [itemPicked, setItemPicked] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const itemLookUp = (e) => {
    setItemPicked(false);
    if (!activeItemList) setActiveItemList(true);

    setTextValue(e.target.value);
    setSearchDropDown(
      getItem.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const itemCheck = () => {
    if (!itemPicked) {
      setTextValue("");
      setdropDownItem();
      setSearchDropDown(dropDownList);
    }
  };

  const pickItem = (itm) => {
    setTextValue(`${itm.name} (${itm.shortName})`);
    setItemPicked(true);
  };

  useEffect(() => {
    setGetItem(dropDownList);
    setSearchDropDown(dropDownList);
    return () => {};
  }, [dropDownList]);

  useEffect(() => {
    if (triggerItem) setDropdownOpen(!dropdownOpen);
    return () => {};
  }, [triggerItem]);

  const clickItem = (itm) => {
    setDropdownOpen(!dropdownOpen);
    pickItem(itm);
    setdropDownItem({
      lcId: itm.lcId,
      siteId: itm.site.siteId,
      siteShortName: itm.site.shortName,
      name: itm.name,
    });
  };

  return (
    <Dropdown
      className={`LocationDropdown${triggerItem ? " active" : ""}`}
      isOpen={dropdownOpen}
      toggle={toggle}
      disabled={disabled}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <Input
          className={extraClass}
          type="text"
          placeholder={placeholder}
          value={textValue}
          onChange={itemLookUp}
          onBlur={itemCheck}
          disabled={disabled}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setTextValue(searchDropDown[0].name);
              clickItem(searchDropDown[0]);
            }
          }}
        />
      </DropdownToggle>
      <DropdownMenu>
        {getItem && (
          <ul>
            {searchDropDown.map((itm, idx) => (
              <li key={idx} onClick={() => clickItem(itm)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {`${itm.name} (${itm.site.shortName})`}
              </li>
            ))}
          </ul>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default PharmaciesDropDown;
