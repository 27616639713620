import React, { useEffect, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

const VALIDATION_MESSAGE = "Please select an item from the list";

function Dropdown({
  list,
  multiple = false,
  name,
  onChange,
  placeholder,
  required = false,
  value,
  ...toggleProps
}) {
  const [selected, setSelected] = useState(value || (multiple ? {} : ""));
  const [toggleText, setToggleText] = useState(placeholder);
  const [validationMessage, setValidationMessage] = useState(
    required ? VALIDATION_MESSAGE : ""
  );
  const [validity, setValidity] = useState({
    customError: required,
    valid: !required,
    valueMissing: required,
  });

  useEffect(() => {
    if (multiple) {
      const selectedLength = getSelectedLength(selected);

      setToggleText(
        selectedLength
          ? `${selectedLength} item${selectedLength === 1 ? "" : "s"}`
          : placeholder
      );
    } else {
      const listItem = list.filter(
        (item) => item.value.toString() === selected
      )[0];

      setToggleText(listItem ? listItem.label || listItem.value : placeholder);
    }

    onChange({
      target: { name, validationMessage, validity, value: selected },
    });
  }, [selected]);

  function getSelectedLength(selected) {
    return Object.values(selected).flat().length;
  }

  function isSelected(itemName, itemValue) {
    return multiple
      ? selected[itemName] && selected[itemName].includes(itemValue)
      : selected === itemValue;
  }

  function handleItemClick({ currentTarget }) {
    const { itemValue } = currentTarget.dataset;
    const itemName = currentTarget.name || name;
    let newSelected, valueMissing;

    if (multiple) {
      if (isSelected(itemName, itemValue)) {
        newSelected = {
          ...selected,
          [itemName]: selected[itemName].filter((item) => item !== itemValue),
        };
      } else {
        newSelected = {
          ...selected,
          [itemName]: [...(selected[itemName] || []), itemValue],
        };
      }
      valueMissing = required && !getSelectedLength(newSelected);
    } else {
      newSelected = itemValue;
      valueMissing = false;
    }

    setSelected(newSelected);
    setValidationMessage(valueMissing ? VALIDATION_MESSAGE : "");
    setValidity({
      customError: valueMissing,
      valid: !valueMissing,
      valueMissing,
    });
  }

  function handleClearButtonClick() {
    setSelected({});
    setValidationMessage(required ? "Please select an item from the list" : "");
    setValidity({
      customError: required,
      valid: !required,
      valueMissing: required,
    });
  }

  function handleSelectAllButtonClick() {
    setSelected(
      list.reduce(
        (obj, item) => ({
          ...obj,
          [item.name || name]: [
            ...(obj[item.name || name] || []),
            item.value.toString(),
          ],
        }),
        {}
      )
    );
    setValidationMessage("");
    setValidity({ customError: false, valid: true, valueMissing: false });
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        aria-required={required ? true : undefined}
        caret
        className={cx({ invalid: !validity.valid })}
        color="transparent"
        {...toggleProps}
      >
        {toggleText}
      </DropdownToggle>
      <DropdownMenu>
        {multiple && (
          <div className="dropdown-menu-buttons">
            <Button
              color="transparent"
              disabled={getSelectedLength(selected) === list.length}
              onClick={handleSelectAllButtonClick}
            >
              Select All
            </Button>
            <Button
              color="transparent"
              disabled={!getSelectedLength(selected)}
              onClick={handleClearButtonClick}
            >
              Clear All
            </Button>
          </div>
        )}
        {list.map((item) => {
          const active = isSelected(
            item.name || name,
            item.value ? item.value.toString() : ""
          );

          return (
            <DropdownItem
              active={active}
              className={cx({ multiple })}
              data-item-value={item.value}
              key={(item.name || "") + item.value}
              name={item.name}
              onClick={handleItemClick}
              toggle={!multiple}
            >
              {item.label || item.value}
              {multiple && active && (
                <FontAwesomeIcon className="on-right" icon={faCheck} />
              )}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Dropdown;
