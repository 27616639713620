import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Input,
  ModalFooter,
} from "reactstrap";
import { CButton } from "@coreui/react";
import PatientService from "../../services/PatientService";

function PatientMainData({
  firstname,
  lastname,
  uniqueIdentifier,
  dob,
  address,
  currentBed
}) {
  return (
    <>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>First Name(s) :</span>
        </Col>
        <Col>
          <Input
            type="text"
            name="detailFirstName"
            disabled="true"
            value={firstname}
            autoComplete="off"
            maxLength="55"
            required
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>Last Name :</span>
        </Col>
        <Col>
          <Input
            type="text"
            name="detailLastName"
            disabled="true"
            value={lastname}
            autoComplete="off"
            maxLength="55"
            required
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>Unique Identifier (MRN or NHS no):</span>
        </Col>
        <Col xs="3">
          <Input
            type="text"
            name="patient number"
            value={uniqueIdentifier}
            disabled="true"
            autoComplete="off"
            maxLength="10"
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>DOB :</span>
        </Col>
        <Col xs="3">
          <Input
            className=""
            name="patient dob"
            value={dob}
            disabled={true}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>Address :</span>
        </Col>
        <Col>
          <Input
            type="textarea"
            name="patient location"
            value={address}
            disabled={true}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row className="modalPatient-dataline">
        <Col xs="5">
          <span>Bed :</span>
        </Col>
        <Col xs="2">
          <Input
            type="text"
            name="bed"
            value={currentBed}
            disabled={true}
            autoComplete="off"
          />
        </Col>
      </Row>
    </>
  );
}

function PatientAllergies({ allergies }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Allergen</th>
          <th>Type</th>

          <th>Severity</th>
        </tr>
      </thead>
      <tbody>
        {allergies.map((allergy, index) => (
          <tr key={`allergy-${index}`}>
            <td>{allergy.allergen}</td>
            <td>{allergy.allergenType}</td>

            <td>{allergy.allergenSeverity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function PatientIssuesAndInfections({ issuesAndInfections }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Issues and infections</th>
        </tr>
      </thead>
      <tbody>
        {issuesAndInfections.map((infections, index) => (
          <tr key={`infections-${index}`}>
            <td>{infections.issuesAndInfections}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function PatientModal({
  isOpen,
  setIsOpen,
  patientId,
  toggle,
  setAlertMessage,
}) {
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    getPatientData(patientId);
  }, [patientId]);

  const getPatientData = async (patientId) => {
    try {
      if (patientId) {
        const response = await PatientService.getPatientById(patientId);
        setPatient(response.data);
      } else {
        setPatient({});
      }
    } catch (error) {
      setAlertMessage({
        message: `There was a problem getting the patient’s data, the eCDR-Pro system may be offline.
        If unable to resolve contact IT service desk.`,
        colour: "danger",
        label: "Error message",
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      centered
      className="PatientModal"
      fullscreen="lg"
      isOpen={isOpen}
      scrollable
      size="xl"
      toggle={toggle}
    >
      <ModalHeader toggle={handleClose}>Patient</ModalHeader>
      <ModalBody>
        <div>
          {patient && (
            <>
              <PatientMainData
                {...patient}
              />
              <Row>
                <Col col xs="7">
                  {patient.patientAllergies && (
                    <PatientAllergies allergies={patient.patientAllergies} />
                  )}
                </Col>

                <Col>
                  {patient.patientIssuesAndInfections && (
                    <PatientIssuesAndInfections
                      issuesAndInfections={patient.patientIssuesAndInfections}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <CButton shape="pill" color="primary" onClick={handleClose}>
          Close
        </CButton>
      </ModalFooter>
    </Modal>
  );
}

export default PatientModal;
