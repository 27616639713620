import React, { useState, useContext } from "react";
import { Row, Col, Button, InputGroup, Input } from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import SystemContext from "../../context/SystemContext";

const PatientAdmin = () => {
  const { user } = useContext(SystemContext);

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);

  const [searchValue, setsearchValue] = useState(messageState);
  const [detail, setdetail] = useState("");
  const [detailEdit, setdetailEdit] = useState(false);
  const [patientDeleted, setpatientDeleted] = useState(false);

  const deletePatient = () => {};

  return (
    <Col className="patientadmin">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        {/* <Col className="d-flex justify-content-end">
          <Link to="/ward/patients/new" className="shadow-sm btn btn-primary">
            <FontAwesomeIcon className="on-left" icon={faUserPlus} />
            <span>ADD NEW PATIENT</span>
          </Link>
        </Col> */}
      </Row>
      <Row className="mb-4">
        {/* <Col md="3">
          <SearchInput
            inputClass="patientadmin-list_input"
            name="patient search"
            type="text"
            placeholder="Search patients"
            value={searchValue}
          />
        </Col> */}
      </Row>
      <Row>
        {detail && (
          <Col
            className={`mb-4 patientadmin-detail patientadmin-list_role-${detail["roleClass"]}`}
          >
            <Row className="patientadmin-detail_actions mb-4 pb-4">
              <Col className="d-flex justify-content-end">
                {!detailEdit && (
                  <Button
                    className="mr-2 shadow-sm"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setdetailEdit(true);
                    }}
                  >
                    <FontAwesomeIcon className="on-left" icon={faEdit} />
                    Edit
                  </Button>
                )}
                <Button
                  className="mr-2 shadow-sm"
                  color="black"
                  onClick={(e) => {
                    e.preventDefault();
                    deletePatient();
                  }}
                >
                  <FontAwesomeIcon className="on-left" icon={faTrashAlt} />
                  Delete
                </Button>
              </Col>
            </Row>
            <Row className={`mt-4 ${patientDeleted && "blur"}`}>
              <Col>
                <h3 className="patientadmin-detail_trust">
                  Calderdale Royal Hospital
                  {detail["trust"]}
                </h3>

                <InputGroup>
                  <span className="block patientadmin-sub">Name :</span>
                  <Input
                    className={`patientadmin-detail_name ${
                      detailEdit && "shadow-sm"
                    }`}
                    type="text"
                    name="staff member name"
                    value=""
                    onChange=""
                    disabled={!detailEdit}
                    readOnly={!detailEdit}
                    autoComplete="off"
                  />
                </InputGroup>

                <InputGroup>
                  <span className="block patientadmin-sub">
                    Unique Identifier (NHS no.) :
                  </span>
                  <Input
                    className={`patientadmin-detail_num ${
                      detailEdit && "shadow-sm"
                    }`}
                    type="text"
                    name="staff member number"
                    value=""
                    onChange=""
                    disabled={!detailEdit}
                    readOnly={!detailEdit}
                    autoComplete="off"
                  />
                </InputGroup>
              </Col>
            </Row>
            {detailEdit && !patientDeleted && (
              <Row className="d-flex justify-content-end patientadmin-detail_sign">
                <Button
                  className="sign-button shadow-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setdetailEdit(!detailEdit);
                  }}
                >
                  <span className="sign-check-off">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <span>Confirm</span>
                </Button>
              </Row>
            )}
            {patientDeleted && (
              <div className="patientadmin-detail_deleted">
                <p className="shadow">
                  This record has been deleted
                  <span>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </p>
              </div>
            )}
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default React.memo(PatientAdmin);
