import React, { useEffect, useState } from "react";
import { Col, Row, Table, FormGroup, Label, Input, Button } from "reactstrap";
import { CAlert, CButton } from "@coreui/react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Moment from "react-moment";
import {
  faBullseye,
  faDotCircle,
  faFirstAid,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import ReturnTableHeader from "../../component/molecules/Pharmacy/ReturnTableHeader";
import ReturnTableRow from "../../component/molecules/Pharmacy/ReturnTableRow";
import ConfirmBlockOrders from "../../component/organisms/ConfirmBlockOrders";

import { getOrdersRules } from "../../config/_ordersrules";
import StockService from "../../services/StockService";

const DEFAULT_SELECTED_VIEW = "InTransit";
let serviceCancelSource;
let messageTimeoutId;

const disposedDestroyed = "DisposedDestroyed";
const forReuse = "ForReuse";

function ReturnOrders({ history, location, locationRoute, user }) {
  const mgId = useSelector((state) => state.settings.mgId);
  let ordersrules = getOrdersRules(mgId);

  useEffect(() => {
    ordersrules = getOrdersRules(mgId);
    refreshData();
  }, [mgId]);

  const messageState = {
    message: "",
    colour: "",
  };

  // default || for reuse || for disposal
  const [currentView, setCurrentView] = useState(
    location.hash.split("#")[1] || "default"
  );

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [currentViewOrders, setCurrentViewOrders] = useState([]);
  const [hasModifiedOrders, setHasModifiedOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadOrders, setLoadOrders] = useState({
    state: false,
    refresh: false,
  });
  const [modifiedOrders, setModifiedOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showConfirmBlock, setShowConfirmBlock] = useState(false);
  const [viewStatus, setViewStatus] = useState(
    ordersrules.initialStatusView.return.filter((status) => status.button)
  );
  const [action, setAction] = useState("");
  const [isSelected, setIsSelected] = useState();

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  // get order list when location is updated
  useEffect(() => {
    if (loadOrders.refresh) {
      history.push(location.pathname);
      changeView(DEFAULT_SELECTED_VIEW);
      setLoadOrders({ state: false, refresh: false });
    }

    if (loadOrders.state) {
      fetchReturnOrders();
      setLoadOrders({ state: false, refresh: false });
      setAction("");
    }
  }, [loadOrders]);

  const refreshData = () => {
    if (mgId && Object.keys(mgId).length > 0){
      const ordersFilter = history.location?.state?.ordersFilter;
      let loadedStatus = location.hash.split("#")[1];

      serviceCancelSource = StockService.getCancelSource();

      if (
        ordersFilter?.currentStatus &&
        ordersFilter.currentStatus !== "default"
      ) {
        changeView(ordersFilter.currentStatus.split("-")[0]);
      } else if (loadedStatus) {
        if (
          !loadedStatus.match(
            new RegExp(
              "^(" + viewStatus.map((item) => item.name).join("|") + ")$"
            )
          )
        ) {
          loadedStatus = "default";
        }

        changeView(loadedStatus);
      }

      fetchReturnOrders();

      return () => {
        serviceCancelSource.cancel();
        clearTimeout(messageTimeoutId);
      };
    }
  }
  // get page loaded status
  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    const isFilteredView = currentView !== "default";
    const filteredOrders = isFilteredView
      ? orders.filter(
          (order) =>
            order.status.name !== disposedDestroyed &&
            order.isDisposalIntent ===
              viewStatus.filter((x) => x.state === true)[0].isDisposalIntent
        )
      : orders.filter((order) => order.status.name !== disposedDestroyed);

    if (currentView !== location.hash.split("#")[1]) {
      history.push(
        location.pathname + (isFilteredView ? "#" + currentView : "")
      );
    }

    setCurrentViewOrders(filteredOrders);
    setShowConfirmBlock(isFilteredView);
  }, [currentView]);

  // update filtered orders on orders change
  useEffect(() => {
    setCurrentViewOrders(
      currentView !== "default"
        ? orders.filter((order) => order.status?.name === currentView)
        : orders.filter((order) => order.status?.name !== disposedDestroyed)
    );
    setModifiedOrders([]);
  }, [orders]);

  useEffect(() => {
    setHasModifiedOrders(modifiedOrders.length > 0);
  }, [modifiedOrders]);

  function splitForAction(order, status) {
    if (status?.isDisposalIntent !== undefined) {
      return (
        status.mgId === order.mgId &&
        status.isDisposalIntent === order.isDisposalIntent
      );
    }
    return status.mgId === order.mgId;
  }
  function fetchReturnOrders() {
    setOrders([]);
    setIsLoading(true);

    StockService.getDrugsReturnedFromWards(user.location.lcId, {
      cancelToken: serviceCancelSource.token,
    })
      .then((response) => {
        setOrders(
          response.data.map((order) => ({
            ...order,
            status: ordersrules.initialStatusView.return.find((status) =>
              splitForAction(order, status)
            ),
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        if (!StockService.isCancel(error)) {
          setAlertMessage({
            message: `There was a problem getting the returned orders, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
          setIsLoading(false);
        }
      });
  }

  // update order content when a table row is changed
  function modifyOrder(updatedOrder) {
    setModifiedOrders([updatedOrder]);
  }

  function removeModifiedOrder(stkMgId) {
    setModifiedOrders((modifiedOrders) =>
      modifiedOrders.filter((order) => order.stkMgId !== stkMgId)
    );
  }

  function changeView(viewName = "default") {
    setCurrentView(viewName);
    setViewStatus((viewStatus) =>
      viewStatus.map((view) => ({ ...view, state: view.name === viewName }))
    );
    setAction("");
  }

  // update view status using buttons
  function viewButtonOnClickHandler({ target }) {
    changeView(currentView !== target.name ? target.name : undefined);
  }

  function handleSelectChanged(value) {
    setIsSelected(value);
  }

  function renderMessage() {
    let message;

    if (isLoading) {
      message = ""; //"* Please wait... *";
    } else if (orders.length === 0) {
      message = "* There are currently no returned orders *";
    } else if (currentViewOrders.length === 0) {
      message = "* There are no matching orders *";
    }

    return message ? (
      <span className="h4 mt-4 text-center">{message}</span>
    ) : null;
  }

  function getOrderedOrders() {
    const groupedOrders = groupByArray(currentViewOrders, "locationName");
    const orderedGroupedOrders = groupedOrders
      .map((x) => x.values)
      .map((x) => ({
        objects: x.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        ),
        oldestDate: x.reduce((r, o) => (o.createdAt < r.createdAt ? o : r))
          .createdAt,
      }));
    const orderGroupedSortedOrders = orderedGroupedOrders.sort(
      (a, b) => new Date(a.oldestDate) - new Date(b.oldestDate)
    );
    const flattenedGroupOrders = orderGroupedSortedOrders
      .map((x) => x.objects)
      .flat();

    return flattenedGroupOrders;
  }
  function groupByArray(array, key) {
    return array.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  // signOff box states
  function renderConfirmBlock() {
    if (
      showConfirmBlock &&
      currentView !== "default" &&
      currentView !== forReuse
    ) {
      return (
        <ConfirmBlockOrders
          blockTitle={
            currentView === "InTransit"
              ? "Drugs arrived in Pharmacy"
              : action === "toDispose"
              ? "Drugs to Destroy / Dispose"
              : action === "generalStock"
              ? "Drugs to Register in Safe Custody"
              : ""
          }
          confirmInitialState={ordersrules.confirmInitialStateForReturn}
          currentStatus={currentView}
          isHasChanged={hasModifiedOrders}
          isReturnOrders
          loadStatus={{ state: true, refresh: false }}
          messageState={messageState}
          orderArray={modifiedOrders}
          setAlertMessage={setAlertMessage}
          setLoadOrders={setLoadOrders}
          action={currentView}
          location={user.location.lcId}
          user={user}
          locationRoute={locationRoute}
        />
      );
    } else if (currentView === forReuse) {
      return (
        <Row className="confirmation-block mt-4">
          <Col>
            <Row>
              <Col className="mb-4 mb-md-0" md="6">
                <h4>To Reuse</h4>
                <p>
                  <strong>Date</strong> :{" "}
                  {/* {orderArray.length} {pageStatus} {String(isWitnessed)} */}
                  <Moment
                    tz="Europe/London"
                    format="dddd Do MMMM YYYY"
                  ></Moment>
                </p>
                <p>
                  <strong>Timestamp</strong> :{" "}
                  <Moment tz="Europe/London" format="h:mm:ss a"></Moment>
                </p>
              </Col>
              {modifiedOrders.length > 0 && (
                <Col
                  md="6"
                  className="d-flex align-items-baseline justify-content-end flex-row "
                  color="primary"
                  sm="8"
                >
                  <Col md="4">
                    <label>Proceed to Reuse stock check :</label>
                  </Col>
                  <Link
                    className="sign-button btn btn-secondary"
                    color="primary"
                    to={{
                      pathname: "/pharmacy/return/reuseStockCheck",
                      state: {
                        stock: currentViewOrders.filter(
                          (x) => x.stkMgId === modifiedOrders[0]?.stkMgId
                        )[0],
                        modifiedOrders: modifiedOrders,
                      },
                    }}
                  >
                    <span className="sign-check-off">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    Next
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      );
    }

    return null;
  }

  return (
    <Col className="ReturnOrders">
      <CAlert
        aria-label={alertMessage.label}
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
      >
        {alertMessage.message}
      </CAlert>
      <Row className="mb-4">
        <Col sm="4" className="ReturnOrders-set-title">
          <h1>Returned CDs</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="8"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon ml-3"
            to={`/pharmacy/return/add-cd#${currentView}`}
          >
            ADD RETURNED CD
            <FontAwesomeIcon className="on-right" icon={faFirstAid} />
            <sup>
              <FontAwesomeIcon icon={faPlus} />
            </sup>
          </Link>
        </Col>
      </Row>
      <Row className="view-buttons ReturnOrders-set-view">
        <span>Set table view:</span>
        {viewStatus.map((view) => (
          <CButton
            className={`shadow-sm ml-2 btn-${view.state ? "on" : "off"}`}
            color="primary"
            key={view.name}
            name={view.name}
            onClick={viewButtonOnClickHandler}
            shape="pill"
            variant="outline"
          >
            {view.button}
          </CButton>
        ))}
      </Row>
      <Row>
        <Col>
          <Table className="return-table">
            <ReturnTableHeader tableViewStatus={currentView} action={action} />
            <tbody>
              {getOrderedOrders().map((order) => (
                <ReturnTableRow
                  key={order.stkMgId}
                  modifyOrder={modifyOrder}
                  order={order}
                  removeModifiedOrder={removeModifiedOrder}
                  tableViewStatus={currentView}
                  action={action}
                  patient={order.patient}
                  isSelected={isSelected}
                  setIsSelected={handleSelectChanged}
                  createdAt={order.createdAt}
                />
              ))}
            </tbody>
          </Table>
          {renderMessage()}
        </Col>
      </Row>
      <Row>
        <Col>
          {currentView === "ForAction" ? (
            <div className="action-block d-flex float-right">
              <h4 className="required">Action</h4>
              <div>
                <FormGroup
                  check
                  className={action === "generalStock" ? "active-radio" : ""}
                >
                  <Label check onClick={() => setAction("generalStock")}>
                    <FontAwesomeIcon
                      icon={
                        action === "generalStock" ? faDotCircle : faBullseye
                      }
                      className="mr-2"
                      size="lg"
                    />
                    <Input
                      type="radio"
                      name="action"
                      value="generalStock"
                      checked={action === "generalStock"}
                      readOnly
                    />
                    To register in safe custody (General Stock)
                  </Label>
                </FormGroup>
                <FormGroup
                  check
                  className={action === "toDispose" ? "active-radio" : ""}
                >
                  <Label check onClick={() => setAction("toDispose")}>
                    <FontAwesomeIcon
                      icon={action === "toDispose" ? faDotCircle : faBullseye}
                      className="mr-2"
                      size="lg"
                    />
                    <Input
                      type="radio"
                      name="action"
                      value="toDispose"
                      checked={action === "toDispose"}
                      readOnly
                    />
                    To dispose
                  </Label>
                </FormGroup>
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>

      {renderConfirmBlock()}
    </Col>
  );
}

export default React.memo(ReturnOrders);
