import React, { useEffect, useState, useContext } from "react";
import Moment from "react-moment";
import Cookies from "js-cookie";
import { CHeader } from "@coreui/react";
import { Row, Col } from "reactstrap";
import "moment-timezone";
import WardPicker from "../../component/organisms/WardPicker";

import SystemContext from "../../context/SystemContext";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const { user } = useContext(SystemContext);

  const messageState = {
    message: "",
    colour: "",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [wardAlert, setwardAlert] = useState(false);
  const [loggedIn, setloggedIn] = useState();
  const [siteId, setsiteId] = useState(user["location"]["siteId"]);

  // const [areaName, setareaName] = useState({
  //   lcId: user["location"]["lcId"],
  //   siteId: user["location"]["siteId"],
  //   siteName: user["location"]["siteName"],
  //   siteShortName: user["location"]["siteShortName"],
  //   wardInfo: user["location"]["wardInfo"],
  // });

  // useEffect(() => {
  //   if (areaName) {
  //     let changeWard = Cookies.get("user");
  //     changeWard = changeWard ? JSON.parse(changeWard) : {};
  //     changeWard["location"] = areaName;
  //     Cookies.set("user", JSON.stringify(changeWard));
  //     setactHap(areaName["lcId"]);
  //   }
  // }, [areaName]);

  useEffect(() => {
    setloggedIn(user);
    return () => {};
  }, [user]);

  return (
    <CHeader fixed={false}>
      <Col className="header-breadcrumbs"></Col>
      <Col className="header-bullet">
        <Row>
          <Col md="8" className="header-left">
            {loggedIn && (
              <>
                <Row>
                  <span className="header-user_name">{loggedIn.username}</span>
                </Row>
                <Row>
                  <span className="header-user_location">
                    <strong>{loggedIn["location"]["siteName"]}</strong>
                  </span>
                  <span>&nbsp;/&nbsp;</span>
                  <span className="header-user_location">Location</span>
                  <span>&nbsp;/&nbsp;</span>
                  <span>{loggedIn["location"]["name"]}</span>
                </Row>
              </>
            )}
          </Col>
          <Col md="4" className="header-right">
            <Row>
              <Moment format="dddd" tz="Europe/London" />
              ,&nbsp;
              <strong>
                <Moment format="Do MMM YYYY" tz="Europe/London" />
              </strong>
            </Row>
            <Row>
              <span className="header-time">
                <Moment format="h:mm a" tz="Europe/London" />
              </span>
            </Row>
          </Col>
        </Row>
      </Col>
    </CHeader>
  );
};

export default Header;
