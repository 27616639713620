import React, { useEffect, useState, useContext } from "react";
import { Button, ButtonToggle } from "reactstrap";
import { CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faTrashAlt,
  faUserPlus,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import SystemContext from "../../context/SystemContext";
import QuantityInput from "./QuantityInput";
import PatientDropdown from "./PatientDropdown";
import LocationDropDown from "./LocationDropDown";
import StockService from "../../services/StockService";

const CreateOrderPadLine = ({
  nxt,
  deleteDrug,
  updateDrugAmount,
  updateDrugUrgent,
  updateDrugPatient,
  setDisable,
  isPatient,
  isPharmacy,
  locationList,
  setSelectedLocation,
  setPharmacyStockRunningBalance,
  setAlertMessage,
  stockPharmacyHistoryId,
  setStockPharmacyHistoryId,
  selectedLocation
}) => {
  const { locationId } = useContext(SystemContext);
  const [balanceValue, setBalanceValue] = useState(0);
  const [assignPatient, setAssignPatient] = useState(false);
  const [triggerPatient, setTriggerPatient] = useState(false);
  const [patientName, setPatient] = useState("");
  const [makeUrgent, setmakeUrgent] = useState(false);
  const [location, setLocation] = useState();
  const [tempLocationId, setTempLocationId] = useState();
  const [pharmacyStock, setPharmacyStock] = useState();
  const [isInvalid, setIsInvalid] = useState(false);

  const [trClass, settrClass] = useState("shadow-sm");

  useEffect(() => {
    if (pharmacyStock && balanceValue > pharmacyStock.runningBalance) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
    updateDrugAmount(nxt, balanceValue);
    setDisable(!balanceValue);
  }, [balanceValue]);

  useEffect(() => {
    if (isInvalid) {
      setAlertMessage({
        message: `Not enough stock in balance to supply quantity`,
        colour: "warning",
      });
    }
  }, [isInvalid]);

  useEffect(() => {
    if (!nxt.isValid && nxt.isValid !== undefined) {
      settrClass("order-status-red");
    } else {
      settrClass("order-status");
    }
  }, [nxt.isValid]);

  useEffect(() => {
    if (patientName) {
      setAssignPatient(true);
      setTriggerPatient(!triggerPatient);
      updateDrugPatient(nxt, patientName);
    } else {
      if (isPharmacy) updateDrugPatient(nxt, null);
    }
  }, [patientName]);

  useEffect(() => {
    if (isPharmacy) {
      setSelectedLocation(nxt, locationId, location ? location.lcId : null);
      setTempLocationId(location ? location.lcId : locationId);
    }
  }, [location]);

  useEffect(() => {
    setAssignPatient(false);
  }, [tempLocationId]);

  useEffect(() => {
    if (nxt["qtyRequested"]) setBalanceValue(nxt["qtyRequested"]);
  }, [nxt]);

  useEffect(() => {
    if (nxt["qtyRequested"]) setBalanceValue(nxt["qtyRequested"]);
    else setBalanceValue(0);
  }, []);

  useEffect(() => {
    // if Prescription then need extra stock details
    if (isPharmacy) getPharmacyStock(nxt.catalogueId, nxt.cdVpId, nxt.cdApId);
  }, [nxt.catalogueId]);

  useEffect(() => {
    if (pharmacyStock)
      setPharmacyStockRunningBalance(nxt, pharmacyStock.runningBalance);
  }, [pharmacyStock]);

  async function getPharmacyStock(catalogueId, cdVpId, cdApId) {

    try {
      const { data } = await StockService.getPharmacyStockByCd(locationId, {
        params: { catalogueId: catalogueId, cdVpId: cdVpId, cdApId: cdApId },
      });
      setPharmacyStock(data);
    } catch (error) {
      setAlertMessage({
        message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
        If unable to resolve contact IT service desk.`,
        colour: "danger",
        label: "Error message",
      });
    }
  }

  function handleHistoryButtonClick(event) {
    event.preventDefault();
    if (stockPharmacyHistoryId === pharmacyStock.stkPhId)
      setStockPharmacyHistoryId();
    else setStockPharmacyHistoryId(pharmacyStock.stkPhId);
  }

  return (
    <tr className={`"shadow-sm" ${trClass}`}>
      {isPharmacy && (
        <td className="prescription-history-trigger">
          <>
            {nxt.pharmacyStockRunningBalance && (
              <ButtonToggle
                active={true}
                className="prescription-history-btn"
                color={
                  pharmacyStock &&
                  pharmacyStock.stkPhId === stockPharmacyHistoryId
                    ? "primary"
                    : "secondary"
                }
                onClick={handleHistoryButtonClick}
                outline
              >
                <FontAwesomeIcon icon={faHistory} />
              </ButtonToggle>
            )}
          </>
        </td>
      )}
      <td className="drug-name">
        <span>{nxt["cdcatalogueNameOrFormularyName"]}</span>
      </td>
      {isPharmacy && (
        <>
          <td className="location-container">
            <LocationDropDown
              dropDownList={locationList}
              inputValue=""
              name="patient-ward"
              placeholder="In Patient Location"
              setdropDownItem={setLocation}
            />
          </td>
          <td>
            {nxt.pharmacyStockRunningBalance && (
              <span>
                {nxt.pharmacyStockRunningBalance} {nxt.doseUom}
              </span>
            )}
          </td>
        </>
      )}
      <td>
        <QuantityInput
          alignCenter
          placeholder={balanceValue}
          onValueChange={setBalanceValue}
          unitsValue={nxt.doseUom}
          invalidate={isInvalid}
          disabled={isPharmacy && !nxt.pharmacyStockRunningBalance}
        />
      </td>
      <td>
        <CTooltip content="Urgent">
          <Button
            color="transparent"
            className={`icon-urgent ${makeUrgent ? "icon-urgent-active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              setmakeUrgent(!makeUrgent);
              updateDrugUrgent(nxt);
            }}
          >
            <FontAwesomeIcon icon={faExclamation} />
          </Button>
        </CTooltip>
      </td>
      {isPatient && (
        <td className="patient-picker">
          <PatientDropdown
            allowCreatePatient
            setPatient={setPatient}
            triggerPatient={triggerPatient}
            alternateLocationId={tempLocationId}
          />
          <CTooltip content="Select Patient">
            <Button
              color="transparent"
              className={`icon-patient ${
                assignPatient ? "icon-patient-active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setTriggerPatient(!triggerPatient);
              }}
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          </CTooltip>
        </td>
      )}
      <td>
        <CTooltip content="Remove">
          <Button
            className="icon-delete"
            onClick={(e) => {
              e.preventDefault();
              deleteDrug(nxt);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </CTooltip>
      </td>
    </tr>
  );
};

export default CreateOrderPadLine;
