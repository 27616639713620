import React, { useEffect, memo } from "react";
import { Table } from "reactstrap";
import FormularyDrugLine from "./FormularyDrugLine";
import SearchInput from "../../component/molecules/SearchInput";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../store/actions";

const FormularyDrugSearchList = ({
  siteId,
  setSearchDrugs,
  mapSearchDrugs,
  minCharsSearch = 0,
  searchDrugsCall,
  searchDrugs,
  setModified,
  modified,
  searchValue,
  setSearchValue,
  showColumns,
  searchAtStart,
  drugSearchPlaceholder
}) => {
  const dispatch = useDispatch();
  const performSearch = () => {
    searchDrugsCall(siteId, searchValue)
      .then((response) => {
        setSearchDrugs(mapSearchDrugs(response.data));
        setModified(false);
      })
      .catch((error) => {
        showAlertMessage(dispatch, {
          message: `Approved list for this ward is unavailable right now, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  };

  useEffect(() => {
    if (searchValue.length > minCharsSearch) {
      performSearch();
    } else {
      setSearchDrugs([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchAtStart) {
      performSearch();
    }
  }, []);

  return (
    <>
      <SearchInput
        disabled={modified}
        inputClass="search-holder"
        name="drug search"
        type="text"
        placeholder={drugSearchPlaceholder}
        displayValue={searchValue}
        value={setSearchValue}
      />
      {searchDrugs.length > 0 && (
        <Table className="CDTable DrugSearchList" hover>
          <thead >
            <tr >
              {showColumns.add && <th className="cd-fixedWidth"></th>}
              <th className="cd--item-info">Name</th>
              <th>VMP</th>
              {showColumns.ap && <th>AMP</th>}
              <th>Safe Custody</th>
              <th>Witnessed</th>
              {showColumns.quantity && (
                <th className="cd-fixedWidth">Show Quantities</th>
              )}
              {showColumns.quantity && <th>Quantity</th>}
            </tr>
          </thead>
          <tbody>
            {searchDrugs.map((cd) => (
              <FormularyDrugLine
                key={cd.id}
                nxt={cd}
                showColumns={showColumns}
              />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default memo(FormularyDrugSearchList);
