import React from "react";
import { CButton } from "@coreui/react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const Pharmacy = "pharmacy";
const InTransit = "InTransit";
const RejectedByWard = "RejectedByWard";
const RejectedByPharmacy = "RejectedByPharmacy";
const ReadyForCollection = "ReadyForCollection";
const ReadyToRegister = "ReadyToRegister";

const OrderTableHeader = ({
  isPharmacy,
  filters,
  currentStatus,
  updateFilter,
  currentView,
  locationRoute,
  rejectOrder,
  isPatient,
  showLocations,
  isPrescription
}) => {
  const isCancellationProcess = locationRoute === Pharmacy && currentStatus === ReadyForCollection;
  if (isPharmacy === undefined){
    isPharmacy = (locationRoute === Pharmacy);
  }
  const CancelButton = (text) => {
    return (<Button
      color="primary"
      className="shadow-sm d-flex align-items-center"
      onClick={(e) => {
        e.preventDefault();
        rejectOrder(isCancellationProcess);
      } }
    >
      <div className="icon-group">
        <FontAwesomeIcon className="icon-1" icon={faCircle} />
        <FontAwesomeIcon className="icon-2" icon={faSlash} />
      </div>
      <span> {text}</span>
    </Button>);
  };
  return (
    <thead>
      <tr>
        <th className="AllOrders-set-status">
          {currentView === "default" ? (
            <div className="d-flex">
              {filters.map((cnt, cdx) => (
                <CButton
                  key={cdx}
                  className={`shadow-sm mr-2 ${
                    cnt["state"] ? "btn-on" : "btn-off"
                  }`}
                  variant="outline"
                  shape="pill"
                  color="primary"
                  name={cnt["name"]}
                  onClick={(e) => {
                    e.preventDefault();
                    updateFilter(cnt);
                  }}
                >
                  {cnt["text"]}
                </CButton>
              ))}
            </div>
          ) : currentStatus.split("-")[0] === "Pharmacy" ||
            currentStatus.split("-")[0] === "ReadyForCollection" ? (
              !isPharmacy && currentStatus.split("-")[0] === "ReadyForCollection" ? (
                <></>
              ) : (
                CancelButton(
                  currentStatus.split("-")[0] === "Pharmacy"
                    ? "REJECT ORDER"
                    : isPrescription
                      ? "CANCEL PRESCRIPTION"
                      : "CANCEL ORDER"
                )
              )
            ) : isCancellationProcess ? (
              CancelButton("CANCEL PRESCRIPTION")
            ) : (
              <></>
            )}
        </th>
        {!isPharmacy && showLocations && <th>Location</th>}
        <th>{isPharmacy ? "Location" : "Ref:"}</th>
        {isPharmacy && <th>Created At</th>}
        {isPharmacy &&
          currentStatus.split("-")[0] !== InTransit &&
          currentStatus.split("-")[0] !== RejectedByWard &&
          currentStatus.split("-")[0] !== RejectedByPharmacy && (
          <th>
            <span>Running</span>Balance
          </th>
        )}
        <th>
          <span>Quantity</span>Requested
        </th>
        <th>
          <span>Quantity</span>Supplied
        </th>
        <th>
          {currentView === "single" // if the view is single
            ? "Status" // then always show the last block as status
            : isPharmacy // else if the view is not single and the route is pharmacy
              ? currentStatus.split("-")[1] === "true" && // and if the status is true
              currentStatus.split("-")[0] === ReadyForCollection // show select the status is ReadyForCollection
                ? "Select"
                : "Status"
              : currentStatus.split("-")[1] === "true" &&
              (currentStatus.split("-")[0] === ReadyToRegister ||
                currentStatus.split("-")[0] === InTransit)
                ? "Select"
                : "Status"}
        </th>
      </tr>
    </thead>
  );
};

export default OrderTableHeader;
