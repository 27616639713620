import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, FormGroup, Row, Table } from "reactstrap";
import cx from "classnames";

import { Label, Loader } from "../../../component/atoms";
import DrugSearchList from "../../../component/organisms/DrugSearchList";
import { replacePropertyValues } from "../../../helpers";
import {
  getInitialState,
  getURLParams,
  getValidationMessage,
  setCustomErrorMessages,
  setValidities,
} from "../../../helpers/forms";
import LocationsService from "../../../services/LocationsService";
import ReportService from "../../../services/ReportService";
import { hideAlertMessage, showAlertMessage } from "../../../store/actions";

const formFields = {
  Medicine: {
    id: "medicine",
    label: "Medicine",
    name: "Medicine",
    required: true,
  },
};
let serviceCancelSource;
let serviceConfig;

function WardBalances({ locationRoute, user }) {
  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState();
  const [formState, setFormState] = useState(
    getInitialState(formFields, useRef)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showValidity, setShowValidity] = useState(false);
  const [report, setReport] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [wardList, setWardList] = useState([]);
  const [unitOfMeasure, setUnitOfMeasure] = useState();

  useEffect(async () => {
    let isCancelled = false;

    serviceCancelSource = LocationsService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    try {
      const { data } = await LocationsService.getLocations(
        user.location.siteId,
        serviceConfig
      );
      setWardList(data.map((item) => item.name).sort());
    } catch (error) {
      if (LocationsService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the ward list, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }

    setValidities(setFormState);

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  async function getReport() {
    const config = { ...serviceConfig, params: getURLParams(formState) };
    let isCancelled = false;

    setIsLoading(true);
    setReport();
    setTotalBalance();

    try {
      const { data: response } = await ReportService.getWardBalances(user.location.siteId, config);
      const data = response.filter((wb) => wardList.includes(wb.locationName));

      setReport(
        data.reduce(
          (obj, item) => ({ ...obj, [item.locationName]: item.balance }),
          {}
        )
      );
      setTotalBalance(
        data.reduce(
          (a, b) => a + (b.locationName === "Pharmacy" ? 0 : b.balance ?? 0),
          0
        )
      );
      if (data[0]) setUnitOfMeasure(data[0].drugUoM);
      setShowValidity(false);
    } catch (error) {
      if (ReportService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the log records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setCustomErrorMessages(setFormState, error.response);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function handleFieldChange({
    target: { name, validationMessage, validity, value },
  }) {
    if (formState[name].innerRef) {
      formState[name].innerRef.current.setCustomValidity("");
    }

    setFormState((formState) =>
      replacePropertyValues(formState, name, {
        customErrorMessage: validationMessage,
        validity,
        value,
      })
    );
  }

  function handleFormSubmit(event) {
    const message = getValidationMessage(formState);

    if (message) {
      setAlertMessage({
        message,
        colour: "warning",
      });
    } else {
      getReport();
    }

    setShowValidity(true);
    event.preventDefault();
  }

  function renderReport() {
    const tableBody = renderTableBody();
    return tableBody.length ? (
      <Col className="order-2 order-md-1" md="8" xl="6">
        <Table className="report-table">
          <thead>
            <tr>
              <th>Location</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </Table>
      </Col>
    ) : (
      <p className="page-message">
        There are currently no ward balances for the controlled drug
      </p>
    );
  }

  function renderTableBody() {
    let result = [];

    wardList.forEach((item) => {
      if (report[item]) {
        result.push(
          <tr key={item}>
            <td className="min-height">{item}</td>
            <td className="text-right">
              {report[item] ?? 0} {unitOfMeasure}
            </td>
          </tr>
        );
      }
    });

    return result;
  }

  return (
    <Col className={`WardBalances WardBalances-${locationRoute} report-page`}>
      <Row className="mb-4">
        <h1>Ward Balances</h1>
      </Row>
      <Form
        className={cx("form horizontal", { "show-validity": showValidity })}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <Row className="full-width-sm">
          <Col md="10">
            <FormGroup
              title={formState.Medicine.customErrorMessage || undefined}
            >
              <Label disabled={isLoading} {...formFields.Medicine} />
              <DrugSearchList
                disableButton
                disabled={isLoading}
                isDrugSearchFromCatalogue
                onChange={handleFieldChange}
                searchListClass="search-list-fixed"
                setAlertMessage={setAlertMessage}
                showDrugName
                selectableSearch
                {...formFields.Medicine}
              />
            </FormGroup>
          </Col>
         </Row>
        <Row className="full-width-sm">
          <Col className="buttons offset-md-3" md="2">
            <Button color="primary" disabled={isLoading} type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="full-width-sm">
        {report && wardList.length && renderReport()}

        {!!totalBalance && (
          <Col className="order-1 order-md-2" md="4">
            <p className="total-balance">
              Total Balance: {totalBalance} {unitOfMeasure}
            </p>
          </Col>
        )}
        <Loader show={isLoading} />
      </Row>
    </Col>
  );
}

export default WardBalances;
