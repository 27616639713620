import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import cx from "classnames";

const DEFAULT_MAX = 999999;

function getMaxLength(max, includeSign) {
  if (includeSign) {
    return max.toString().length + 1;
  }

  return max.toString().length;
}

function getPattern(allowNegative, max, allowDecimals) {
  const sign = allowNegative ? "-?" : "";

  if (allowDecimals){
    return `(?=^${sign}(.{1,${getMaxLength(max)}}|(?:.{1,${getMaxLength(max)}}\\.[0-9]{0,3}))$)(^${sign}([0-9]+\\.)?[0-9]*$)`;
  }
  return `(?=^${sign}.{1,${getMaxLength(max)}}$)(^${sign}([0-9]+\\.)?[0-9]*$)`;
  
}

function toNumber(str) {
  return Number(str);
}

function NumberInput({
  allowInvalidInput = true,
  allowNaN = false,
  allowNegative = false,
  allowDecimals = false,
  className,
  defaultValueAsNumber = 0,
  initialValue = "",
  invalidate,
  max = DEFAULT_MAX,
  min = allowNegative ? DEFAULT_MAX * -1 : 0,
  onBlur,
  onChange,
  onValueChange,
  pattern = getPattern(allowNegative, max, allowDecimals),
  showEmptyValue = false,
  value,
  resetValueAsNumber,
  ...inputProps
}) {
  const inputRef = useRef();

  const [inputReset, setInputReset] = useState(null);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [valueAsNumber, setValueAsNumber] = useState();

  useEffect(() => {
    if (invalidate !== undefined) {
      setIsValid(!invalidate);
    }
  }, [invalidate]);

  useEffect(() => {
    if (resetValueAsNumber === true) {
      setValueAsNumber(defaultValueAsNumber);
    }
  }, [resetValueAsNumber]);

  useEffect(() => {
    if (value !== undefined) {
      if (value === "") {
        setInputValue(showEmptyValue ? defaultValueAsNumber : value);
      } else {
        const valuePropAsNumber = toNumber(value);

        if (!isNaN(valuePropAsNumber)) {
          setInputValue(value);
        }
      }
    }
  }, [value]);

  useLayoutEffect(() => {
    if (inputReset) {
      inputReset.element.setSelectionRange(
        inputReset.caretPos,
        inputReset.caretPos
      );
      setInputReset(null);
    }
  }, [inputReset]);

  useEffect(() => {
    if (valueAsNumber !== undefined) {
      if (onChange) {
        onChange({ target: { ...inputRef.current } });
      }

      if (onValueChange) {
        onValueChange(valueAsNumber);
      }

      setIsValid(isValidNumber(valueAsNumber) && !invalidate);
    }
  }, [valueAsNumber]);

  function isValidNumber(num) {
    if (min !== undefined && num < min) {
      return false;
    } else if (max !== undefined && num > max) {
      return false;
    }

    return true;
  }

  function matchesPattern(str) {
    return str.match(new RegExp(pattern));
  }

  function resetInputCaret() {
    setInputReset({
      caretPos:
        inputRef.current.selectionEnd -
        (inputRef.current.value.length - inputValue.toString().length),
      element: inputRef.current,
    });
  }

  function handleInputBlur(event) {
    const {
      target: { value },
    } = event;

    if (value !== "" && matchesPattern(value)) {
      const inputValueAsNumber = toNumber(value);

      if (isNaN(inputValueAsNumber)) {
        setInputValue(showEmptyValue ? valueAsNumber : "");
      } else {
        const decimals = value.split(".")[1];

        setInputValue(
          inputValueAsNumber.toFixed(decimals ? decimals.length : 0)
        );
      }
    }

    if (onBlur) {
      onBlur(event);
    }
  }

  function handleInputChange({ target: { value } }) {
    if (value === "") {
      setInputValue(showEmptyValue ? defaultValueAsNumber : value);
      setValueAsNumber(allowNaN ? NaN : defaultValueAsNumber);
    } else if (matchesPattern(value)) {
      const inputValueAsNumber = toNumber(value);

      if (isNaN(inputValueAsNumber)) {
        setInputValue(value);
        setValueAsNumber(allowNaN ? NaN : defaultValueAsNumber);
      } else if (allowInvalidInput || isValidNumber(inputValueAsNumber)) {
        setInputValue(value);
        setValueAsNumber(inputValueAsNumber);
      } else {
        resetInputCaret();
      }
    } else {
      resetInputCaret();
    }
  }

  return (
    <Input
      autoComplete="off"
      className={cx("NumberInput", className, {
        invalid: !isValid,
        valid: isValid,
      })}
      innerRef={inputRef}
      inputMode="numeric"
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      pattern={pattern}
      style={{
        "--content-width": getMaxLength(max, true) + "ch",
        "--content-min-width": getMaxLength(DEFAULT_MAX, true) + "ch",
      }}
      value={inputValue}
      {...inputProps}
    />
  );
}

export default NumberInput;
