import AuthService from "./AuthService";

const OrdersService = {};

OrdersService.getAllOrders = async (locationId) => {
  return await AuthService.get(`/order/orders/${locationId}`);
};

OrdersService.getWardOrder = async (locationId, ShowAllOfType = []) => {
  let url = `/order/ward/${locationId}`;
  if (ShowAllOfType.length > 0) {
    url += `?`;
    ShowAllOfType.map((e, i) => i === ShowAllOfType.length -1 ? url += `showTypes=${e}` : url += `showTypes=${e}&`);
  }
  return await AuthService.get(url);
};

OrdersService.getWardDashboard = async (locationId, ShowAllOfType = []) => {
  let url = `/order/ward/${locationId}/dashboard`;
  if (ShowAllOfType.length > 0) {
    url += `?`;
    ShowAllOfType.map((e, i) => i === ShowAllOfType.length -1 ? url += `showTypes=${e}` : url += `showTypes=${e}&`);
  }

  return await AuthService.get(url);
};

OrdersService.getPharmacyDashboard = async (locationId) => {
  return await AuthService.get(`/order/pharmacy/dashboard/${locationId}`);
};

OrdersService.createOrder = async (locationId, orderArray, lcFrom) => {
  let url = `/order/CreateOrders/${locationId}`;
  if (lcFrom) {
    url += `?lcFrom=${lcFrom}`;
  }
  return await AuthService.post(url, orderArray);
};

OrdersService.createOrderNamedPatient = async (locationId, orderArray) => {
  return await AuthService.post(`/order/CreateOrdersNamedPatient/${locationId}`, orderArray);
};

OrdersService.updateOrder = async (orderArray) => {
  return await AuthService.patch("/order/UpdateOrders", orderArray);
};

OrdersService.WardStockEditDatix = async (stockManage) => {
  return await AuthService.patch("/order/WardStockEditDatix", stockManage);
};

OrdersService.adminToPatient = async (adminToPatient) => {
  return await AuthService.post("/order/AdministerToPatient", adminToPatient);
};

OrdersService.adminToPatientPharmacy = async (adminToPatient) => {
  return await AuthService.post("/order/AdministerToPatient?IsPharmacy=true", adminToPatient);
};

OrdersService.createOrderMessage = async (orderId, messageArray) => {
  return await AuthService.post(
    `/order/${orderId}/CreateOrderComment`,
    messageArray
  );
};

export default OrdersService;
