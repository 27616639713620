export const reports = {
  pharmacy: [
    {
      name: "CD Orders",
      path: "/pharmacy/reports/cd-orders",
    },
    {
      name: "Ward Balances",
      path: "/pharmacy/reports/ward-balances",
    },
    {
      name: "Disposal",
      path: "/pharmacy/reports/cd-disposal",
    },
    {
      name: "Compliance Audits",
      path: "/pharmacy/reports/Compliance-Audit-History-Report",
    },
    {
      name: "Stock Checks",
      path: "/pharmacy/reports/stock-checks",
    },
    {
      name: "General, Named Patient and TTO CDs",
      path: "/pharmacy/reports/general-stock",
    },
  ],
  ward: [
    {
      name: "CD Orders",
      path: "/ward/reports/cd-orders",
    },
    {
      name: "Stock Checks",
      path: "/ward/reports/stock-checks",
    },
    {
      name: "General Stock and Named Patient CDs",
      path: "/ward/reports/general-stock",
    },
    {
      name: "Compliance Audits",
      path: "/ward/reports/Compliance-Audit-History-Report",
    },
  ],
};
