// DUAL PAGES
import AdministerStock from "../pages/AdministerStock";
import CreateOrderNamedPatient from "../pages/CreateOrderNamedPatient";
import CurrentStock from "../pages/CurrentStock";
import Dashboard from "../pages/Dashboard";
import Disposal from "../pages/Disposal";
import Help from "../pages/Help";
import NewPatient from "../pages/NewPatient";
import Reports from "../pages/Reports";
import SingleOrder from "../pages/SingleOrder";
import StockAudit from "../pages/StockAudit";
import ComplianceAuditHistoryReport from "../pages/Reports/ComplianceAuditHistoryReport";
import StockCheckReport from "../pages/Reports/StockCheck";
import Settings from "../pages/Settings";

// PHARMACY PAGES
import PharmacyAddIllicitDrug from "../pages/pharmacy/AddIllicitDrug";
import AddStock from "../pages/pharmacy/AddStock";
import TransferCD from "../pages/pharmacy/TransferCD";
import PharmacyAllOrders from "../pages/pharmacy/AllOrders";
import ApprovedLocationList from "../pages/pharmacy/ApprovedLocationList";
import ApprovedLocations from "../pages/pharmacy/ApprovedLocations";
import CloneLocationList from "../pages/pharmacy/CloneLocationList";
import CreateApprovedList from "../pages/pharmacy/CreateApprovedList";
import CreateNewAuditLine from "../pages/pharmacy/CreateNewAuditLine";
import PharmacyIllicitDrugs from "../pages/pharmacy/IllicitDrugs";
import ReturnOrders from "../pages/pharmacy/ReturnOrders";
import StockCheckReturn from "../pages/pharmacy/StockCheckReturn";
import StockAuditClone from "../pages/pharmacy/StockAuditClone";
import StockAuditCreate from "../pages/pharmacy/StockAuditCreate";
import StockAuditIndividual from "../pages/pharmacy/StockAuditIndividual";
import StockAuditManagement from "../pages/pharmacy/StockAuditManagement";
import PharmacyCDDisposal from "../pages/pharmacy/reports/CDDisposal";
import PharmacyCDOrders from "../pages/pharmacy/reports/CDOrders";
import PharmacyWardBalances from "../pages/pharmacy/reports/WardBalances";
import AddReturnedCD from "../pages/pharmacy/AddReturnedCD";
import ManageFormulary from "../pages/pharmacy/ManageFormulary";
import ManageFormularyCD from "../pages/ManageFormularyCD";
import AddToFormulary from "../pages/pharmacy/AddToFormulary";
import ManageLocations from "../pages/pharmacy/ManageLocations";
import EditLocation from "../pages/pharmacy/EditLocation";
import ManagePatient from "../pages/pharmacy/ManagePatients";

// WARD PAGES
import AddCD from "../pages/ward/AddCD";
import WardAddIllicitDrug from "../pages/ward/AddIllicitDrug";
import AddPatientsOwnStock from "../pages/ward/AddPatientsOwnStock";
import WardAllOrders from "../pages/ward/AllOrders";
import CreateOrder from "../pages/ward/CreateOrder";
import WardIllicitDrugs from "../pages/ward/IllicitDrugs";
import PatientAdmin from "../pages/ward/PatientAdmin";
import PatientsOwnStock from "../pages/ward/PatientsOwnStock";
import RemoveFromCupboard from "../pages/ward/RemoveFromCupboard";
import StaffAdmin from "../pages/ward/StaffAdmin";
import WardCDOrders from "../pages/ward/reports/CDOrders";
import GeneralStock from "../pages/ward/reports/GeneralStock";
import AddClinicalWaste from "../pages/ward/AddClinicalWaste";

const routes = [
  {
    path: "/pharmacy/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/pharmacy/stock",
    exact: true,
    name: "Current stock",
    component: CurrentStock,
  },
  {
    path: "/pharmacy/stock/transfer",
    exact: true,
    name: "Create Inter-Pharmacy Transfer",
    component: TransferCD,
  },
  {
    path: "/pharmacy/stock/administer",
    exact: true,
    name: "Administer stock",
    component: AdministerStock,
  },
  {
    path: "/pharmacy/stock/add",
    exact: true,
    name: "Add new stock",
    component: AddStock,
  },
  {
    path: "/pharmacy/stock/add-cd",
    exact: true,
    name: "Add CD",
    component: AddCD,
  },
  {
    path: "/pharmacy/stock/disposal",
    exact: true,
    name: "Stock disposal",
    component: Disposal,
  },
  {
    path: "/pharmacy/stock/illicit",
    exact: true,
    name: "Illicit drugs",
    component: PharmacyIllicitDrugs,
  },
  {
    path: "/pharmacy/stock/illicit/add/:id?",
    exact: true,
    name: "Add illicit drugs",
    component: PharmacyAddIllicitDrug,
  },
  {
    path: "/pharmacy/stock/orders",
    exact: true,
    name: "Pharmacy orders",
    component: PharmacyAllOrders,
  },
  {
    path: "/pharmacy/stock/orders/#:mode",
    exact: true,
    name: "Pharmacy orders",
    component: PharmacyAllOrders,
  },
  {
    path: "/pharmacy/stock/orders/single",
    exact: true,
    name: "Single order",
    component: SingleOrder,
  },
  {
    path: "/pharmacy/return",
    exact: true,
    name: "Drugs returned",
    component: ReturnOrders,
  },
  {
    path: "/pharmacy/return/reuseStockCheck",
    exact: true,
    name: "Stock Check Return",
    component: StockCheckReturn,
  },
  {
    path: "/pharmacy/return/add-cd",
    exact: true,
    name: "Add CD",
    component: AddReturnedCD,
  },
  {
    path: "/pharmacy/locations/approved",
    exact: true,
    name: "Approved stock location",
    component: ApprovedLocations,
  },
  {
    path: "/pharmacy/locations/approved/location/:location",
    exact: true,
    name: "Approved stock location for clinical area",
    component: ApprovedLocationList,
  },
  {
    path: "/pharmacy/locations/clone/location/:location",
    exact: true,
    name: "Clone stock list",
    component: CloneLocationList,
  },
  {
    path: "/pharmacy/locations/approved/create",
    exact: true,
    name: "Create new stock list",
    component: CreateApprovedList,
  },
  {
    path: "/pharmacy/audit",
    exact: true,
    name: "Stock audit Management",
    component: StockAuditManagement,
  },
  {
    path: "/pharmacy/audit/create",
    exact: true,
    name: "Stock audit ward",
    component: StockAuditCreate,
  },
  {
    path: "/pharmacy/audit/create/new-line",
    exact: true,
    name: "Stock audit new line",
    component: CreateNewAuditLine,
  },
  {
    path: "/pharmacy/audit/:ward",
    exact: true,
    name: "Stock audit ward",
    component: StockAuditIndividual,
  },
  {
    path: "/pharmacy/audit/clone/:ward",
    exact: true,
    name: "Clone stock audit ward",
    component: StockAuditClone,
  },
  {
    path: "/pharmacy/reports",
    exact: true,
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pharmacy/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pharmacy/reports/cd-disposal",
    exact: true,
    name: "Disposal Report",
    component: PharmacyCDDisposal,
  },
  {
    path: "/pharmacy/reports/cd-orders",
    exact: true,
    name: "CD Orders Report",
    component: PharmacyCDOrders,
  },
  {
    path: "/pharmacy/reports/Compliance-Audit-History-Report",
    exact: true,
    name: "Compliance Audits",
    component: ComplianceAuditHistoryReport,
  },
  {
    path: "/pharmacy/reports/ward-balances",
    exact: true,
    name: "Ward Balances Report",
    component: PharmacyWardBalances,
  },
  {
    path: "/pharmacy/reports/stock-checks",
    exact: true,
    name: "Stock Check Report",
    component: StockCheckReport,
  },
  {
    path: "/pharmacy/help",
    exact: true,
    name: "Help",
    component: Help,
  },
  {
    path: "/pharmacy/patients/new",
    exact: true,
    name: "Location patients Admin",
    component: NewPatient,
  },
  {
    path: "/pharmacy/patients/manage",
    exact: true,
    name: "Manage Patients",
    component: ManagePatient,
  },
  {
    path: "/pharmacy/stock/CreateOrderNamedPatient",
    exact: true,
    name: "Create Order Named Patient",
    component: CreateOrderNamedPatient,
  },
  {
    path: "/pharmacy/stock/audit",
    exact: true,
    name: "Stock Audit",
    component: StockAudit,
  },
  {
    path: "/pharmacy/stock/interpharmacy",
    exact: true,
    name: "Area orders",
    component: WardAllOrders,
  },
  {
    path: "/pharmacy/stock/interpharmacy/#:mode",
    exact: true,
    name: "Area orders",
    component: WardAllOrders,
  },
  {
    path: "/pharmacy/formulary/manage",
    exact: true,
    name: "Manage Formulary",
    component: ManageFormulary,
  },
  {
    path: "/pharmacy/locations/manage",
    exact: true,
    name: "Manage Locations",
    component: ManageLocations,
  },
  {
    path: "/pharmacy/location",
    exact: true,
    name: "Manage Locations",
    component: EditLocation,
  },
  {
    path: "/pharmacy/formulary/CD",
    exact: true,
    name: "Manage Formulary",
    component: ManageFormularyCD,
  },
  {
    path: "/pharmacy/formulary/add",
    exact: true,
    name: "Add CD to Formulary from Catalogue",
    component: AddToFormulary,
  },
  {
    path: "/pharmacy/reports/general-stock",
    exact: true,
    name: "General Stock Report",
    component: GeneralStock,
  },
  {
    path: "/ward/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/ward/stock",
    exact: true,
    name: "Current stock",
    component: CurrentStock,
  },
  {
    path: "/ward/stock/add",
    exact: true,
    name: "Add new stock",
    component: AddStock,
  },
  {
    path: "/ward/stock/add-cd",
    exact: true,
    name: "Add CD",
    component: AddCD,
  },
  {
    path: "/ward/stock/add-clinical-waste",
    exact: true,
    name: "Add Clinical Waste",
    component: AddClinicalWaste,
  },
  {
    path: "/ward/stock/administer",
    exact: true,
    name: "",
    component: AdministerStock,
  },
  {
    path: "/ward/stock/audit",
    exact: true,
    name: "Stock Audit",
    component: StockAudit,
  },
  {
    path: "/ward/stock/disposal",
    exact: true,
    name: "Stock disposal",
    component: Disposal,
  },
  {
    path: "/ward/stock/orders",
    exact: true,
    name: "Area orders",
    component: WardAllOrders,
  },
  {
    path: "/ward/stock/orders/#:mode",
    exact: true,
    name: "Area orders",
    component: WardAllOrders,
  },
  {
    path: "/ward/stock/orders/single",
    exact: true,
    name: "Single order",
    component: SingleOrder,
  },
  {
    path: "/ward/stock/createorder",
    exact: true,
    name: "Create Order",
    component: CreateOrder,
  },
  {
    path: "/ward/stock/createordernamedpatient",
    exact: true,
    name: "Create Order Named Patient",
    component: CreateOrderNamedPatient,
  },
  {
    path: "/ward/stock/illicit",
    exact: true,
    name: "Illicit drugs",
    component: WardIllicitDrugs,
  },
  {
    path: "/ward/stock/illicit/add/:id?",
    exact: true,
    name: "Add illicit drugs",
    component: WardAddIllicitDrug,
  },
  {
    path: "/ward/stock/remove",
    exact: true,
    name: "Remove rug from cupboard",
    component: RemoveFromCupboard,
  },
  {
    path: "/ward/patients",
    exact: true,
    name: "Location patients Admin",
    component: PatientAdmin,
  },
  {
    path: "/ward/patients/manage",
    exact: true,
    name: "Manage Patients",
    component: ManagePatient,
  },
  {
    path: "/ward/patients/new",
    exact: true,
    name: "Location patients Admin",
    component: NewPatient,
  },
  {
    path: "/ward/patient/stock",
    exact: true,
    name: "Patients Drug Register",
    component: PatientsOwnStock,
  },
  {
    path: "/ward/patient/stock/add",
    exact: true,
    name: "Add Patients Own Drugs",
    component: AddPatientsOwnStock,
  },
  {
    path: "/ward/patient/stock/administer",
    exact: true,
    name: "Administer Patients Own Drugs",
    component: AdministerStock,
  },
  {
    path: "/ward/staff",
    exact: true,
    name: "Location staff Admin",
    component: StaffAdmin,
  },
  {
    path: "/ward/reports",
    exact: true,
    name: "Reports",
    component: Reports,
  },
  {
    path: "/ward/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/ward/reports/cd-orders",
    exact: true,
    name: "CD Orders Report",
    component: WardCDOrders,
  },
  {
    path: "/ward/reports/stock-checks",
    exact: true,
    name: "Stock Check Report",
    component: StockCheckReport,
  },
  {
    path: "/ward/reports/general-stock",
    exact: true,
    name: "General Stock Report",
    component: GeneralStock,
  },
  {
    path: "/ward/reports/Compliance-Audit-History-Report",
    exact: true,
    name: "Compliance Audits",
    component: ComplianceAuditHistoryReport,
  },
  {
    path: "/ward/help",
    exact: true,
    name: "Help",
    component: Help,
  },
];

export default routes;
