import React from "react";
import { CContainer, CFade } from "@coreui/react";

const MainContent = (props) => {
  const ViewComponent = props.component;
  return (
    <main className="c-main">
      <CContainer fluid>
        <CFade>
          <ViewComponent {...props} />
        </CFade>
      </CContainer>
    </main>
  );
};

export default React.memo(MainContent);
