import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, ButtonToggle } from "reactstrap";
import { CSwitch, CTooltip } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";

import { Loader } from "../component/atoms";
import ConfirmBlock from "../component/organisms/ConfirmBlock";
import {
  initialStateSignedWitnessed,
  initialStateSigned,
} from "../config/confirm";
import { getDateStrings, truncateString } from "../helpers";
import StockService from "../services/StockService";
import { hideAlertMessage, showAlertMessage } from "../store/actions";
import CheckableInputs from "../component/molecules/CheckableInputs";
import InfiniteScroll from "react-infinite-scroll-component";

const TYPE_PHARMACY = 1;
const TYPE_WARD = 2;
const TYPE_ILLICIT = 3;
const TYPE_CLINICALWASTE = 4;
let serviceCancelSource;
let serviceConfig;
const DEFAULT_VIEW_ID = 1;
const MAX_DRUGNAME_LENGTH = 250;
const newFunctionality = true;
const viewStatesPharmacy = newFunctionality
  ? [
      {
        buttonText: "No Authorised Witness",
        hash: "NonWitness",
        stateId: 1,
        isAuthorisedWitnessRequired: false,
        isApproved: true,
        title: "Awaiting Disposal",
        confirmBlockTitle: "Disposal Witnessed",
      },
      {
        buttonText: "Authorised Witness",
        hash: "Witness",
        stateId: 2,
        isAuthorisedWitnessRequired: true,
        isApproved: true,
        title: "Awaiting Authorised Disposal",
        confirmBlockTitle: "Disposal Witnessed",
      },
    ]
  : [
      {
        buttonText: "Awaiting Approval",
        hash: "Awaiting",
        stateId: 1,
        isApproved: false,
        title: "Awaiting Approval",
        confirmBlockTitle: "Approve For Disposal",
      },
      {
        buttonText: "No Authorised Witness",
        hash: "NonWitness",
        stateId: 1,
        isAuthorisedWitnessRequired: false,
        isApproved: true,
        title: "Awaiting Disposal",
        confirmBlockTitle: "Disposal Witnessed",
      },
      {
        buttonText: "Authorised Witness",
        hash: "Witness",
        stateId: 2,
        isAuthorisedWitnessRequired: true,
        isApproved: true,
        title: "Awaiting Authorised Disposal",
        confirmBlockTitle: "Disposal Witnessed",
      },
    ];

const viewStatesWard = [
  {
    buttonText: "Disposal",
    hash: "Disposal",
    stateId: 1,
    isApproved: true,
    title: "Awaiting Disposal",
    confirmBlockTitle: "Approve Disposal",
  },
];

function Disposal({ isPharmacy, locationId, locationRoute, user, location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );

  const [alertMessage, setAlertMessage] = useState();
  const [confirmedStaff, setConfirmedStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [disposalHistory, setDisposalHistory] = useState([]);
  const [page, setPage] = useState(1); // initial page number
  const [hasMore, setHasMore] = useState(true);
  const [disposalRegister, setDisposalRegister] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeViewId, setActiveViewId] = useState(getViewStateId(location));
  const [filteredDisposalRegister, setFilterDisposalRegister] = useState();
  const [activeViewState, setActiveViewState] = useState(
    getViewState(DEFAULT_VIEW_ID)
  );

  useEffect(() => {
    filterDisposalRegister(activeViewState.stateId);
    return () => {};
  }, [disposalRegister]);

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    getDisposalRegister();
    getDisposalHistory();

    return () => {
      serviceCancelSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (alertMessage) {
      showAlertMessage(dispatch, alertMessage);
    } else {
      hideAlertMessage(dispatch);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (isConfirmed) {
      setConfirmedStaffErrors();

      if (filteredDisposalRegister.some((item) => item.selected)) {
        dispose();
      } else {
        setAlertMessage({
          message: `Please select a substance to ${
            !activeViewState.isApproved ? "approve disposal" : "dispose"
          }`,
          colour: "warning",
        });
        setIsConfirmed(false);
      }
    }
  }, [isConfirmed]);

  function getViewStateId(location) {
    let viewState;
    if (isPharmacy) {
      viewState = viewStatesPharmacy.find(
        (item) => item.hash === location.hash.substring(1)
      );
    } else {
      viewState = viewStatesWard.find(
        (item) => item.hash === location.hash.substring(1)
      );
    }
    return viewState ? viewState.stateId : DEFAULT_VIEW_ID;
  }

  function getViewState(stateId) {
    if (isPharmacy) {
      return viewStatesPharmacy.find((item) => item.stateId === stateId);
    } else {
      return viewStatesWard.find((item) => item.stateId === stateId);
    }
  }

  function handleViewButtonClick({ currentTarget: { name } }) {
    const stateId = Number(name);

    if (stateId !== activeViewId) {
      setActiveViewId(stateId);
      setActiveViewState(getViewState(stateId));
      filterDisposalRegister(stateId);
    }
  }

  function filterDisposalRegister(stateId) {
    if (disposalRegister) {
      const viewState = getViewState(stateId);
      if (isPharmacy) {
        const sortedDisposalRegister = disposalRegister.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        const filteredDisposalRegister = sortedDisposalRegister.filter(
          (item) =>
            (item.authorisedWitnessRequired ===
              viewState.isAuthorisedWitnessRequired ||
              !viewState.isApproved) &&
            item.isApproved === viewState.isApproved
        );
        setFilterDisposalRegister(filteredDisposalRegister);
      } else {
        setFilterDisposalRegister(disposalRegister);
      }
    }
  }

  async function getDisposalRegister() {
    let isCancelled = false;

    setIsLoading(true);

    try {
      const { data } = await StockService.forDisposal(
        locationRoute,
        locationId,
        serviceConfig
      );

      setDisposalRegister(data);
    } catch (error) {
      if (StockService.isCancel(error)) {
        isCancelled = true;
      } else {
        setAlertMessage({
          message: `There was a problem getting the Disposal records, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  function refreshDisposalHistory () {
    getDisposalHistory(1, []);
  }

  async function getDisposalHistory(forcePage, forceHistory) {
    const pageToUse = forcePage || page;
    const historyToUse = forceHistory || disposalHistory;
    try {
      const { data } = await StockService.disposalHistory(
        locationRoute,
        locationId,
        pageToUse,
        serviceConfig
      );

      setDisposalHistory([].concat(historyToUse, data));
      setPage((prevPage)=> prevPage+1);
      setHasMore(data.length > 0);

    } catch (error) {
      if (!StockService.isCancel(error)) {
        setAlertMessage({
          message: `There was a problem getting the disposal history, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "warning",
        });
      }
    }
  }

  async function dispose() {
    const selectedItem = filteredDisposalRegister.find((item) => item.selected);
    const payload = [
      {
        createdBy: user.username,
        id: selectedItem.id,
        signedBy: confirmedStaff.signed,
        typeId: selectedItem.typeId,
        witnessedBy: confirmedStaff.witnessed,
        disposalReference: selectedItem.disposalReference,
        AuthorisedWitnessRequired: selectedItem.authorisedWitnessRequired,
      },
    ];
    let isCancelled = false;

    setIsLoading(true);

    try {
      const response = await StockService.dispose(
        locationRoute,
        locationId,
        !activeViewState.isApproved,
        payload,
        serviceConfig
      );

      if (response.status === 202) {
        setAlertMessage({
          message: `Substances have been ${
            !activeViewState.isApproved ? "approved for disposal" : "disposed"
          }`,
          colour: "success",
        });
          getDisposalRegister();
          refreshDisposalHistory();
      } else {
        setAlertMessage({
          message: `There was a problem recording the substances as
            ${
              activeViewState.isApproved ? "disposed" : "approved for disposal"
            }, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (StockService.isCancel(error)) {
        isCancelled = true;
      } else {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem recording the substances as
              ${
                activeViewState.isApproved
                  ? "disposed"
                  : "approved for disposal"
              }, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
          });
        }

        setIsLoading(false);
      }
    } finally {
      if (!isCancelled) {
        setIsConfirmed(false);
      }
    }
  }

  function handleSwitchChange({ target }) {
    setFilterDisposalRegister((filteredDisposalRegister) =>
      filteredDisposalRegister.map((item) => ({
        ...item,
        selected: item.id === target.name ? !item.selected : false,
      }))
    );
  }

  function getPatientTooltipContent(patient) {
    const patientStr =
      `${patient.firstname} ${patient.lastname}` +
      `${patient.address ? `, ${patient.address}` : ""}`;
    const textStr = "Return PO CD: ";

    return textStr + patientStr;
  }

  function renderDrugName(drugName) {
    if (drugName.length < MAX_DRUGNAME_LENGTH) return drugName;

    return (
      <CTooltip content={drugName} placement="right">
        <span>{drugName.slice(0, MAX_DRUGNAME_LENGTH - 3).concat("...")}</span>
      </CTooltip>
    );
  }

  function renderDisposalRegisterRow(item) {
    return (
      <tr
        className={`type-${
          item.typeId === TYPE_ILLICIT
            ? "illicit"
            : item.typeId === TYPE_CLINICALWASTE
            ? "clinical"
            : "cd"
        }`}
        key={item.id}
      >
        <td>
          <div className="drug-name">
            {renderDrugName(item.drugName)}
            {item.comments && <span className="comments">{item.comments}</span>}
            {item.isPatientOwn ? (
              <CTooltip content={getPatientTooltipContent(item.patient)}>
                <span className={`status-icon status-on ml-1 mr-0`}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </span>
              </CTooltip>
            ) : (
              <span className={`status-icon status-off ml-1 mr-0`}>
                <FontAwesomeIcon icon={faUserPlus} />
              </span>
            )}
          </div>
        </td>
        {isPharmacy && <td className="category">{item.category}</td>}
        {isPharmacy && (
          <td className="reference">
            <CheckableInputs
              disabled={true}
              inline
              list={[
                {
                  checked: item.authorisedWitnessRequired,
                  className: "checked-yes",
                  value: true,
                },
              ]}
            />
          </td>
        )}
        {isPharmacy && <td className="location">{item.locationName}</td>}
        {isPharmacy && (
          <td>
            {<Moment format="Do MMM YYYY, HH:mm " date={item.createdAt} />}
          </td>
        )}
        {isPharmacy && <td className="reference">{item.disposalReference}</td>}
        <td className="quantity-unit">
          {item.typeId !== TYPE_ILLICIT && item.typeId !== TYPE_CLINICALWASTE && (
            <>
              <span className="quantity">{item.quantity}</span>{" "}
              <span>{item.doseUom}</span>
            </>
          )}
        </td>
        <td>
          <CSwitch
            color="success"
            disabled={isLoading}
            name={item.id}
            onChange={handleSwitchChange}
            shape="pill"
            checked={item.selected}
          />
        </td>
      </tr>
    );
  }

  function renderDisposalRegister() {
    return (
      <Table className="register-table" hover>
        <thead>
          <tr>
            <th>{activeViewState.title}</th>
            {isPharmacy && <th></th>}
            {isPharmacy && <th>Authorised Witness Required</th>}
            {isPharmacy && <th>Location</th>}
            {isPharmacy && <th>Created At</th>}
            {isPharmacy && <th>Reference</th>}
            <th className="quantity-unit">Quantity</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>{filteredDisposalRegister.map(renderDisposalRegisterRow)}</tbody>
      </Table>
    );
  }

  function renderViewButton({ buttonText, stateId }) {
    return (
      <ButtonToggle
        active={stateId === activeViewId}
        className="btn-pill"
        color="primary"
        disabled={isLoading}
        key={stateId}
        name={stateId}
        onClick={handleViewButtonClick}
        outline
        title={`${stateId === activeViewId ? "" : "Select this filter"}`}
      >
        {buttonText}
      </ButtonToggle>
    );
  }

  function renderPageMessage() {
    if (isLoading) {
      return null;
    } else if (disposalRegister) {
      return (
        <p className="page-message">
          There are currently no substances registered for disposal{" "}
          {!activeViewState.isApproved ? "approval" : ""}
        </p>
      );
    } else {
      return (
        <p className="page-message">
          Failed to load disposal register, please reload the page
        </p>
      );
    }
  }

  function renderQuantity(value, units) {
    return (
      <>
        {value}
        <span className="quantity-units" title={units}>
          {units}
        </span>
      </>
    );
  }

  function renderDisposalHistory() {
    if (!disposalHistory.length) {
      return (
        <p className="page-message">
          There is currently no disposal history information for this location
        </p>
      );
    }

    return (
      <InfiniteScroll
        dataLength={disposalHistory.length}
        next={getDisposalHistory}
        hasMore={hasMore}
        refreshFunction={refreshDisposalHistory}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        scrollableTarget="history-table"
      >
        <Table className="history-table">
          <colgroup>
            <col span="2" />
            <col className="management" span={isPharmacy ? 4 : 2} />
          </colgroup>
          <thead>
            <tr>
              <td colSpan="2">&nbsp;</td>
              <td className="management" colSpan={isPharmacy ? 4 : 2}>
                &nbsp;
              </td>
              <td colSpan="3">&nbsp;</td>
            </tr>
            <tr>
              <th>Date</th>
              <th>Time</th>
              {isPharmacy && <th className="management">Type</th>}
              <th className="management align-left">
                Drug{isPharmacy && " or Substance"}
              </th>
              <th className="management">Quantity</th>
              {isPharmacy && <th className="management">Ref</th>}
              <th className="align-left">Comments</th>
              <th>Confirmed by</th>
              <th>Witnessed by</th>
            </tr>
          </thead>
          <tbody>
            {disposalHistory.map((item, index) => {
              const dateStrings = item.signedAt
                ? getDateStrings(item.signedAt)
                : {};

              return (
                <tr key={index}>
                  <td>
                    <time dateTime={item.signedAt}>{dateStrings.date}</time>
                  </td>
                  <td>
                    <time dateTime={item.signedAt}>{dateStrings.time}</time>
                  </td>
                  {isPharmacy && <td>{item.type}</td>}
                  <td className="align-left">{item.description}</td>
                  <td className="quantity">
                    {renderQuantity(item.drugQuantity, item.drugQuantityUom)}
                  </td>
                  {isPharmacy && <td>{item.reference}</td>}
                  <td style={{ whiteSpace: 'break-spaces' }} className="align-left break-words">{item.comments}</td>
                  <td className="break-words" title={item.signedBy}>
                    {truncateString(item.signedBy, SIGNATURE_DISPLAY_MAX_LENGTH)}
                  </td>
                  <td className="break-words" title={item.witnessedBy}>
                    {truncateString(
                      item.witnessedBy,
                      SIGNATURE_DISPLAY_MAX_LENGTH
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
    );
  }

  return (
    <div className={`Disposal Disposal-${locationRoute}`}>
      <header className="page-header row">
        <h1 className="page-heading">Disposal</h1>
        {!isPharmacy && (
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to="/ward/stock/add-clinical-waste"
          >
            Add Clinical Waste
            <FontAwesomeIcon className="on-right" icon={faTrashAlt} />
            <sup>
              <FontAwesomeIcon icon={faPlus} />
            </sup>
          </Link>
        )}
      </header>
      {isPharmacy && (
        <div className="view-buttons">
          <span>Set table view:</span>
          {viewStatesPharmacy.map(renderViewButton)}
        </div>
      )}
      {filteredDisposalRegister?.length > 0 ? (
        <>
          {renderDisposalRegister()}
          <ConfirmBlock
            className="ml-0 mr-0"
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={
              activeViewState.isApproved
                ? initialStateSignedWitnessed
                : initialStateSigned
            }
            inputsConfirmed={isConfirmed}
            isLoading={isLoading}
            setConfirmedStaff={setConfirmedStaff}
            setInputsConfirmed={setIsConfirmed}
            title={activeViewState.confirmBlockTitle}
          />
        </>
      ) : (
        <>
          {renderPageMessage()}
          <Loader show={isLoading} />
        </>
      )}
      {disposalHistory && (
        <>
          <h5 className="history-heading">Disposal History</h5>
          {renderDisposalHistory()}
        </>
      )}
    </div>
  );
}

export default Disposal;
