import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { CAlert } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { useSelector } from "react-redux";

import ConfirmBlock from "../../component/organisms/ConfirmBlock";
import {
  initialStateSignedCollected,
  initialStateSignedWitnessed,
} from "../../config/confirm";
import { getDateStrings, getTimestamp } from "../../helpers";
import IllicitDrugService from "../../services/IllicitDrugService";

const inputs = [
  {
    name: "description",
    label: "Description (form, colour, quantity):",
    required: true,
  },
  {
    name: "datixNo",
    label: "Datix incident report ref. no.:",
  },
  {
    name: "source",
    label: "Patient details (or source description, if unknown):",
    required: true,
  },
  {
    name: "notes",
    label: "Notes:",
    type: "textarea",
  },
];
const actionTabs = [
  { heading: "(Temp) Store in Ward" },
  { heading: "Return to Pharmacy" },
];
const mainPagePath = "/ward/stock/illicit";
const formInitialState = inputs.reduce(
  (stateObj, input) => ({ ...stateObj, [input.name]: "" }),
  {}
);
const messageInitialState = {};
let messageTimeoutId;
let redirectTimeoutId;
let serviceCancelSource;
let serviceConfig;

function AddIllicitDrug({ history, locationRoute, match, user }) {
  const ILLICIT_STATUS_TEMP_STORE = useSelector(
    (state) => state.settings.ILLICIT_STATUS_TEMP_STORE
  );
  const ILLICIT_STATUS_RETURNED_TO_PHARMACY = useSelector(
    (state) => state.settings.ILLICIT_STATUS_RETURNED_TO_PHARMACY
  );

  const formElement = useRef();

  const [activeTab, setActiveTab] = useState(0);
  const [addedDrug, setAddedDrug] = useState(null);
  const [alertMessage, setAlertMessage] = useState(messageInitialState);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmStaff, setConfirmStaff] = useState();
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();
  const [formIsReadOnly, setFormIsReadOnly] = useState(
    Boolean(match.params.id)
  );
  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);
  const [isLoading, setIsLoading] = useState(Boolean(match.params.id));

  useEffect(async () => {
    serviceCancelSource = IllicitDrugService.getCancelSource();
    serviceConfig = { cancelToken: serviceCancelSource.token };

    if (match.params.id) {
      let isCancelled = false;

      try {
        const { data } = await IllicitDrugService.getIllicitDrug(
          match.params.id,
          serviceConfig
        );

        setAddedDrug(data);
        setFormState({
          datixNo: data.datixNo,
          description: data.description,
          notes: data.notes,
          source: data.source,
        });
      } catch (error) {
        if (IllicitDrugService.isCancel(error)) {
          isCancelled = true;
        } else {
          setAlertMessage({
            message: `There was a problem getting the drug information, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    }

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageInitialState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    if (confirmed) {
      setConfirmedStaffErrors();

      if (addedDrug) {
        returnDrugToPharmacy();
      } else if (formElement.current.checkValidity()) {
        addDrug();
      } else {
        setAlertMessage({
          message: "Please fill in the required fields",
          colour: "warning",
          label: "Warning message",
        });
        setConfirmed(false);
        setFormShowValidity(true);
      }
    }
  }, [confirmed]);

  function setupRedirect() {
    redirectTimeoutId = setTimeout(() => history.push(mainPagePath), 5000);
  }

  async function addDrug() {
    const timestamp = getTimestamp();
    const payload = {
      CreatedAt: timestamp,
      CreatedBy: user.username,
      DatixNo: formState.datixNo,
      Description: formState.description,
      LocationId: user.location.lcId,
      Notes: formState.notes,
      SignedAt: timestamp,
      SignedBy: confirmStaff.signed,
      Source: formState.source,
      WitnessedAt: timestamp,
      WitnessedBy: confirmStaff.witnessed,
    };
    let isCancelled = false;

    setIsLoading(true);

    try {
      const response = await IllicitDrugService.addIllicitDrug(
        locationRoute,
        payload,
        serviceConfig
      );

      if (response.status === 201) {
        setAlertMessage({
          message: "The illicit substance has been added",
          colour: "success",
          label: "Success message",
          timeout: 5000,
        });
        setAddedDrug({
          illicitDrugManagements: [
            {
              signedAt: timestamp,
              signedBy: confirmStaff.signed,
              statusId: ILLICIT_STATUS_TEMP_STORE,
              witnessedBy: confirmStaff.witnessed,
            },
          ],
        });
        setupRedirect();
      } else {
        setAlertMessage({
          message: `There was a problem adding the illicit substance, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
          label: "Error message",
        });
        setConfirmed(false);
      }
    } catch (error) {
      if (IllicitDrugService.isCancel(error)) {
        isCancelled = true;
      } else {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem adding the illicit substance, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }

        setConfirmed(false);
      }
    } finally {
      if (!isCancelled) {
        setIsLoading(false);
      }
    }
  }

  async function returnDrugToPharmacy() {
    const timestamp = getTimestamp();
    const payload = {
      CollectedAt: timestamp,
      CollectedBy: confirmStaff.collected,
      CreatedBy: user.username,
      IllicitDrugId: addedDrug.id,
      SignedAt: timestamp,
      SignedBy: confirmStaff.signed,
      StatusId: ILLICIT_STATUS_RETURNED_TO_PHARMACY,
    };

    try {
      const response = await IllicitDrugService.returnIllicitDrugToPharmacy(
        payload,
        serviceConfig
      );

      if (response.status === 201) {
        setAlertMessage({
          message: "The illicit substance has been returned to the pharmacy",
          colour: "success",
          label: "Success message",
          timeout: 5000,
        });
        setupRedirect();
      } else {
        setAlertMessage({
          message: `There was a problem returning the illicit substance to the pharmacy,
            the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
          colour: "danger",
          label: "Error message",
        });
        setConfirmed(false);
      }
    } catch (error) {
      if (!IllicitDrugService.isCancel(error)) {
        if (error.response?.status === 401) {
          setConfirmedStaffErrors(error.response.data);
        } else {
          setAlertMessage({
            message: `There was a problem returning the illicit substance to the pharmacy,
              the eCDR-Pro system may be offline. If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        }

        setConfirmed(false);
      }
    }
  }

  function inputChangeHandler({ target: { name, value } }) {
    setFormState((formState) => ({ ...formState, [name]: value }));
  }

  function formSubmitHandler(event) {
    event.preventDefault();
  }

  function tabClickHandler({ currentTarget: { dataset } }) {
    setActiveTab(Number(dataset.tab));
  }

  function renderFormGroup({ label, name, required, ...inputProps }) {
    return (
      <FormGroup key={name} className="AddIllicitDrug-Input">
        <label
          className="form-label"
          disabled={formIsReadOnly}
          htmlFor={formIsReadOnly ? undefined : name}
        >
          {required && <span className="mandatory-label">*</span>}
          {label}
        </label>
        {formIsReadOnly ? (
          <p className="input-value">{formState[name]}</p>
        ) : (
          <Input
            autoComplete="off"
            disabled={isLoading}
            id={name}
            name={name}
            onChange={inputChangeHandler}
            readOnly={isLoading}
            required={required}
            value={formState[name]}
            {...inputProps}
          />
        )}
      </FormGroup>
    );
  }

  function renderTab({ heading }, index) {
    return (
      <NavItem key={index}>
        <NavLink
          className={cx({ active: activeTab === index })}
          data-tab={index}
          disabled={activeTab === index}
          id={"tab-" + index}
          onClick={tabClickHandler}
        >
          <h3>{heading}</h3>
        </NavLink>
      </NavItem>
    );
  }

  function renderTempStore() {
    const storeItem = addedDrug.illicitDrugManagements.filter(
      (mgmt) => mgmt.statusId === ILLICIT_STATUS_TEMP_STORE
    )[0];

    if (storeItem) {
      return (
        <>
          <h4>Received and recorded</h4>
          <dl className="stock-details">
            <div>
              <dt>Signed by:</dt>
              <dd>{storeItem.signedBy}</dd>
            </div>
            <div>
              <dt>Witnessed by:</dt>
              <dd>{storeItem.witnessedBy}</dd>
            </div>
            <div>
              <dt className="sr-only">Signed at:</dt>
              <dd>
                <time dateTime={storeItem.signedAt}>
                  {getDateStrings(storeItem.signedAt).datetime}
                </time>
              </dd>
            </div>
          </dl>
        </>
      );
    }

    return null;
  }

  function renderReturnedToPharmacy() {
    const returnedItem = addedDrug.illicitDrugManagements.filter(
      (mgmt) => mgmt.statusId === ILLICIT_STATUS_RETURNED_TO_PHARMACY
    )[0];

    if (returnedItem) {
      return (
        <>
          <h4>Sealed container collected by:</h4>
          <dl className="stock-details">
            <div>
              <dt>Signed by:</dt>
              <dd>{returnedItem.signedBy}</dd>
            </div>
            <div>
              <dt>Collected by:</dt>
              <dd>{returnedItem.collectedBy}</dd>
            </div>
            <div>
              <dt className="sr-only">Signed at:</dt>
              <dd>
                <time dateTime={returnedItem.signedAt}>
                  {getDateStrings(returnedItem.signedAt).datetime}
                </time>
              </dd>
            </div>
          </dl>
        </>
      );
    }

    return (
      <ConfirmBlock
        confirmedStaffErrors={confirmedStaffErrors}
        confirmInitialState={initialStateSignedCollected}
        inputsConfirmed={confirmed}
        setConfirmedStaff={setConfirmStaff}
        setInputsConfirmed={setConfirmed}
        title="Sealed container collected by:"
      />
    );
  }

  return (
    <Col className={`AddIllicitDrug AddIllicitDrug-${locationRoute}`}>
      <CAlert
        aria-label={alertMessage.label}
        className="shadow"
        closeButton
        color={alertMessage.colour}
        show={Boolean(alertMessage.message)}
      >
        {alertMessage.message}
      </CAlert>
      <Row className="mb-4">
        <Col sm="8">
          <h1>Add Illicit Substance</h1>
        </Col>
        <Col
          className="d-flex align-items-baseline justify-content-end flex-row"
          sm="4"
        >
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to={mainPagePath}
          >
            <FontAwesomeIcon className="on-left" icon={faReplyAll} />
            Back to main page
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="mb-4">Description of Substance</h2>
          <Form
            className={cx("form", {
              loading: isLoading,
              "show-validity": formShowValidity,
            })}
            innerRef={formElement}
            onSubmit={formSubmitHandler}
          >
            {inputs.map(renderFormGroup)}
            {isLoading && (
              <div className="loader">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading…</span>
                </div>
              </div>
            )}
          </Form>
        </Col>
      </Row>
      {!formIsReadOnly && (
        <ConfirmBlock
          confirmedStaffErrors={confirmedStaffErrors}
          confirmInitialState={initialStateSignedWitnessed}
          inputsConfirmed={confirmed}
          setConfirmedStaff={setConfirmStaff}
          setInputsConfirmed={setConfirmed}
          title="Received and recorded"
        />
      )}
      <Row>
        <Col>
          <h2>Action</h2>
          <Nav className={"tabs-length-" + actionTabs.length} tabs>
            {actionTabs.map(renderTab)}
          </Nav>
          <TabContent
            activeTab={activeTab}
            aria-labelledby={"tab-" + activeTab}
          >
            <TabPane tabId={0}>{addedDrug && renderTempStore()}</TabPane>
            <TabPane tabId={1}>
              {addedDrug && renderReturnedToPharmacy()}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Col>
  );
}

export default AddIllicitDrug;
