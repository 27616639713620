import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import ProgressBar from "./ProgressBar";

const DashboardPanel = ({ quantity, urgent, text, url, colour, isLoading }) => {
  return (
    <Col className="dash-item" md="3">
      <Link to={url}>
        <div className="dash-item_block">
          <div className="dash-item_block-title">
            <h3>{isLoading ? "…" : quantity}</h3>
            <p className="dash-item-title align-bottom">{text}</p>
          </div>
          <div className="dash-urgent">
            <div className="dash-urgent-percentage">
              <div className="dash-urgent-bumper">
                {isLoading ? "…" : urgent}
              </div>
              <ProgressBar
                progress={
                  isLoading || urgent === 0 ? 0 : (urgent / quantity) * 100
                }
                size={88}
                strokeWidth={9}
                circleTwoStroke={colour}
              />
            </div>
            <p>Urgent</p>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default DashboardPanel;
