import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Form,
  FormGroup,
  Input,
  ButtonToggle,
} from "reactstrap";
import { CAlert } from "@coreui/react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import Label from "../component/atoms/Label";
import CreateOrderPadLine from "../component/molecules/CreateOrderPadLine";
import ConfirmBlock from "../component/organisms/ConfirmBlock";
import DrugSearchList from "../component/organisms/DrugSearchList";
import { getOrdersRules } from "../config/_ordersrules";
import OrdersService from "../services/OrdersService";
import LocationsService from "../services/LocationsService";
import StockService from "../services/StockService";

import { getDateStrings, truncateString } from "../helpers";
import { MANAGEMENT_EVENT_TYPE, historyEvents } from "../config/historyEvents";

let messageTimeoutId;
let redirectTimeoutId;
let serviceCancelSource;

const CreateOrderNamedPatient = ({
  history,
  isPharmacy,
  locationId,
  locationRoute,
  user,
}) => {
  const SIGNATURE_DISPLAY_MAX_LENGTH = useSelector(
    (state) => state.settings.SIGNATURE_DISPLAY_MAX_LENGTH
  );
  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  const formRef = useRef();

  const messageState = {
    message: "",
    colour: "",
  };
  const formInitialState = {
    prescribedBy: "",
    valid: locationRoute === "ward",
  };

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [disable, setDisable] = useState(false);
  const [order, setOrder] = useState([]);
  const [inputsConfirmed, setInputsConfirmed] = useState(false);
  const [confirmedStaff, setConfirmedStaff] = useState("");
  const [confirmedStaffErrors, setConfirmedStaffErrors] = useState();

  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);
  const [locations, setLocationList] = useState();

  const [stockHistory, setStockHistory] = useState([]);
  const [showOptionalHistoryEvents, setShowOptionalHistoryEvents] =
    useState(false);
  const [historyStockId, setHistoryStockId] = useState();

  const addToOrder = (selectedDrug) => {
    const newItem = selectedDrug["catalogueId"]
      ? {
        catalogueId: selectedDrug["catalogueId"],
        cdVpId: selectedDrug["cdCatalogue"]
          ? selectedDrug["cdCatalogue"]["cdVpId"]
          : selectedDrug["cdVpId"],
        cdcatalogueNameOrFormularyName: selectedDrug["cdCatalogue"]
          ? selectedDrug["cdCatalogue"]["cdcatalogueNameOrFormularyName"]
          : selectedDrug["cdcatalogueNameOrFormularyName"],
        siteId: user.location.siteId,
        qtyRequested: selectedDrug["qtyRequested"],
        createdby: user.username,
        cdApId: selectedDrug["cdCatalogue"]
          ? selectedDrug["cdCatalogue"]["cdApId"]
          : selectedDrug["cdApId"],
        isPatient: false,
        isUrgent: false,
        doseUom: selectedDrug["cdCatalogue"]
          ? selectedDrug.cdCatalogue.cdCataloguePackSize[0]?.uom
          : selectedDrug.cdCataloguePackSize[0]?.uom,
        tempId: Date.now(),
        isValid: true,
      }
      : {
        catalogueId: selectedDrug["catalogueId"],
        cdVpId: selectedDrug["cdCatalogue"]
          ? selectedDrug["cdCatalogue"]["cdVpId"]
          : selectedDrug["cdVpId"],
        cdcatalogueNameOrFormularyName: selectedDrug["cdCatalogue"]
          ? selectedDrug["cdCatalogue"]["cdcatalogueNameOrFormularyName"]
          : selectedDrug["cdcatalogueNameOrFormularyName"],
        siteId: user.location.siteId,
        qtyRequested: selectedDrug["qtyRequested"],
        createdby: user.username,
        cdApId: null,
        isPatient: false,
        isUrgent: false,
        doseUom: selectedDrug["cdCatalogue"]
          ? selectedDrug.cdCatalogue.cdCataloguePackSize[0]?.uom
          : selectedDrug.cdCataloguePackSize[0]?.uom,
        tempId: Date.now(),
        isValid: true,
      };
    setOrder((order) => [
      ...order,
      {
        ...newItem,
      },
    ]);
  };

  useEffect(() => {
    serviceCancelSource = StockService.getCancelSource();

    return () => {
      serviceCancelSource.cancel();
      clearTimeout(messageTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, []);

  useEffect(() => {
    if (isPharmacy) {
      LocationsService.getLocations(user.location.siteId)
        .then(({ data }) => {
          setLocationList(data);
        })
        .catch((error) => {
          setAlertMessage({
            message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
            colour: "danger",
            label: "Error message",
          });
        });
    }
  }, []);

  useEffect(() => {
    if (selectedDrug) {
      addToOrder(selectedDrug);
      setSelectedDrug(null);
    }
  }, [selectedDrug]);

  useEffect(() => {
    if (order.length === 0) {
      setFormShowValidity(false);
    }
  }, [order.length]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    if (historyStockId) {
      fetchHistory(historyStockId);
    } else {
      setStockHistory([]);
    }
  }, [historyStockId]);

  const deleteDrug = (drug) => {
    setOrder((order) =>
      order.filter((item) => item["tempId"] !== drug["tempId"])
    );
    setDisable(false);
  };

  useEffect(() => {
    if (inputsConfirmed) {
      setConfirmedStaffErrors();

      if (!disable && formState.valid) {
        sendOrder();
      } else {
        setAlertMessage({
          message: "Please fill in all the required fields",
          colour: "warning",
        });
        setInputsConfirmed(false);
        setFormShowValidity(true);
      }
    }
  }, [inputsConfirmed]);

  function fetchHistory(stkPhId) {
    StockService.getPharmacyStockHistory(stkPhId, {
      cancelToken: serviceCancelSource.token,
    })
      .then((response) => {
        setStockHistory(response.data);
      })
      .catch((error) => {
        if (!StockService.isCancel(error)) {
          setAlertMessage({
            message: `Unable to fetch complete stock history, the eCDR-Pro system may be offline.
              If unable to resolve contact IT service desk.`,
            colour: "warning",
            label: "Warning message",
          });
        }
      });
    return;
  }

  function renderQuantity(value, units) {
    return (
      <>
        {value}
        <span className="quantity-units" title={units}>
          {units}
        </span>
      </>
    );
  }

  function handleHistoryButtonClick() {
    setShowOptionalHistoryEvents(!showOptionalHistoryEvents);
  }

  // history block
  const renderHistory = () => {
    return (
      <Table className="history-table">
        <colgroup>
          <col span="2" />
          <col className="management" span="3" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan="2" />
            <th className="management align-left" colSpan="3">
              CD Management
            </th>
            <th colSpan="2" />
            <th colSpan="4" />
          </tr>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th className="management align-left">Event</th>
            <th className="management">Quantity</th>
            <th className="management">Ref</th>
            <th className="align-left">Patient</th>
            <th className="align-left">Prescriber</th>
            <th className="align-left">Comments</th>
            <th>Confirmed by</th>
            <th>Witnessed by</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {stockHistory.map((item, index) => {
            const dateStrings = item.signedAt
              ? getDateStrings(item.signedAt)
              : {};
            const eventConfig = historyEvents[item.event];

            if (!eventConfig) {
              console.error(
                "Unrecognised stock history event type",
                item.event
              );
              return null;
            }

            if (!showOptionalHistoryEvents && eventConfig.optionalEvent) {
              return null;
            }

            return (
              <tr
                className={eventConfig.groupBody ? "group-body" : undefined}
                key={index}
              >
                <td>
                  <time dateTime={item.signedAt}>{dateStrings.date}</time>
                </td>
                <td>
                  <time dateTime={item.signedAt}>{dateStrings.time}</time>
                </td>
                {eventConfig.type === MANAGEMENT_EVENT_TYPE ? (
                  <>
                    <td className="align-left">{item.eventDisplayText}</td>
                    <td className="quantity">
                      {!eventConfig.noQuantity &&
                        renderQuantity(item.drugQuantity, item.drugQuantityUom)}
                    </td>
                    <td>{item.reference}</td>
                  </>
                ) : (
                  <td colSpan="3" />
                )}
                <td className="align-left persist-linebreaks">
                  {item.patientFirstname} {item.patientLastname}
                  {item.patientUniqueIdentifier && <br />}
                  {item.patientUniqueIdentifier}
                  {item.patientAddress && <br />}
                  {item.patientAddress}
                </td>
                <td className="align-left">{item.prescribedBy}</td>
                <td className="align-left break-words">
                  {(!eventConfig.groupBody || item.parentStockHistoryId) &&
                    item.comments}
                </td>
                <td className="break-words" title={item.signedBy}>
                  {truncateString(item.signedBy, SIGNATURE_DISPLAY_MAX_LENGTH)}
                </td>
                <td className="break-words" title={item.witnessedBy}>
                  {truncateString(
                    item.witnessedBy,
                    SIGNATURE_DISPLAY_MAX_LENGTH
                  )}
                </td>
                <td className="align-right quantity">
                  {!eventConfig.noBalance &&
                    item.balance !== null &&
                    renderQuantity(item.balance, item.drugQuantityUom)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const deleteAllDrugs = () => {
    setOrder([]);
  };

  const updateDrugUrgent = (drug) => {
    const urgentItem = order.map((item) => {
      if (item["tempId"] === drug["tempId"]) {
        const updatedItem = {
          ...item,
          isUrgent: !item["isUrgent"],
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(urgentItem);
  };

  const updateDrugPatient = (drug, patientInfo) => {
    const patientRelated = order.map((item) => {
      if (item["tempId"] === drug["tempId"]) {
        const updatedItem = {
          ...item,
          isPatient: patientInfo !== null,
          ptId: patientInfo ? patientInfo["ptId"] : null,
          ptName: patientInfo ? patientInfo["patientName"] : null,
          ptAddress: patientInfo ? patientInfo["patientAddress"] : null
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(patientRelated);
  };

  const updateDrugAmount = (drug, amount) => {
    const newItem = order.map((item) => {
      if (item["tempId"] === drug["tempId"]) {
        const updatedItem = {
          ...item,
          qtyRequested: parseFloat(amount),
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(newItem);
  };
  const setValidItems = (invalidItemList) => {
    const updatedOrders = order.map((item) => {
      if (invalidItemList.find((x) => x.key === item["tempId"])) {
        const updatedItem = {
          ...item,
          isValid: false,
        };
        return updatedItem;
      } else {
        const updatedItem = {
          ...item,
          isValid: true,
        };
        return updatedItem;
      }
    });
    setOrder(updatedOrders);
  };

  const setPharmacyStockRunningBalance = (drug, balance) => {
    const newItem = order.map((item) => {
      if (item["tempId"] === drug["tempId"]) {
        const updatedItem = {
          ...item,
          pharmacyStockRunningBalance: parseInt(balance),
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(newItem);
  };

  const updateSelectedLocation = (drug, selectedLocation, prescripedLocation) => {
    const newItem = order.map((item) => {
      if (item["tempId"] === drug["tempId"]) {
        const updatedItem = {
          ...item,
          prescribedLocationId: prescripedLocation,
          lcId:selectedLocation,
        };
        return updatedItem;
      }
      return item;
    });
    setOrder(newItem);
  };

  const hasDuplicateOrder = (orders) => {
    const lookup = orders.reduce((a, e) => {
      a[e.ptId + "_" + e.catalogueId] =
        ++a[e.ptId + "_" + e.catalogueId] || 0;
      return a;
    }, {});

    return orders.filter(
      (e) => lookup[e.ptId + "_" + e.catalogueId]
    );
  };

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function handleInputChange({ target: { checked, name, type, value } }) {
    setFormState((formState) => ({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
      valid: formRef.current.checkValidity(),
    }));
  }

  const checkIfValidCombinedBalance = (tempId) => {
    const itemOrder = order.find((x) => x.tempId === tempId);
    const relatedOrders = order.filter(
      (item) =>
        item["catalogueId"] === itemOrder.catalogueId
    );
    const combinedQtyRequested = relatedOrders
      .map((item) => item.qtyRequested)
      .reduce((prev, curr) => prev + curr, 0);

    if (combinedQtyRequested > itemOrder.pharmacyStockRunningBalance) {
      return {
        key: itemOrder.tempId,
        valid: false,
        combinedQuantitySupplied: combinedQtyRequested,
        drugName: itemOrder.cdcatalogueNameOrFormularyName,
        runningBalance: itemOrder.pharmacyStockRunningBalance,
        uom: itemOrder.doseUom,
      };
    }

    return { valid: true };
  };

  const sendOrder = () => {
    if (order.filter((item) => item["isPatient"] === false).length > 0) {
      setAlertMessage({
        message: "Please select a patient before submitting",
        colour: "danger",
      });
      setInputsConfirmed(false);
    } else {
      if (
        order.filter(
          (item) => item["qtyRequested"] <= 0 || !item["qtyRequested"]
        ).length > 0
      ) {
        setAlertMessage({
          message: "Please enter an amount for each order item",
          colour: "warning",
        });
        setInputsConfirmed(false);
      } else {
        var duplicateItems = hasDuplicateOrder(order);
        if (duplicateItems.length > 0) {
          setAlertMessage({
            message:
              "Duplicated order of " +
              duplicateItems[0].cdcatalogueNameOrFormularyName +
              " for " +
              duplicateItems[0].ptName,
            colour: "danger",
          });
          setInputsConfirmed(false);
        } else {
          let isValid = true;
          let invalidItemList = [];
          if (isPharmacy) {
            order.forEach((element) => {
              const check = checkIfValidCombinedBalance(element.tempId);

              if (!check.valid) {
                isValid = false;
                invalidItemList.push(check);
              }
            });
          }
          if (!isValid) {
            setValidItems(invalidItemList);
            const invalidDrugs = invalidItemList.map((o) => o.drugName);
            const filtered = invalidItemList.filter(
              ({ drugName }, index) =>
                !invalidDrugs.includes(drugName, index + 1)
            );
            let alertMessage = `Unable to complete supply, as not enough stock in balance to fulfil one or more orders:`;
            let invalidItemDetails = [];
            filtered.forEach((invalidItem) => {
              invalidItemDetails.push({
                key: invalidItem.key,
                message: `${invalidItem.drugName} has a request of ${invalidItem.combinedQuantitySupplied}
                                                      ${invalidItem.uom} to be supplied against 
                                                      ${invalidItem.runningBalance} ${invalidItem.uom} balance`,
              });
            });
            setAlertMessage({
              message: alertMessage,
              dataList: invalidItemDetails,
              colour: "warning",
            });
            setFormShowValidity(true);
            setInputsConfirmed(false);
            return;
          }

          const { valid, ...formData } = formState;
          const payload = order.map(({ tempId, ptName, ...item }) => ({
            ...item,
            isPharmacy,
            signedBy: confirmedStaff.signedBy,
            ...formData,
          }));
          if (locationRoute === "pharmacy") {
            OrdersService.createOrder(locationId, payload)
            .then((response) => {
              if (response.status === 201) {
                setAlertMessage({
                  message: "Your order has been sent",
                  colour: "success",
                  timeout: 5000,
                });

                const { catalogueId, cdVpId, cdApId } = payload[0];

                StockService.getPharmacyStock(locationId).then((res) => {
                  const pharmacyStock = res.data;
                  const foundItemIndex = pharmacyStock.findIndex(
                    (el) => el.catalogueId === catalogueId
                  );

                  if (foundItemIndex < 0) {
                    setAlertMessage({
                      message:
                        "Unable to redirect because no matching drug was found in stock",
                      colour: "warning",
                    });
                    setInputsConfirmed(false);

                    return;
                  }

                  history.push("/pharmacy/stock/administer", {
                    nxt: pharmacyStock[foundItemIndex],
                  });
                });
              } else {
                setAlertMessage({
                  message: `There was a problem creating the order, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
                setInputsConfirmed(false);
              }
            })
            .catch((error) => {
              if (error.response?.status === 401) {
                setConfirmedStaffErrors(error.response.data);
              } else {
                setAlertMessage({
                  message: `There was a problem creating the order, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
              }

              setInputsConfirmed(false);
            });
          }
          else {
            OrdersService.createOrderNamedPatient(locationId, payload)
            .then((response) => {
              if (response.status === 201) {
                setAlertMessage({
                  message: "Your order has been sent",
                  colour: "success",
                  timeout: 5000,
                });

                  redirectTimeoutId = setTimeout(() => {
                    history.push(`/${locationRoute}/dashboard`);
                  }, 5000);
              } else {
                setAlertMessage({
                  message: `There was a problem creating the order, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
                setInputsConfirmed(false);
              }
            })
            .catch((error) => {
              if (error.response?.status === 401) {
                setConfirmedStaffErrors(error.response.data);
              } else {
                setAlertMessage({
                  message: `There was a problem creating the order, the eCDR-Pro system may be offline.
                    If unable to resolve contact IT service desk.`,
                  colour: "danger",
                });
              }

              setInputsConfirmed(false);
            });
          }

        }
      }
    }
  };

  return (
    <>
      <Col className="CreateOrderNamedPatient">
        {alertMessage["message"] && (
          <CAlert
            color={alertMessage["colour"]}
            className="shadow"
            closeButton
            onClick={() =>
              setAlertMessage({
                message: "",
                colour: "",
              })
            }
          >
            {alertMessage["message"]}
            {alertMessage["dataList"] &&
              alertMessage["dataList"].map((item) => {
                return (
                  <span key={item.key}>
                    <hr />
                    <div>{item.message}</div>
                  </span>
                );
              })}
          </CAlert>
        )}
        <Row>
          <Col md="6">
            <h1>
              {" "}
              {locationRoute === "ward"
                ? "Create new order for Named Patient"
                : "Dispense prescription"}
            </h1>
          </Col>
          <Col md="6 d-flex align-items-end flex-column">
            <Button
              color="primary"
              className="shadow-sm"
              onClick={(e) => {
                e.preventDefault();
                deleteAllDrugs();
              }}
            >
              CLEAR ALL ITEMS
              <FontAwesomeIcon className="on-right" icon={faTrashAlt} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="6" xl="4">
            <DrugSearchList
              searchListClass="search-list-fixed"
              setAlertMessage={setAlertMessage}
              setSelectedDrug={setSelectedDrug}
              selectableSearch
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table hover>
              <thead>
                <tr>
                  {isPharmacy ? (
                    <>
                      <th colSpan="3">Order items</th>
                      <th>
                        <span className="running-balance">Running Balance</span>
                      </th>
                      <th>
                        <span className="running-balance"></span>
                      </th>
                    </>
                  ) : (
                    <th colSpan="5">Order items</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {order.map((od, odx) => (
                  <CreateOrderPadLine
                    key={od.tempId}
                    nxt={od}
                    deleteDrug={deleteDrug}
                    updateDrugAmount={updateDrugAmount}
                    updateDrugUrgent={updateDrugUrgent}
                    updateDrugPatient={updateDrugPatient}
                    setDisable={setDisable}
                    isPatient={true}
                    isPharmacy={isPharmacy}
                    locationList={locations}
                    setSelectedLocation={updateSelectedLocation}
                    setAlertMessage={setAlertMessage}
                    setPharmacyStockRunningBalance={
                      setPharmacyStockRunningBalance
                    }
                    setStockPharmacyHistoryId={setHistoryStockId}
                    stockPharmacyHistoryId={historyStockId}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {locationRoute === "pharmacy" && order.length > 0 ? (
          <Row>
            <Form
              className={cx("form align-right", {
                "show-validity": formShowValidity,
              })}
              innerRef={formRef}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <FormGroup>
                <Label id="prescribed-by" label="Prescriber Name" required />
                <Input
                  autoComplete="off"
                  id="prescribed-by"
                  name="prescribedBy"
                  onChange={handleInputChange}
                  required
                  value={formState.prescribedBy}
                />
              </FormGroup>
            </Form>
          </Row>
        ) : null}
        {order.length > 0 && (
          <ConfirmBlock
            confirmedStaffErrors={confirmedStaffErrors}
            confirmInitialState={ordersrules.confirmInitialState.CreateOrder}
            inputsConfirmed={inputsConfirmed}
            setInputsConfirmed={setInputsConfirmed}
            setConfirmedStaff={setConfirmedStaff}
            setFormShowValidity={setFormShowValidity}
          />
        )}
        {stockHistory.length > 0 && (
          <Row className="prescription-stock-history">
            <h5>History</h5>
            <ButtonToggle
              active={showOptionalHistoryEvents}
              className="history-btn btn-pill"
              color="primary"
              onClick={handleHistoryButtonClick}
              outline
            >
              {showOptionalHistoryEvents ? "Hide" : "Show"} Order History
            </ButtonToggle>
            {renderHistory()}
          </Row>
        )}
      </Col>
    </>
  );
};

export default React.memo(CreateOrderNamedPatient);
