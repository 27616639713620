import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { CAlert, CButton } from "@coreui/react";
import cx from "classnames";
import { useSelector } from "react-redux";

import Label from "../../component/atoms/Label";
import CheckableInputs from "../../component/molecules/CheckableInputs";
import OrderTableHeader from "../../component/molecules/OrderTableHeader";
import OrderTableBodyMultiselect from "../../component/molecules/OrderTableBodyMultiselect";
import ConfirmBlockOrders from "../../component/organisms/ConfirmBlockOrders";

import SystemContext from "../../context/SystemContext";
import OrdersService from "../../services/OrdersService";
import { getOrdersRules } from "../../config/_ordersrules";
import { getDateValue } from "../../helpers";

const REFRESH_INTERVAL = 30000;
const requestedList = [
  { label: "Yes", name: "requested", value: "yes" },
  { label: "No", name: "requested", value: "" },
];
const providedList = [
  { label: "Yes", name: "provided", value: "yes" },
  { label: "No", name: "provided", value: "" },
];
const formInitialState = {
  collectedBy: "",
  collectedByAddress: "",
  missingIdReason: undefined,
  provided: undefined,
  requested: undefined,
};

let intervalId;
let messageTimeoutId;

const AllOrders = () => {
  const mgId = useSelector((state) => state.settings.mgId);
  const ordersrules = getOrdersRules(mgId);

  const { locationRoute, user } = useContext(SystemContext);

  const hashFilter = useLocation();
  const history = useHistory();

  const formRef = useRef();

  const locationName = "location";
  const locationNameKey = "name";

  const messageState = useState({
    message: "",
    colour: "",
  });
  const initialFilters = [
    { name: "default", text: "All", state: true },
    { name: "notpatient", text: "Stock Orders", state: false },
    { name: "patient", text: "Patient Orders", state: false },
    { name: "prescription", text: "Prescriptions", state: false },
    { name: "urgent", text: "Urgent", state: false },
  ];

  const [filters, setFilters] = useState(initialFilters); // patient, notpatient,  urgent
  const [currentFilter, setCurrentFilter] = useState("default"); // default || patient || notpatident || urgent
  const [filterClass, setFilterClass] = useState("default"); // filter class

  const [status, setStatus] = useState(
    ordersrules.initialStatusView[locationRoute]
  );
  const [loadOrders, setLoadOrders] = useState({ state: true, refresh: false });
  const [loadStatus, setLoadStatus] = useState("");

  const [currentView, setCurrentView] = useState("default"); // default || single
  const [currentStatus, setCurrentStatus] = useState("default"); // default transit || register || pharmacy || rejected

  const [alertMessage, setAlertMessage] = useState(messageState);
  const [isHasChanged, setIsHasChanged] = useState(false);

  const [orderSet, setOrderSet] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [formShowValidity, setFormShowValidity] = useState(false);
  const [formState, setFormState] = useState(formInitialState);

  const [isPrescriptionOrdersSelected, setIsPrescriptionOrdersSelected] =
    useState(false);
  const [isNamedOrdersSelected, setIsNamedOrdersSelected] = useState(false);
  const [isCollectedByAlreadyFilled, setIsCollectedByAlreadyFilled] =
    useState(false);

  // orders list ordering
  const getAllOrderItems = () => {
    OrdersService.getAllOrders(user.location.lcId).then(({ data }) => {
      // Group by location/prescribedLocation first (lower lcId's first), then isUrgent (urgent first), then by createdAt (newest first)
      const sortedOrders = data.sort((a, b) => {
        if (
          (a.prescribedLocationId ?? a.lcId) ===
          (b.prescribedLocationId ?? b.lcId)
        ) {
          if (a.isUrgent === b.isUrgent) {
            return getDateValue(b.createdAt) - getDateValue(a.createdAt);
          }

          return b.isUrgent - a.isUrgent;
        }

        return (
          (a.prescribedLocationId ?? a.lcId) -
          (b.prescribedLocationId ?? b.lcId)
        );
      });

      setOrders(
        sortedOrders.map((drg) => {
          return {
            odId: drg["odId"],
            cdApId: drg["cdCatalogue"]["id"],
            cdVpId: drg["cdCatalogue"]["virtualId"],
            lcId: drg["lcId"],
            catalogueId: drg["catalogueId"],
            siteId: drg["siteId"],
            stId: drg["stId"],
            qtyRequested: drg["qtyRequested"],
            qtySupplied: drg["qtySupplied"],
            isPatient: drg["isPatient"],
            isUrgent: drg["isUrgent"],
            reference: drg["reference"],
            createdAt: drg["createdAt"],
            createdBy: drg["createdBy"],
            cdCatalogue: drg["cdCatalogue"],
            status: drg["status"],
            location: drg["location"],
            prescribedLocation: drg["prescribedLocation"],
            orderComments: drg["orderComments"],
            orderStatuses: drg["orderStatuses"],
            patient: drg["patient"],
            pharmacyStockRunningBalance: drg["pharmacyStockRunningBalance"],
            isValid: true,
          };
        })
      );
      setIsHasChanged(false);
    });
  };

  // get order list when location is updated
  useEffect(() => {
    if (loadOrders.refresh) {
      history.push(hashFilter.pathname);

      status.map((nm) => (nm["state"] = false));
      filters.map((nm) => (nm["state"] = false));

      setCurrentStatus("default");
      setCurrentFilter("default");
    }
    if (loadOrders.state) {
      getAllOrderItems();
      setLoadOrders({ state: false, refresh: false });
      setIsHasChanged(false);
      setOrderSet([]);

      setFormShowValidity(false);
      setFormState(formInitialState);
    }
  }, [loadOrders]);

  // get page loaded status
  useEffect(() => {
    const ordersFilter = history.location?.state?.ordersFilter;
    const loadedStatus = hashFilter.hash.split("#")[1];
    setIsHasChanged(false);

    if (ordersFilter) {
      if (
        ordersFilter.currentStatus &&
        ordersFilter.currentStatus !== "default"
      ) {
        const historyCurrentStatusName =
          ordersFilter.currentStatus.split("-")[0];
        status.map((nm) =>
          nm["name"] === historyCurrentStatusName
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );
        setCurrentStatus(ordersFilter.currentStatus);

        if (
          historyCurrentStatusName === "RejectedByPharmacy" ||
          historyCurrentStatusName === "RejectedByWard"
        ) {
          setLoadStatus("Rejected");
        } else {
          setLoadStatus(historyCurrentStatusName);
        }
      }
      if (ordersFilter.currentFilter) {
        const historyCurrentFilterName =
          ordersFilter.currentFilter.split("-")[0];
        const viewFilters = filters;

        viewFilters.map((nm) =>
          nm["name"] === historyCurrentFilterName
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );

        setFilters(viewFilters);
        setCurrentFilter(`${historyCurrentFilterName}-true`);
      }
    } else {
      if (loadedStatus) {
        setLoadStatus(
          loadedStatus.charAt(0).toUpperCase() + loadedStatus.slice(1)
        );
        status.map((nm) =>
          nm["name"] === loadedStatus
            ? (nm["state"] = true)
            : (nm["state"] = false)
        );
        setCurrentStatus(loadedStatus + "-true");
      } else {
        status.map((nm) => (nm["state"] = false));
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // update filtered orders on orders change
  useEffect(() => {
    const viewStatus = currentStatus.split("-")[0];

    setFilteredOrders([]);

    if (viewStatus) {
      if (viewStatus === "default") {
        setFilteredOrders(orders);
      } else if (viewStatus.includes("Rejected")) {
        setTimeout(() => {
          setFilteredOrders( getRejectedOrders(orders)
          );
        }, 100);
      } else {
        setTimeout(function () {
          setFilteredOrders(
            orders.filter((item) => item["status"]["name"] === viewStatus)
          );
        }, 100);
      }
    } else {
      setTimeout(function () {
        setFilteredOrders(
          orders.filter(
            (item) =>
              item["status"]["name"] !== "ReadyToRegister" &&
              item["status"]["name"] !== "Registered"
          )
        );
      }, 100);
    }
    return () => {};
  }, [orders]);

  useEffect(() => {
    if (currentFilter) {
      setFilterClass(
        currentFilter.split("-")[1] === "true"
          ? "show-" + currentFilter.split("-")[0]
          : ""
      );
      return () => {};
    }
  }, [currentFilter]);

  useEffect(() => {
    const hasPrescriptionOrders = !!orderSet.find(
      (el) => el.isPatient && el.isPharmacyLocation
    );
    const hasNamedOrders = !!orderSet.find(
      (el) => (el.isPatient && !el.isPharmacyLocation) || !el.isPatient
    );

    if (
      isPrescriptionOrdersSelected !== hasPrescriptionOrders ||
      isNamedOrdersSelected !== hasNamedOrders
    ) {
      setFormState(formInitialState);
      setFormShowValidity(false);
    }

    setIsPrescriptionOrdersSelected(hasPrescriptionOrders);
    setIsNamedOrdersSelected(hasNamedOrders);
  }, [orderSet]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage(messageState),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    if (formRef.current) {
      setFormState((formState) => ({
        ...formState,
        valid: formRef.current.checkValidity(),
      }));
    }
  }, [formState.provided]);

  useEffect(() => {
    setIsCollectedByAlreadyFilled(!!formState.collectedBy);
  }, [formState.collectedBy, isNamedOrdersSelected]);

  const checkIfValid = (qtySupplied, orderId) => {
    if (
      qtySupplied > filteredOrders.find((x) => x.odId === orderId).qtyRequested
    ) {
      filteredOrders.find((x) => x.odId === orderId).isValid = false;
      setFilteredOrders(filteredOrders);
      return false;
    }
    filteredOrders.find((x) => x.odId === orderId).isValid = true;
    setFilteredOrders(filteredOrders);
    return true;
  };

  const checkIfValidCombinedBalance = (completeLine, orderId) => {
    const order = filteredOrders.find((x) => x.odId === orderId);
    const relatedOrders = completeLine.filter(
      (item) => item["catalogueId"] === order.catalogueId
    );
    const combinedQtySupplied = relatedOrders
      .map((item) => item.qtySupplied)
      .reduce((prev, curr) => prev + curr, 0);

    if (
      combinedQtySupplied >
      filteredOrders.find((x) => x.odId === orderId).pharmacyStockRunningBalance
    ) {
      filteredOrders.find((x) => x.odId === orderId).isValid = false;
      setFilteredOrders(filteredOrders);
      return {
        valid: false,
        combinedQuantitySupplied: combinedQtySupplied,
        drugName: order.cdCatalogue.cdcatalogueNameOrFormularyName,
        runningBalance: order.pharmacyStockRunningBalance,
        uom: order.cdCatalogue.doseUom,
      };
    }
    filteredOrders.find((x) => x.odId === orderId).isValid = true;
    setFilteredOrders(filteredOrders);
    return { valid: true };
  };

  // signOff box states
  function renderConfirmBlock() {
    const viewStatus = currentStatus.split("-")[0];
    const disableCollectedBy = isCollectedByAlreadyFilled;
    const disableSignedBy = viewStatus === "ReadyForCollection" && !formState.collectedBy;

    if (viewStatus === "Pharmacy" || viewStatus === "ReadyForCollection") {
      return (
        <ConfirmBlockOrders
          col2StyleClass={`${
            viewStatus === "Pharmacy" ? "d-flex align-items-center" : ""
          }`}
          setLoadOrders={setLoadOrders}
          loadStatus={{ state: true, refresh: false }}
          orderArray={orderSet}
          blockTitle={
            ordersrules["initialStatusView"]["statusSet"].find(
              (obj) => obj["name"] === viewStatus
            )["confirmationBlock"]
          }
          currentStatus={currentStatus}
          confirmInitialState={ordersrules.confirmInitialState}
          messageState={messageState}
          setAlertMessage={setAlertMessage}
          isHasChanged={isHasChanged}
          checkIfValid={checkIfValid}
          disableCollectedBy={disableCollectedBy}
          disableSignedBy={disableSignedBy}
          formState={
            isNamedOrdersSelected
              ? { collectedBy: formState.collectedBy, valid: formState.valid }
              : formState
          }
          setFormShowValidity={setFormShowValidity}
          checkIfValidBalance={checkIfValidCombinedBalance}
        />
      );
    } else return null;
  }

  // updated the page filters
  const updateFilter = (set) => {
    if (currentFilter !== `${set["name"]}-${set["state"]}`) {
      const viewFilters = filters;

      viewFilters.map((nm) =>
        nm["name"] === set["name"]
          ? (nm["state"] = !set["state"])
          : (nm["state"] = false)
      );

      setFilters(viewFilters);
      setCurrentFilter(`${set["name"]}-${set["state"]}`);
    }
  };

  const getRejectedOrders = (orders) => orders.filter(
    (item) => item["status"]["name"] === "RejectedByPharmacy" ||
      item["status"]["name"] === "RejectedByWard" ||
      item["status"]["name"] === "Cancelled"
  );

  // update status using buttons
  const updateStatus = (set) => {
    // Back out if we are already selected
    if (currentStatus.split("-")[0] === set["name"]) return;

    // gets array of status used in buttons
    const viewStatus = status;

    // sets the current status as true
    viewStatus.map((nm) =>
      nm["name"] === set["name"]
        ? (nm["state"] = !set["state"])
        : (nm["state"] = false)
    );

    const newStatus =
      set["state"] && set["name"] !== "default"
        ? set["name"] + "-" + set["state"]
        : "default";

    setCurrentStatus(newStatus);

    const current = newStatus;
    const look = set["name"];
    setFilteredOrders([]);
    setOrderSet([]);

    if (look && current !== "default") {
      if (
        set["name"] === "RejectedByPharmacy" ||
        set["name"] === "RejectedByWard"
      ) {
        history.push(hashFilter.pathname + "#Rejected");
        setTimeout(function () {
          setFilteredOrders(
            getRejectedOrders(orders)
          );
        }, 50);
      } else {
        history.push(hashFilter.pathname + "#" + set["name"]);
        setTimeout(function () {
          setFilteredOrders(
            orders.filter((item) => item["status"]["name"] === look)
          );
        }, 50);
      }
    } else {
      history.push(hashFilter.pathname);
      setTimeout(function () {
        setFilteredOrders(
          orders.filter(
            (item) =>
              item["status"]["name"] !== "ReadyToRegister" &&
              item["status"]["name"] !== "Registered"
          )
        );
      }, 100);
    }
  };

  // update order conentent when a table row is changed
  const updateRows = (row) => {
    // if orderSet exists
    if (orderSet) {
      // check if row already exists in orderSet
      if (orderSet.find((od) => od["odId"] === row["odId"])) {
        const item = orderSet.map((item) => {
          if (item["odId"] === row["odId"]) {
            const updatedItem = {
              ...item,
              qtySupplied: row["qtySupplied"],
              isInvalid: row["isInvalid"],
            };
            setIsHasChanged(true);

            return updatedItem;
          }
          return item;
        });

        // if it does update object in array
        setOrderSet(item);
      } else {
        // if it doesn't add item to array
        setOrderSet([...orderSet, row]);
        setIsHasChanged(true);
      }
    } else {
      // create order set
      setOrderSet([row]);
      setIsHasChanged(true);
    }
  };

  const deleteRow = (odId) => {
    const remaining = orderSet.filter((od) => od["odId"] !== odId);
    setOrderSet(remaining);

    if (!remaining.length) {
      setIsHasChanged(false);
    }
  };

  function handleInputChange({ target: { checked, name, type, value } }) {
    setFormState((formState) => ({
      ...formState,
      [name]:
        type === "checkbox"
          ? checked
          : type === "radio"
            ? Boolean(value)
            : value,
      valid: formRef.current.checkValidity(),
    }));
  }

  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function renderForm() {
    if (
      currentStatus.split("-")[0] === "ReadyForCollection" &&
      filteredOrders.length
    ) {
      if (isPrescriptionOrdersSelected) {
        return (
          <Row>
            <Form
              className={cx("form align-right", {
                "show-validity": formShowValidity,
              })}
              innerRef={formRef}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <FormGroup>
                <Label label="Proof of Identity" />
                <Label label="Requested" required id="requested" />
                <CheckableInputs
                  className="identity-proof-inputs"
                  inline
                  list={requestedList}
                  onChange={handleInputChange}
                  type="radio"
                />
              </FormGroup>
              <FormGroup>
                <Label label="Provided" required id="provided" />
                <CheckableInputs
                  className="identity-proof-inputs"
                  inline
                  list={providedList}
                  onChange={handleInputChange}
                  type="radio"
                />
              </FormGroup>
              <FormGroup>
                <Label
                  id="missing-id-reason"
                  label="Record justification for supply without ID"
                  required={!formState.provided}
                  disabled={formState.provided}
                />
                <Input
                  autoComplete="off"
                  id="missing-id-reason"
                  name="missingIdReason"
                  onChange={handleInputChange}
                  required={!formState.provided}
                  value={formState.missingIdReason}
                  disabled={formState.provided}
                />
              </FormGroup>
              <FormGroup>
                <Label id="collected-by" label="Collected by" required />
                <Input
                  autoComplete="off"
                  id="collected-by"
                  name="collectedBy"
                  onChange={handleInputChange}
                  required
                  value={formState.collectedBy}
                />
              </FormGroup>
              <FormGroup>
                <Label id="collected-by-address" label="Address" required />
                <Input
                  autoComplete="off"
                  id="collected-by-address"
                  name="collectedByAddress"
                  onChange={handleInputChange}
                  required
                  type="textarea"
                  value={formState.collectedByAddress}
                />
              </FormGroup>
            </Form>
          </Row>
        );
      }

      if (isNamedOrdersSelected) {
        return (
          <Row>
            <Form
              className={cx("form align-right", {
                "show-validity": formShowValidity,
              })}
              innerRef={formRef}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <FormGroup>
                <Label id="collected-by" label="Collected by" required />
                <Input
                  autoComplete="off"
                  id="collected-by"
                  name="collectedBy"
                  onChange={handleInputChange}
                  required
                  value={formState.collectedBy}
                />
              </FormGroup>
            </Form>
          </Row>
        );
      }
    }

    return null;
  }

  function getOrderedOrders() {
    //group into location and split by
    const groupedOrders = groupByArray(
      filteredOrders,
      locationName,
      locationNameKey
    );
    const urgentOrders = groupedOrders
      .map((x) => x.values)
      .filter((x) => x.filter((y) => y.isUrgent === true).length > 0);
    const nonUrgentOrders = groupedOrders
      .map((x) => x.values)
      .filter((x) => x.filter((y) => y.isUrgent === true).length === 0);

    // Order the urgent orders by isUrgentFlag then by date
    const sortedUrgent = urgentOrders.map((x) => ({
      objects: x
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .sort((a) => (a.isUrgent === true ? -1 : 1)),
      oldestDate: x.reduce((r, o) => (o.createdAt < r.createdAt ? o : r))
        .createdAt,
    }));
    const sortedUrgentGroups = sortedUrgent.sort(
      (a, b) => new Date(a.oldestDate) - new Date(b.oldestDate)
    );
    const flattenedGroupUrgentOrders = sortedUrgentGroups
      .map((x) => x.objects)
      .flat();

    const sortedNonUrgent = nonUrgentOrders.map((x) => ({
      objects: x.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
      oldestDate: x.reduce((r, o) => (o.createdAt < r.createdAt ? o : r))
        .createdAt,
    }));
    const sortedNonUrgentGroups = sortedNonUrgent.sort(
      (a, b) => new Date(a.oldestDate) - new Date(b.oldestDate)
    );
    const flattenedGroupNonUrgentOrders = sortedNonUrgentGroups
      .map((x) => x.objects)
      .flat();

    return [...flattenedGroupUrgentOrders, ...flattenedGroupNonUrgentOrders];
  }

  function groupByArray(array, key, subkey = null) {
    return array.reduce(function (rv, x) {
      const v =
        key instanceof Function ? key(x) : subkey ? x[key][subkey] : x[key];
      const el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  return (
    <Col className={`AllOrders AllOrders-${locationRoute}`}>
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
          {alertMessage["dataList"] &&
            alertMessage["dataList"].map((item) => {
              return (
                <>
                  <hr />
                  <div key={item.message}>{item.message}</div>
                </>
              );
            })}
        </CAlert>
      )}
      <Row>
        <Col md="3" className="AllOrders-set-title">
          <h1>Orders overview</h1>
        </Col>
        <Col
          md="9"
          className="d-flex align-items-center justify-content-end flex-row AllOrders-set-view"
        >
          Set table view :
          {status.map((vit, vdx) => (
            <CButton
              key={vdx}
              className={`shadow-sm ml-2 ${
                vit["state"] ? "btn-on" : "btn-off"
              }`}
              variant="outline"
              shape="pill"
              color="primary"
              name={vit["name"]}
              onClick={(e) => {
                e.preventDefault();
                updateStatus(vit);
              }}
            >
              {vit["button"]}
            </CButton>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className={filterClass}>
          <Table hover>
            <OrderTableHeader
              filters={filters}
              currentStatus={currentStatus}
              updateFilter={updateFilter}
              currentView={currentView}
              locationRoute={locationRoute}
            />
            <OrderTableBodyMultiselect
              orders={getOrderedOrders()}
              locationRoute={locationRoute}
              ordersrules={ordersrules}
              currentView={currentView}
              currentStatus={currentStatus}
              currentFilter={currentFilter}
              updateRows={updateRows}
              deleteRow={deleteRow}
              ordersFilter={{ currentFilter, currentStatus }}
              setAlertMessage={setAlertMessage}
              isPrescriptionOrdersSelected={isPrescriptionOrdersSelected}
              isNamedOrdersSelected={isNamedOrdersSelected}
            />
          </Table>
          {orders.length === 0 && (
            <span className="h4 mt-4">
              * There are currently no orders related to this ward *
            </span>
          )}
        </Col>
      </Row>
      {renderForm()}
      {renderConfirmBlock()}
    </Col>
  );
};

export default React.memo(AllOrders);
