import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { CAlert } from "@coreui/react";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Location from "../../component/molecules/Pharmacy/Location";

import SystemContext from "../../context/SystemContext";
import LocationsService from "../../services/LocationsService";
import SearchInput from "../../component/molecules/SearchInput";

let messageTimeoutId;

const StockAuditManagement = () => {
  const { user, locationRoute } = useContext(SystemContext);

  const [alertMessage, setAlertMessage] = useState({
    message: "",
    colour: "danger",
  });

  // const siteId;
  const [locations, setlocations] = useState([]);
  const [searchResults, setsearchResults] = useState([]);
  const [searchValue, setsearchValue] = useState("");

  useEffect(() => {
    LocationsService.getLocations(user["location"]["siteId"], {
      params: { incPharm: true },
    })
      .then((response) => {
        setlocations(response.data);
        setsearchResults(response.data);
      })
      .catch((error) => {
        setAlertMessage({
          message: `Ward list is unavailable right now, the eCDR-Pro system may be offline.
            If unable to resolve contact IT service desk.`,
          colour: "danger",
        });
      });
  }, []);

  useEffect(() => {
    if (searchValue.length < 1) {
      setsearchResults(locations);
    } else {
      setsearchResults((locations) =>
        locations.filter((item) =>
          item["name"].toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue]);

  useEffect(() => {
    clearTimeout(messageTimeoutId);

    if (alertMessage.timeout) {
      messageTimeoutId = setTimeout(
        () => setAlertMessage({}),
        alertMessage.timeout
      );
    }
  }, [alertMessage]);

  return (
    <Col className="StockAuditManagement">
      {alertMessage["message"] && (
        <CAlert color={alertMessage["colour"]} className="shadow" closeButton>
          {alertMessage["message"]}
        </CAlert>
      )}
      <Row className="mb-4">
        <Col>
          <h1>CD Compliance Audit locations</h1>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 2 }}>
          <SearchInput
            name="location search"
            type="text"
            placeholder="Ward search"
            value={setsearchValue}
          />
        </Col>
        <Col className="d-flex justify-content-end" md={{ size: 5, offset: 1 }}>
          <Link
            className="shadow-sm btn btn-primary btn-icon mr-5"
            to="/pharmacy/audit/create/new-line"
          >
            ADD NEW AUDIT LINE
            <FontAwesomeIcon className="on-right" icon={faClipboard} />
          </Link>
          <Link
            className="shadow-sm btn btn-primary btn-icon"
            to="/pharmacy/audit/create"
          >
            APPLY MASTER LIST
            <FontAwesomeIcon className="on-right" icon={faClipboard} />
          </Link>
        </Col>
      </Row>
      <Row className="location-list">
        {searchResults.map((loc, ldx) => (
          <Col md="3" key={ldx}>
            <Location
              key={ldx}
              name={loc.name + (loc.isOnline ? "" : " (Offline)") }
              lcId={loc.lcId}
              isOnline={loc.isOnline}
              url={`/pharmacy/audit/${loc.lcId}`}
              copyUrl={`/pharmacy/audit/clone/${loc.lcId}`}
            />
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default React.memo(StockAuditManagement);
